import React from 'react';
import { useTranslation } from 'react-i18next';
import truncateString from '../utils/truncateString';
import determineCalloutOffsets from '../utils/determineCalloutOffsets';
import { OrderingSelectors } from 'polygon-ordering';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { MEMBER_MODAL_ID } from '../modals/MemberModal';
import { NON_MEMBER_MODAL_ID } from '../modals/NonMemberModal';
import { getLoyaltyDropdownMenu } from '../utils/getAvailableScreens';
import StandardButton from './StandardButton';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { PURCHASE_GIFT_CARD_ROUTE } from '../screens/PurchaseGiftCardScreen';
import { SIGN_IN_MODAL_ID } from '../modals/SignInModal';

const { getMember } = OrderingSelectors;

const HeaderMemberButton: React.FC = () => {
  const member = useAppSelector(getMember);
  const currentModalId = useAppSelector(state => state.currentModalId);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selfRef = React.useRef<HTMLDivElement>(null);
  const screens = getLoyaltyDropdownMenu();
  const memberModalOpen = currentModalId === MEMBER_MODAL_ID;
  const nonMemberModalOpen = currentModalId === NON_MEMBER_MODAL_ID;
  const isGiftcardPurchaseEnabled = useAppSelector(state => state.config.enableGiftcardPurchase);
  const availableRoutes = isGiftcardPurchaseEnabled
    ? screens.filter(screen => screen.path === PURCHASE_GIFT_CARD_ROUTE)
    : [];

  return (
    <StandardButton
      ref={selfRef}
      invisibleContainer
      onClick={() => {
        const newModalId = member
          ? memberModalOpen
            ? null
            : MEMBER_MODAL_ID
          : nonMemberModalOpen
          ? null
          : availableRoutes.length !== 0
          ? NON_MEMBER_MODAL_ID
          : SIGN_IN_MODAL_ID;
        dispatch(
          setCurrentModal({
            modalId: newModalId,
            calloutOffsets:
              newModalId === MEMBER_MODAL_ID || NON_MEMBER_MODAL_ID
                ? determineCalloutOffsets(
                    (
                      selfRef as React.MutableRefObject<HTMLDivElement>
                    ).current.getBoundingClientRect(),
                  )
                : null,
          }),
        );
      }}
      label={
        member
          ? truncateString((member.givenName || '').toUpperCase(), deviceTypeMobile ? 10 : 20) ||
            t('memberNameFallback')
          : t('button.headerMemberAbsent')
      }
      themeKey="headerMemberButton"
      showCaret={member ? true : availableRoutes.length !== 0 ? true : false}
      reverseCaret={memberModalOpen || nonMemberModalOpen}
      containerStyle={memberModalOpen || nonMemberModalOpen ? styles.modalOpen : undefined}
    />
  );
};

const styles: Styles = {
  modalOpen: {
    opacity: 0.5,
  },
};

export default HeaderMemberButton;
