import lodash from 'lodash';
// import { v4 as uuidv4 } from 'uuid';

import { safeString } from '../misc';

import processItem from './processItem';
import processOrderingImagePath from './processOrderingImagePath';
import processImageMap from './processImageMap';
import Logger from '../Logger';

export default function processMenu(
  rawMenu: RawMenu,
  top?: boolean,
  excludedChoiceSets?: string[],
): [MenuNode | undefined, _Items, ChoiceSets, string[]] {
  let allItems: _Items = {};
  let menuSpecificItemIds: string[] = [];
  let subMenus: MenuNode[] = [];
  let allChoiceSets: ChoiceSets = {};
  let allUpsells: string[] = [];
  let menu;

  const name = top ? 'main' : (rawMenu.KeyText || '').trim();

  try {
    (rawMenu.Submenus || []).forEach((rawSubMenu, index) => {
      try {
        const [subMenu, subMenuItems, subMenuChoiceSets, subMenuUpsells] =
          processMenu(rawSubMenu, false, excludedChoiceSets);

        subMenus = lodash.compact([...subMenus, subMenu]);
        allItems = { ...allItems, ...subMenuItems };
        allChoiceSets = { ...allChoiceSets, ...subMenuChoiceSets };
        allUpsells = [...allUpsells, ...subMenuUpsells];
      } catch (e) {
        Logger.log(
          `failed to process submenu "${rawSubMenu.KeyText}" of "${name}" keypad`,
          'error',
          e,
        );
      }
    });
  } catch (e) {
    Logger.log(`failed to process submenus of "${name}" keypad`, 'error', e);
  }

  try {
    (rawMenu.PLUs || []).forEach(rawItem => {
      try {
        if (rawItem.Available) {
          const [processedItems, processedItemChoiceSets] = processItem(
            rawItem,
            excludedChoiceSets,
          );

          menuSpecificItemIds = [
            ...menuSpecificItemIds,
            String(rawItem.PLUCode),
          ];
          allItems = { ...allItems, ...processedItems };
          allChoiceSets = { ...allChoiceSets, ...processedItemChoiceSets };
        }
      } catch (e) {
        Logger.log(
          `failed to process plu "${rawItem.LongName}" (id: ${rawItem.PLUCode}) of "${name}" keypad`,
          'error',
          e,
        );
      }
    });
  } catch (e) {
    Logger.log(`failed to process plus of "${name}" keypad`, 'error', e);
  }

  const items = lodash.uniq(menuSpecificItemIds);

  let brandId;

  // negative numbers not falsey
  if (rawMenu.BrandID != null && rawMenu.BrandID >= 0) {
    brandId = String(rawMenu.BrandID);
  }

  if (rawMenu.UpSell) {
    allUpsells = lodash.uniq([...allUpsells, ...items]);
  } else {
    menu = {
      id: `${safeString(name)}${brandId ? `-${brandId}` : ''}`,
      shortName: name.split(' ')[0],
      name,
      subMenus,
      items,
      colour: rawMenu.BColour ? `#${rawMenu.BColour}` : undefined,
      images: {
        default: processOrderingImagePath(rawMenu.ImagePath),
        ...processImageMap(rawMenu.ImageMap),
      },
      brandId,
    };
  }

  // @Feat menu-perform-cache: Log to see how long it takes to process the menu
  Logger.log(`@@@ finished processing the menu @@@`);

  return [menu, allItems, allChoiceSets, allUpsells];
}
