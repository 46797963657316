import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import lodash from 'lodash';

import * as applyBarcode from '../../actionCreators/flows/applyBarcode';
import * as fetchPOSPoints from '../../actionCreators/flows/fetchPOSPoints';
import * as setMember from '../../actionCreators/flows/setMember';
import * as stagePurchaseFromItem from '../../actionCreators/flows/stagePurchaseFromItem';

import { addToShadowCart } from '../../actionCreators/shadowOrder';
import { addOffers, setOfferId } from '../../actionCreators/currentOrder';

import getLocationId from '../../selectors/getLocationId';
import getItems from '../../selectors/getItems';

import { FAILURE_REASON } from '../../constants/failureReasons';

import {
  requestAndWaitForFlow,
  makeErrorSerialisable,
  createFlowApprover,
} from '../../utils/sagas';

export const requested = createFlowApprover(applyBarcode);

export function* approved(
  action: ReturnType<typeof applyBarcode.actions.approved>,
) {
  const {
    payload: { barcode, locationId: actionLocationId },
    meta: { flowId },
  } = action;

  let reason: applyBarcode.FailureReason;

  try {
    const locationId = actionLocationId || (yield select(getLocationId));

    if (!locationId) {
      throw new Error('no location id');
    }

    const payloads = yield call(
      requestAndWaitForFlow,
      fetchPOSPoints,
      { barcode, locationId },
      flowId,
    );

    if (!payloads.success) {
      reason = lodash.get(
        payloads,
        'failure.reason',
        FAILURE_REASON.FETCH_FAILED,
      );
      throw new Error('pos points fetch failed');
    }

    const { member, offer, giftCard, itemDetails } = payloads.success as {
      offer: Offer;
      member: Member;
      giftCard: _GiftCard;
      itemDetails: ItemLookupResult;
    };

    if (member) {
      yield call(requestAndWaitForFlow, setMember, { member }, flowId);
    }

    if (offer) {
      yield put(addOffers([offer]));
      yield put(setOfferId(offer.id));
    }

    let itemAdded;

    if (itemDetails) {
      const allItems: Items | undefined = yield select(getItems);

      if (!allItems) {
        // no menu data yet, add item to shadow cart

        const shadowItem = {
          id: itemDetails.id,
          name: itemDetails.name,
          quantity: 1,
          ingredients: [],
        };

        yield put(addToShadowCart([shadowItem]));
      } else {
        const itemInMenuData = allItems[itemDetails.id];

        if (!itemInMenuData) {
          reason = FAILURE_REASON.PLU_NOT_FOUND;

          throw new Error('could not find item');
        }

        yield call(
          requestAndWaitForFlow,
          stagePurchaseFromItem,
          { itemId: itemDetails.id },
          flowId,
        );

        itemAdded = true;
      }
    }

    yield put(
      applyBarcode.actions.succeeded(
        { member, offer, giftCard, itemDetails, itemAdded },
        flowId,
      ),
    );
  } catch (e) {
    yield put(
      applyBarcode.actions.failed(
        { error: makeErrorSerialisable(e), reason },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(applyBarcode.events.REQUESTED, requested),
    takeEvery(applyBarcode.events.APPROVED, approved),
  ]);
}
