import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import lodash from 'lodash';

import * as validateOrder from '../../actionCreators/flows/validateOrder';

import getValidateBody from '../../selectors/getValidateBody';
import getLocation from '../../selectors/getLocation';
import { getAttempts } from '../../selectors/config';

import Logger from '../../utils/Logger';
import Api, { FetchParams, ApiResponse } from '../../utils/Api';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';
import smoothPickupTime from '../../utils/ordering/smoothPickupTime';

import { FAILURE_REASON } from '../../constants/failureReasons';

export const requested = createFlowApprover(validateOrder);

function processValidationError(response: any): {
  reason: validateOrder.FailureReason;
  errorText: string | undefined;
} {
  let reason: validateOrder.FailureReason;
  let errorText;

  reason = FAILURE_REASON.UNKNOWN;

  errorText = lodash.get(response, 'error', '');

  let extraInfo = lodash.get(response, 'extra_info');
  extraInfo = Array.isArray(extraInfo) ? extraInfo : [];

  let firstValidationMsg;

  try {
    firstValidationMsg = extraInfo
      .map(
        (err?: { msg?: string }) =>
          lodash.get(err, 'msg', '').trim() || undefined,
      )
      .filter((msg?: string) => Boolean(msg))[0];
  } catch (e) {
    Logger.log('problem parsing extra info');
  }

  if (firstValidationMsg) {
    errorText += `: ${firstValidationMsg}`;
  }

  if (errorText.includes('uncontactable')) {
    reason = FAILURE_REASON.LOCATION_OFFLINE;
  } else if (errorText.includes('hours') || errorText.includes('closed')) {
    reason = FAILURE_REASON.LOCATION_CLOSED;
  }
  return { reason, errorText };
}

// validation in progress is set/unset automatically in the reducer
export function* approved(
  action: ReturnType<typeof validateOrder.actions.approved>,
) {
  const {
    payload: { authenticationMethod = 'trusted' },
    meta: { flowId },
  } = action;

  try {
    const attemptsConfig = (yield select(getAttempts)) as AttemptsConfig;
    let attemptsRemaining = attemptsConfig.validateOrder;
    let response;
    let valid = false;

    const baseBody = (yield select(getValidateBody)) as ReturnType<
      typeof getValidateBody
    >;

    const body = {
      ...baseBody,
    };

    if (body.SaleType === 105) {
      body.PickupTime = 'ASAP';
    }

    if (body.PickupTime != undefined) {
      const location = (yield select(getLocation)) as POSLocation;

      body.PickupTime = smoothPickupTime(
        lodash.get(baseBody, 'PickupTime'),
        location.averageOrderWaitTime,
      );
    }

    Logger.log('validating order:', undefined, body);
    Logger.log(JSON.stringify(body, null, 2));

    const params: FetchParams = {
      path: {
        trusted: '/api/v1/sale/validate_cart',
        member: '/api/v1/sale/validate_cart',
        none: '/api/v1/sale/validate_cart/nonmember',
      }[authenticationMethod],
      method: 'POST',
      body,
      applicationErrorAllowed: true,
    };

    while (attemptsRemaining) {
      attemptsRemaining -= 1;

      response = (yield call(
        Api.fetch,
        params,
        authenticationMethod,
      )) as ApiResponse;

      Logger.log('validation response:', undefined, response);
      valid = response.success;

      if (valid) {
        break;
      }
    }

    let reason: validateOrder.FailureReason;
    let errorText;

    if (!valid) {
      const processed = processValidationError(response);
      reason = processed.reason;
      errorText = processed.errorText;
    }

    yield put(
      validateOrder.actions.succeeded({ valid, reason, errorText }, flowId),
    );
  } catch (e) {
    yield put(
      validateOrder.actions.failed(
        {
          error: makeErrorSerialisable(e),
          reason:
            lodash.get(e, 'details.type', '') === 'connectivity'
              ? FAILURE_REASON.FETCH_FAILED
              : undefined,
        },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(validateOrder.events.REQUESTED, requested),
    takeEvery(validateOrder.events.APPROVED, approved),
  ]);
}
