import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import * as fetchMenu from '../../actionCreators/flows/fetchMenu';

import { getOrderingProvider, getMenuSource } from '../../selectors/config';

import boostMenuProvider from '../boostMenuProvider';
import defaultMenuProvider from '../defaultMenuProvider';

import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';

export const requested = createFlowApprover(fetchMenu);

export function* approved(
  action: ReturnType<typeof fetchMenu.actions.approved>,
) {
  const {
    payload: { locationId, saleType },
    meta: { flowId },
  } = action;

  try {
    const menuSource = yield select(getMenuSource);

    let menuProvider: any;
    switch (menuSource) {
      case 'boost-test':
      case 'boost': {
        menuProvider = boostMenuProvider;
        break;
      }
      default:
        menuProvider = defaultMenuProvider;
    }

    const orderingProvider = yield select(getOrderingProvider);

    let [rootMenuNode, items, choiceSets, upsells, boostIngredients = null]: [
      MenuNode,
      _Items,
      ChoiceSets,
      string[],
      BoostIngredient[] | null | undefined,
    ] = yield call(menuProvider, {
      locationId,
      saleType,
      orderingProvider,
      menuSource,
    });

    const processedMenu: Menu = {
      id: uuidv4(),
      locationId,
      fetchedTime: moment().toISOString(),
      rootMenuNode,
      items,
      choiceSets,
      upsells,
      boostIngredients,
    };

    yield put(fetchMenu.actions.succeeded({ menu: processedMenu }, flowId));
  } catch (e) {
    yield put(
      fetchMenu.actions.failed({ error: makeErrorSerialisable(e) }, flowId),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(fetchMenu.events.REQUESTED, requested),
    takeEvery(fetchMenu.events.APPROVED, approved),
  ]);
}
