import { sumObjectProperties } from '../misc';

import sortChoices from './sortChoices';

export default function calculateChoiceSetTotals(
  choiceSet: ValidatedChoiceSet,
  freeChoiceOrderingMethod: FreeChoiceOrderingMethod,
): ChoiceSetTotals {
  let freeChoicesRemaining = choiceSet.free;

  const sortedChoices = sortChoices(
    choiceSet.choices,
    freeChoiceOrderingMethod,
  );

  const result = sumObjectProperties(
    sortedChoices.map(choice => {
      let adjustedQuantity = choice.quantity;

      if (freeChoicesRemaining && choice.quantity) {
        const adjustment = Math.min(freeChoicesRemaining, choice.quantity);
        freeChoicesRemaining -= adjustment;
        adjustedQuantity = choice.quantity - adjustment;
      }

      return {
        moneyPrice: choice.baseMoneyPrice * adjustedQuantity,
        pointsPrice: choice.basePointsPrice * adjustedQuantity,
        pointsAward: choice.basePointsAward * adjustedQuantity,
      };
    }),
  );

  // unpack and repack to workaround issue with declaring correct return type
  const { pointsPrice, pointsAward, moneyPrice } = result;

  return {
    pointsPrice,
    pointsAward,
    moneyPrice,
  };
}
