import { call, put } from 'redux-saga/effects';

import { setThemeOverrides } from '../slices/config/themeOverrides';

import fetchThemeOverrides from './fetchThemeOverrides';

export default function* applyServerThemeOverrides() {
  try {
    const themeOverrides = yield call(fetchThemeOverrides);

    yield put(setThemeOverrides(themeOverrides));

    yield call(() => {
      const asJSON = JSON.stringify(themeOverrides, null, 2);

      localStorage.setItem('orderingDevThemeOverrides', asJSON);
    });
  } catch (e) {
    console.error(e);
  }
}
