import { ActionType, getType } from 'typesafe-actions';

import * as currentOrder from '../../actionCreators/currentOrder';

const initialState = null;

export default function (
  state: MaxOrderValue[] | null = initialState,
  action: ActionType<typeof currentOrder>,
): MaxOrderValue[] | null {
  switch (action.type) {
    case getType(currentOrder.resetOrder): {
      return initialState;
    }
    case getType(currentOrder.setMaxOrderValues): {
      const { value } = action.payload;
      return value || initialState;
    }
    default:
      return state;
  }
}
