import { ActionType, getType } from 'typesafe-actions';

import * as currentOrder from '../../actionCreators/currentOrder';
import * as config from '../../actionCreators/config';

const initialState = true;

export default function (
  state: boolean = initialState,
  action: ActionType<typeof currentOrder | typeof config>,
): boolean {
  switch (action.type) {
    case getType(currentOrder.resetOrder): {
      return initialState;
    }
    case getType(config.setRememberCreditCard): {
      const { value } = action.payload;
      return Boolean(value);
    }
    default:
      return state;
  }
}
