import React, { useState, useEffect, useRef } from 'react';
import lodash from 'lodash';
import { useTranslation } from 'react-i18next';

import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';

import { MENU_TOP_ANCHOR } from '../constants';

import getThemeLookup from '../selectors/getThemeLookup';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import getFilteredRootCategory from '../selectors/getFilteredRootCategory';
import getFilteredMenuItemCount from '../selectors/getFilteredMenuItemCount';
import getCurrentCategoryId from '../selectors/getCurrentCategoryId';
import getOrderInProgress from '../selectors/getOrderInProgress';

import combineStyles from '../utils/combineStyles';
import debug from '../utils/debug';
import { getHeight } from '../utils/screen';

import { setCurrentCategoryId } from '../slices/currentCategoryId';

import ScreenHero from '../components/ScreenHero';
import MenuLocationDetails from '../components/MenuLocationDetails';
import MenuCategoriesBar from '../components/MenuCategoriesBar';
import MenuCategory from '../components/MenuCategory';
import RedirectAndPreserveSearch from '../components/RedirectAndPreserveSearch';
import ScrollAnchor from '../components/ScrollAnchor';
import Text from '../components/Text';

import { INITIAL_SCREEN_ROUTE } from './InitialScreen';
import { COMPLETED_ORDER_SCREEN_ROUTE } from './CompletedOrderScreen';
import { useAppSelector, useAppDispatch } from '../app/hooks';

const { getOrderSubmitted } = OrderingSelectors;
const { fetchStockBalances } = OrderingOperations;

export const MENU_SCREEN_ROUTE = '/menu';

const MenuScreen = () => {
  const [scrollTop, setScrollTop] = useState<any>(null);
  const [categoryRefs, setCategoryRefs] = useState<{ [key: string]: any }>({});
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const rootCategory = useAppSelector(getFilteredRootCategory);
  const orderSubmitted = useAppSelector(getOrderSubmitted);
  const itemCount = useAppSelector(getFilteredMenuItemCount);
  const orderInProgress = useAppSelector(getOrderInProgress);
  const currentCategoryId = useAppSelector(getCurrentCategoryId);
  const menuCategoriesBarHeight = useAppSelector(state => state.menuCategoriesBarHeight);
  const dispatch = useAppDispatch();
  const enableStockPolling = useAppSelector(state => state.config.enableStockPolling);
  const stockPollingInterval = useAppSelector(state => state.config.stockPollingInterval);
  const [checkPLUQuantityOnLoad, setCheckPLUQuantityOnLoad] = useState(true);
  const interval = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (checkPLUQuantityOnLoad) {
      dispatch(fetchStockBalances({}));
      setCheckPLUQuantityOnLoad(false);
    }

    if (enableStockPolling && stockPollingInterval) {
      interval.current = setInterval(
        () => dispatch(fetchStockBalances({})),
        stockPollingInterval * 1000,
      );
      return () => {
        clearInterval(interval.current as NodeJS.Timeout);
      };
    }
  });

  const reportRef = (categoryId: string, ref: any) => {
    setCategoryRefs(prevState => {
      return { ...prevState, [categoryId]: ref };
    });
  };

  const scrollToCategory = (categoryId: string, offset?: number) => {
    const ADDITIONAL_ADJUSTMENT = deviceTypeMobile ? 90 : 30; // magic number changes if you use psb

    const categoryRef = categoryRefs[categoryId];
    setScrollTop(
      (categoryRef?.current?.offsetTop || 0) -
        Number(menuCategoriesBarHeight) -
        ADDITIONAL_ADJUSTMENT +
        (offset || 0),
    );
  };

  const setCurrentCategoryIdAndScroll = (newCurrentCategoryId: string) => {
    if (currentCategoryId !== newCurrentCategoryId) {
      dispatch(setCurrentCategoryId(newCurrentCategoryId));
    }

    scrollToCategory(newCurrentCategoryId);
  };

  const onScroll = () => {
    try {
      if (scrollTop != null) {
        setScrollTop(null);
      }

      // distance from top of screen (or is it the scroll container?)
      // magic number (based on feel + avg/min height of menu items)
      const THRESHOLD = 250;

      const categoryBottomPositions = lodash
        .chain(categoryRefs)
        .toPairs()
        .map(([categoryId, ref]) =>
          ref?.current
            ? {
                categoryId,
                bottom: ref.current.getBoundingClientRect().bottom,
              }
            : undefined,
        )
        .filter()
        .orderBy('bottom', 'asc')
        .value();

      let newCurrentCategoryId;

      const firstBelowThreshold = categoryBottomPositions.find(
        (bar: any) => bar.bottom > THRESHOLD,
      );
      const last = categoryBottomPositions[categoryBottomPositions.length - 1];

      if (firstBelowThreshold) {
        newCurrentCategoryId = firstBelowThreshold.categoryId;
      } else if (last) {
        newCurrentCategoryId = last.categoryId;
      }

      if (newCurrentCategoryId == null) {
        return;
      }

      if (newCurrentCategoryId !== currentCategoryId) {
        dispatch(setCurrentCategoryId(newCurrentCategoryId));
      }
    } catch (e) {
      debug(false, 'menu onScroll encountered an problem', e);
    }
  };

  useEffect(() => {
    onScroll();
  });

  if (orderSubmitted) {
    return <RedirectAndPreserveSearch to={COMPLETED_ORDER_SCREEN_ROUTE} />;
  }

  if (!orderInProgress) {
    return <RedirectAndPreserveSearch to={INITIAL_SCREEN_ROUTE} />;
  }

  const categories = rootCategory?.subCategories || [];

  return (
    <ScreenHero
      onScroll={lodash.throttle(onScroll, 300)}
      scrollTop={scrollTop}
      heroChildren={<MenuLocationDetails />}
    >
      <div>
        <MenuCategoriesBar
          setCurrentCategoryId={setCurrentCategoryIdAndScroll}
          jumpToTop={() => setTimeout(() => scrollToCategory(MENU_TOP_ANCHOR, 0), 100)}
        />

        <div
          style={combineStyles(
            deviceTypeMobile ? styles.mainContainerMobile : styles.mainContainerDesktop,
            !deviceTypeMobile && {
              // have to be able to scroll the last category into the active zone
              // assumes you don't increase the window height after render by too much
              paddingBottom: itemCount ? getHeight() * 0.6 : 0,
            },
            p('screen', ['backgroundColor']),
            p('menuScreen', ['backgroundColor']),
          )}
        >
          <ScrollAnchor reportRef={reportRef} identifier={MENU_TOP_ANCHOR} />

          {itemCount ? (
            lodash.map(categories, category => (
              <MenuCategory key={category.id} category={category} reportRef={reportRef} />
            ))
          ) : (
            <div style={combineStyles(styles.noResults, { minHeight: getHeight() })}>
              <Text
                themeKey="noResultsInMenuSearchMessage"
                value={t('noResultsInMenuSearchMessage')}
              />
            </div>
          )}
        </div>
      </div>
    </ScreenHero>
  );
};

const styles = {
  mainContainerDesktop: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: 35,
  },
  mainContainerMobile: {
    flex: 1,
    padding: '35px 25px 500px 25px',
  },

  noResults: {
    paddingTop: 30,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

export default MenuScreen;
