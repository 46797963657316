import { ActionType, getType } from 'typesafe-actions';

import * as currentOrder from '../../actionCreators/currentOrder';

const initialState = null;

export default function (
  state: PaymentGatewayConfig | null = initialState,
  action: ActionType<typeof currentOrder>,
): PaymentGatewayConfig | null {
  switch (action.type) {
    case getType(currentOrder.resetOrder): {
      return initialState;
    }
    case getType(currentOrder.setPaymentGatewayConfig): {
      const { value } = action.payload;
      return value || initialState;
    }
    case getType(currentOrder.setPaymentGatewayConfigPaymentMethods): {
      const { value } = action.payload;
      const data = { ...state!.data, paymentMethods: value };
      return { ...state!, data: data };
    }
    case getType(currentOrder.applyBufferData): {
      const { paymentGatewayConfig } = action.payload.value;
      return paymentGatewayConfig === undefined
        ? state
        : paymentGatewayConfig || initialState;
    }
    default:
      return state;
  }
}
