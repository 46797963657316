import { put, takeEvery, all, select, call } from 'redux-saga/effects';

import * as applyBufferFlow from '../../actionCreators/flows/applyBuffer';
import * as applyShadowOrderFlow from '../../actionCreators/flows/applyShadowOrder';

import { clearBuffer } from '../../actionCreators/buffer';
import {
  applyBufferData,
  setCachedMenu,
} from '../../actionCreators/currentOrder';
// import { setCachedMenu, setEtag } from '../actionCreators/currentOrder';

import getBufferReadyToApply from '../../selectors/getBufferReadyToApply';
import { $getLocationId } from '../../selectors/getLocationId';
import { $getSaleType } from '../../selectors/getSaleType';
import getMenu, { $getMenu } from '../../selectors/getMenu';
import { $getUnenhancedOffers } from '../../selectors/getUnenhancedOffers';
import { $getUnenhancedStagedPurchases } from '../../selectors/getUnenhancedStagedPurchases';
import { $getUnenhancedOpenPurchase } from '../../selectors/getUnenhancedOpenPurchase';
import { $getDeliveryAddress } from '../../selectors/getDeliveryAddress';
import { $getDeliveryTime } from '../../selectors/getDeliveryTime';
import { $getTimeEstimateReturned } from '../../selectors/getTimeEstimateReturned';
import { $getDeliveryProvider } from '../../selectors/getDeliveryProvider';
import { $getDeliverySurcharges } from '../../selectors/getDeliverySurcharges';
import { $getMinimumOrderInformation } from '../../selectors/getMinimumOrderInformation';
import { $getOrderingProviderSurchargeInstructions } from '../../selectors/getOrderingProviderSurchargeInstructions';
import { $getCreditCardPaymentInformation } from '../../selectors/getCreditCardPaymentInformation';
import { $getPaymentGatewayConfig } from '../../selectors/getPaymentGatewayConfig';

import { SALE_TYPE } from '../../constants/saleType';

import Logger from '../../utils/Logger';
import LifecycleHooks from '../../utils/LifecycleHooks';
import {
  makeErrorSerialisable,
  trueOrUndefined,
  requestAndWaitForFlow,
} from '../../utils/sagas';

export function* requested(
  action: ReturnType<typeof applyBufferFlow.actions.requested>,
) {
  const {
    payload,
    meta: { flowId },
  } = action;

  try {
    const readyToApply = yield select(getBufferReadyToApply);
    const proceed = yield call(
      LifecycleHooks.get(applyBufferFlow.events.REQUESTED),
      action,
    );

    if (readyToApply && trueOrUndefined(proceed)) {
      yield put(applyBufferFlow.actions.approved({ ...payload }, flowId));
    } else {
      yield put(applyBufferFlow.actions.blocked({}, flowId));
    }
  } catch (e) {
    yield put(
      applyBufferFlow.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export function* approved(
  action: ReturnType<typeof applyBufferFlow.actions.approved>,
) {
  const {
    payload: {},
    meta: { flowId },
  } = action;

  try {
    const readyToApply = yield select(getBufferReadyToApply);

    if (!readyToApply) {
      throw new Error('buffer not ready to apply');
    }

    const [
      locationId,
      saleType,
      menu,
      offers,
      stagedPurchases,
      openPurchase,
      deliveryAddress,
      deliveryTime,
      timeEstimateReturned,
      deliveryProvider,
      deliverySurcharges,
      minimumOrderInformation,
      orderingProviderSurchargeInstructions,
      creditCardPaymentInformation,
      paymentGatewayConfig,
    ]: [
      ReturnType<typeof $getLocationId>,
      ReturnType<typeof $getSaleType>,
      ReturnType<typeof $getMenu>,
      ReturnType<typeof $getUnenhancedOffers>,
      ReturnType<typeof $getUnenhancedStagedPurchases>,
      ReturnType<typeof $getUnenhancedOpenPurchase>,
      ReturnType<typeof $getDeliveryAddress>,
      ReturnType<typeof $getDeliveryTime>,
      ReturnType<typeof $getTimeEstimateReturned>,
      ReturnType<typeof $getDeliveryProvider>,
      ReturnType<typeof $getDeliverySurcharges>,
      ReturnType<typeof $getMinimumOrderInformation>,
      ReturnType<typeof $getOrderingProviderSurchargeInstructions>,
      ReturnType<typeof $getCreditCardPaymentInformation>,
      ReturnType<typeof $getPaymentGatewayConfig>,
    ] = yield all([
      select($getLocationId),
      select($getSaleType),
      select($getMenu),
      select($getUnenhancedOffers),
      select($getUnenhancedStagedPurchases),
      select($getUnenhancedOpenPurchase),
      select($getDeliveryAddress),
      select($getDeliveryTime),
      select($getTimeEstimateReturned),
      select($getDeliveryProvider),
      select($getDeliverySurcharges),
      select($getMinimumOrderInformation),
      select($getOrderingProviderSurchargeInstructions),
      select($getCreditCardPaymentInformation),
      select($getPaymentGatewayConfig),
    ]);

    const nullIfNotDelivery = <V>(value: V) =>
      saleType === SALE_TYPE.DELIVERY ? value : null;

    yield put(
      applyBufferData({
        locationId,
        saleType,
        menu,
        offers,
        stagedPurchases,
        openPurchase: openPurchase || undefined,
        deliveryAddress: nullIfNotDelivery(deliveryAddress),
        deliveryTime: nullIfNotDelivery(deliveryTime),
        timeEstimateReturned: nullIfNotDelivery(timeEstimateReturned),
        deliveryProvider: nullIfNotDelivery(deliveryProvider),
        deliverySurcharges: nullIfNotDelivery(deliverySurcharges),
        minimumOrderInformation,
        orderingProviderSurchargeInstructions,
        creditCardPaymentInformation,
        paymentGatewayConfig,
      }),
    );

    yield put(clearBuffer({}));

    const orderMenu = yield select(getMenu);

    // calling the setCacheMenu doesn't work so it is applied inside of ApplyBuffer (actionCreators/currentOrder.ts)
    // console.log({ orderMenu });
    // yield put(setCachedMenu(orderMenu.menu as Menu));

    if (orderMenu) {
      try {
        yield call(requestAndWaitForFlow, applyShadowOrderFlow, {}, flowId);
      } catch (e) {
        Logger.log('applyShadowOrderFlow failed but applyBuffer will proceed');
      }
    }

    yield put(applyBufferFlow.actions.succeeded({}, flowId));
  } catch (e) {
    yield put(
      applyBufferFlow.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }

  return undefined;
}

export default function* watcher() {
  yield all([
    takeEvery(applyBufferFlow.events.REQUESTED, requested),
    takeEvery(applyBufferFlow.events.APPROVED, approved),
  ]);
}
