import { ActionType, getType } from 'typesafe-actions';

import * as currentOrder from '../../actionCreators/currentOrder';

import { actions as openPurchase } from '../../actionCreators/flows/openPurchase';
import { actions as openStagedPurchase } from '../../actionCreators/flows/openStagedPurchase';
import { actions as stageOpenPurchase } from '../../actionCreators/flows/stageOpenPurchase';
import { actions as changeOpenPurchaseItem } from '../../actionCreators/flows/changeOpenPurchaseItem';
import { actions as adjustOpenPurchaseChoice } from '../../actionCreators/flows/adjustOpenPurchaseChoice';
import { actions as resetOpenPurchaseChoiceSets } from '../../actionCreators/flows/resetOpenPurchaseChoiceSets';

const initialState = null;

export default function (
  state: _Purchase | null = initialState,
  action: ActionType<
    | typeof currentOrder
    | typeof openPurchase
    | typeof openStagedPurchase
    | typeof stageOpenPurchase
    | typeof changeOpenPurchaseItem
    | typeof adjustOpenPurchaseChoice
    | typeof resetOpenPurchaseChoiceSets
  >,
): _Purchase | null {
  switch (action.type) {
    case getType(currentOrder.resetOrder): {
      return initialState;
    }
    case getType(currentOrder.setOpenPurchase): {
      const { value } = action.payload;
      return value ? value : initialState;
    }
    case getType(currentOrder.applyBufferData): {
      const { openPurchase } = action.payload.value;
      return openPurchase === undefined ? state : openPurchase;
    }
    case getType(adjustOpenPurchaseChoice.succeeded):
    case getType(changeOpenPurchaseItem.succeeded):
    case getType(resetOpenPurchaseChoiceSets.succeeded):
    case getType(openPurchase.succeeded):
    case getType(openStagedPurchase.succeeded): {
      const { purchase } = action.payload;
      return purchase ? purchase : initialState;
    }
    case getType(currentOrder.adjustOpenPurchaseQuantity): {
      const { value } = action.payload;
      return state
        ? { ...state, quantity: state.quantity + value }
        : initialState;
    }
    default:
      return state;
  }
}
