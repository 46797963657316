import { ActionType, getType } from 'typesafe-actions';

import * as config from '../../actionCreators/config';

const initialState = null;

export default function (
  state: number[] | null = initialState,
  action: ActionType<typeof config>,
): number[] | null {
  switch (action.type) {
    case getType(config.setStockBalanceThresholds): {
      const { value } = action.payload;
      return value || initialState;
    }
    default:
      return state;
  }
}
