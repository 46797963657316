import lodash from 'lodash';

export default function createIngredientFamilies(
  allBoostIngredients: BoostIngredient[],
  familyInfo: SDict<{
    id: string;
    name: string;
    swapMinimum: number;
  }>,
): BoostIngredientFamilies {
  const ingredientsByFamily = lodash.groupBy(allBoostIngredients, 'family');

  return lodash.mapValues(familyInfo, family => ({
    ...family,
    ingredients: ingredientsByFamily[family.id] || [],
  }));
}
