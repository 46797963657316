import React from 'react';
import { useAppSelector } from '../app/hooks';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core';
import getThemeLookup from '../selectors/getThemeLookup';
import { useFormikContext } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const FormCheckBox = ({
  name,
  required = false,
  onChange,
  disabled,
  label,
}: {
  name: string;
  required?: boolean;
  onChange: (e: any) => void;
  disabled?: boolean;
  label: string | React.ReactNode;
}) => {
  const p = useAppSelector(getThemeLookup);

  const useStyles = makeStyles(() => ({
    formControlLabel: {
      color: p('checkboxLabelColor', ['color']).color,
    },
    root: {
      '& ::-webkit-calendar-picker-indicator': {
        filter: `invert(${p('fieldIcons', ['color']).color})`,
      },
      //label - placeholder background color
      '& .MuiFormLabel-root': {
        backgroundColor: p('labelBackgroundColor', ['color']).color,
        borderRadius: 5,
        paddingLeft: 5,
        paddingRight: 5,
      },

      //text field errors
      '&  .MuiFormHelperText-root.Mui-error': {
        backgroundColor: p('helperBackground', ['color']).color,
        color: p('helperColor', ['color']).color,
      },
      // focused color for textfield with variant='outlined'
      '& .MuiOutlinedInput-root': {
        //textfield background color
        background: p('fieldBackground', ['color']).color,

        //text field border unfocused
        '& fieldset': {
          borderColor: p('fieldDefaultBorder', ['color']).color,
        },
        //text field border focused
        '&.Mui-focused fieldset': {
          borderColor: p('fieldBorderFocus', ['color']).color,
        },
        //text field border hover
        '&:hover fieldset': {
          borderColor: p('fieldHoverBorderColor', ['color']).color,
        },
      },
      //checkbox
      '&$checked': {
        color: p('checkboxCheckedColor', ['color']).color,
      },
    },

    checked: {},
  }));

  const muiStyles = useStyles();
  const formik = useFormikContext<FormikFields>();

  const { setFieldTouched, values, touched, errors } = formik;

  return (
    <>
      <FormControlLabel
        name={name}
        label={label}
        className={muiStyles.formControlLabel}
        control={
          <Checkbox
            name={name}
            required={required}
            onChange={onChange}
            style={{
              color: !values[name as keyof FormikFields]
                ? p('defaultCheckboxColor', ['color']).color
                : '',
            }}
            onBlur={() => setFieldTouched(name)}
            checked={Boolean(values[name as keyof FormikFields])}
            disabled={disabled ? disabled : false}
            classes={{
              root: muiStyles.root,
              checked: muiStyles.checked,
            }}
          />
        }
      />

      {touched[name as keyof FormikFields] && errors[name as keyof FormikFields] && (
        <FormHelperText error>{errors[name as keyof FormikFields]}</FormHelperText>
      )}
    </>
  );
};

export default FormCheckBox;
