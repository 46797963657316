import { put, call, select } from 'redux-saga/effects';

import Api, { FetchParams, ApiResponse } from '../utils/Api';

import { setEtag } from '../actionCreators/currentOrder';
import getCurrentOrder from '../selectors/getCurrentOrder';

import processMenu from '../utils/processors/processMenu';
import { mapBooleanToNumber, blankOrString } from '../utils/sagas';

import { getExcludedChoiceSets } from '../selectors/config';

import moment from 'moment';
import Logger from '../utils/Logger';

export default function* (parameters: MenuProviderParams) {
  const {
    locationId,
    saleType,
    orderingProvider,
    loadDetails = true,
    defaultSelectedOnly = false,
    nonEmptyChoiceSetsOnly = false,
    hideUnavailable = false,
  } = parameters;

  const path = [
    '/api/v1/stores/',

    locationId,

    '/menu?load_details=',
    mapBooleanToNumber(loadDetails),

    `&sale_type=${saleType}`,

    blankOrString(
      `&ordering_provider=${orderingProvider}`,
      orderingProvider == null,
    ),

    blankOrString(
      `&default_selected_only=${mapBooleanToNumber(defaultSelectedOnly)}`,
      !defaultSelectedOnly,
    ),

    blankOrString(
      `&hide_unavailable_choices_and_plus=${mapBooleanToNumber(
        hideUnavailable,
      )}`,
      !hideUnavailable,
    ),

    blankOrString(
      `&non_empty_choice_sets_only=${mapBooleanToNumber(
        nonEmptyChoiceSetsOnly,
      )}`,
      !nonEmptyChoiceSetsOnly,
    ),
  ].join('');

  const timeStart = moment();

  const requestParams: FetchParams = {
    path,
    method: 'GET',
  };

  console.log('fetching menu:', path);

  const response: ApiResponse = yield call(Api.fetch, requestParams);
  const rawMenu: RawMenu = response.data;

  const excludeChoiceSets = yield select(getExcludedChoiceSets);

  // @Feat menu-perform-cache: Log to see how long it takes to process the menu
  const timeEnd = moment();
  const result = timeEnd.diff(timeStart);
  Logger.log(`@@@ Menu call took ${result} @@@`);

  const currentOrder = yield select(getCurrentOrder);

  // if the Api doesn't have an etag then the etag in the redux state will always be null
  // so the code will never reach the second if condition below
  if (currentOrder.etag === null) {
    yield put(setEtag(response.data['Etag'] as string));
    const processedMenu = processMenu(rawMenu, true, excludeChoiceSets);
    Logger.log(
      `----- First Menu call being processed $eTAG: ${response.data['Etag']} ------`,
    );
    return processedMenu;
  }

  if (currentOrder.etag === response.data['Etag']) {
    if (currentOrder.cachedMenu === null) {
      // This should be a rare case
      Logger.log(`@@@ About to Process the Menu || cachedMenu === null || @@@`);
      yield put(setEtag(response.data['Etag'] as string));
      return processMenu(rawMenu, true, excludeChoiceSets);
    } else {
      Logger.log('**** No need to process the menu || Use CACHED menu || ****');
      return [
        currentOrder.cachedMenu.rootMenuNode,
        currentOrder.cachedMenu.items,
        currentOrder.cachedMenu.choiceSets,
        currentOrder.cachedMenu.upsells,
      ];
    }
  } else {
    Logger.log(
      `----- Menu has been changed $eTAG: ${currentOrder.etag} ------`,
    );
    yield put(setEtag(response.data['Etag'] as string));
    return processMenu(rawMenu, true, excludeChoiceSets);
  }
}
