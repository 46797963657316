import React, { useRef, useEffect } from 'react';
import lodash from 'lodash';
import { useAppSelector } from '../app/hooks';

import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';

import combineStyles from '../utils/combineStyles';

import Text from './Text';
import MenuItem from './MenuItem';

interface IProps {
  category: Category;
  reportRef: (id: string, ref: React.RefObject<HTMLDivElement>) => void;
}
const MenuCategory: React.FC<IProps> = props => {
  const ref = useRef<HTMLDivElement>(null);
  const { category, reportRef } = props;
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const disableCategoryCount = useAppSelector(state => state.config.disableCategoryCount);
  useEffect(() => {
    reportRef(category.id, ref);
  }, []);

  return (
    <div ref={ref} style={styles.category}>
      <Text themeKey="menuCategoryHeading" style={styles.heading}>
        {`${category.name} `}
        {!disableCategoryCount ? (
          <Text themeKey="menuCategoryHeadingCount">{`(${category.items.length})`}</Text>
        ) : null}
      </Text>

      <div
        style={combineStyles(styles.items, {
          gridTemplateColumns: deviceTypeMobile ? '1fr' : 'repeat( auto-fill, minmax(490px, 1fr) )',
        })}
      >
        {lodash.map(category?.items, item => (
          <MenuItem key={item.id} item={item} brandId={category.brandId} />
        ))}
      </div>
    </div>
  );
};
const styles = {
  category: {
    marginBottom: 70,
  },
  heading: {
    display: 'inline-block', // should this be on all Text components?
    marginBottom: 20,
  },
  items: {
    display: 'grid',
    gridGap: 10,
  },
};

export default MenuCategory;
