import { sumObjectProperties } from '../misc';

import calculateChoiceSetTotals from './calculateChoiceSetTotals';

export default function calculateTotalsForPurchase(
  purchase: Purchase,
  freeChoiceOrderingMethod: FreeChoiceOrderingMethod,
): PurchaseTotals {
  const choiceSetTotals = sumObjectProperties(
    purchase.choiceSets.map(set =>
      calculateChoiceSetTotals(set, freeChoiceOrderingMethod),
    ),
    ['moneyPrice', 'pointsPrice', 'pointsAward'],
  );
  const { item } = purchase;

  const individualMoneyPrice = item.baseMoneyPrice + choiceSetTotals.moneyPrice;
  const moneyPrice = individualMoneyPrice * purchase.quantity;

  const individualPointsPrice =
    item.basePointsPrice + choiceSetTotals.pointsPrice;
  const pointsPrice = individualPointsPrice * purchase.quantity;

  const individualPointsAward =
    item.basePointsAward + choiceSetTotals.pointsAward;
  const pointsAward = individualPointsAward * purchase.quantity;

  return {
    pointsPrice,
    pointsAward,
    moneyPrice,
    moneyDiscount: 0,
    discountedMoneyPrice: moneyPrice,
  };
}
