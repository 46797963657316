import { createSelector } from 'reselect';

import changePurchaseItem from '../utils/ordering/changePurchaseItem';
import findItemOrSubstitute from '../utils/ordering/findItemOrSubstitute';

import getCurrentOrder from './getCurrentOrder';
import getItems, { $getItems } from './getItems';

const getUnenhancedOpenPurchase = createSelector(
  [getCurrentOrder],
  (order): _Purchase | null => order.openPurchase,
);

export default getUnenhancedOpenPurchase;

export const $getUnenhancedOpenPurchase = createSelector(
  [getUnenhancedOpenPurchase, $getItems, getItems],
  (purchase, bufferItems, orderItems): _Purchase | null => {
    if (!bufferItems || !orderItems || !purchase) {
      return null;
    }

    const item = orderItems[purchase.itemId];

    if (!item) {
      return null;
    }

    let newItem = findItemOrSubstitute(bufferItems, purchase.itemId, item.name);

    if (!newItem) {
      return null;
    }

    return changePurchaseItem(purchase, orderItems[purchase.itemId], newItem);
  },
);
