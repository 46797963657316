import { createCustomAction } from 'typesafe-actions';

import { TyroTransactionData } from 'polygon-utils';

import {
  standardEmptyACC,
  getStandardFailedWithReasonACC,
  standardFlowACC,
} from '../../utils/sagas';

import { FAILURE_REASON } from '../../constants/failureReasons';

const REQUESTED = '$TYRO_PAYMENT/REQUESTED';
const BLOCKED = '$TYRO_PAYMENT/BLOCKED';
const APPROVED = '$TYRO_PAYMENT/APPROVED';
const SUCCEEDED = '$TYRO_PAYMENT/SUCCEEDED';
const FAILED = '$TYRO_PAYMENT/FAILED';

const request = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      amount: number;
    }>(type);
};

const succeed = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      transactionData: TyroTransactionData;
    }>(type);
};

export type FailureReason =
  | undefined
  | FAILURE_REASON.PAYMENT_CANCELLED
  | FAILURE_REASON.SIGNATURE_REQUIRED;

export const events = {
  REQUESTED,
  BLOCKED,
  APPROVED,
  SUCCEEDED,
  FAILED,
};

export const actions = {
  requested: createCustomAction(REQUESTED, request),
  approved: createCustomAction(APPROVED, request),
  blocked: createCustomAction(BLOCKED, standardEmptyACC),
  succeeded: createCustomAction(SUCCEEDED, succeed),
  failed: createCustomAction(
    FAILED,
    getStandardFailedWithReasonACC<FailureReason>(),
  ),
};

export default actions.requested;
