import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowRightFill } from 'react-icons/ri';

import { OrderingOperations } from 'polygon-ordering';

import StandardButton from './StandardButton';
import { useAppDispatch } from '../app/hooks';

const { startNewOrder } = OrderingOperations;

interface IProps {
  containerStyle?: React.CSSProperties;
}

const NewOrderButton: React.FC<IProps> = ({ containerStyle }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <StandardButton
      label={t('button.startNewOrder')}
      themeKey="newOrderButton"
      onClick={() =>
        dispatch(
          startNewOrder({
            preserveMember: true,
            lifecycleEventParams: { shouldReload: true },
          }),
        )
      }
      RightIconComponent={RiArrowRightFill}
      containerStyle={containerStyle}
    />
  );
};

export default NewOrderButton;
