import React from 'react';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';

import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';

import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES, CONTAINER_PROPERTIES } from '../utils/theme';

import getThemeLookup from '../selectors/getThemeLookup';
import { useAppSelector, useAppDispatch } from '../app/hooks';

const { getTable } = OrderingSelectors;
const { setTable } = OrderingOperations;

interface IProps {
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  themeKey: string;
  placeholder?: string;
  className?: string;
}

const TableNumber: React.FC<IProps> = ({
  className,
  style,
  containerStyle,
  themeKey,
  placeholder,
}) => {
  const p = useAppSelector(getThemeLookup);
  const table = useAppSelector(getTable);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  return (
    <div className={className} style={combineStyles(styles.mainContainer, containerStyle)}>
      <input
        type="text"
        value={table ? table : ''}
        maxLength={5}
        inputMode="numeric"
        placeholder={placeholder || t('placeholder.tableNumber')}
        onChange={event => dispatch(setTable(event?.target?.value.replace(' ', '')))}
        style={combineStyles(
          styles.input,
          p('defaultText', TEXT_PROPERTIES),
          p('input', CONTAINER_PROPERTIES),
          p(themeKey, [...TEXT_PROPERTIES, 'padding', 'border', 'borderRadius']),
          !table && p('tableNumberInvalid', CONTAINER_PROPERTIES),
          style,
        )}
      />
    </div>
  );
};

const styles = {
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    textAlign: 'center',
  },
};

export default TableNumber;
