import lodash from 'lodash';

import tallyAndValidateChoiceSets from './tallyAndValidateChoiceSets';
import choiceSetsWithQuantities from './choiceSetsWithQuantities';
import adjustChoiceSetsForQuantityDiscount from './adjustChoiceSetsForQuantityDiscount';
import adjustChoiceSetsForQuantityDiscountV2 from './adjustChoiceSetsForQuantityDiscountV2';

export default function enhancePurchase(
  purchase: _Purchase,
  allItems: Items,
  choiceSetDiscountConfig?: ChoiceSetQuantityDiscountConfig,
): Purchase | undefined {
  const item = allItems[purchase.itemId];

  if (!item) {
    return undefined;
  }

  let choiceSets = tallyAndValidateChoiceSets(
    choiceSetsWithQuantities(item.choiceSets, purchase.choiceSelections),
    choiceSetDiscountConfig,
  );

  // filter out conditional choicesets where condition has not been met
  let filterList: string[] = [];

  lodash.keys(item.conditionalChoiceSets).forEach(conditionChoiceId => {
    const found = Boolean(
      choiceSets.find(choiceSet => {
        return Boolean(
          choiceSet.choices.find(
            choice => choice.id === conditionChoiceId && choice.quantity,
          ),
        );
      }),
    );

    if (!found) {
      filterList = lodash.uniq([
        ...filterList,
        ...item.conditionalChoiceSets[conditionChoiceId],
      ]);
    }
  });

  choiceSets = choiceSets.filter(
    choiceSet => !filterList.includes(choiceSet.id),
  );

  if (choiceSetDiscountConfig) {
    const {
      useChoiceSetDiscountMap = true,
      choiceSetKey,
      // choiceSetDiscountMap = {},
      discountPlu,
    } = choiceSetDiscountConfig;
    if (!useChoiceSetDiscountMap && choiceSetKey && discountPlu) {
      choiceSets = adjustChoiceSetsForQuantityDiscount(
        choiceSets,
        choiceSetDiscountConfig,
      );
    } else if (useChoiceSetDiscountMap) {
      choiceSets = adjustChoiceSetsForQuantityDiscountV2(
        choiceSets,
        choiceSetDiscountConfig,
      );
    }
  }

  const choicesWithQuantity = choiceSets
    .reduce(
      (acc: ChoiceWithQuantity[], set): ChoiceWithQuantity[] => [
        ...acc,
        ...set.choices,
      ],
      [],
    )
    .filter(choice => choice.quantity);

  const valid = Object.values(choiceSets).reduce(
    (acc, choiceSet) => acc && Boolean(choiceSet.valid),
    true,
  );

  const sizes = item.sizes.map(id => {
    const sizeItem = allItems[id];

    return {
      id,
      name: sizeItem.sizeDescription || sizeItem.name,
      sizeDescription: sizeItem.sizeDescription,
      baseMoneyPrice: sizeItem.baseMoneyPrice,
      kilojoules: sizeItem.kilojoules,
    };
  });

  const popularChoices: PopularChoice[] = [];

  item.popularChoices.forEach(choiceId => {
    choiceSets.forEach(choiceSet => {
      choiceSet.choices.forEach(choice => {
        if (choice.id === choiceId) {
          popularChoices.push({ choice, choiceSetKey: choiceSet.key });
        }
      });
    });
  });

  return {
    id: purchase.id,
    multiSized: sizes.length > 1,
    item,
    choiceSets,
    valid,
    sizes: lodash.orderBy(sizes, 'baseMoneyPrice', ['desc']),
    quantity: purchase.quantity,
    choicesWithQuantity,
    baseMoneyPrice: item.baseMoneyPrice,
    basePointsPrice: item.basePointsPrice,
    popularChoices,
    brandId: purchase.brandId,
  };
}
