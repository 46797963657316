import { ActionType, getType } from 'typesafe-actions';

import * as currentOrder from '../../actionCreators/currentOrder';

import { actions as finaliseOrder } from '../../actionCreators/flows/finaliseOrder';
import { actions as topUpMemberMoney } from '../../actionCreators/flows/topUpMemberMoney';

const initialState = false;

export default function (
  state: boolean = initialState,
  action: ActionType<
    typeof currentOrder | typeof finaliseOrder | typeof topUpMemberMoney
  >,
): boolean {
  switch (action.type) {
    case getType(currentOrder.resetOrder): {
      return initialState;
    }
    case getType(currentOrder.setSaleInProgress): {
      const { value } = action.payload;
      return Boolean(value);
    }
    case getType(topUpMemberMoney.requested):
    case getType(finaliseOrder.requested): {
      return true;
    }
    case getType(topUpMemberMoney.failed):
    case getType(topUpMemberMoney.blocked):
    case getType(topUpMemberMoney.succeeded):
    case getType(finaliseOrder.failed):
    case getType(finaliseOrder.blocked):
    case getType(finaliseOrder.succeeded): {
      return false;
    }
    default:
      return state;
  }
}
