import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormDateFields from '../components/FormDateFields';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { logEvent } from '../utils/analytics';
import { useFormikContext } from 'formik';
import { EVENT_CATEGORIES } from '../constants/events';
import FormTextField from '../components/FormTextField';
import FormCheckBox from '../components/FormCheckBox';
import FormSelect from '../components/FormSelect';

const ProfileFields = ({ field }: { field: FormikFields }) => {
  const formik = useFormikContext<FormikFields>();

  const { values, handleBlur, handleChange } = formik;

  const renderFormField = () => {
    const { inputType, label } = field;

    if (field.editMode !== 'hidden') {
      switch (inputType) {
        case 'email':
        case 'number':
        case 'text':
        case 'password':
          return (
            <FormControl key={field.id} fullWidth>
              <FormTextField
                name={field.id!}
                type={inputType === 'number' ? 'digits' : field.inputType!}
                onChange={handleChange}
                label={label!}
                required={field.required || field.mode === 'required'}
                disabled={field.mode === 'display'}
              />
            </FormControl>
          );
        case 'sendemail':
        case 'sendsms':
        case 'terms':
        case 'checkbox':
          return (
            <FormControl key={field.id} fullWidth>
              <FormCheckBox
                required={field.required || field.mode === 'required'}
                onChange={handleChange}
                name={field.id!}
                disabled={field.editMode === 'display'}
                label={label!}
              />
            </FormControl>
          );
        case 'radio':
          return (
            <FormControl key={field.id} fullWidth>
              <RadioGroup
                id={field.id}
                name={field.id}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[field.id as keyof FormikFields]}
              >
                {field.possibleValues?.map(({ value, label }: any, index: number) => (
                  <FormControlLabel
                    key={index}
                    value={value}
                    control={<Radio disabled={field.editMode === 'display'} />}
                    label={label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          );
        case 'select':
          return (
            <FormControl key={field.id} fullWidth>
              <FormSelect
                required={field.required || field.mode === 'required'}
                label={label!}
                name={field.id!}
                onChange={handleChange}
                disabled={field.mode === 'display'}
                menuItems={field?.possibleValues}
              />
            </FormControl>
          );
        case 'date':
        case 'datetime':
        case 'time':
          return (
            <FormControl key={field.id} fullWidth>
              <FormDateFields
                type={field.inputType!}
                disabled={field.editMode === 'display'}
                required={field.required || field.mode === 'required'}
                name={field.id!}
                label={label!}
                onChange={handleChange}
              />
            </FormControl>
          );
        default:
          logEvent(
            { action: 'noComponentForField', category: EVENT_CATEGORIES.FAILURE },
            { label: 'noComponentForField', value: JSON.stringify(field) },
          );
          return null;
      }
    }
  };

  return <div>{renderFormField()}</div>;
};

export default ProfileFields;
