import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { useStripe, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { PaymentRequest } from '@stripe/stripe-js';
import { setPaymentFormInUse } from '../slices/paymentFormInUse';
import { useFormikContext } from 'formik';
import { processPayment } from '../thunks/processPayment';
import { dollarsToCents } from '../libs/polygon-ordering/src/utils/misc';
import { OrderingSelectors } from 'polygon-ordering';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../utils/snackbar';

const { getCalculatedGiftcardSurcharge } = OrderingSelectors;

const { getStripeCurrency } = OrderingSelectors;
const AppleGooglePayButtons = ({ route }: { route: string }) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<null | PaymentRequest>(null);
  const stripeCurrency = useAppSelector(getStripeCurrency);
  const [paymentMethod, setPaymentMethod] = useState<PAYMENT_METHOD>();
  const dispatch = useAppDispatch();
  const formik = useFormikContext<FormikFields>();

  const { values } = formik;

  const surcharge = useAppSelector(state =>
    getCalculatedGiftcardSurcharge(state, Number(values.Amount)),
  );

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'AU',
        currency: stripeCurrency,
        total: {
          label: 'Payment Total',
          amount: dollarsToCents(values.Amount!) + Number(values.surcharge),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then(async result => {
        if (result?.applePay || result?.googlePay) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on('cancel', () => {
        dispatch(setPaymentFormInUse(false));
      });

      paymentRequest.on('paymentmethod', ev => {
        console.log(ev);
        dispatch(setPaymentFormInUse(true));

        const data = {
          values: formik.values,
          token: ev.paymentMethod,
          route,
          giftcardSurcharge: surcharge.value,
        };

        dispatch(processPayment(data)).then((response: any) => {
          if (response.meta.requestStatus === 'fulfilled') {
            ev.complete('success');
          }

          if (response.meta.requestStatus === 'rejected') {
            ev.complete('fail');
          }
        });
      });
    }
  }, [paymentRequest]);

  return <>{paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />}</>;
};

export default AppleGooglePayButtons;
