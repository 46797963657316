import { createSelector } from 'reselect';

import calculateTotalsForOrder from '../utils/ordering/calculateTotalsForOrder';

import getStagedPurchasesTotals, {
  $getStagedPurchasesTotals,
} from './getStagedPurchasesTotals';
import getSelectedOffer, { $getSelectedOffer } from './getSelectedOffer';
import getSurcharges, { $getSurcharges } from './getSurcharges';
import getRewardsAccrualRate from './getRewardsAccrualRate';
import getStagedPurchases, { $getStagedPurchases } from './getStagedPurchases';
import { getCouponOrderingMethod, getFreeChoiceOrderingMethod } from './config';

const get = (
  purchaseTotals: StagedPurchasesTotals,
  offer: Offer | undefined,
  surcharges: Surcharge[],
  rewardsAccrualRate: number,
  purchasedItems: any[],
  couponOrderingMethod: any,
  freeChoiceOrderingMethod: any,
): OrderTotals | undefined => {
  return calculateTotalsForOrder(
    purchaseTotals,
    offer,
    surcharges,
    rewardsAccrualRate,
    purchasedItems,
    couponOrderingMethod,
    freeChoiceOrderingMethod,
  );
};

export default createSelector(
  [
    getStagedPurchasesTotals,
    getSelectedOffer,
    getSurcharges,
    getRewardsAccrualRate,
    getStagedPurchases,
    getCouponOrderingMethod,
    getFreeChoiceOrderingMethod,
  ],
  get,
);

export const $getOrderTotals = createSelector(
  [
    $getStagedPurchasesTotals,
    $getSelectedOffer,
    $getSurcharges,
    getRewardsAccrualRate,
    $getStagedPurchases,
    getCouponOrderingMethod,
    getFreeChoiceOrderingMethod,
  ],
  get,
);
