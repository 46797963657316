import { createSelector } from 'reselect';
import getSaleType from './getSaleType';
import getMaxOrderValueRules from './getMaxOrderValueRules';
import getLocationId from './getLocationId';
import getDeliveryProvider from './getDeliveryProvider';
import { SALE_TYPE } from '../constants/saleType';

// Binary flags for factors that affect the MAX RULE THING (Most important -> Least important)
const BINARY_FLAGS = {
  DeliveryProvider: 0b1000,
  SaleType: 0b0100,
  Location: 0b0010,
  State: 0b0001,
};

export default createSelector(
  [getMaxOrderValueRules, getSaleType, getLocationId, getDeliveryProvider],
  (rules, saleType, locationId, deliveryProvider): MaxOrderValue | null => {
    // Returning null if no rules
    if (!rules) return null;

    // Filter rules based on specified conditions
    const filteredRules = rules.filter(rule => {
      // If locationId is specified and the rule's Location does not match, skipping this rule
      if (
        rule.Location &&
        locationId &&
        rule.Location.toString() !== locationId
      )
        return false;

      // If saleType is specified and the rule's SaleType does not match, skipping this rule
      if (rule.SaleType && rule.SaleType !== saleType) return false;

      // If the rule is specifically for delivery, and the saleType is delivery,
      // and the DeliveryProvider does not match the specified provider, skipping this rule
      if (
        rule.SaleType === SALE_TYPE.DELIVERY &&
        saleType === SALE_TYPE.DELIVERY &&
        rule.DeliveryProvider &&
        rule.DeliveryProvider !== deliveryProvider?.id
      )
        return false;

      // Rule passes all conditions, including it in filteredRules array
      return true;
    });

    /**
     * Calculates the priority value for a given rule based on its properties.
     * Priority is determined by the presence of certain properties:
     * - If the rule has a DeliveryProvider, it adds 8 to the priority.
     * - If the rule has a SaleType, it adds 4 to the priority.
     * - If the rule has a Location, it adds 2 to the priority.
     * - If the rule has a State, it adds 1 to the priority.
     * The resulting priority value indicates the importance of the rule. It helps us to decide which rule to give precedence.
     */
    const calculateRulePriority = (rule: MaxOrderValue): number =>
      (rule.DeliveryProvider ? BINARY_FLAGS.DeliveryProvider : 0) +
      (rule.SaleType ? BINARY_FLAGS.SaleType : 0) +
      (rule.Location ? BINARY_FLAGS.Location : 0) +
      (rule.State ? BINARY_FLAGS.State : 0);

    // Sorting to bring the highest precedence rule at the top
    filteredRules.sort((a, b) => {
      return calculateRulePriority(b) - calculateRulePriority(a);
    });

    // Returning the highest precedence rule
    return filteredRules[0] ?? null;
  },
);
