import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'initialZoom',
  initialState: false as number | boolean,
  reducers: {
    setInitialZoom(state, action: PayloadAction<number>) {
      return action.payload || 1;
    },
  },
});

export const { setInitialZoom } = slice.actions;

export default slice.reducer;
