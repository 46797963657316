import { call, put, takeEvery, all } from 'redux-saga/effects';

import * as fetchPayLaterRules from '../../actionCreators/flows/fetchPayLaterRules';
import { setPayLaterRules } from '../../actionCreators/config';

import Api, { FetchParams, ApiResponse } from '../../utils/Api';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';
import processPayLaterRule from '../../utils/processors/processPayLaterRule';

export const requested = createFlowApprover(fetchPayLaterRules);

export function* approved(
  action: ReturnType<typeof fetchPayLaterRules.actions.approved>,
) {
  const {
    meta: { flowId },
  } = action;

  try {
    const params: FetchParams = {
      path: '/api/v1/payattillrules',
      method: 'GET',
    };

    const response: ApiResponse = yield call(Api.fetch, params);

    const rawPayLaterRules: RawPayLaterRule[] = response.data;

    const processedPayLaterRules = rawPayLaterRules.map(processPayLaterRule);

    yield put(setPayLaterRules(processedPayLaterRules));

    yield put(fetchPayLaterRules.actions.succeeded({}, flowId));
  } catch (e) {
    yield put(
      fetchPayLaterRules.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(fetchPayLaterRules.events.REQUESTED, requested),
    takeEvery(fetchPayLaterRules.events.APPROVED, approved),
  ]);
}
