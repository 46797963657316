import React from 'react';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import ModalFloatingHeader from './ModalFloatingHeader';
import Text from './Text';
import { useAppSelector } from '../app/hooks';
import { MEMBER_MODAL_ID } from '../modals/MemberModal';
import { FILTERS_MODAL_ID } from '../modals/FiltersModal';
import { NON_MEMBER_MODAL_ID } from '../modals/NonMemberModal';
interface IProps {
  title?: string;
  closeButtonLabel?: string;
  previousModalId: string | null;
  locked?: boolean;
  forceFloatingTitle: boolean;
  clickBackgroundToClose: boolean;
  setCurrentModal: (params: ModalParams) => void;
  containerStyle?: React.CSSProperties;
  mobileContainerStyle?: React.CSSProperties;
  tallestPossible?: boolean;
  hideFloatingHeader?: boolean;
}

const ModalMobile: React.FC<IProps> = ({
  title,
  forceFloatingTitle,
  closeButtonLabel,
  clickBackgroundToClose,
  children,
  previousModalId,
  setCurrentModal,
  containerStyle,
  mobileContainerStyle,
  tallestPossible,
  locked,
  hideFloatingHeader,
}) => {
  const close = () => setCurrentModal(null);
  const goBack = () => previousModalId && setCurrentModal({ modalId: previousModalId });
  const p = useAppSelector(getThemeLookup);
  const activeModal = useAppSelector(state => state.currentModalId);

  const showFloatingTitle = forceFloatingTitle || Boolean(previousModalId);

  const nonRadiusModals = [MEMBER_MODAL_ID, FILTERS_MODAL_ID, NON_MEMBER_MODAL_ID];

  return (
    <div
      style={combineStyles(
        styles.overlay,
        p('modalOverlay', ['backgroundColor']),
        p('modalOverlayMobile', ['backgroundColor']),
        nonRadiusModals.includes(activeModal!) && styles.memberOverlay,
      )}
      onClick={!locked && clickBackgroundToClose ? close : undefined}
      className="modalOverlay"
    >
      <div
        style={combineStyles(styles.wrapper, tallestPossible && styles.tallestPossibleWrapper)}
        onClick={event => event.stopPropagation()} // don't trigger the overlay's onClick event
        className="modalWrapper"
      >
        {!hideFloatingHeader && (
          <ModalFloatingHeader
            goBack={goBack}
            close={close}
            closeButtonLabel={closeButtonLabel}
            showFloatingTitle={showFloatingTitle}
            previousModalId={previousModalId}
            title={title}
            locked={locked}
            deviceTypeMobile
          />
        )}

        <div
          style={combineStyles(
            nonRadiusModals.includes(activeModal!) ? styles.memberModal : styles.modal,
            tallestPossible && styles.tallestPossibleModal,
            containerStyle,
            mobileContainerStyle,
            p('screen', ['backgroundColor']),
            p('modalMobile', ['backgroundColor']),
          )}
          className="modalBody"
        >
          {!showFloatingTitle && (
            <Text themeKey="modalTitle" style={styles.title}>
              {title}
            </Text>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

const styles: Styles = {
  overlay: {
    zIndex: 200,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    overflow: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backdropFilter: 'blur(3px)',
    WebkitBackdropFilter: 'blur(3px)',
  },
  memberOverlay: {
    marginTop: 55, // height of header
  },

  wrapper: {
    width: '100%',
    maxHeight: '100%',
    overflow: 'none',
    display: 'flex',
    flexDirection: 'column',
  },

  memberModal: {
    padding: '40px 40px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  modal: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: '40px 40px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  title: {
    textAlign: 'center',
  },

  tallestPossibleWrapper: {
    height: '100%',
    maxHeight: '100%',
    // paddingBottom: 20,
  },
  tallestPossibleModal: {
    flex: 1,
  },
};

export default ModalMobile;
