import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import lodash from 'lodash';

import * as fetchOrderingProviderDetails from '../../actionCreators/flows/fetchOrderingProviderDetails';
import {
  setBufferMinimumOrderInformation,
  setBufferOrderingProviderSurchargeInstructions,
  setBufferCreditCardPaymentInformation,
} from '../../actionCreators/buffer';
import {
  setMinimumOrderInformation,
  setOrderingProviderSurchargeInstructions,
  setCreditCardPaymentInformation,
  setCutoffTimes,
} from '../../actionCreators/currentOrder';

import { getOrderingProvider } from '../../selectors/config';
import getSaleType, { $getSaleType } from '../../selectors/getSaleType';
import getLocationId, { $getLocationId } from '../../selectors/getLocationId';
import getDeliveryProvider, {
  $getDeliveryProvider,
} from '../../selectors/getDeliveryProvider';

import Api, { FetchParams, ApiResponse } from '../../utils/Api';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';
import processMinimumOrderInformation from '../../utils/processors/processMinimumOrderInformation';
import processHolidaySurchargeInformation from '../../utils/processors/processHolidaySurchargeInformation';
import processServiceFeeInformation from '../../utils/processors/processServiceFeeInformation';
import processCreditCardPaymentInformation from '../../utils/processors/processCreditCardPaymentInformation';
import moment from 'moment';

export const requested = createFlowApprover(fetchOrderingProviderDetails);

export function* approved(
  action: ReturnType<typeof fetchOrderingProviderDetails.actions.approved>,
) {
  const {
    payload: { bufferMode = false, date },
    meta: { flowId },
  } = action;

  try {
    const orderingProvider: ReturnType<typeof getOrderingProvider> =
      yield select(getOrderingProvider);

    const deliveryProvider: ReturnType<typeof getDeliveryProvider> =
      yield select(bufferMode ? $getDeliveryProvider : getDeliveryProvider);

    const saleType = yield select(bufferMode ? $getSaleType : getSaleType);

    const locationId: ReturnType<typeof getLocationId> = yield select(
      bufferMode ? $getLocationId : getLocationId,
    );

    const urlParams = new URLSearchParams(
      lodash.pickBy(
        {
          sale_type: saleType,
          ordering_provider: orderingProvider,
          ...(date ? { order_date: date } : {}),
        },
        value => value != null,
      ),
    );

    const params: FetchParams = {
      path: `/api/v1/stores/${locationId}/order_provider_details?${urlParams.toString()}`,
      method: 'GET',
    };

    const response: ApiResponse = yield call(Api.fetch, params);

    const processedMinOrderInfo = processMinimumOrderInformation(
      response.data.MinimumOrderInfo as RawMinimumOrderInformation,
    );

    //mock data

    // const cutofTimes = [
    //   {
    //     MoneyLower: 0,
    //     SaleType: 107,
    //     Time: 7.0,
    //   },
    //   {
    //     MoneyLower: 7000,
    //     SaleType: 107,
    //     Time: 1.0,
    //   },
    // ];

    // yield put(setCutoffTimes(cutofTimes));

    const orderingProviderSurchargeInstructions: SurchargeInstruction[] = [];

    if (response.data.HolidaySurchargeInfo) {
      orderingProviderSurchargeInstructions.push(
        processHolidaySurchargeInformation(
          response.data.HolidaySurchargeInfo as RawHolidaySurchargeInformation,
        ),
      );
    }

    if (response.data.ServiceFeeInfo) {
      orderingProviderSurchargeInstructions.push(
        processServiceFeeInformation(
          response.data.ServiceFeeInfo as RawServiceFeeInformation,
        ),
      );
    }

    const processedCreditCardPaymentInfo = response.data.CreditCardPaymentInfo
      ? processCreditCardPaymentInformation(
          response.data
            .CreditCardPaymentInfo as RawCreditCardPaymentInformation,
        )
      : null;

    if (bufferMode) {
      yield put(setBufferMinimumOrderInformation(processedMinOrderInfo));
      yield put(
        setBufferOrderingProviderSurchargeInstructions(
          orderingProviderSurchargeInstructions,
        ),
      );
      yield put(
        setBufferCreditCardPaymentInformation(processedCreditCardPaymentInfo),
      );
    } else {
      yield put(
        setOrderingProviderSurchargeInstructions(
          orderingProviderSurchargeInstructions,
        ),
      );
      yield put(setMinimumOrderInformation(processedMinOrderInfo));
      yield put(
        setCreditCardPaymentInformation(processedCreditCardPaymentInfo),
      );
    }

    yield put(fetchOrderingProviderDetails.actions.succeeded({}, flowId));
  } catch (e) {
    yield put(
      fetchOrderingProviderDetails.actions.failed(
        { error: makeErrorSerialisable(e as Error) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(fetchOrderingProviderDetails.events.REQUESTED, requested),
    takeEvery(fetchOrderingProviderDetails.events.APPROVED, approved),
  ]);
}
