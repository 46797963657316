import { ActionType, getType } from 'typesafe-actions';

import * as currentOrder from '../../actionCreators/currentOrder';
import { SALE_TYPE } from '../../constants/saleType';

const initialState = SALE_TYPE.PICKUP;

export default function (
  state: SALE_TYPE = initialState,
  action: ActionType<typeof currentOrder>,
): SALE_TYPE {
  switch (action.type) {
    case getType(currentOrder.resetOrder): {
      return initialState;
    }
    case getType(currentOrder.setSaleType): {
      const { value } = action.payload;
      return value == null ? initialState : value;
    }
    case getType(currentOrder.applyBufferData): {
      const { saleType } = action.payload.value;
      return saleType === undefined ? state : saleType;
    }
    default:
      return state;
  }
}
