// Imports
import React, { useEffect, useState, useMemo } from 'react';
import moment, { Moment } from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { OrderingConstants, OrderingSelectors } from 'polygon-ordering';

import { generateMomentsInRangeFDO as generateMomentsInRange } from '../utils/generateMomentsInRange';
import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES, SELECT_PROPERTIES } from '../utils/theme';
import { useAppSelector } from '../app/hooks';
import Text from './Text';
import getThemeLookup from '../selectors/getThemeLookup';
/* 
Helper function to find utc off set string from utcoffset seconds 
like if you pass 34200 to this function which is adelaide offset 
this function will return +09:30
*/

const getTimeFromUTCOffset = (utcOffset: number) => {
  // Calculate the total offset in hours (including minutes)
  const totalOffsetHours = utcOffset / 3600;

  // Calculate the hours and minutes
  const hours = Math.floor(totalOffsetHours);
  const minutes = Math.floor((totalOffsetHours - hours) * 60);

  // Determine the sign of the offset
  const sign = totalOffsetHours >= 0 ? '+' : '-';

  // Format the time string
  const formattedTime = `${sign}${Math.abs(hours).toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;

  return formattedTime;
};

const { getLocation } = OrderingSelectors;
const { ASAP_TIME } = OrderingConstants;
const REFRESH_INTERVAL_DURATION = 1000 * 60; // 1 minute

interface IProps {
  themeKey: string;
  lockASAP?: boolean;
  readonly?: boolean;
  minuteStep?: number;
  timePadding?: number;
  showTimeInErrorMessage?: boolean;
  reducedWidth?: number;
  value?: string;
  earliestTime?: string;
  latestTime?: string;
  storeTimeSlots?: Array<{
    openingTime: string;
    closingTime: string;
  }>;
  timeInErrorMessage?: string;
  suppressEstimate?: boolean;
  setValue: (value: null | string) => void;
}

let DateTimeSelector: React.FC<IProps> = ({
  themeKey,
  reducedWidth,
  minuteStep = 5,
  timePadding = 0,
  earliestTime,
  latestTime,
  storeTimeSlots,
  value: valueProp,
  readonly,
  lockASAP,
  showTimeInErrorMessage,
  timeInErrorMessage,
  suppressEstimate,
  setValue,
}) => {
  const p = useAppSelector(getThemeLookup);
  const { t } = useTranslation();
  const [, forceUpdate] = useState({});

  const { timeZone, utcOffset } = useAppSelector(getLocation) as POSLocation;

  const storeUtc = getTimeFromUTCOffset(utcOffset) || moment().format('Z');

  useEffect(() => {
    const interval = setInterval(() => forceUpdate({}), REFRESH_INTERVAL_DURATION);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // Selected pickup time
  let currentPickupTime: string | undefined = valueProp;

  const currentPickupMomentTime = moment(currentPickupTime).utcOffset(storeUtc);

  const selectedDate = {
    y: currentPickupMomentTime.utcOffset(storeUtc).year(),
    M: currentPickupMomentTime.utcOffset(storeUtc).month(),
    d: currentPickupMomentTime.utcOffset(storeUtc).date(),
  };

  if (!currentPickupTime || currentPickupTime.toUpperCase() === ASAP_TIME || lockASAP) {
    currentPickupTime = undefined;
  }

  /*
  RF2 : https://gatorojotest.redcatcloud.com.au/api/v1/sale/validate_cart
  Backend need to fix this
  */

  const momentEarliestTime = moment(earliestTime).utcOffset(storeUtc);
  const momentLatestTime = moment(latestTime).utcOffset(storeUtc);

  // Earliest time moment
  const earliestTimeMoment =
    valueProp === ASAP_TIME
      ? momentEarliestTime.clone()
      : moment({
          ...selectedDate,
          h: momentEarliestTime.hour(),
          m: momentEarliestTime.minute(),
        }).utcOffset(storeUtc, true);

  // User current time + avg order wait time
  const estimatedOrderCompletionTime = moment().utcOffset(storeUtc).add(timePadding, 'minutes');

  // Earliest selectable moment time by user
  const earliestSelectableMoment = estimatedOrderCompletionTime.isAfter(earliestTimeMoment)
    ? estimatedOrderCompletionTime.clone()
    : earliestTimeMoment.clone();

  // Latest selectable moment time by user

  const latestMomentWithSelectedDate = moment({
    ...selectedDate,
    h: momentLatestTime.hour(),
    m: momentLatestTime.minute(),
  }).utcOffset(storeUtc, true);

  const latestSelectableMoment =
    valueProp === ASAP_TIME || momentLatestTime.isAfter(latestMomentWithSelectedDate)
      ? momentLatestTime.clone()
      : latestMomentWithSelectedDate.clone();

  // If latest selectable moment is same or before earliest selectable moment then NO VALID TIME
  const noValidTime = latestSelectableMoment.isSameOrBefore(earliestSelectableMoment);

  // Checking is current pickup time after latest time
  const isCurrentPickupAfterLatestTime = currentPickupMomentTime.isAfter(latestSelectableMoment);

  // Checking is current pickup time before earliest time
  const isCurrentPickupBeforeEarliestTime =
    currentPickupMomentTime.isBefore(earliestSelectableMoment);

  // Display Times
  const displayTimes = useMemo(
    () =>
      generateMomentsInRange(
        moment({
          ...selectedDate,
          h: earliestSelectableMoment.hour(),
          m: earliestSelectableMoment.minute(),
        }).utcOffset(storeUtc, true),
        moment({
          ...selectedDate,
          h: latestSelectableMoment.hour(),
          m: latestSelectableMoment.minute(),
        }).utcOffset(storeUtc, true),
        minuteStep,
        storeTimeSlots,
      ),
    [earliestTime, valueProp, latestTime, timePadding, minuteStep, storeTimeSlots],
  );

  // useEffect(() => {
  //   if (displayTimes.length) {
  //     if (!displayTimes.map(t => t.toISOString()).includes(value) && value !== ASAP_TIME) {
  //       setValue(displayTimes[0].toISOString());
  //     }
  //   }
  // }, [displayTimes]);

  // Calculating estimated ASAP Time
  const estimatedASAP = Intl.DateTimeFormat(undefined, {
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
    timeZone,
  }).format(new Date(earliestSelectableMoment.toISOString(true)));

  if (isCurrentPickupBeforeEarliestTime || isCurrentPickupAfterLatestTime || noValidTime) {
    currentPickupTime = undefined;
  }

  const value = currentPickupTime ? currentPickupMomentTime.toISOString(true) : ASAP_TIME;

  const problemExists = noValidTime || isCurrentPickupAfterLatestTime;

  const handleTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { target } = e;

    if (!target.value) {
      setValue(null);
    } else if (target.value === ASAP_TIME) {
      setValue(ASAP_TIME);
    } else {
      setValue(moment(target.value).utcOffset(storeUtc).format());
    }
  };

  return (
    <div style={problemExists && showTimeInErrorMessage ? styles.mainContainer : undefined}>
      <select
        key={String(value !== ASAP_TIME)} // force a full rerender to prevent warning about mixing padding and paddingLeft/Right
        value={value}
        onChange={handleTimeChange}
        disabled={readonly || lockASAP || noValidTime}
        style={combineStyles(
          { textAlignLast: 'center' },
          p('defaultText', TEXT_PROPERTIES),
          p('input', SELECT_PROPERTIES),
          p(themeKey, SELECT_PROPERTIES),
          { width: 170 },
        )}
      >
        {problemExists ? (
          <option value={ASAP_TIME}>{noValidTime ? t('noValidTime') : t('invalidTime')}</option>
        ) : (
          <>
            {!moment(valueProp).startOf('day').diff(moment().startOf('day')) && (
              <option value={ASAP_TIME}>{`${t('asap')}${
                suppressEstimate ? '' : ` (${estimatedASAP})`
              }`}</option>
            )}

            {displayTimes.map((time: Moment) => {
              const formatted = Intl.DateTimeFormat('en-AU', {
                //@ts-ignore
                timeStyle: 'short',
                timeZone,
              }).format(new Date(time.toISOString(true)));

              return (
                <option key={time.toISOString(true)} value={time.toISOString(true)}>
                  {formatted}
                </option>
              );
            })}
          </>
        )}
      </select>

      {problemExists && showTimeInErrorMessage && (
        <Text themeKey="timeInErrorMessage" block style={styles.timeInErrorMessage}>
          {timeInErrorMessage}
        </Text>
      )}
    </div>
  );
};
const styles: Styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  timeInErrorMessage: {
    marginTop: 7,
    textAlign: 'center',
    maxWidth: 130,
  },
};
export default DateTimeSelector;
