import React from 'react';
import PurchaseGiftCardOptions from './PurchaseGiftCardOptions';
import SuperPaymentForm from '../components/SuperPaymentForm';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { OrderingSelectors, OrderingHooks } from 'polygon-ordering';
import { useAppSelector } from '../app/hooks';
import NonMemberGiftCardFields from './NonMemberGiftCardFields';

const { getMember } = OrderingSelectors;
const { useFormattedCurrency } = OrderingHooks;

const PurchaseGiftCardForSelf: React.FC = () => {
  const member = useAppSelector(getMember);

  const memberSchema = {
    Amount: Yup.number().required('Required'),
  };

  const nonMemberSchema = {
    FirstName: Yup.string().required('Required'),
    LastName: Yup.string().required('Required'),
    Email: Yup.string().email().required('Required'),
    ConfirmEmail: Yup.string().email().required('Required'),
    Amount: Yup.number().required('Required'),
  };
  const memberValidationSchema = Yup.object().shape(memberSchema);
  const nonMemberValidationSchema = Yup.object().shape(nonMemberSchema);

  const handleFormSubmit = () => {};

  const memberInitialValues = { Amount: '' };
  const nonMemberInitialValues = {
    Amount: '',
    FirstName: '',
    LastName: '',
    Email: '',
    ConfirmEmail: '',
  };

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={member ? memberValidationSchema : nonMemberValidationSchema}
      initialValues={member ? memberInitialValues : nonMemberInitialValues}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          {!member && <NonMemberGiftCardFields />}
          <PurchaseGiftCardOptions formatCurrency={useFormattedCurrency} />
          <SuperPaymentForm
            route={member ? 'memberPurchaseGiftCardForSelf' : 'nonMemberPurchaseGiftCardForSelf'}
          />
        </Form>
      )}
    </Formik>
  );
};

export default PurchaseGiftCardForSelf;
