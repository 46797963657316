import { OFFER_FOR_PURCHASE } from '../../constants';

import calculateTotalsForPurchase from './calculateTotalsForPurchase';
import applyOfferToStagedPurchases from './applyOfferToStagedPurchases';

export default function stagedPurchasesWithTotals(
  purchases: Purchase[],
  freeChoiceOrderingMethod: FreeChoiceOrderingMethod,
  selectedOffer: Offer | undefined,
  couponOrderingMethod: CouponOrderingMethod,
): PurchaseWithTotals[] {
  const purchasesWithTotals = purchases.map(purchase => ({
    ...purchase,
    ...calculateTotalsForPurchase(purchase, freeChoiceOrderingMethod),
  }));

  if (!selectedOffer) {
    return purchasesWithTotals;
  }

  if (OFFER_FOR_PURCHASE.includes(selectedOffer.type)) {
    return applyOfferToStagedPurchases(
      purchasesWithTotals,
      selectedOffer,
      couponOrderingMethod,
    );
  }

  return purchasesWithTotals;
}
