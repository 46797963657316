import { createCustomAction } from 'typesafe-actions';

import { standardEmptyACC, standardFlowACC } from '../../utils/sagas';

import { FAILURE_REASON } from '../../constants/failureReasons';

const REQUESTED = '$FETCH_DELIVERY_ESTIMATE/REQUESTED';
const BLOCKED = '$FETCH_DELIVERY_ESTIMATE/BLOCKED';
const APPROVED = '$FETCH_DELIVERY_ESTIMATE/APPROVED';
const SUCCEEDED = '$FETCH_DELIVERY_ESTIMATE/SUCCEEDED';
const FAILED = '$FETCH_DELIVERY_ESTIMATE/FAILED';

export type FailureReason =
  | undefined
  | FAILURE_REASON.DELIVERY_UNAVAILABLE
  | FAILURE_REASON.MISSING_PARAMETER
  | FAILURE_REASON.UNKNOWN
  | FAILURE_REASON.ADDRESS_NOT_FOUND;

const request = <T>(type: T) => {
  return standardFlowACC<
    T,
    {
      deliveryAddress?: string;
      desiredDeliveryTime?: string;
      locationId?: string;
      multiple?: boolean;
      forceASAPDeliveryEstimate?: boolean;
    }
  >(type);
};

const success = <T>(type: T) => {
  return standardFlowACC<
    T,
    {
      multiple: boolean;
    }
  >(type);
};

const failure = <T>(type: T) => {
  return standardFlowACC<
    T,
    {
      error?: SerialisableException;
      reason?: FailureReason;
      deliveryAddress?: string;
      userReason?: string;
      systemReason?: string;
      matchedAddressString?: string;
    }
  >(type);
};

export const events = {
  REQUESTED,
  BLOCKED,
  APPROVED,
  SUCCEEDED,
  FAILED,
};

export const actions = {
  requested: createCustomAction(REQUESTED, request),
  approved: createCustomAction(APPROVED, request),
  blocked: createCustomAction(BLOCKED, standardEmptyACC),
  succeeded: createCustomAction(SUCCEEDED, success),
  failed: createCustomAction(FAILED, failure),
};

export default actions.requested;
