import { call, put, takeEvery, all, select } from 'redux-saga/effects';

import * as fetchSaleStatus from '../../actionCreators/flows/fetchSaleStatus';

import getSaleDetails from '../../selectors/getSaleDetails';

import processSale from '../../utils/processors/processSale';
import Api, { FetchParams, ApiResponse } from '../../utils/Api';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';

export const requested = createFlowApprover(fetchSaleStatus);

export function* approved(
  action: ReturnType<typeof fetchSaleStatus.actions.approved>,
) {
  const {
    payload: { authenticationMethod = 'trusted' },
    meta: { flowId },
  } = action;

  try {
    const saleDetails = yield select(getSaleDetails);
    const { transactionId } = saleDetails;

    if (transactionId == null) {
      throw new Error('no transaction id to look up');
    }

    const params: FetchParams = {
      path: {
        member: `/api/v1/profile/orders?q=${transactionId}`,
        trusted: `/api/v1/auth/orderstatus/${transactionId}`,
        none: `/api/v1/sale/order/${transactionId}`,
      }[authenticationMethod],
      method: 'GET',
    };

    const response: ApiResponse = yield call(
      Api.fetch,
      params,
      authenticationMethod,
    );

    let rawSale: RawSale;

    if (['member', 'none'].includes(authenticationMethod)) {
      rawSale = response.data[0];
    } else {
      rawSale = response.data;
    }

    const processedSale = processSale(rawSale);

    yield put(
      fetchSaleStatus.actions.succeeded({ saleDetails: processedSale }, flowId),
    );
  } catch (e) {
    yield put(
      fetchSaleStatus.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(fetchSaleStatus.events.REQUESTED, requested),
    takeEvery(fetchSaleStatus.events.APPROVED, approved),
  ]);
}
