import { utils } from 'polygon-utils';
import { safeString } from '../misc';
import processChoice from './processChoice';

const { deterministicSerialiser, sha256 } = utils;

export default function processChoiceSet(
  rawChoiceSet: RawChoiceSet,
): ChoiceSet {
  const availableChoices = rawChoiceSet.Choices.filter(
    choice => choice.Available === 1,
  );

  const processed = {
    key: safeString(rawChoiceSet.ChoiceName),
    name: rawChoiceSet.ChoiceName,
    free: rawChoiceSet.NumChoicesFree,
    max: rawChoiceSet.NumChoicesMax || undefined,
    individualMax: rawChoiceSet.ItemMax || undefined,
    min: rawChoiceSet.NumChoicesMin || undefined,
    choices: availableChoices.map(processChoice),
    displayType: safeString(rawChoiceSet.ChoiceDisplayType),
  };

  return {
    ...processed,
    id: sha256(deterministicSerialiser(processed)),
  };
}
