import { ActionType, getType } from 'typesafe-actions';
import lodash from 'lodash';

import * as currentOrder from '../../actionCreators/currentOrder';

// Q: should this use `hash` instead of `id`?
const mergeOffers = (previousOffers: Offer[] = [], newOffers: Offer[] = []) =>
  lodash.unionBy(newOffers, previousOffers, 'id');

const initialState: Offer[] = [];

export default function (
  state: Offer[] = initialState,
  action: ActionType<typeof currentOrder>,
): Offer[] {
  switch (action.type) {
    case getType(currentOrder.resetOrder): {
      return initialState;
    }
    case getType(currentOrder.setOffers): {
      const { value } = action.payload;
      return value || initialState;
    }
    case getType(currentOrder.addOffers): {
      const { value } = action.payload;

      return mergeOffers(state, value);
    }
    case getType(currentOrder.applyBufferData): {
      const { offers } = action.payload.value;
      return offers === undefined ? state : offers || initialState;
    }
    default:
      return state;
  }
}
