import { put, takeEvery, all, select, call } from 'redux-saga/effects';

import * as setGiftCard from '../../actionCreators/flows/setGiftCard';
import * as fetchGiftCard from '../../actionCreators/flows/fetchGiftCard';
import * as updateSelectedPaymentMethods from '../../actionCreators/flows/updateSelectedPaymentMethods';

import getGiftCard from '../../selectors/getGiftCard';
import { getImportedGiftCardRegex } from '../../selectors/config';

import validateGiftCard from '../../utils/ordering/validateGiftCard';
import {
  makeErrorSerialisable,
  createFlowApprover,
  requestAndWaitForFlow,
} from '../../utils/sagas';

export const requested = createFlowApprover(setGiftCard);

export function* approved(
  action: ReturnType<typeof setGiftCard.actions.approved>,
) {
  const {
    payload: { giftCard, suppressAutoFetch },
    meta: { flowId },
  } = action;

  try {
    yield put(setGiftCard.actions.succeeded({ giftCard }));

    const storedGiftCard = yield select(getGiftCard);
    const importedGiftCardRegex = yield select(getImportedGiftCardRegex);

    const valid = validateGiftCard(storedGiftCard, importedGiftCardRegex);

    if (valid && !suppressAutoFetch) {
      const result = yield call(
        requestAndWaitForFlow,
        fetchGiftCard,
        { giftCard: storedGiftCard },
        flowId,
      );
      if (result.succeeded) {
        // Call updateSelectedPaymentMethods to update amount charged to gift card.
        yield put(
          updateSelectedPaymentMethods.actions.requested({
            giftCardValidated: true,
          }),
        );
      }
    }
  } catch (e) {
    yield put(
      setGiftCard.actions.failed({ error: makeErrorSerialisable(e) }, flowId),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(setGiftCard.events.REQUESTED, requested),
    takeEvery(setGiftCard.events.APPROVED, approved),
  ]);
}
