import { ActionType, getType } from 'typesafe-actions';

import * as config from '../../actionCreators/config';
export default function (
  state: boolean = true,
  action: ActionType<typeof config>,
): boolean {
  switch (action.type) {
    case getType(config.setFlattenCategories): {
      const { value } = action.payload;

      return Boolean(value);
    }
    default:
      return Boolean(state);
  }
}
