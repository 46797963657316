import { ActionType, getType } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';

import * as currentOrder from '../../actionCreators/currentOrder';

export default function (
  state: string = uuidv4(),
  action: ActionType<typeof currentOrder>,
): string {
  switch (action.type) {
    case getType(currentOrder.resetOrder): {
      const { uuid } = action.payload;
      return uuid;
    }
    case getType(currentOrder.resetSaleUUID): {
      return uuidv4();
    }
    default:
      return state;
  }
}
