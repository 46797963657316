import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../components/Modal';
import StandardButton from '../components/StandardButton';
import Text from '../components/Text'; // TODO: rework this to use Text component
import { setCurrentModal } from '../thunks/setCurrentModal';
import combineStyles from '../utils/combineStyles';
import { useAppSelector, useAppDispatch } from '../app/hooks';

export const GIFTCARD_PURCHASE_SUCCESSFUL_MODAL = 'GIFTCARD_PURCHASE_SUCCESSFUL_MODAL';

const GiftcardSuccessModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const modalParams = useAppSelector(state => state.modalParams);
  const giftcard = modalParams?.giftcard;

  return (
    <Modal
      title={modalParams?.title}
      desktopMaxWidth={350}
      desktopMinWidth={350}
      desktopContainerStyle={styles.modalDesktopContainer}
      hideFloatingHeader
    >
      <Text
        block
        style={combineStyles(styles.message, {
          marginTop: modalParams?.title ? 25 : 15,
        })}
        value={'Payment Complete'}
        themeKey="messageModalMessage"
      />
      <Text
        block
        style={combineStyles(styles.message, {
          marginTop: modalParams?.title ? 25 : 15,
        })}
        value={`We've sent an email to ${giftcard?.email} with the card details.`}
        themeKey="messageModalMessage"
      />

      <Text
        block
        style={combineStyles(styles.message, {
          marginTop: modalParams?.title ? 25 : 15,
        })}
        value={`Receipt: ${giftcard?.SalesID} `}
        themeKey="messageModalMessage"
      />

      <StandardButton
        label={t('button.ok')}
        onClick={() => dispatch(setCurrentModal(null))}
        containerStyle={styles.okButton}
        themeKey="messageModalButton"
      />
    </Modal>
  );
};

const styles: Styles = {
  modalDesktopContainer: {
    padding: '45px 50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  message: {
    textAlign: 'center',
  },

  okButton: {
    marginTop: 40,
  },
};

export default GiftcardSuccessModal;
