import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import Modal from '../components/Modal';
import Text from '../components/Text';
import combineStyles from '../utils/combineStyles';
import StandardButton from '../components/StandardButton';
import { setCurrentModal } from '../thunks/setCurrentModal';

export const LOYALTY_OFFER_MODAL_ID = 'LOYALTY_OFFER_MODAL_ID';

const LoyaltyOfferModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const offer = useAppSelector(state => state.loyaltySelectedOffer);

  return (
    <Modal
      desktopMaxWidth={350}
      desktopMinWidth={350}
      desktopContainerStyle={styles.modalDesktopContainer}
      hideFloatingHeader
    >
      <Text
        style={combineStyles(styles.message)}
        block
        value={offer?.LongDescription}
        themeKey="messageModalMessage"
      />

      <StandardButton
        label={t('button.ok')}
        onClick={() => dispatch(setCurrentModal(null))}
        containerStyle={styles.okButton}
        themeKey="messageModalButton"
      />
    </Modal>
  );
};

const styles: Styles = {
  modalDesktopContainer: {
    padding: '45px 50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  message: {
    textAlign: 'center',
  },

  okButton: {
    marginTop: 40,
  },
};

export default LoyaltyOfferModal;
