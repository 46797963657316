import { createSelector } from 'reselect';

import getCurrentOrder from './getCurrentOrder';
import getOffers, { $getOffers } from './getOffers';

const get = (offers: Offer[] | undefined, order: Order): Offer | undefined => {
  if (!offers || !offers.length || order.offerId == null) {
    return undefined;
  }

  return offers.find(offer => offer.id === order.offerId);
};

export default createSelector([getOffers, getCurrentOrder], get);

export const $getSelectedOffer = createSelector(
  [$getOffers, getCurrentOrder],
  get,
);
