import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: FutureOrderingConfig = {
  enableFutureOrdering: false,
  futureOrderingLimit: 0,
  futureOrderingLimitCatering: 0,
};

const slice = createSlice({
  name: 'futureOrderingConfig',
  initialState,
  reducers: {
    setFutureOrderingConfig(_, action: PayloadAction<FutureOrderingConfig>) {
      return action.payload;
    },
  },
});

export const { setFutureOrderingConfig } = slice.actions;
export default slice.reducer;
