import { ActionType, getType } from 'typesafe-actions';

import * as config from '../../actionCreators/config';

export default function (
  state: string = 'test',
  action: ActionType<typeof config>,
): string {
  switch (action.type) {
    case getType(config.setPaymentEnvironment): {
      const { value } = action.payload;
      return value || 'test';
    }
    default:
      return state;
  }
}
