// These must be the first lines
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from 'notistack';

import 'react-perfect-scrollbar/dist/css/styles.css';

import * as serviceWorker from './serviceWorker';

import { store, persistor } from './store';
import storage from 'redux-persist/lib/storage';
import { PERSIST_KEY } from './constants';

import './styles/index.css';
import './styles/perfectScrollbarAdjustments.css';

import { initialiseI18next } from './utils/i18next';

import HistoryRefSaver from './components/HistoryRefSaver';
import SnackbarRefSaver from './components/SnackbarRefSaver';
import App from './components/App';

import { HelmetProvider } from 'react-helmet-async';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { useAppDispatch } from './app/hooks';
import bootAction from './actions/boot';

let retryCount = 0;
function Fallback({ error, resetErrorBoundary }: FallbackProps) {
  const dispatch = useAppDispatch();
  if (retryCount < 2) {
    // clear state and retry
    retryCount += 1;
    setTimeout(() => {
      dispatch({ type: '$RESET_ROOT_STATE' });
      dispatch(bootAction());
      resetErrorBoundary();
    }, 3500);
    return (
      <>
        {'Something went wrong :('}
        <br />
        {'Attempting to reload.'}
      </>
    );
  }

  return <>{'Something went wrong :('}</>;
}

// this needs to be initialized ASAP
initialiseI18next();

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <ErrorBoundary FallbackComponent={Fallback}>
        <PersistGate loading={null} persistor={persistor}>
          <SnackbarProvider maxSnack={3} dense>
            <SnackbarRefSaver />
            <BrowserRouter basename={process.env.PUBLIC_URL}>
              <HistoryRefSaver />
              <App />
            </BrowserRouter>
          </SnackbarProvider>
        </PersistGate>
      </ErrorBoundary>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
