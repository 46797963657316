import { createCustomAction } from 'typesafe-actions';

import {
  standardEmptyACC,
  standardFlowACC,
  getStandardFailedWithReasonACC,
} from '../../utils/sagas';

import { FAILURE_REASON } from '../../constants/failureReasons';

import * as tyroPayment from './tyroPayment';

const REQUESTED = '$RECORD_PAYMENT/REQUESTED';
const BLOCKED = '$RECORD_PAYMENT/BLOCKED';
const APPROVED = '$RECORD_PAYMENT/APPROVED';
const SUCCEEDED = '$RECORD_PAYMENT/SUCCEEDED';
const FAILED = '$RECORD_PAYMENT/FAILED';

const request = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      paymentAmount?: number;
      subPayments: SubPayment[];
      paymentGatewayToken?: string;
      paymentGatewayPublicKey?: string;
      extraParams?: PaymentHookResult;
    }>(type);
};

const succeed = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      payment: Payment;
    }>(type);
};

export const events = {
  REQUESTED,
  BLOCKED,
  APPROVED,
  SUCCEEDED,
  FAILED,
};

export type FailureReason =
  | undefined
  | tyroPayment.FailureReason
  | FAILURE_REASON.UNKNOWN_PAYMENT_METHOD
  | FAILURE_REASON.MISSING_PAYMENT_HOOK
  | FAILURE_REASON.MISSING_EFTPOS_PROVIDER
  | FAILURE_REASON.PAYMENT_CANCELLED;
// | FAILURE_REASON.PAYMENT_FAILED

export const actions = {
  requested: createCustomAction(REQUESTED, request),
  approved: createCustomAction(APPROVED, request),
  blocked: createCustomAction(BLOCKED, standardEmptyACC),
  succeeded: createCustomAction(SUCCEEDED, succeed),
  failed: createCustomAction(
    FAILED,
    getStandardFailedWithReasonACC<FailureReason>(),
  ),
};

export default actions.requested;
