import lodash from 'lodash';

import Logger from '../Logger';

import choiceIsThisIngredient from './choiceIsThisIngredient';

export default function instructionChoicesFromIngredients(
  familyIngredients: BoostIngredient[],
  baseChoiceId: string,
): Choice[] {
  return lodash.compact(
    familyIngredients.map(ingredient => {
      const instructionChoice = ingredient.choiceMap.instruction;

      if (!instructionChoice) {
        Logger.log(
          "no 'instruction' choice for ingredient, excluding ingredient from swap",
          'info',
          lodash.pick(ingredient, ['name', 'id']),
        );

        return undefined;
      }

      return {
        ...instructionChoice,
        baseQuantity: choiceIsThisIngredient(ingredient, baseChoiceId) ? 1 : 0,
      };
    }),
  );
}
