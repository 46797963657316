export default function processOrderingImagePath(imagePath?: string) {
  if (!imagePath) {
    return undefined;
  }

  const trimmedPath = imagePath.trim();

  if (trimmedPath.startsWith('http')) {
    return trimmedPath;
  }

  return `/static/img/ordering/${trimmedPath}`;
}
