import { ActionType, getType } from 'typesafe-actions';
import * as currentOrder from '../../actionCreators/currentOrder';

const initialState = null;

export default function (
  state: string | null = initialState,
  action: ActionType<typeof currentOrder>,
): string | null {
  switch (action.type) {
    case getType(currentOrder.resetOrder): {
      return initialState;
    }
    case getType(currentOrder.setLocationId): {
      const { value } = action.payload;
      return value == null ? initialState : value;
    }
    case getType(currentOrder.applyBufferData): {
      const { locationId } = action.payload.value;
      return locationId === undefined ? state : locationId;
    }
    default:
      return state;
  }
}
