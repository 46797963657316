export default function choiceSetsWithQuantities(
  choiceSets: ChoiceSet[],
  choiceSelections: ChoiceSelections,
): ChoiceSetWithQuantity[] {
  return choiceSets.map(choiceSet => ({
    ...choiceSet,
    choices: choiceSet.choices.map(choice => {
      const quantity = (choiceSelections[choiceSet.key] || []).filter(
        selection => selection === choice.id,
      ).length;

      return {
        ...choice,
        quantity,
        moneyPrice: quantity * choice.baseMoneyPrice,
      };
    }),
  }));
}
