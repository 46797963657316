import { ActionType, getType } from 'typesafe-actions';

import * as currentOrder from '../../actionCreators/currentOrder';

import { actions as recordPayment } from '../../actionCreators/flows/recordPayment';

const initialState = null;

export default function (
  state: Payment | null = initialState,
  action: ActionType<typeof currentOrder | typeof recordPayment>,
): Payment | null {
  switch (action.type) {
    case getType(currentOrder.resetOrder): {
      return initialState;
    }
    case getType(currentOrder.setPayment): {
      const { value } = action.payload;
      return value || null;
    }
    case getType(recordPayment.succeeded): {
      const { payment } = action.payload;
      return payment || null;
    }
    default:
      return state;
  }
}
