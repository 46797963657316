import { safeString } from '../misc';

export default function translateChoiceSelectionsBetweenItems(
  previousSelections: ChoiceSelections,
  previousItem: Item,
  nextItem: Item,
): ChoiceSelections {
  const nextSelections: ChoiceSelections = {};

  nextItem.choiceSets.forEach(nextSet => {
    nextSelections[nextSet.key] = [];
  });

  if (!previousSelections) {
    return nextSelections;
  }

  Object.keys(previousSelections).forEach(currentSetId => {
    const matchingSet = nextItem.choiceSets.find(
      set => set.key === currentSetId,
    );
    const previousSet = previousItem.choiceSets.find(
      set => set.key === currentSetId,
    );

    if (matchingSet && previousSet) {
      const choiceMap: SDict<string> = {};

      previousSet.choices.forEach(previousChoice => {
        const matchingChoice = matchingSet.choices.find(
          choice =>
            choice.id === previousChoice.id ||
            safeString(choice.name) === safeString(previousChoice.name),
        );

        if (matchingChoice) {
          choiceMap[previousChoice.id] = matchingChoice.id;
        }
      });

      nextSelections[currentSetId] = previousSelections[currentSetId].map(
        choiceId => choiceMap[choiceId],
      );
    }
  });

  return nextSelections;
}
