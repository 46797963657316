import {
  BOOST_ICE_INGREDIENT_IDS,
  BOOST_WITHOUT_ICE_INGREDIENT_ID,
} from '../../constants/boost';

import { safeString } from '../misc';

export default function determineProcessedFamilyId(
  rawIngredientFamilies: BoostRawIngredientFamily[],
  rawIngredient: BoostRawIngredient,
): string | undefined {
  // more hacks because the ice ingredients have bad family values
  if (
    [...BOOST_ICE_INGREDIENT_IDS, BOOST_WITHOUT_ICE_INGREDIENT_ID].includes(
      rawIngredient.id,
    )
  ) {
    return 'ice';
  }

  const rawFamily = rawIngredientFamilies.find(
    rawFamily => rawFamily.id === rawIngredient.familyId,
  );

  if (!rawFamily) {
    return undefined;
  }

  // because of "Yoghurt/Sorbet"
  const namePrecedingAnySlash = rawFamily.name.split('/')[0];

  return safeString(namePrecedingAnySlash);
}
