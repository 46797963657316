import lodash from 'lodash';

import processChoiceSet from './processChoiceSet';
import processOrderingImagePath from './processOrderingImagePath';
import processImageMap from './processImageMap';

function sizeDescriptionFromName(name: string): string {
  return (name.match(/\[(.*?)\]/) || [])[1];
}

function nameWithoutSizeDescription(name: string): string {
  return (name || '').replace(/\[.*?\]/g, '').trim();
}

function extractChoiceSets(rawSize: RawSize) {
  return [
    ...(rawSize.RequiredChoices || []),
    ...(rawSize.OptionalChoices || []),
  ];
}

function hasChoices(choiceSet: ChoiceSet) {
  return Boolean(choiceSet.choices.length);
}

export default function processSize(
  rawSize: RawSize,
  excludedChoiceSets?: string[],
): [_Size, ChoiceSets] {
  const rawChoiceSets = extractChoiceSets(rawSize);

  const rawImageDefault = rawSize.ImageLarge || rawSize.ImagePath || '';
  const rawName = rawSize.LongName || rawSize.PLUItem || '';
  const sizeDescription =
    rawSize.PLUModLongName || sizeDescriptionFromName(rawName);

  const sizeChoiceSets: ChoiceSets = {};
  let popularChoiceSet: ChoiceSet | undefined;
  const conditionalChoiceSets: SDict<string[]> = {};

  const shouldExcludeChoiceSet = (choiceSet: ChoiceSet) =>
    !hasChoices(choiceSet) ||
    (excludedChoiceSets || []).includes(choiceSet.key);

  rawChoiceSets.map(processChoiceSet).forEach(choiceSet => {
    if (shouldExcludeChoiceSet(choiceSet)) {
      return;
    }

    if (choiceSet.key === 'pop') {
      popularChoiceSet = choiceSet;
    } else {
      sizeChoiceSets[choiceSet.id] = choiceSet;
    }
  });

  let popularChoices: string[] = [];

  if (popularChoiceSet) {
    popularChoices = popularChoiceSet.choices.map(choice => choice.id);
  }

  // nested/combo choicesets
  rawChoiceSets.forEach(rawChoiceSet => {
    rawChoiceSet.Choices.forEach(rawChoice => {
      if (!rawChoice.ComboPLU) {
        return;
      }

      const rawNestedChoiceSets = extractChoiceSets(
        (rawChoice as unknown) as RawSize,
      );

      if (rawNestedChoiceSets.length) {
        const processedNestedChoiceSets = rawNestedChoiceSets
          .map(processChoiceSet)
          .filter(choiceSet => !shouldExcludeChoiceSet(choiceSet));

        processedNestedChoiceSets.forEach(choiceSet => {
          sizeChoiceSets[choiceSet.id] = choiceSet;
        });

        conditionalChoiceSets[
          rawChoice.PLUCode
        ] = processedNestedChoiceSets.map(choiceSet => choiceSet.id);
      }
    });
  });

  const size = {
    id: String(rawSize.PLUCode),
    available: rawSize.Available,
    name: nameWithoutSizeDescription(rawName),
    sizeDescription,
    description: (rawSize.Description || '').trim() || undefined,
    images: {
      default: processOrderingImagePath(rawImageDefault),
      ...processImageMap(rawSize.ImageMap),
    },
    baseMoneyPrice: rawSize.Price,
    basePointsPrice: rawSize.RedeemPoints / 100,
    basePointsAward: rawSize.AwardPoints / 100,
    kilojoules: rawSize.Kilojoules,
    choiceSets: lodash.keys(sizeChoiceSets),
    conditionalChoiceSets,
    category:
      rawSize.CategoryID === undefined ? undefined : String(rawSize.CategoryID),
    class: rawSize.ClassID === undefined ? undefined : String(rawSize.ClassID),
    popularChoices,
  };

  return [size, sizeChoiceSets];
}
