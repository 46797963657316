import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { RiArrowRightLine } from 'react-icons/ri';

import { OrderingSelectors } from 'polygon-ordering';

import combineStyles from '../utils/combineStyles';

import getThemeLookup from '../selectors/getThemeLookup';
import getHideTimes from '../selectors/getHideTimes';

import { setCurrentModal } from '../thunks/setCurrentModal';

import { DELIVERY_DETAILS_MODAL_ID } from '../modals/DeliveryDetailsModal';

import Text from './Text';
import TouchableOpacity from './TouchableOpacity';
import { useAppSelector, useAppDispatch } from '../app/hooks';

const { getDeliveryAddressString, getDeliveryTime } = OrderingSelectors;

const DeliveryDetails: React.FC = () => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const deliveryAddress = useAppSelector(getDeliveryAddressString);
  const deliveryTime = useAppSelector(getDeliveryTime);
  const hideTimes = useAppSelector(getHideTimes);
  const dispatch = useAppDispatch();

  return (
    <TouchableOpacity
      onClick={() =>
        dispatch(
          setCurrentModal({
            modalId: DELIVERY_DETAILS_MODAL_ID,
            preserveReturnModalDetails: true,
          }),
        )
      }
      style={styles.mainContainer}
      ariaLabel="delivery details"
    >
      <Text themeKey="deliveryDetailAddress" style={styles.deliveryDetail} block>
        {deliveryAddress}
      </Text>

      {!hideTimes && (
        <>
          <Text themeKey="deliveryDetailTime" style={styles.deliveryDetail}>
            <Text themeKey="deliveryDetailTimeETA">{`${t('eta')}: `}</Text>
            {moment(deliveryTime).format('LT')}
          </Text>
        </>
      )}

      <div style={styles.edit}>
        <Text themeKey="deliveryDetailEdit">{t('editDeliveryDetails')}</Text>
        <RiArrowRightLine
          style={combineStyles(styles.editIcon, p('deliveryDetailEditIcon', ['color', 'fontSize']))}
        />
      </div>
    </TouchableOpacity>
  );
};

const styles: Styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  deliveryDetail: {
    width: '100%',
    maxWidth: 220,
    textAlign: 'center',
    marginBottom: 10,
  },
  edit: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  editIcon: {
    marginLeft: 4,
  },
};

export default DeliveryDetails;
