import React from 'react';
import { useTranslation } from 'react-i18next';

import { OrderingSelectors } from 'polygon-ordering';

import { RiAlertFill } from 'react-icons/ri';

import combineStyles from '../utils/combineStyles';

import getThemeLookup from '../selectors/getThemeLookup';
import getShowMinExcludesSurchargeMessage from '../selectors/getShowMinExcludesSurchargeMessage';
import getInvalidStagedPurchasePresent from '../selectors/getInvalidStagedPurchasePresent';
import getMinimumOrderAmountNotMet from '../selectors/getMinimumOrderAmountNotMet';
import getInvalidOfferPresent from '../selectors/getInvalidOfferPresent';
import { useAppSelector } from '../app/hooks';

import Text from './Text';

const { getStagedPurchaseCount, getSelectedOffer, getSelectedOfferMiniSpendingDiffTotalPrice,getCurrencySymbol } =
  OrderingSelectors;

export const Message = ({
  message,
  showAlertIcon,
}: {
  message: string;
  showAlertIcon?: boolean;
}) => {
  const p = useAppSelector(getThemeLookup);

  return (
    <div style={styles.messageContainer}>
      {showAlertIcon && (
        <RiAlertFill
          style={combineStyles(
            styles.messageIcon,
            p('orderMessage', ['color', 'fontSize']),
            p('orderMessageIcon', ['color', 'fontSize']),
          )}
        />
      )}

      <Text themeKey="orderMessage" style={styles.messageText}>
        {message}
      </Text>
    </div>
  );
};

const OrderMessages = () => {
  const { t } = useTranslation();
  const minimumOrderAmountNotMet = useAppSelector(getMinimumOrderAmountNotMet);
  const invalidStagedPurchasePresent = useAppSelector(getInvalidStagedPurchasePresent);
  const invalidOfferPresent = useAppSelector(getInvalidOfferPresent);
  const stagedPurchaseCount = useAppSelector(getStagedPurchaseCount);
  const showMinExcludesSurchargeMessage = useAppSelector(getShowMinExcludesSurchargeMessage);

  //minimum order with coupon
  const selectedOffer = useAppSelector(getSelectedOffer);
  const minimumCouponAmountNotMet = useAppSelector(getSelectedOfferMiniSpendingDiffTotalPrice);
    const currency = useAppSelector(getCurrencySymbol);

  return (
    <>
      {minimumOrderAmountNotMet && Boolean(stagedPurchaseCount) && (
        <Message message={t('minimumOrderWarningMessage')} showAlertIcon />
      )}

      {invalidStagedPurchasePresent && (
        <Message message={t('invalidStagedPurchasePresentMessage')} showAlertIcon />
      )}

      {showMinExcludesSurchargeMessage && (
        <Message message={t('minimumExcludesSurchargeMessage')} />
      )}

      {invalidOfferPresent && <Message message={t('incompatibleOfferMessage')} showAlertIcon />}

      {minimumCouponAmountNotMet > 0 && Boolean(stagedPurchaseCount) && (
        <Message
          message={t('minimumOrderWithCouponMessage', {
            amount: minimumCouponAmountNotMet / 100.0,
            minimum: selectedOffer?.MinOrderValue,
            currency,
          })}
          showAlertIcon
        />
      )}
    </>
  );
};

const styles: Styles = {
  messageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginBottom: 15,
  },
  messageIcon: {
    marginRight: 6,
  },
  messageText: {
    textAlign: 'center',
  },
};

export default OrderMessages;
