import { put, takeEvery, all, select } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import * as stagePurchaseFromItem from '../../actionCreators/flows/stagePurchaseFromItem';

import getItems from '../../selectors/getItems';

import purchaseFromItem from '../../utils/ordering/purchaseFromItem';
import enhancePurchase from '../../utils/ordering/enhancePurchase';
import removeUnusedNestedChoiceSelections from '../../utils/ordering/removeUnusedNestedChoiceSelections';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';

export const requested = createFlowApprover(stagePurchaseFromItem);

export function* approved(
  action: ReturnType<typeof stagePurchaseFromItem.actions.approved>,
) {
  const {
    payload: { itemId, brandId },
    meta: { flowId },
  } = action;

  try {
    const allItems: Items = yield select(getItems);

    if (!allItems) {
      throw new Error('allItems is undefined');
    }

    const item = allItems[itemId];
    const purchase = removeUnusedNestedChoiceSelections(
      purchaseFromItem(item, uuidv4(), undefined, undefined, brandId),
      allItems,
    );

    const enhanced = enhancePurchase(purchase, allItems);

    if (!enhanced || !enhanced.valid) {
      throw new Error('cannot stage invalid purchase');
    }

    yield put(stagePurchaseFromItem.actions.succeeded({ purchase }, flowId));
  } catch (e) {
    yield put(
      stagePurchaseFromItem.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(stagePurchaseFromItem.events.REQUESTED, requested),
    takeEvery(stagePurchaseFromItem.events.APPROVED, approved),
  ]);
}
