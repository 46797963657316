import lodash from 'lodash';
import json5 from 'json5'; // more forgiving json parser
import { profileFieldConfig } from '../fields';
import { DEFAULT_RE_CAPTCHA_SITE_KEY } from '../../constants';
import moment from 'moment';

export function processProfile(raw) {
  const result = {};

  Object.keys(raw).forEach(rawFieldName => {
    const config = profileFieldConfig[rawFieldName];

    if (config) {
      result[config.appAlias] = raw[rawFieldName];
    }
  });

  return result;
}

function processOrderingConfig(raw) {
  return {
    deliveryTimeAsap: raw.delivery_time_asap,
    couponOrderingMethod: raw.coupon_order_type,
    enableDineInNameMode: Boolean(raw.enable_dine_in_name_mode),
    enableNonMemberPhoneNumberRequired: Boolean(raw.enable_phone_number_required),
    enableOrderNotes: Boolean(raw.enable_order_notes),
    hideFreeChoiceMessage: Boolean(raw.hide_free_choice_message),
    enableDeliveryNotes: raw.enable_delivery_notes !== false, // TODO: get this added to PC
    enableTipping: Boolean(raw.tipping_enabled),
    freeChoiceOrderingMethod: raw.coupon_order_type, // TODO: get this added to polygon central
    orderingCurrency: raw.order_currency || 'aud',
    fallbackLatitude: raw.default_latitude,
    fallbackLongitude: raw.default_longitude,
    initialZoom: raw.default_zoom,
    enableGuestOrdering: Boolean(raw.non_member_ordering),
    tableNumberLabel: raw.table_no_cust_label || null,
    enableMultipleDeliveryEstimate: Boolean(raw.enable_multi_delivery_estimate),
    enableDuplicateSaleProtection: Boolean(raw.enable_duplicate_sale_protection),
    enableSaleInputTypes: Boolean(raw.enable_sale_input_types),
    choiceSetDiscountConfig: {
      tierSize: raw.choice_set_discount_tier_size,
      choiceSetKey: raw.choice_set_discount_choice_set_key,
      discountPlu: raw.choice_set_discount_discount_plu,
      threshold: raw.choice_set_discount_threshold,
      useChoiceSetDiscountMap: raw.use_choice_set_discount_map,
      choiceSetDiscountMap: raw.choice_set_discount_map
        ? JSON.parse(raw.choice_set_discount_map)
        : undefined,
    },
    thresholdOrderingProviders: raw.threshold_ordering_providers,
    enablePayLater: Boolean(raw.pay_at_till_payment_enabled),
    hideNegativeIngredients: Boolean(raw.hide_negative_ingredients),
    futureOrderingConfig: {
      enableFutureOrdering: Boolean(raw.enable_future_ordering),
      futureOrderingLimit: raw.future_ordering_limit || 0,
      futureOrderingLimitCatering: raw.future_ordering_limit_catering
        ? raw.future_ordering_limit_catering
        : raw.future_ordering_limit || 1,
    },
    stockBalanceThreshold0: raw.stock_balance_threshold_0,
    stockBalanceThreshold1: raw.stock_balance_threshold_1,
    stockBalanceThreshold2: raw.stock_balance_threshold_2,
    stockBalanceThreshold3: raw.stock_balance_threshold_3,
    stockBalanceThreshold4: raw.stock_balance_threshold_4,
    stockBalanceIgnoreThreshold: raw.stock_balance_ignore_threshold,

    fallbackOpeningHours: raw.fallback_opening_hours || moment().toISOString(),
    fallbackClosingHours: raw.fallback_closing_hours || moment().endOf('day').toISOString(),

    includeDiscountMinSpend: raw.include_discount_min_spend,
    includeCouponDiscountSurcharge: raw.apply_coupon_before_surcharge_calculation,
    localiseOrderTimes: raw.localise_order_times,
  };
}

function processLoyaltyConfig(raw) {
  return {
    brazeAnalyticsKey: raw.brazeAnalyticsKey,
    purchaseUnits: raw.purchase_units,
    disableClaimMissingPointsScreen: raw.claim_missing_points_screen,
    disableOffersScreen: raw.offers_screen,
    disablePastOrdersScreen: raw.past_orders_screen,
    disableMemberMoneyScreen: raw.member_money_screen,
    disableProfileScreen: raw.profile_screen,
    disableReplaceCardScreen: raw.replace_card_screen,
    disableRewardsScreen: raw.rewards_screen,
    disableTransactions: raw.transaction_history_screen,
    disableFavouritesScreen: raw.favourites_screen,

    // this key is not used any where, it's not even in raw hmmmmm.
    disableWebLoyalty:
      raw.disable_loyalty_site !== undefined ? Boolean(raw.disable_loyalty_site) : true,

    useQrCode: Boolean(raw.use_qr_code),
    showExpiredCoupons: Boolean(raw.show_expired_coupons),
    showRedeemedCoupons: Boolean(raw.show_redeemed_coupons),
    enableOnlyLoyalty: Boolean(raw.enable_only_loyalty),
    enableOnlyGiftcardPurchases: Boolean(raw.enable_only_giftcard_purchases),
    tierLoyaltyEnabled: Boolean(raw.default_loyalty_tier !== null),
    mobileRegexWhiteList: raw.mobile_whitelist || null,
    appName: raw.loyalty_app_name,
    giftCardPlu: raw.gift_card_plucode,
    hybridCardPlu: raw.hybrid_card_plucode,
    importedGiftCardRegex: raw.gift_card_alias_regex || null,
    loyaltyMoneyName: raw.money_name,
    loyaltyMoneySymbol: raw.money_currency,
    loyaltyPointsName: raw.points_currency,
    loyaltyDisplayAddReceipt: Boolean(raw.display_add_receipt),
    loyaltyRequiresVerification: Boolean(raw.registration_requires_verification),
    supportEmail: raw.client_support_email,
    termsAndConditionsLink: raw.terms_url,
    termsAndConditionsText: raw.terms_and_conditions || '',
    displayPointsAsWholeNumber: Boolean(raw.display_points_as_whole_num),
    creditLoyaltyThreshold: raw.credit_loyalty_threshold,
    creditLoyaltyEnabled: raw.credit_loyalty_enabled,
    ringView: Boolean(raw.credit_loyalty_enabled),
    enableGiftcardPurcase:
      raw.enableWebGiftcardPurchase || raw.enable_web_gc_purchase
        ? raw.auto_gift_cardset !== ''
          ? true
          : false
        : false,
    displayWallet: Boolean(raw.display_wallet_loyalty),
    selfDelete: Boolean(raw.self_delete),
    loyaltySendVerificationEmail: raw.send_verification_email,
    loyaltyEnableSmsVerification: raw.enable_sms_verification,
    //registration verification limitation
    verificationAttempts: {
      verificationMaxAttempts: raw.maximum_verification_attempts,
      verificationDelay: raw.verification_delay,
      verificationLockout: raw.verification_lockout,
    },
  };
}

function processPaymentConfig(raw) {
  return {
    paymentGatewayClientId: raw.gateway_client_id,
    paymentGatewayPublicKey: raw.gateway_public_key,
    enableDynamicPaymentGatewayConfig: Boolean(raw.use_payment_intents),
    merchantAccount: raw.stripe_connected_account,
    enableStripeCvcRevalidation: Boolean(raw.enable_stripe_cvc_revalidation),
  };
}

function brazeAnalytics(raw) {
  return {
    brazeEnabled: raw.braze_enabled,
    brazeKey: raw.braze_app_id_web,
    brazeSdkEndpoint: raw.braze_sdk_endpoint,
  };
}

function processSystemConfig(raw) {
  return {
    enableReCaptcha: Boolean(raw.use_google_recaptcha),
    reCaptchaSiteKey: raw.google_recaptcha_public || DEFAULT_RE_CAPTCHA_SITE_KEY,
    googleLoginButtonClientKey: raw.google_login_client_id,
    facebookLoginButtonClientId: raw.facebook_login_client_id,
    appleLoginButtonClientId: raw.apple_login_client_id,
    //member register form
    passwordComplexity: lodash.get(raw, 'password_complexity'),

    // Script & HTML injection
    orderingScriptInjection: raw.ordering_script_injection,
    loyaltyScriptInjection: raw.loyalty_script_injection,
    loyaltyHtmlHeadInjection: raw.loyalty_html_head_injection,
    reportingScriptInjection: raw.reporting_script_injection,
  };
}

function processCardSets(rawCardsetsList) {
  const result = {};

  rawCardsetsList.forEach(rawCardset => {
    result[rawCardset.ID] = {
      accumulatePoints: Boolean(rawCardset.AccumulatePoints), // seems unused?
      alphaPrefix: rawCardset.Alpha,
      cardType: rawCardset.CardType,
      id: String(rawCardset.ID),
      numericPrefix: rawCardset.Numeric,
      pointsAwardRate: rawCardset.PointsAwardRate,
      pointsRedeemRate: rawCardset.PointsRedeemRate,
    };
  });

  return result;
}

// fields for member registration form
function processPossibleValues(possibilities) {
  if (!possibilities) {
    return [];
  }

  return possibilities.map(p => ({ value: p.value, label: p.name }));
}

function processProfileField(raw) {
  const possibleValues = processPossibleValues(raw.values);
  let invalidField = false;

  if (raw.input_type === 'select' && possibleValues.length === 0) {
    invalidField = true;
  }

  let confirmsFieldId;

  if (raw.confirm && raw.name.includes('Confirm')) {
    confirmsFieldId = raw.confirm;
  }

  const label = raw.label ? raw.label.replace(/\[(.*?)\]/g, '') : undefined;

  const descriptionRegex = /\[(.*?)\]/;

  const matches = descriptionRegex.exec(raw.label || '');

  let description;

  if (matches && matches[1]) {
    description = matches[1];
  }

  return {
    id: raw.name,
    label: label,
    registerMode: invalidField ? 'disabled' : raw.mode, // required | optional | disabled
    editMode: invalidField ? 'hidden' : raw.edit_mode, // once | editable | hidden | six_monthly
    confirmsFieldId,
    inputType: raw.input_type,
    minLength: raw.minlen,
    maxLength: raw.maxlen,
    defaultValue: raw.default,
    placeholderValue: raw.placeholder,
    description,
    possibleValues,
  };
}

// TODO: refactor all of this
function processFrontendConfig(raw) {
  const hideTimesForSaleTypes = (raw.hideTimesForSaleTypes || []).map(saleType =>
    typeof saleType === 'number' ? saleType : parseInt(saleType),
  );

  const deliveryAvailableTime = (time, fallback) => {
    console.log('the time:', time);
    if (time) {
      const parsedTime = time.split(':');
      return moment().hour(parsedTime[0]).minute(parsedTime[1]).toISOString();
    } else {
      return fallback;
    }
  };

  return {
    firebaseApiKey: raw.firebaseApiKey,
    firebaseAppId: raw.firebaseAppId,
    firebaseAuthDomain: raw.firebaseAuthDomain,
    firebaseMeasurementId: raw.firebaseMeasurementId,
    firebaseMessagingSenderId: raw.firebaseMessagingSenderId,
    firebaseProjectId: raw.firebaseProjectId,
    firebaseStorageBucket: raw.firebaseStorageBucket,
    tiktokAnalyticsKey: raw.tiktokAnalyticsKey,
    googleAnalyticsKey: raw.googleAnalyticsKey,
    facebookAnalyticsKey: raw.facebookAnalyticsKey,
    googleTagManagerKey: raw.googleTagAnalyticsKey,
    brandingFavicon: raw.brandingFavicon,
    closingPadding: raw.closingPadding,
    collapsableChoiceSetsEnabled: Boolean(raw.collapsableChoiceSetsEnabled),
    disableAllBrandsMenuTab: Boolean(raw.disableAllBrandsMenuTab),
    disableCategoryCount: Boolean(raw.disableCategoryCount),
    disableLoyalty: Boolean(raw.disableLoyalty),
    disableMenuSearch: Boolean(raw.disableMenuSearch),
    disableOffers: Boolean(raw.disableCoupons),
    disablePickupTimeSelection: Boolean(raw.disablePickupTimeSelection),
    enabledPaymentMethods: raw.enabledPaymentMethods,
    enabledSaleTypes: raw.enabledSaleTypes,
    enableMenuLocationDetails: Boolean(raw.enableMenuStoreDetails),
    enableFraudPreventionMessage: Boolean(raw.enableFraudPreventionMessage),
    enableVirtualBrands: Boolean(raw.enableVirtualBrands),
    enableAutoselectFirstLocation: Boolean(raw.enableAutoselectFirstLocation),
    excludedChoiceSets: raw.excludedChoiceSets,
    flattenDeliverySurcharges: raw.flattenDeliverySurcharges,
    floatingScreenBackgroundImagePath: raw.floatingScreenBackgroundImagePath,
    fontFamilies: raw.fontFamilies,
    geocoderCountryRestriction: raw.geocoderCountryRestriction,
    googleMapsApiKey: raw.googleMapsApiKey,
    headerBrandImagePath: raw.headerBrandImagePath,
    headerBrandingType: raw.headerBrandingType,
    headerBrandName: raw.headerBrandName,
    heroScreenImagePath: raw.heroScreenImagePath,
    hideTimesForSaleTypes,
    hideLocationHours: Boolean(raw.hideLocationHours),
    hideMenuItemSizeDescription: raw.hideMenuItemSizeDescription,
    internationalMobileAllowed: raw.internationalMobileAllowed,
    locationAddressTemplate: raw.locationAddressTemplate,
    openingPadding: raw.openingPadding,
    relaxMenuItemHeightConsistency: raw.relaxMenuItemHeightConsistency,
    collapseOptionalChoiceSetsByDefault: Boolean(raw.collapseOptionalChoiceSetsByDefault),
    stringOverrides: processStringOverrides(raw.stringOverrides),
    hideHeroScreenImage: raw.hideHeroScreenImage,
    enableStockPolling: Boolean(raw.enableStockPolling),
    stockPollingInterval: raw.stockPollingInterval,
    //useQrCode: Boolean(raw.useQrCode), // This is in the loyalty config
    showExpiredCoupons: Boolean(raw.showExpiredCoupons),
    showRedeemedCoupons: Boolean(raw.showRedeemedCoupons),

    deliveryTimeOpen: deliveryAvailableTime(raw.deliveryTimeOpen, moment().toISOString()),
    deliveryTimeClose: deliveryAvailableTime(
      raw.deliveryTimeClose,
      moment().endOf('day').toISOString(),
    ),

    /* Script injection has been split up into three parts, however, the web section still exists */
    scriptInjection: raw.scriptInjection,
  };
}

function processStringOverrides(raw) {
  let result = (raw || '').trim() || undefined;

  if (typeof result === 'string') {
    try {
      result = json5.parse(result);
    } catch (e) {
      console.error('problem processing string overrides', { result, e });
      result = undefined;
    }
  }

  return result;
}

export default function processConfig(raw) {
  return {
    ...brazeAnalytics(raw.braze_cfg),
    ...processLoyaltyConfig(raw.loyalty_cfg),
    ...processOrderingConfig(raw.ordering_cfg),
    ...processPaymentConfig(raw.payment_cfg),
    ...processSystemConfig(raw.system_cfg),
    ...processFrontendConfig(raw.webordering_cfg),
    profileFields: raw.screen_cfg.REGISTER.map(processProfileField),
    cardsets: processCardSets(lodash.get(raw, 'cardsets.data') || []),
  };
}

// additional fields taken from polygon-mobile
// loyaltySendVerificationEmail: loyalty_cfg.send_verification_email,
// loyaltyEnableSmsVerification: loyalty_cfg.enable_sms_verification,
// loyaltyTwoFactorAuthentication: loyalty_cfg.two_factor_authentication,
// selfDeleteEnabled: !!loyalty_cfg.self_delete,
// minimumPasswordLength: lodash.get(system_cfg, 'minimum_password_length'),
// minimumOrderEnabled: !!ordering_cfg.min_order_requirements, // not actually used it seems
// passwordComplexity: lodash.get(system_cfg, 'password_complexity'),

// additional things taken from the kiosk
// yield put(setEnableDineInNameMode(enableDineInNameMode));
