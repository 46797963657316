import { call, put, takeEvery, all, select } from 'redux-saga/effects';

import * as fetchHistoricalOrders from '../../actionCreators/flows/fetchHistoricalOrders';
import { setHistoricalOrders } from '../../actionCreators/member';

import { getOrderingProvider } from '../../selectors/config';
import getMember from '../../selectors/getMember';

import Api, { FetchParams, ApiResponse } from '../../utils/Api';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';
import processHistoricalOrder from '../../utils/processors/processHistoricalOrder';
import { ORDERING_PROVIDER } from '../../constants/orderingProvider';

export const requested = createFlowApprover(fetchHistoricalOrders);

export function* approved(
  action: ReturnType<typeof fetchHistoricalOrders.actions.approved>,
) {
  const {
    payload: { authenticationMethod = 'member' },
    meta: { flowId },
  } = action;

  try {
    const orderingProvider = yield select(getOrderingProvider);
    const member = yield select(getMember);

    const params: FetchParams =
      orderingProvider === ORDERING_PROVIDER.KIOSK
        ? {
            path: `/api/v1/profile/trusted-orders/${member.memberNumber}`,
            method: 'GET',
          }
        : {
            path: '/api/v1/profile/orders',
            method: 'GET',
          };

    const response: ApiResponse = yield call(
      Api.fetch,
      params,
      orderingProvider === ORDERING_PROVIDER.KIOSK
        ? 'trusted'
        : authenticationMethod,
    );

    const rawOrders: RawHistoricalOrder[] = response.data;
    const processedOrders = rawOrders.map(processHistoricalOrder);

    yield put(setHistoricalOrders(processedOrders));

    yield put(fetchHistoricalOrders.actions.succeeded({}, flowId));
  } catch (e) {
    yield put(
      fetchHistoricalOrders.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(fetchHistoricalOrders.events.REQUESTED, requested),
    takeEvery(fetchHistoricalOrders.events.APPROVED, approved),
  ]);
}
