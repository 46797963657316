import { getCurrencySymbol } from '../selectors/config';
import { useSelector } from 'react-redux';
import { centsToDollars } from '../utils/misc';

const useFormattedCurrency = (
  cents?: number | null,
  {
    hideZeroCents,
    hideSymbol,
  }: { hideZeroCents?: boolean; hideSymbol?: boolean } = {},
) => {
  const symbol = useSelector(getCurrencySymbol);
  const amount = centsToDollars(cents);

  if (amount == undefined) {
    return '$ERR';
  }

  const result = `${amount < 0 ? '-' : ''}${
    Boolean(hideSymbol) ? '' : symbol
  }${Math.abs(amount).toFixed(2)}`;

  if (hideZeroCents && result.endsWith('.00')) {
    return result.slice(0, -3);
  }

  return result;
};

export default useFormattedCurrency;
