import { createCustomAction } from 'typesafe-actions';

import { singleValuePayload } from '../utils/actions';

export const SET_SHADOW_NOTES = '$SET_SHADOW_NOTES';
export const setShadowNotes = createCustomAction(
  SET_SHADOW_NOTES,
  singleValuePayload<typeof SET_SHADOW_NOTES, string>(),
);

export const SET_SHADOW_CART = '$SET_SHADOW_CART';
export const setShadowCart = createCustomAction(
  SET_SHADOW_CART,
  singleValuePayload<typeof SET_SHADOW_CART, ShadowItem[] | null>(),
);

export const ADD_TO_SHADOW_CART = '$ADD_TO_SHADOW_CART';
export const addToShadowCart = createCustomAction(
  ADD_TO_SHADOW_CART,
  singleValuePayload<typeof ADD_TO_SHADOW_CART, ShadowItem[] | null>(),
);
