import { createSelector } from 'reselect';
import lodash from 'lodash';

import { OrderingSelectors } from 'polygon-ordering';

const { getLocation, getRootCategory } = OrderingSelectors;

export default createSelector([getRootCategory, getLocation], (rootCategory, location) => {
  const categories = rootCategory?.subCategories || [];
  const representedBrandIds = lodash
    .chain(categories)
    .map(category => category.brandId)
    .uniq()
    .value();

  return (location?.brands || []).filter(brand => representedBrandIds.includes(brand.id));
});
