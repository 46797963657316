import { eventChannel } from 'redux-saga';

import { caseInsensitiveContains } from './utils';
import logEventToConsole from './utils/logEventToConsole';

import LoggingHandler from './LoggingHandler';

interface TyroInitiateTransactionParams {
  amount: string;
  integratedReceipt: boolean;
  integratedReceiptWidth: string;
  mid?: number;
  tid?: number;
  integrationKey?: string;
  transactionId?: string;

  //   enableSurcharge?: boolean;
  //   requestCardToken?: boolean;
  //   cashout?: string;
}

interface TyroInitiatePurchaseParams extends TyroInitiateTransactionParams {
  enableSurcharge?: boolean;
  requestCardToken?: boolean;
  cashout?: string;
}

interface TyroPairingResponse {
  message: string;
  status: 'inProgress' | 'success' | 'failure';
  integrationKey?: string;
}

// example 1
// {
//   "result": "APPROVED",
//   "transactionId": "6d265105cb1bd64b5f383914a04ea8a746c0",
//   "cardType": "MasterCard",
//   "transactionReference": "719096",
//   "authorisationCode": "055397",
//   "issuerActionCode": "00",
//   "elidedPan": "xxxxxxxxxxxx7213",
//   "rrn": "719096042758",
//   "baseAmount": "6.00",
//   "transactionAmount": "6.00",
//   "customerReceipt": "
//                CUSTOMER COPY

//                   CS Test
//               Sydney NSW 2000

//             Tyro Payments EFTPOS

//             DEBIT MasterCard
//   AID: A0000000041010
//   Card: xxxxxxxxxxxx7213(t)

//   Purchase           AUD             $6.00
//   Surcharge          AUD             $0.10
//                                 ----------
//   Total              AUD             $6.10

//   APPROVED                              00
//   No pin or signature required

//   Terminal ID: 752
//   Transaction Ref: 719096
//   Authorisation No: 055397
//   31 May 2019 at 04:27 AM
//   "
// }

// {
//   "result": "CANCELLED",
//   "transactionId": "612bb35ae1c2b4416a287b1fcb800b5fd3dc",
//   "customerReceipt": "             CUSTOMER COPY             \n\n                CS Test                 \n            Sydney NSW 2000             \n\n         TRANSACTION CANCELLED          \n\n          Tyro Payments EFTPOS          \n\nCard: xxxxxxxxxxxx7213(t)\n\nTerminal ID: 752\n31 May 2019 at 09:25 AM\n"
// }

// example 2
// {
//   "result": "APPROVED",
//   "transactionId": "bf4f3c0c9e68c740393ad5b03a45d7ba24d3",
//   "cardType": "EFTPOS",
//   "transactionReference": "719352",
//   "authorisationCode": "034756",
//   "issuerActionCode": "00",
//   "elidedPan": "xxxxxxxxxxxx5100",
//   "rrn": "719352051520",
//   "baseAmount": "60.00",
//   "transactionAmount": "60.00",
//   "customerReceipt": "             CUSTOMER COPY             \n\n                CS Test                 \n            Sydney NSW 2000             \n\n          Tyro Payments EFTPOS          \n\nEFTPOS - Savings\nCard: xxxxxxxxxxxx5100(s)\n\nPurchase           AUD            $60.00\n                              ----------\nTotal              AUD            $60.00\n\nAPPROVED                              00\n\nTerminal ID: 752\nTransaction Ref: 719352\nAuthorisation No: 034756\n03 Jun 2019 at 05:15 AM\n"
// }
export interface TyroTransactionData {
  result:
    | 'APPROVED'
    | 'CANCELLED'
    | 'REVERSED'
    | 'DECLINED'
    | 'SYSTEM'
    | 'ERROR'
    | 'NOT STARTED'
    | 'UNKNOWN';
  cardType?: string; // 'Visa' | 'Mastercard';
  customerReceipt?: string; // only if requested
  transactionReference: string;
  authorisationCode: string;
  issuerActionCode: string;
  elidedPan: string;
  rrn: string;

  transactionId?: string;
  // tipAmount: string;
  // tipCompletionReference: string;
  // tabCompletionReference: string;
  // preAuthCompletionReference: string;
  // cardToken: string;
  // cardTokenExpiryDate: string;
  // cardTokenStatusCode: string;
  // cardTokenErrorMessage: string;
}

// ex 1
// isError: true
// options: ["OK"]
// text: "Invalid transaction details (400)."

// ex 2
// isError: true
// options: (2) ["RETRY", "QUIT"]
// text: "Terminal is busy processing another transaction (503)."

// ex 3
// isManualCancel: true
// options: (2) ["YES", "NO"]
// text: "Are you sure you want to cancel?"

// ex 4
// text: "Cancel this transaction?"
// options: (2) ["YES", "NO"]

// ex 5
// options: (2) ["YES", "NO"]
// text: "APPROVED W/ SIGNATURE. Signature OK?"
interface TyroQuestion {
  isError: boolean;
  options: string[];
  text: string;
}

type TyroAnswerCallback = (answer: string) => void;
type TyroStatusCallback =  (statusMsg: string, txnStarted: boolean) => void;

interface TyroMerchantReceiptResponse {
  signatureRequired: boolean;
}

interface TyroTransactionCallbacks {
  transactionCompleteCallback: (transactionData: TyroTransactionData) => void;

  receiptCallback: (response: TyroMerchantReceiptResponse) => void;

  questionCallback: (
    question: TyroQuestion,
    answerCallback: TyroAnswerCallback,
  ) => void;

  statusMessageCallback: TyroStatusCallback;
}

interface IClient {
  pairTerminal: (
    mid: string,
    tid: string,
    callback: (response: TyroPairingResponse) => void,
  ) => void;

  initiatePurchase: (
    requestParams: TyroInitiatePurchaseParams,
    transactionCallbacks: TyroTransactionCallbacks,
  ) => void;

  initiateRefund: (
    requestParams: TyroInitiateTransactionParams,
    transactionCallbacks: TyroTransactionCallbacks,
  ) => void;

  cancelCurrentTransaction: (question?: TyroQuestion) => void;
}

interface TYRO {
  IClient: {
    new (
      apiKey: string,
      params: {
        posProductVendor: string;
        posProductName: string;
        posProductVersion: string;
      },
    ): IClient;
  };
  IClientWithUI:{
    new (
        apiKey: string,
        params: {
          posProductVendor: string;
          posProductName: string;
          posProductVersion: string;
        },
    ): IClient;
  };
}

export interface TyroWrapperResult {
  success: boolean;
  transactionData?: TyroTransactionData;
  requestedSignature?: boolean;
  integrationKey?: string;
}

type Emitter = (input: TyroWrapperResult) => void;

export const TYRO_LOG_TOOL_LOCATIONS = {
  simulator: 'https://iclientsimulator.test.tyro.com/logs.html',
  prod: 'https://iclient.tyro.com/logs.html',
  test: 'https://iclient.test.tyro.com/logs.html',
};

const TYRO_STATUS_MAP = {
  APPROVED: 'Transaction Approved',
  CANCELLED: 'Transaction Cancelled',
  REVERSED: 'Transaction Reversed',
  DECLINED: 'Transaction Decline',
  'SYSTEM ERROR': 'There was a system error',
  'NOT STARTED': 'Transaction not started',
  UNKNOWN: 'An Unknown status was returned from the Eftpos terminal',
}

export default class TyroWrapper {
  private iclient?: IClient;
  private emitter?: Emitter;

  private transactionInProgress?: boolean;
  private requestedSignature?: boolean;
  private txnStarted?: boolean;

  private loggingHandler?: LoggingHandler;

  private statusCallback?: TyroStatusCallback;

  constructor (loggingHandler?: LoggingHandler) {
    if (loggingHandler) {
      this.loggingHandler = loggingHandler;
    }
  }

  static async downloadTYRO (paymentEnvironment: string, windowOverride: any) {
    const scriptPromise = new Promise((resolve, reject) => {
      const win = windowOverride || window;
      const script = win.document.createElement('script');
      win.document.body.appendChild(script);
      script.onload = resolve;
      script.onerror = reject;
      script.setAttribute('id', 'tyroWrapper');
      script.async = true;

      switch (paymentEnvironment.toLowerCase()) {
        case 'production':
        case 'prod': {
          script.src = 'https://iclient.tyro.com/iclient-v1.js';
          break;
        }
        case 'simulated':
        case 'simulator': {
          script.src = 'https://iclientsimulator.test.tyro.com/iclient-v1.js';
          //script.src = 'https://iclientsimulator.test.tyro.com/iclient-with-ui-v1.js';
          break;
        }
        case 'test':
        case 'testing':
        default: {
          script.src = 'https://iclient.test.tyro.com/iclient-v1.js';
          break;
        }
      }
    });

    await scriptPromise.finally();

    logEventToConsole({
      message: 'downloaded TYRO',
      level: 'info',
      details: {
        paymentEnvironment,
        windowOverride,
      },
    });
  }

  async init (
    paymentEnvironment: string,
    tyroApiKey: string,
    posProductVendor: string,
    posProductName: string,
    posProductVersion: string,
    windowOverride?: any,
  ) {
    this.log('tyro init', undefined, {
      paymentEnvironment,
      tyroApiKey,
      posProductVendor,
      posProductName,
      posProductVersion,
    });

    await TyroWrapper.downloadTYRO(paymentEnvironment, windowOverride);

    const TYRO: TYRO = (windowOverride || (window as any)).TYRO;

    if (!TYRO) {
      throw new Error('tyro library download failed');
    }

    //this.iclient = new TYRO.IClientWithUI(tyroApiKey, {
    this.iclient = new TYRO.IClient(tyroApiKey, {
      posProductVendor,
      posProductName,
      posProductVersion,
    });

    this.transactionInProgress = false;
    this.requestedSignature = false;
  }

  private log (message: string, level: LogLevel = 'info', details?: {}) {
    if (this.loggingHandler) {
      this.loggingHandler.log(message, level, { ...details, persist: true });
    } else {
      logEventToConsole({
        message,
        level,
        details,
      });
    }
  }

  private logForStaff (message: string) {
    if (this.loggingHandler) {
      this.loggingHandler.log(message, 'info', { forStaff: true });
    }
  }

  private startTransactionShared () {
    if (this.transactionInProgress) {
      throw new Error('transaction already in progress');
    }

    const channel = eventChannel(emitter => {
      this.emitter = emitter;

      return this.closeChannelRequestHandler.bind(this);
    });

    this.transactionInProgress = true;
    this.requestedSignature = false;
    this.txnStarted = false;

    return channel;
  }

  private endTransactionShared (result: TyroWrapperResult) {
    if (!this.emitter) {
      throw new Error('no emitter configured');
    }

    this.emitter(result);

    this.transactionInProgress = false;

    this.emitter = undefined;
  }

  private closeChannelRequestHandler () {
    this.log('closeChannelRequestHandler');

    this.cancelCurrentTransaction();
  }

  startPaymentTransaction (requestParams: TyroInitiatePurchaseParams, statusCallback?: TyroStatusCallback) {
    this.log('startPaymentTransaction', undefined, requestParams);
    this.logForStaff(
      `starting payment transaction for $${parseInt(requestParams.amount) /
        100}`,
    );

    const channel = this.startTransactionShared();

    if (!this.iclient) {
      throw new Error('not initialised');
    }

    this.statusCallback = statusCallback;

    this.iclient.initiatePurchase(requestParams, {
      receiptCallback: this.receiptCallback.bind(this),
      questionCallback: this.questionCallback.bind(this),
      statusMessageCallback: this.statusMessageCallback.bind(this),
      transactionCompleteCallback: this.transactionCompleteCallback.bind(this),
    });

    return channel;
  }

  startRefundTransaction (requestParams: TyroInitiateTransactionParams) {
    this.log('startRefundTransaction');

    this.logForStaff(
      `starting refund transaction for $${parseInt(requestParams.amount) / 100}`,
    );

    const channel = this.startTransactionShared();

    if (!this.iclient) {
      throw new Error('not initialised');
    }

    this.iclient.initiateRefund(requestParams, {
      receiptCallback: this.receiptCallback.bind(this),
      questionCallback: this.questionCallback.bind(this), // TODO: does refund need its own question callback?
      statusMessageCallback: this.statusMessageCallback.bind(this),
      transactionCompleteCallback: this.transactionCompleteCallback.bind(this),
    });

    return channel;
  }

  pairTerminal (
    mid: string,
    tid: string,
    callback: (response: TyroPairingResponse) => any,
  ) {
    if (!this.iclient) {
      throw new Error('not initialised');
    }

    this.iclient.pairTerminal(mid, tid, callback);
  }

  cancelCurrentTransaction (question?: TyroQuestion) {
    this.log('cancelCurrentTransaction', undefined, { question });

    this.logForStaff('cancelling transaction');

    if (!this.iclient) {
      throw new Error('not initialised');
    }

    this.iclient.cancelCurrentTransaction();
  }

  private questionCallback (
    question: TyroQuestion,
    answerCallback: TyroAnswerCallback,
  ) {
    this.log('tyro question', undefined, { question });
    this.logForStaff(`question from tyro: ${question.text}`);

    const { options = [] } = question;

    // manual control
    // answerCallback(prompt(JSON.stringify(question, null, 2)) as string);

    const answer = (option: string) => {
      this.log('tyro answer', 'info', { option });
      this.logForStaff(`response to tyro: ${option}`);

      answerCallback(option);
    };

    const matches = (s: string) => caseInsensitiveContains(question.text, s);
    if (options.includes('QUIT')) {
      answer('QUIT');
    } else if (matches('signature ok')) {
      answer('NO');
      this.requestedSignature = true;
    } else if (matches('cancel') && options.includes('YES')) {
      answer('YES');
    } else if (options.length === 1 && options.includes('OK')) {
      answer('OK');
    } else {
      this.cancelCurrentTransaction(question);
    }
  }

  private statusMessageCallback (status: string) {
    this.log('tyro status message', undefined, { status });
    this.logForStaff(`status message: ${status}`);

    if (this.statusCallback) {
      const reProcessing = /Processing\s+transaction/gi;
      let statusMsg = status;
      if (statusMsg in TYRO_STATUS_MAP)
        statusMsg = TYRO_STATUS_MAP[statusMsg  as keyof typeof TYRO_STATUS_MAP ];

      if (reProcessing.test(status))
        this.txnStarted = true;

      this.statusCallback(statusMsg, this.txnStarted!);
    }
  }

  private receiptCallback (response: TyroMerchantReceiptResponse) {
    this.log('tyro merchant receipt', undefined, { response });
  }

  private transactionCompleteCallback (transactionData: TyroTransactionData) {
    this.log('tyro transaction complete', undefined, { transactionData });
    this.logForStaff(`tyro transaction complete: ${transactionData.result}`);

    const transactionSuccessful = transactionData.result === 'APPROVED';

    this.endTransactionShared({
      success: transactionSuccessful,
      transactionData,
      requestedSignature: !!this.requestedSignature,
    });
  }
}
