import React from 'react';
import { useTranslation } from 'react-i18next';
import { DEVICE_TYPES, DESKTOP_MODAL_TYPES } from '../constants';
import ModalMobile from './ModalMobile';
import ModalDesktop from './ModalDesktop';
import ModalCallout from './ModalCallout';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { setCurrentModal } from '../thunks/setCurrentModal';

interface IProps {
  title?: string;
  forceFloatingTitle?: boolean;
  closeButtonLabel?: string;
  clickBackgroundToClose?: boolean;
  desktopMaxWidth?: number;
  desktopMinWidth?: number;
  desktopMinHeight?: number;
  desktopMaxHeight?: number;
  desktopModalType?: number;
  desktopContainerStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  mobileContainerStyle?: React.CSSProperties;
  tallestPossible?: boolean;
  locked?: boolean;
  hideFloatingHeader?: boolean;
  expandToMaxWidth?: boolean;
}

const Modal: React.FC<IProps> = ({
  title,
  forceFloatingTitle = false,
  closeButtonLabel: closeButtonLabelProp,
  clickBackgroundToClose = false,
  desktopModalType = DESKTOP_MODAL_TYPES.DEFAULT,
  desktopMaxWidth,
  desktopMinWidth,
  desktopMaxHeight,
  desktopMinHeight,
  desktopContainerStyle,
  containerStyle,
  mobileContainerStyle,
  tallestPossible,
  expandToMaxWidth,
  children,
  locked,
  hideFloatingHeader,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const deviceType = useAppSelector(state => state.deviceType);
  const previousModalId = useAppSelector(state => state.previousModalId);
  const calloutOffsets = useAppSelector(state => state.calloutOffsets);

  let closeButtonLabel = closeButtonLabelProp;

  if (closeButtonLabelProp == null) {
    closeButtonLabel = t('button.modalClose.cancel');
  }

  let Component;

  if (deviceType === DEVICE_TYPES.MOBILE) {
    Component = ModalMobile;
  } else if (deviceType === DEVICE_TYPES.DESKTOP) {
    if (desktopModalType === DESKTOP_MODAL_TYPES.CALLOUT) {
      Component = ModalCallout;
    } else {
      Component = ModalDesktop;
    }
  }

  if (!Component) {
    dispatch(setCurrentModal(null));
    return null;
  }

  return (
    <Component
      children={children}
      closeButtonLabel={closeButtonLabel}
      previousModalId={previousModalId}
      clickBackgroundToClose={clickBackgroundToClose}
      title={title}
      forceFloatingTitle={forceFloatingTitle}
      setCurrentModal={params => dispatch(setCurrentModal(params))}
      desktopMaxWidth={desktopMaxWidth}
      desktopMinWidth={desktopMinWidth}
      desktopMaxHeight={desktopMaxHeight}
      desktopMinHeight={desktopMinHeight}
      containerStyle={containerStyle}
      desktopContainerStyle={desktopContainerStyle}
      mobileContainerStyle={mobileContainerStyle}
      tallestPossible={tallestPossible}
      expandToMaxWidth={expandToMaxWidth}
      calloutOffsets={calloutOffsets}
      locked={locked}
      hideFloatingHeader={hideFloatingHeader}
    />
  );
};

export default Modal;
