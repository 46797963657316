import { put, takeEvery, all, call, select } from 'redux-saga/effects';

import * as setMemberFlow from '../../actionCreators/flows/setMember';
import * as fetchPaymentGatewayTokensFlow from '../../actionCreators/flows/fetchPaymentGatewayTokens';
import * as fetchOffersFlow from '../../actionCreators/flows/fetchOffers';
import * as fetchFavouriteOrdersFlow from '../../actionCreators/flows/fetchFavouriteOrders';
import * as fetchHistoricalOrdersFlow from '../../actionCreators/flows/fetchHistoricalOrders';
import * as fetchPaymentGatewayConfigFlow from '../../actionCreators/flows/fetchPaymentGatewayConfig';
import * as fetchTransactionHistory from '../../actionCreators/flows/fetchTransactionHistory';

import { setMemberData } from '../../actionCreators/member';
import { getOrderingProvider } from '../../selectors/config';

import { getEnableDynamicPaymentGatewayConfig } from '../../selectors/config';

import processMember from '../../utils/processors/processMember';
import {
  createFlowApprover,
  makeErrorSerialisable,
  requestAndWaitForFlow,
} from '../../utils/sagas';

// import * as OrderingConstants from '../../constants';
import { ORDERING_PROVIDER } from '../../constants/orderingProvider';

export const requested = createFlowApprover(setMemberFlow);

export function* approved(
  action: ReturnType<typeof setMemberFlow.actions.approved>,
) {
  const {
    payload: { member, rawMember, supressFetchingAssociatedData },
    meta: { flowId },
  } = action;

  try {
    if (!member && !rawMember) {
      throw new Error('no member data supplied');
    }

    let processedMember = member;
    const orderingProvider = yield select(getOrderingProvider);

    if (rawMember) {
      processedMember = processMember(rawMember);
    }

    yield put(setMemberData(processedMember as Member));

    if (orderingProvider === ORDERING_PROVIDER.KIOSK) {
      yield put(
        setMemberFlow.actions.succeeded({ member: processedMember }, flowId),
      );
      // This call takes an avg of 3s so show success before we fetch member orders
      yield call(requestAndWaitForFlow, fetchHistoricalOrdersFlow, {}, flowId);
    } else {
      yield call(requestAndWaitForFlow, fetchOffersFlow, {}, flowId);
      yield call(requestAndWaitForFlow, fetchHistoricalOrdersFlow, {}, flowId);
      yield call(requestAndWaitForFlow, fetchTransactionHistory, {}, flowId);

      if (!supressFetchingAssociatedData) {
        const enableDynamicPaymentGatewayConfig = yield select(
          getEnableDynamicPaymentGatewayConfig,
        );

        if (enableDynamicPaymentGatewayConfig) {
          yield call(
            requestAndWaitForFlow,
            fetchPaymentGatewayConfigFlow,
            {},
            flowId,
          );
        }

        yield all([
          call(
            requestAndWaitForFlow,
            fetchPaymentGatewayTokensFlow,
            {},
            flowId,
          ),
          call(requestAndWaitForFlow, fetchFavouriteOrdersFlow, {}, flowId),
        ]);
      }
      yield put(
        setMemberFlow.actions.succeeded({ member: processedMember }, flowId),
      );
    }
  } catch (e) {
    yield put(
      setMemberFlow.actions.failed({ error: makeErrorSerialisable(e) }, flowId),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(setMemberFlow.events.REQUESTED, requested),
    takeEvery(setMemberFlow.events.APPROVED, approved),
  ]);
}
