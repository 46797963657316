import { put, takeEvery, all, select } from 'redux-saga/effects';

import * as stageOpenPurchase from '../../actionCreators/flows/stageOpenPurchase';

import getUnenhancedOpenPurchase from '../../selectors/getUnenhancedOpenPurchase';
import getOpenPurchasePreviouslyStaged from '../../selectors/getOpenPurchasePreviouslyStaged';
import getItems from '../../selectors/getItems';

import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';
import removeUnusedNestedChoiceSelections from '../../utils/ordering/removeUnusedNestedChoiceSelections';

export const requested = createFlowApprover(stageOpenPurchase);

export function* approved(
  action: ReturnType<typeof stageOpenPurchase.actions.approved>,
) {
  const {
    meta: { flowId },
  } = action;

  try {
    const openPurchase = yield select(getUnenhancedOpenPurchase);
    const previouslyStaged = yield select(getOpenPurchasePreviouslyStaged);

    const allItems = yield select(getItems);

    yield put(
      stageOpenPurchase.actions.succeeded(
        {
          purchase: removeUnusedNestedChoiceSelections(openPurchase, allItems),
          previouslyStaged,
        },
        flowId,
      ),
    );
  } catch (e) {
    yield put(
      stageOpenPurchase.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(stageOpenPurchase.events.REQUESTED, requested),
    takeEvery(stageOpenPurchase.events.APPROVED, approved),
  ]);
}
