import { ActionType, getType } from 'typesafe-actions';

import * as config from '../../actionCreators/config';

export default function (
  state: ChoiceSetQuantityDiscountConfig | null = null,
  action: ActionType<typeof config>,
): ChoiceSetQuantityDiscountConfig | null {
  switch (action.type) {
    case getType(config.setChoiceSetQuantityDiscountConfig): {
      const { value } = action.payload;

      // only apply discount if all required config is present
      // threshold is not required
      if (
        !value ||
        !value.choiceSetKey ||
        !value.tierSize ||
        !value.discountPlu
      ) {
        return null;
      }

      return value;
    }
    default:
      return state;
  }
}
