import { createCustomAction } from 'typesafe-actions';

import { singleValuePayload } from '../utils/actions';
import { PAYMENT_METHOD } from '../constants/paymentMethod';
import { SALE_TYPE } from '../constants/saleType';

const SET_MOBILE_REGEX_WHITELIST = '$SET_MOBILE_REGEX_WHITELIST';
export const setMobileRegexWhitelist = createCustomAction(
  SET_MOBILE_REGEX_WHITELIST,
  singleValuePayload<typeof SET_MOBILE_REGEX_WHITELIST, string | null>(),
);

const SET_PAYMENT_ENVIRONMENT = '$SET_PAYMENT_ENVIRONMENT';
export const setPaymentEnvironment = createCustomAction(
  SET_PAYMENT_ENVIRONMENT,
  singleValuePayload<typeof SET_PAYMENT_ENVIRONMENT, string>(),
);

const SET_MERCHANT_CONFIG = '$SET_MERCHANT_CONFIG';
export const setMerchantConfig = createCustomAction(
  SET_MERCHANT_CONFIG,
  singleValuePayload<typeof SET_MERCHANT_CONFIG, MerchantConfig>(),
);

const SET_PRINTERLESS = '$SET_PRINTERLESS';
export const setPrinterless = createCustomAction(
  SET_PRINTERLESS,
  singleValuePayload<typeof SET_PRINTERLESS, boolean>(),
);

const SET_COUPON_ORDERING_METHOD = '$SET_COUPON_ORDERING_METHOD';
export const setCouponOrderingMethod = createCustomAction(
  SET_COUPON_ORDERING_METHOD,
  singleValuePayload<
    typeof SET_COUPON_ORDERING_METHOD,
    CouponOrderingMethod | null
  >(),
);

const SET_FREE_CHOICE_ORDERING_METHOD = '$SET_FREE_CHOICE_ORDERING_METHOD';
export const setFreeChoiceOrderingMethod = createCustomAction(
  SET_FREE_CHOICE_ORDERING_METHOD,
  singleValuePayload<
    typeof SET_FREE_CHOICE_ORDERING_METHOD,
    FreeChoiceOrderingMethod | null
  >(),
);

const SET_ORDERING_WINDOW_PADDING = '$SET_ORDERING_WINDOW_PADDING';
export const setOrderingWindowPadding = createCustomAction(
  SET_ORDERING_WINDOW_PADDING,
  singleValuePayload<
    typeof SET_ORDERING_WINDOW_PADDING,
    OrderingWindowPadding | null | undefined
  >(),
);

const SET_API_HANDLER_READY = '$SET_API_HANDLER_READY';
export const setApiHandlerReady = createCustomAction(
  SET_API_HANDLER_READY,
  singleValuePayload<typeof SET_API_HANDLER_READY, boolean>(),
);

const SET_INTERNATIONAL_MOBILE_ALLOWED = '$SET_INTERNATIONAL_MOBILE_ALLOWED';
export const setInternationalMobileAllowed = createCustomAction(
  SET_INTERNATIONAL_MOBILE_ALLOWED,
  singleValuePayload<typeof SET_INTERNATIONAL_MOBILE_ALLOWED, boolean>(),
);

const SET_ENABLED_PAYMENT_METHODS = '$SET_ENABLED_PAYMENT_METHODS';
export const setEnabledPaymentMethods = createCustomAction(
  SET_ENABLED_PAYMENT_METHODS,
  singleValuePayload<typeof SET_ENABLED_PAYMENT_METHODS, PAYMENT_METHOD[]>(),
);

const SET_ENABLED_SALE_TYPES = '$SET_ENABLED_SALE_TYPES';
export const setEnabledSaleTypes = createCustomAction(
  SET_ENABLED_SALE_TYPES,
  singleValuePayload<typeof SET_ENABLED_SALE_TYPES, SALE_TYPE[]>(),
);

const SET_REQUIRED_PURCHASER_DETAILS = '$SET_REQUIRED_PURCHASER_DETAILS';
export const setRequiredPurchaserDetails = createCustomAction(
  SET_REQUIRED_PURCHASER_DETAILS,
  singleValuePayload<
    typeof SET_REQUIRED_PURCHASER_DETAILS,
    PurchaserDetails[]
  >(),
);

const SET_REQUIRED_GUEST_PURCHASER_DETAILS =
  '$SET_REQUIRED_GUEST_PURCHASER_DETAILS';
export const setRequiredGuestPurchaserDetails = createCustomAction(
  SET_REQUIRED_GUEST_PURCHASER_DETAILS,
  singleValuePayload<
    typeof SET_REQUIRED_GUEST_PURCHASER_DETAILS,
    PurchaserDetails[]
  >(),
);

const SET_EFTPOS_CONFIG = '$SET_EFTPOS_CONFIG';
export const setEftposConfig = createCustomAction(
  SET_EFTPOS_CONFIG,
  singleValuePayload<typeof SET_EFTPOS_CONFIG, EftposConfig>(),
);

const SET_CHOICE_SET_QUANTITY_DISCOUNT_CONFIG =
  '$SET_CHOICE_SET_QUANTITY_DISCOUNT_CONFIG';
export const setChoiceSetQuantityDiscountConfig = createCustomAction(
  SET_CHOICE_SET_QUANTITY_DISCOUNT_CONFIG,
  singleValuePayload<
    typeof SET_CHOICE_SET_QUANTITY_DISCOUNT_CONFIG,
    ChoiceSetQuantityDiscountConfig
  >(),
);

const SET_PRODUCT_CONFIG = '$SET_PRODUCT_CONFIG';
export const setProductConfig = createCustomAction(
  SET_PRODUCT_CONFIG,
  singleValuePayload<typeof SET_PRODUCT_CONFIG, ProductConfig>(),
);

const SET_ATTEMPTS_CONFIG = '$SET_ATTEMPTS_CONFIG';
export const setAttemptsConfig = createCustomAction(
  SET_ATTEMPTS_CONFIG,
  singleValuePayload<typeof SET_ATTEMPTS_CONFIG, AttemptsConfig>(),
);

const SET_ORDERING_PROVIDER = '$SET_ORDERING_PROVIDER';
export const setOrderingProvider = createCustomAction(
  SET_ORDERING_PROVIDER,
  singleValuePayload<typeof SET_ORDERING_PROVIDER, number>(),
);

const SET_MENU_SOURCE = '$SET_MENU_SOURCE';
export const setMenuSource = createCustomAction(
  SET_MENU_SOURCE,
  singleValuePayload<typeof SET_MENU_SOURCE, string | undefined>(),
);

const SET_FLATTEN_CATEGORIES = '$SET_FLATTEN_CATEGORIES';
export const setFlattenCategories = createCustomAction(
  SET_FLATTEN_CATEGORIES,
  singleValuePayload<typeof SET_FLATTEN_CATEGORIES, boolean>(),
);

const SET_LOYALTY_REQUIRES_VERIFICATION = '$SET_LOYALTY_REQUIRES_VERIFICATION';
export const setLoyaltyRequiresVerification = createCustomAction(
  SET_LOYALTY_REQUIRES_VERIFICATION,
  singleValuePayload<typeof SET_LOYALTY_REQUIRES_VERIFICATION, boolean>(),
);

const SET_REMEMBER_CREDIT_CARD = '$SET_REMEMBER_CREDIT_CARD';
export const setRememberCreditCard = createCustomAction(
  SET_REMEMBER_CREDIT_CARD,
  singleValuePayload<typeof SET_REMEMBER_CREDIT_CARD, any>(),
);

export const SET_IMPORTED_GIFT_CARD_REGEX = '$SET_IMPORTED_GIFT_CARD_REGEX';
export const setImportedGiftCardRegex = createCustomAction(
  SET_IMPORTED_GIFT_CARD_REGEX,
  singleValuePayload<typeof SET_IMPORTED_GIFT_CARD_REGEX, string | null>(),
);

export const SET_CARDSETS = '$SET_CARDSETS';
export const setCardsets = createCustomAction(
  SET_CARDSETS,
  singleValuePayload<typeof SET_CARDSETS, SDict<Cardset> | null>(),
);

const SET_EXCLUDED_CHOICE_SETS = '$SET_EXCLUDED_CHOICE_SETS';
export const setExcludedChoiceSets = createCustomAction(
  SET_EXCLUDED_CHOICE_SETS,
  singleValuePayload<typeof SET_EXCLUDED_CHOICE_SETS, string[] | null>(),
);

const SET_BRANDS = '$SET_BRANDS';
export const setBrands = createCustomAction(
  SET_BRANDS,
  singleValuePayload<typeof SET_BRANDS, Brand[] | null>(),
);

const SET_SHOW_HIDDEN_LOCATIONS = '$SET_SHOW_HIDDEN_LOCATIONS';
export const setShowHiddenLocations = createCustomAction(
  SET_SHOW_HIDDEN_LOCATIONS,
  singleValuePayload<typeof SET_SHOW_HIDDEN_LOCATIONS, boolean>(),
);

const SET_ENABLE_MULTIPLE_DELIVERY_ESTIMATE =
  '$SET_ENABLE_MULTIPLE_DELIVERY_ESTIMATE';
export const setEnableMultipleDeliveryEstimate = createCustomAction(
  SET_ENABLE_MULTIPLE_DELIVERY_ESTIMATE,
  singleValuePayload<typeof SET_ENABLE_MULTIPLE_DELIVERY_ESTIMATE, boolean>(),
);

const SET_ENABLE_DINE_IN_NAME_MODE = '$SET_ENABLE_DINE_IN_NAME_MODE';
export const setEnableDineInNameMode = createCustomAction(
  SET_ENABLE_DINE_IN_NAME_MODE,
  singleValuePayload<typeof SET_ENABLE_DINE_IN_NAME_MODE, boolean>(),
);

const SET_ENABLE_NON_MEMBER_PHONE_NUMBER_REQUIRED =
  '$SET_ENABLE_NON_MEMBER_PHONE_NUMBER_REQUIRED';
export const setEnableNonMemberPhoneNumberRequired = createCustomAction(
  SET_ENABLE_NON_MEMBER_PHONE_NUMBER_REQUIRED,
  singleValuePayload<
    typeof SET_ENABLE_NON_MEMBER_PHONE_NUMBER_REQUIRED,
    boolean
  >(),
);

const SET_ENABLE_DUPLICATE_SALE_PROTECTION =
  '$SET_ENABLE_DUPLICATE_SALE_PROTECTION';
export const setEnableDuplicateSaleProtection = createCustomAction(
  SET_ENABLE_DUPLICATE_SALE_PROTECTION,
  singleValuePayload<typeof SET_ENABLE_DUPLICATE_SALE_PROTECTION, boolean>(),
);

const SET_ENABLE_DYNAMIC_PAYMENT_GATEWAY_CONFIG =
  '$SET_ENABLE_DYNAMIC_PAYMENT_GATEWAY_CONFIG';
export const setEnableDynamicPaymentGatewayConfig = createCustomAction(
  SET_ENABLE_DYNAMIC_PAYMENT_GATEWAY_CONFIG,
  singleValuePayload<
    typeof SET_ENABLE_DYNAMIC_PAYMENT_GATEWAY_CONFIG,
    boolean
  >(),
);

const SET_SALE_INPUT_TYPE = '$SET_SALE_INPUT_TYPE';
export const setSaleInputType = createCustomAction(
  SET_SALE_INPUT_TYPE,
  singleValuePayload<typeof SET_SALE_INPUT_TYPE, number | null>(),
);

const SET_ENABLE_GUEST_ORDERING = '$SET_ENABLE_GUEST_ORDERING';
export const setEnableGuestOrdering = createCustomAction(
  SET_ENABLE_GUEST_ORDERING,
  singleValuePayload<typeof SET_ENABLE_GUEST_ORDERING, boolean>(),
);

const SET_THRESHOLD_ORDERING_PROVIDERS = '$SET_THRESHOLD_ORDERING_PROVIDERS';
export const setThresholdOrderingProviders = createCustomAction(
  SET_THRESHOLD_ORDERING_PROVIDERS,
  singleValuePayload<
    typeof SET_THRESHOLD_ORDERING_PROVIDERS,
    string[] | null
  >(),
);

const SET_ENABLE_PAY_LATER = '$SET_ENABLE_PAY_LATER';
export const setEnablePayLater = createCustomAction(
  SET_ENABLE_PAY_LATER,
  singleValuePayload<typeof SET_ENABLE_PAY_LATER, boolean>(),
);

const SET_PAY_LATER_RULES = '$SET_PAY_LATER_RULES';
export const setPayLaterRules = createCustomAction(
  SET_PAY_LATER_RULES,
  singleValuePayload<typeof SET_PAY_LATER_RULES, PayLaterRule[] | null>(),
);

const SET_HIDE_NEGATIVE_INGREDIENTS = '$SET_HIDE_NEGATIVE_INGREDIENTS';
export const setHideNegativeIngredients = createCustomAction(
  SET_HIDE_NEGATIVE_INGREDIENTS,
  singleValuePayload<typeof SET_HIDE_NEGATIVE_INGREDIENTS, boolean>(),
);

const SET_LOW_STOCK_THRESHOLD = '$SET_LOW_STOCK_THRESHOLD';
export const setLowStockThreshold = createCustomAction(
  SET_LOW_STOCK_THRESHOLD,
  singleValuePayload<typeof SET_LOW_STOCK_THRESHOLD, number | null>(),
);

const SET_STOCK_BALANCE_THRESHOLDS = '$SET_STOCK_BALANCE_THRESHOLDS';
export const setStockBalanceThresholds = createCustomAction(
  SET_STOCK_BALANCE_THRESHOLDS,
  singleValuePayload<typeof SET_STOCK_BALANCE_THRESHOLDS, number[] | null>(),
);

const SET_STOCK_BALANCE_IGNORE_THRESHOLD =
  '$SET_STOCK_BALANCE_IGNORE_THRESHOLD';
export const setStockBalanceIgnoreThreshold = createCustomAction(
  SET_STOCK_BALANCE_IGNORE_THRESHOLD,
  singleValuePayload<typeof SET_STOCK_BALANCE_IGNORE_THRESHOLD, number>(),
);

const SET_FUTURE_ORDERING_LIMIT = '$SET_FUTURE_ORDERING_LIMIT';
export const setFutureOrderingLimit = createCustomAction(
  SET_FUTURE_ORDERING_LIMIT,
  singleValuePayload<typeof SET_FUTURE_ORDERING_LIMIT, number>(),
);

const SET_FUTURE_ORDERING_LIMIT_CATERING =
  '$SET_FUTURE_ORDERING_LIMIT_CATERING';
export const setFutureOrderingLimitCatering = createCustomAction(
  SET_FUTURE_ORDERING_LIMIT_CATERING,
  singleValuePayload<typeof SET_FUTURE_ORDERING_LIMIT_CATERING, number>(),
);

const SET_FUTURE_ORDERING = '$SET_FUTURE_ORDERING';
export const setFutureOrdering = createCustomAction(
  SET_FUTURE_ORDERING,
  singleValuePayload<typeof SET_FUTURE_ORDERING, boolean>(),
);

const SET_INCLUDE_DISCOUNT_MIN_SPEND = '$SET_INCLUDE_DISCOUNT_MIN_SPEND';
export const setIncludeDiscountMinSpend = createCustomAction(
  SET_INCLUDE_DISCOUNT_MIN_SPEND,
  singleValuePayload<typeof SET_INCLUDE_DISCOUNT_MIN_SPEND, boolean>(),
);

const SET_LOCALISE_ORDER_TIMES = '$SET_LOCALISE_ORDER_TIMES';
export const setLocaliseOrderTimes = createCustomAction(
  SET_LOCALISE_ORDER_TIMES,
  singleValuePayload<typeof SET_LOCALISE_ORDER_TIMES, boolean>(),
);
