// NOTE: in order to be usable inside a .d.ts file, this enum
// must not be exported/imported explicitly. This makes it kind of global.
// It must also be a const enum, otherwise it will fail (only a runtime,
// the compiler will say everything is fine)
import { DUMMY } from './dummy';

export enum OFFER_TYPE {
  AMOUNT_OFF_ORDER = 2,
  PERCENTAGE_OFF_ORDER = 1,
  PRICE_OVERRIDE_ITEM_OR_INGREDIENT = 112,
  AMOUNT_OFF_ITEM = 6,
  PERCENTAGE_OFF_ITEM = 81,
  PERCENTAGE_OFF_ITEM_POS_ENFORCED = 13,
  PERCENTAGE_OFF_ITEM_AND_INGREDIENTS = 5,
  //BOGO COUPONS
  AMOUNT_OFF_BOGO = 113,
  PERCENTAGE_OFF_BOGO = 114,
}
