import lodash from 'lodash';

export default function identicalPurchase(
  purchaseA: _Purchase,
  purchaseB: _Purchase,
): boolean {
  if (purchaseA.itemId !== purchaseB.itemId) {
    return false;
  }

  if (!lodash.isEqual(purchaseA.choiceSelections, purchaseB.choiceSelections)) {
    return false;
  }

  return true;
}
