import processFavouriteItem from './processFavouriteItem';

export default function (rawFavourite: RawFavouriteOrder): FavouriteOrder {
  return {
    id: rawFavourite.ID,
    created: rawFavourite.OrderDate,
    name: rawFavourite.Description,
    default: Boolean(rawFavourite.Default),
    items: rawFavourite.Items.map(item => processFavouriteItem(item)),
  };
}
