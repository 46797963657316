import { put, takeEvery, all, select } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import * as openPurchase from '../../actionCreators/flows/openPurchase';

import getItems from '../../selectors/getItems';

import purchaseFromItem from '../../utils/ordering/purchaseFromItem';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';

export const requested = createFlowApprover(openPurchase);

export function* approved(
  action: ReturnType<typeof openPurchase.actions.approved>,
) {
  const {
    payload: { itemId, brandId },
    meta: { flowId },
  } = action;

  try {
    const allItems: Items = yield select(getItems);

    if (!allItems) {
      throw new Error('allItems is undefined');
    }

    const item = allItems[itemId];
    const purchase = purchaseFromItem(
      item,
      uuidv4(),
      undefined,
      undefined,
      brandId,
    );

    yield put(openPurchase.actions.succeeded({ purchase }, flowId));
  } catch (e) {
    yield put(
      openPurchase.actions.failed({ error: makeErrorSerialisable(e) }, flowId),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(openPurchase.events.REQUESTED, requested),
    takeEvery(openPurchase.events.APPROVED, approved),
  ]);
}
