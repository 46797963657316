import { ActionType, getType } from 'typesafe-actions';

import * as currentOrder from '../../../actionCreators/currentOrder';
import { actions as fetchMember } from '../../../actionCreators/flows/fetchMember';

const initialState = null;

export default function (
  state: string | null = initialState,
  action: ActionType<typeof currentOrder> | ActionType<typeof fetchMember>,
): string | null {
  switch (action.type) {
    case getType(currentOrder.setPurchaser): {
      const { value } = action.payload;
      return value.familyName;
    }
    case getType(currentOrder.setPurchaserFamilyName): {
      const { value } = action.payload;
      return value;
    }
    default:
      return state;
  }
}
