// NOTE: server-friendly format
interface FavouriteItem {
  Qty: number;
  PLUCode: number;
  Ingredients?: FavouriteItem[];
}

export function filterSystemPLUs(input: FavouriteItem[]): FavouriteItem[] {
  return input.filter(item => item.PLUCode > 10);
}

function createFavouriteItemFromShadowItem(
  shadowItem: ShadowItem,
  ingredient = false,
): FavouriteItem {
  let result: FavouriteItem = {
    PLUCode: parseInt(shadowItem.id),
    Qty: shadowItem.quantity || 1,
  };

  if (!ingredient) {
    result = {
      ...result,
      Ingredients: filterSystemPLUs(
        (shadowItem.ingredients || []).map(ingredient =>
          createFavouriteItemFromShadowItem(ingredient, true),
        ),
      ),
    };
  }

  return result;
}

export default createFavouriteItemFromShadowItem;
