import lodash from 'lodash';

import Logger from '../Logger';

import {
  BOOST_ICE_INGREDIENT_IDS,
  BOOST_WITHOUT_ICE_INGREDIENT_ID,
} from '../../constants/boost';

import determineProcessedFamilyId from './determineProcessedFamilyId';
import choiceForVariety from './choiceForVariety';

const FAUX_WITHOUT_ICE = {
  id: '2322',
  name: 'Without Ice',
  description: 'ice',
  kilojoules: 0,
  baseMoneyPrice: 0,
  basePointsPrice: 0,
  basePointsAward: 0,
  baseQuantity: 0,
  images: {
    default: '/static/img/ordering/ice.png',
  },
};

export default function processBoostAppIngredient(
  rawIngredient: BoostRawIngredient,
  masterChoices: SDict<Choice>,
  ingredientFamilies: BoostRawIngredientFamily[],
) {
  console.group(
    `processBoostAppIngredient ${rawIngredient.id}:${rawIngredient.name}`,
  );

  const familyId = determineProcessedFamilyId(
    ingredientFamilies,
    rawIngredient,
  );

  if (familyId === undefined || familyId === 'nothing') {
    Logger.log(
      "filtering boost app data ingredient due to bad value for 'family'",
      'info',
      { familyId },
    );

    console.groupEnd();

    return undefined;
  }

  if (rawIngredient.id === BOOST_WITHOUT_ICE_INGREDIENT_ID) {
    Logger.log(
      "filtering 'Without Ice'",
      'info',
      lodash.pick(rawIngredient, ['name', 'id']),
    );

    console.groupEnd();

    return undefined;
  }

  const insteadOfChoice = choiceForVariety(
    rawIngredient,
    'insteadOf',
    masterChoices,
    familyId,
  );

  const withChoice = choiceForVariety(
    rawIngredient,
    'with',
    masterChoices,
    familyId,
  );

  let withoutChoice = choiceForVariety(
    rawIngredient,
    'without',
    masterChoices,
    familyId,
  );

  const instructionChoice = choiceForVariety(
    rawIngredient,
    'instruction',
    masterChoices,
    familyId,
  );

  const fallbackChoice = insteadOfChoice || instructionChoice || withChoice;

  if (BOOST_ICE_INGREDIENT_IDS.includes(rawIngredient.id)) {
    withoutChoice = FAUX_WITHOUT_ICE;
  }

  const choiceMap = {
    insteadOf: insteadOfChoice || fallbackChoice,
    with: withChoice || fallbackChoice,
    instruction: instructionChoice || fallbackChoice,
    without: withoutChoice,
  };

  console.groupEnd();

  return {
    id: rawIngredient.id,
    name: rawIngredient.name,
    family: familyId,
    choiceMap,
  };
}
