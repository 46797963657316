import { ActionType, getType } from 'typesafe-actions';

import * as config from '../../actionCreators/config';

export default function (
  state: PurchaserDetails[] = [],
  action: ActionType<typeof config>,
): PurchaserDetails[] {
  switch (action.type) {
    case getType(config.setRequiredPurchaserDetails): {
      const { value } = action.payload;
      return value || [];
    }
    default:
      return state;
  }
}
