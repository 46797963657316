import { createSelector } from 'reselect';
import lodash from 'lodash';

import enhanceMenuNode from '../utils/ordering/enhanceMenuNode';
import flattenCategories from '../utils/ordering/flattenCategories';

import getMenu from './getMenu';
import { getFlattenCategories } from './config';
import getItems from './getItems';

export default createSelector(
  [getMenu, getFlattenCategories, getItems],
  (orderMenu, shouldFlattenCategories, allItems): Category | undefined => {
    if (!orderMenu || !allItems) {
      return undefined;
    }

    let enhanced = enhanceMenuNode(orderMenu.rootMenuNode, allItems);

    if (shouldFlattenCategories) {
      enhanced = {
        ...enhanced,
        subCategories: lodash.uniqBy(flattenCategories(enhanced), 'id'),
      };
    }

    return enhanced;
  },
);
