import { put, takeEvery, take, all, select, race } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { TyroWrapper, TyroWrapperResult } from 'polygon-utils';

import * as tyroPayment from '../../actionCreators/flows/tyroPayment';

import { cancelPaymentInProgress } from '../../actionCreators/currentOrder';

import {
  getProductConfig,
  getEftposConfig,
  getPaymentEnvironment,
} from '../../selectors/config';

import Logger from '../../utils/Logger';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';

import { FAILURE_REASON } from '../../constants/failureReasons';
import PaymentHooks from '../../utils/PaymentHooks';
import { EFTPOS_STATUS_UPDATE } from '../../constants';

export const requested = createFlowApprover(tyroPayment);

export function* approved(
  action: ReturnType<typeof tyroPayment.actions.approved>,
) {
  const {
    payload: { amount },
    meta: { flowId },
  } = action;

  let reason: tyroPayment.FailureReason;

  try {
    const { vendor, name, version } = yield select(getProductConfig);

    const { merchantId, terminalId, pairingKey, apiKey } = yield select(
      getEftposConfig,
    );

    const paymentEnvironment = yield select(getPaymentEnvironment);

    if (
      !vendor ||
      !name ||
      !version ||
      !merchantId ||
      !terminalId ||
      !pairingKey ||
      !apiKey
    ) {
      throw new Error('missing necessary config');
    }

    const tyroWrapper = new TyroWrapper(Logger as any);

    yield tyroWrapper.init(paymentEnvironment, apiKey, vendor, name, version);

    const hook: any = PaymentHooks.get(EFTPOS_STATUS_UPDATE);

    const statusCallback = (statusMsg: string, txnStarted: boolean) => {
      if (hook) {
        hook({ statusMsg: statusMsg, txnStarted: txnStarted });
      }
    };

    const transactionEventChannel = tyroWrapper.startPaymentTransaction(
      {
        amount: String(amount),
        integratedReceipt: true,
        integratedReceiptWidth: '40', // TODO: lift to config
        mid: parseInt(merchantId),
        tid: parseInt(terminalId),
        integrationKey: pairingKey,
      },
      hook ? statusCallback : undefined,
    );

    let result: TyroWrapperResult | undefined;
    let cancelResult: any;

    [result, cancelResult] = yield race([
      //@ts-ignore
      take(transactionEventChannel),
      take(getType(cancelPaymentInProgress)),
    ]);

    if (!result) {
      reason = FAILURE_REASON.PAYMENT_CANCELLED;
      tyroWrapper.cancelCurrentTransaction();
      //@ts-ignore
      result = yield take(transactionEventChannel);
    }

    if (result && result.success && result.transactionData) {
      yield put(
        tyroPayment.actions.succeeded(
          { transactionData: result.transactionData },
          flowId,
        ),
      );
    } else {
      if (!result) {
        result = { success: false, requestedSignature: false };
      }

      if (result.requestedSignature) {
        reason = FAILURE_REASON.SIGNATURE_REQUIRED;
      }

      throw {
        ...makeErrorSerialisable(new Error('tyro payment failed')),
        details: result,
      };
    }
  } catch (e) {
    yield put(
      tyroPayment.actions.failed(
        { error: makeErrorSerialisable(e), reason },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(tyroPayment.events.REQUESTED, requested),
    takeEvery(tyroPayment.events.APPROVED, approved),
  ]);
}
