import { ActionType, getType } from 'typesafe-actions';

import { actions as fetchLocations } from '../actionCreators/flows/fetchLocations';

export default function (
  state: SDict<POSLocation> | SDict<POSLocationShort> | null = null,
  action: ActionType<typeof fetchLocations>,
): SDict<POSLocation> | SDict<POSLocationShort> | null {
  switch (action.type) {
    case getType(fetchLocations.succeeded): {
      const { locations, merge } = action.payload;

      if (
        state === null ||
        !merge ||
        Object.values(state).some(loc => !loc._isFullData)
      ) {
        return locations as SDict<POSLocation>;
      } else {
        return { ...state, ...locations } as SDict<POSLocation>;
      }
    }
    case getType(fetchLocations.succeededShort): {
      const { locationsShort } = action.payload;

      if (!state || !Object.values(state).some(loc => loc._isFullData)) {
        return locationsShort as SDict<POSLocationShort>;
      }
    }
    default:
      return state;
  }
}
