import lodash from 'lodash';
import choiceSetDiscountPlus from './choiceSetDiscountPlus';

export default function adjustedSelections(
  max: number | undefined,
  individualMax: number | undefined,
  previousChoiceSelections: ChoiceSelections,
  targetChoiceSetId: string,
  targetChoiceId: string,
  choiceSetDiscountConfig?: ChoiceSetQuantityDiscountConfig,
): ChoiceSelections {
  if (choiceSetDiscountConfig) {
    const { useChoiceSetDiscountMap, choiceSetDiscountMap, discountPlu } =
      choiceSetDiscountConfig;
    if (!useChoiceSetDiscountMap && discountPlu) {
      if (targetChoiceId === discountPlu) return previousChoiceSelections;
    }
    if (useChoiceSetDiscountMap && choiceSetDiscountMap) {
      const { plus } = choiceSetDiscountPlus(choiceSetDiscountMap);
      if (plus.includes(targetChoiceId)) return previousChoiceSelections;
    }
  }
  const targetSelections = previousChoiceSelections[targetChoiceSetId];
  const targetQuantity = lodash.sumBy(targetSelections, id =>
    id === targetChoiceId ? 1 : 0,
  );

  if (individualMax && targetQuantity >= individualMax) {
    return previousChoiceSelections;
  }

  // get currect selections array from purchase and add a copy of the choice id to the array

  const newTargetSelections = [
    ...previousChoiceSelections[targetChoiceSetId],
    targetChoiceId,
  ];

  if (!max) {
    return {
      ...previousChoiceSelections,
      [targetChoiceSetId]: newTargetSelections,
    };
  }

  // if we are going to be clipping the array,
  // removing the oldest selection (for a different choice)
  // feels most natural to users
  if (
    newTargetSelections.length > max &&
    newTargetSelections[0] === targetChoiceId
  ) {
    const index = newTargetSelections.findIndex(
      selection => selection !== targetChoiceId,
    );
    if (index !== -1) {
      newTargetSelections.splice(index, 1);
    }
  }

  return {
    ...previousChoiceSelections,
    [targetChoiceSetId]: newTargetSelections.slice(-max),
  };
}
