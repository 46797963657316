import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import StandardButton from '../components/StandardButton';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../utils/snackbar';
import { RedcatApiHandler } from 'polygon-utils';
import * as Yup from 'yup';
import Wallet from '../components/Wallet';
import { REPLACE_CARD_ROUTE } from '../screens/ReplaceCardScreen';
import { useLocation } from 'react-router-dom';
import getThemeLookup from '../selectors/getThemeLookup';
import { useAppSelector } from '../app/hooks';
import FormTextField from './FormTextField';
import { OrderingSelectors } from 'polygon-ordering';

interface IProps {
  showWallet?: boolean;
  checkBalance?: boolean;
  topUp?: boolean;
}

const { getCurrencySymbol } = OrderingSelectors;

const GiftCardForm: React.FC<IProps> = ({ showWallet, checkBalance }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const p = useAppSelector(getThemeLookup);
  const currency = useAppSelector(getCurrencySymbol);

  const handleFormSubmit = (
    { MemberNo, VerifyPIN }: { MemberNo: string; VerifyPIN: string },
    {
      setSubmitting,
      resetForm,
    }: { setSubmitting: (isSubmitting: boolean) => void; resetForm: () => void },
  ) => {
    RedcatApiHandler.authorisedFetch({
      path: checkBalance
        ? '/api/v1/members/giftcard_balance'
        : '/api/v1/profile/card_transfer_init',
      method: 'POST',
      body: { MemberNo, VerifyPIN },
    })
      .then(res => {
        if (checkBalance) {
          enqueueSuccessSnackbar(t('giftCardMemberBalance', { balance: res.data.Money, currency }));
        } else {
          enqueueSuccessSnackbar(res.data);
        }

        setSubmitting(false);
        resetForm();
      })
      .catch(err => {
        enqueueErrorSnackbar(err);
        setSubmitting(false);
      });
  };

  const getButtonLabel = () => {
    let label = '';

    if (checkBalance) {
      label = t('button.giftCardBalance');
    }

    if (!showWallet && !checkBalance) {
      label = t('button.updateDetails');
    }
    return label;
  };

  const schemas = {
    MemberNo: Yup.string().required('Required'),
    VerifyPIN: Yup.string().required('Required'),
  };
  const validationSchema = Yup.object().shape(schemas);

  const isGiftCard = pathname === REPLACE_CARD_ROUTE ? false : true;

  return (
    <div className="text-center py-2">
      <Formik
        validationSchema={validationSchema}
        initialValues={{ MemberNo: '', VerifyPIN: '', Amount: '' }}
        onSubmit={handleFormSubmit}
      >
        {({
          values: { MemberNo, VerifyPIN },
          handleSubmit,
          handleChange,
          submitForm,
          isValid,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormTextField
              id="MemberNo"
              name="MemberNo"
              type="text"
              label={isGiftCard ? t('placeholder.giftCardNo') : t('placeholder.memberNo')}
              required
              onChange={handleChange}
            />
            <FormTextField
              id="VerifyPIN"
              name="VerifyPIN"
              type="text"
              label={isGiftCard ? t('placeholder.verifyPin') : t('placeholder.verificationCode')}
              required
              onChange={handleChange}
            />

            {showWallet && <Wallet MemberNo={MemberNo} VerifyPIN={VerifyPIN} giftCard />}

            {!showWallet && (
              <div style={{ marginTop: 20 }}>
                <StandardButton
                  disabled={!isValid || isSubmitting || MemberNo === '' || VerifyPIN === ''}
                  onClick={() => submitForm()}
                  themeKey="loyaltyButton"
                  containerStyle={styles.button}
                  label={getButtonLabel()}
                  showSpinner={isSubmitting}
                />
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

const styles: Styles = {
  button: {
    borderRadius: 5,
    height: 40,
  },
};

export default GiftCardForm;
