import React from 'react';
import StandardButton from './StandardButton';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { ORDER_TYPE_MODAL_ID } from '../modals/OrderTypeModal';
import history from '../history';
import { OrderingOperations } from 'polygon-ordering';
import getOrderInProgress from '../selectors/getOrderInProgress';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { useTranslation } from 'react-i18next';

const { setShadowCart, applyShadowOrder } = OrderingOperations;

const ReorderButton: React.FC<{ order: EnhancedHistoricalOrder | FavouriteOrder }> = ({
  order,
}) => {
  const orderInProgress = useAppSelector(getOrderInProgress);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <div className="position-absolute" style={{ bottom: 0, right: 10 }}>
      <StandardButton
        containerStyle={styles.button}
        themeKey="signInSubmitButton"
        label={t('button.reOrder')}
        onClick={() => {
          //@ts-ignore type mismatch  EnhancedHistoricalOrder | FavouriteOrder
          const newOrderItems = order.items.map(item => ({
            id: item.id.toString(),
            name: item.name,
            quantity: item.quantity,
            ingredients: item.ingredients,
          }));

          dispatch(setShadowCart(newOrderItems));
          if (orderInProgress) {
            dispatch(applyShadowOrder({}));
            history.push('menu');
          } else {
            dispatch(
              setCurrentModal({
                modalId: ORDER_TYPE_MODAL_ID,
                preserveReturnModalDetails: true,
              }),
            );
          }
        }}
      />
    </div>
  );
};

const styles: Styles = {
  button: {
    borderRadius: 5,
    height: 20,
  },
};

export default ReorderButton;
