import { ActionType, getType } from 'typesafe-actions';

import * as config from '../../actionCreators/config';
import { SALE_TYPE } from '../../constants/saleType';

const initialState = null;

export default function (
  state: SALE_TYPE[] | null = initialState,
  action: ActionType<typeof config>,
): SALE_TYPE[] | null {
  switch (action.type) {
    case getType(config.setEnabledSaleTypes): {
      const { value } = action.payload;

      if (!value) {
        return initialState;
      }

      return value.map(saleType =>
        typeof saleType === 'number' ? saleType : parseInt(saleType),
      );
    }
    default:
      return state;
  }
}
