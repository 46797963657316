import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import SuperPaymentForm from '../components/SuperPaymentForm';
import * as Yup from 'yup';
import FormTextField from '../components/FormTextField';

export const MEMBER_MONEY_ROUTE = '/member-money';

const MemberMoneyScreen = () => {
  const { t } = useTranslation();
  const handleFormSubmit = () => {};

  const schemas = {
    Amount: Yup.string().required('Required'),
  };
  const validationSchema = Yup.object().shape(schemas);

  return (
    <div className="text-center py-2">
      <Formik
        validationSchema={validationSchema}
        initialValues={{ Amount: '' }}
        onSubmit={handleFormSubmit}
      >
        {({ handleSubmit, handleChange }) => (
          <Form onSubmit={handleSubmit}>
            <FormTextField
              onChange={handleChange}
              name="Amount"
              type="number"
              label={t('placeholder.giftcardAmount')}
            />
            <SuperPaymentForm route="memberCashTopUp" />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MemberMoneyScreen;
