import { call, put, takeEvery, all } from 'redux-saga/effects';

import * as fetchTransactionHistory from '../../actionCreators/flows/fetchTransactionHistory';
import { setTransactionHistory } from '../../actionCreators/member';

import Api, { FetchParams, ApiResponse } from '../../utils/Api';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';

export const requested = createFlowApprover(fetchTransactionHistory);

export function* approved(
  action: ReturnType<typeof fetchTransactionHistory.actions.approved>,
) {
  const {
    payload: { authenticationMethod = 'member' },
    meta: { flowId },
  } = action;

  try {
    const params: FetchParams = {
      path: '/api/v1/profile/transactions?sort=TxnDate&dir=desc&group_sales=true',
      method: 'GET',
    };

    const response: ApiResponse = yield call(
      Api.fetch,
      params,
      authenticationMethod,
    );

    const rawTransactions = response.data;

    yield put(setTransactionHistory(rawTransactions));

    yield put(fetchTransactionHistory.actions.succeeded({}, flowId));
  } catch (e) {
    yield put(
      fetchTransactionHistory.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(fetchTransactionHistory.events.REQUESTED, requested),
    takeEvery(fetchTransactionHistory.events.APPROVED, approved),
  ]);
}
