import lodash from 'lodash';

export default function sortChoices(
  choices: ChoiceWithQuantity[],
  freeChoiceOrderingMethod: FreeChoiceOrderingMethod,
) {
  let direction: 'asc' | 'desc';

  if (freeChoiceOrderingMethod === 'cheapest') {
    direction = 'asc';
  } else {
    direction = 'desc';
  }

  return lodash.orderBy(choices, 'baseMoneyPrice', [direction]);
}
