import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrderingSelectors, OrderingHooks } from 'polygon-ordering';
import Text from './Text';
import PaymentMethod from './PaymentMethod';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import { useAppSelector } from '../app/hooks';

const { getMember, getUnavailableReasonsMemberMoney } = OrderingSelectors;
const { useFormattedCurrency } = OrderingHooks;

const PaymentMethodMemberMoney: React.FC = () => {
  const { t } = useTranslation();
  const member = useAppSelector(getMember);
  const unavailableReasonsMemberMoney = useAppSelector(getUnavailableReasonsMemberMoney);
  const meoneyBalance = useFormattedCurrency(Number(member?.moneyBalance));

  if (!member) {
    return null;
  }

  const reason = unavailableReasonsMemberMoney[0];

  return (
    <PaymentMethod
      method={PAYMENT_METHOD.MEMBER_MONEY}
      right={<Text themeKey="paymentMethodAvailableBalance">{'Available: ' + meoneyBalance}</Text>}
      subtitle={
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          {reason ? (
            <Text themeKey="paymentMethodUnavailableReason">
              {t(`paymentMethodUnavailableReason.${reason}`)}
            </Text>
          ) : (
            <Text themeKey="paymentMethodUnavailableReason">&nbsp;</Text>
          )}
        </div>
      }
    ></PaymentMethod>
  );
};

export default PaymentMethodMemberMoney;
