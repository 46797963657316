import lodash from 'lodash';

import { safeString } from '../misc';

export default (items: Items, id: string, name?: string | null) => {
  let item;

  if (id != null) {
    item = items[String(id)];
  }

  if (!item && name) {
    item = lodash
      .values(items)
      .find(i => safeString(i.name) === safeString(name));
  }

  return item;
};
