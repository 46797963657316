import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as currentOrder from '../../actionCreators/currentOrder';
import { getType } from 'typesafe-actions';

const initialState = null as null | string;

const slice = createSlice({
  name: 'desiredDeliveryTime',
  initialState,
  reducers: {
    setDesiredDeliveryTime(state, action: PayloadAction<string | null>) {
      const { payload } = action;
      return payload == null ? initialState : payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getType(currentOrder.resetOrder), () => initialState);
  },
});

export const { setDesiredDeliveryTime } = slice.actions;

export default slice.reducer;
