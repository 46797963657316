import { ActionType, getType } from 'typesafe-actions';

import * as currentOrder from '../../actionCreators/currentOrder';

const initialState: SubPayment[] = [];

export default function (
  state: SubPayment[] | [] = initialState,
  action: ActionType<typeof currentOrder>,
): SubPayment[] {
  switch (action.type) {
    case getType(currentOrder.addSelectedPaymentMethod): {
      const { value } = action.payload;
      const newState = state;
      return [...newState, value];
    }
    case getType(currentOrder.removeSelectedPaymentMethod): {
      const { value } = action.payload;
      const newState = state.filter(subPayment => {
        return subPayment.method !== value;
      });
      return newState;
    }
    case getType(currentOrder.setSelectedPaymentMethods): {
      const { value } = action.payload;
      return value || initialState;
    }
    case getType(currentOrder.resetOrder): {
      return initialState;
    }
    default:
      return state;
  }
}
