import lodash from 'lodash';

import Logger from '../Logger';

import choiceSelectionsFromBase from '../ordering/choiceSelectionsFromBase';
import instructionChoicesFromIngredients from './instructionChoicesFromIngredients';

export default function generateSwapChoiceSetsForItem(
  item: _Item,
  baseChoiceSets: ChoiceSets,
  ingredientFamilies: BoostIngredientFamilies,
): [ChoiceSets, string[]] {
  console.group(
    `generateSwapChoiceSetsForItem:${item.id}:${item.name}:${
      item.sizeDescription || '_'
    }`,
  );

  const nonSwapBaseNames: string[] = [];
  let swapChoiceSets: ChoiceSets = {};

  const itemChoiceSets: ChoiceSet[] = item.choiceSets.map(
    setId => baseChoiceSets[setId],
  );
  // this includes non-instruct choiceSets because boost can't always put them in juice instruction

  lodash
    .entries(choiceSelectionsFromBase(itemChoiceSets, 'id'))
    .forEach(([setId, choiceSelections]) => {
      choiceSelections.forEach(choiceId => {
        const baseChoiceSet = baseChoiceSets[setId];

        const baseChoice = baseChoiceSet.choices.find(
          choice => choice.id === choiceId,
        ) as Choice;

        let ingredientFamily: BoostIngredientFamily | undefined;
        let ingredientFoo: BoostIngredient | undefined;

        lodash.values(ingredientFamilies).forEach(family =>
          family.ingredients.some(ingredient => {
            lodash.values(ingredient.choiceMap).forEach(choice => {
              if (choice && choice.id === baseChoice.id) {
                ingredientFamily = family;
                ingredientFoo = ingredient;
              }
            });
          }),
        );

        if (!ingredientFamily) {
          Logger.log(
            "couldn't find plu in validated boost data. Adding as non-swappable-base.",
            'info',
            lodash.pick(baseChoice, ['name', 'id']),
          );

          nonSwapBaseNames.push(baseChoice.name);

          return;
        }

        const choices = instructionChoicesFromIngredients(
          ingredientFamily.ingredients,
          baseChoice.id,
        );

        // TODO: update the swap id and key, this is important
        const newSwapId = `swap,${baseChoiceSet.key},${lodash.get(
          ingredientFoo,
          'choiceMap.instruction.id',
          choiceId,
        )}`; // needs to be the instruction type choice id?

        swapChoiceSets[newSwapId] = {
          free: 0,
          max: 1,
          individualMax: 1,
          displayType: 'swap',

          id: newSwapId,
          key: newSwapId,
          name: `autogenerated-swap-${baseChoice.name}-${baseChoice.id}`,
          min: ingredientFamily.swapMinimum,
          choices,
        };
      });
    });

  console.groupEnd();

  return [swapChoiceSets, lodash.uniq(nonSwapBaseNames)];
}
