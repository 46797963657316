import { createAsyncThunk } from '@reduxjs/toolkit';
import { IN_DEVELOPMENT } from '../constants';

import debug from '../utils/debug';
import setLocalValue from '../utils/setLocalValue';
import { clearOffers } from '../slices/offers';
import { clearProfile } from '../slices/profile';

// import syncMember from '../sagas/syncMember';
import syncMemberAction from '../actions/syncMember';

import redcatApiFetch from '../api';

export const clearCookies = createAsyncThunk(
  'clearCookies',
  async (_data: undefined, { dispatch }) => {
    debug('clearing cookies');
    console.log('clearing cookies');

    await redcatApiFetch({
      method: 'GET',
      path: '/auth/logout2',
    });

    if (IN_DEVELOPMENT) {
      setLocalValue('memberAuthToken', undefined);
    }

    dispatch(syncMemberAction());
    dispatch(clearOffers(null));
    dispatch(clearProfile({}));
  },
);
