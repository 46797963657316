export default function processSaleItem(
  rawSaleItem: RawSaleItem,
  alreadyInCents = false,
  ingredient = false,
): ShadowItem {
  let ingredients = null;

  if (!ingredient) {
    ingredients = (rawSaleItem.Ingredients || []).map(ingredient => {
      return processSaleItem(ingredient, alreadyInCents, true);
    });
  }

  return {
    name: rawSaleItem.DisplayName || rawSaleItem.Name || rawSaleItem.Item,
    quantity: rawSaleItem.Qty,
    ingredients,
    id: String(rawSaleItem.PLUCode),
  };
}
