import { put, takeEvery, all, select } from 'redux-saga/effects';

import * as applyShadowOrder from '../../actionCreators/flows/applyShadowOrder';

import {
  addStagedPurchases,
  setNotes,
} from '../../actionCreators/currentOrder';
import {
  setShadowCart,
  setShadowNotes,
} from '../../actionCreators/shadowOrder';

import getItems from '../../selectors/getItems';
import getNotes from '../../selectors/getNotes';
import getShadowOrder from '../../selectors/getShadowOrder';

import { makeErrorSerialisable, createFlowApprover } from '../../utils/sagas';
import constructPurchaseFromShadow from '../../utils/ordering/constructPurchaseFromShadow';

export const requested = createFlowApprover(applyShadowOrder);

export function* approved(
  action: ReturnType<typeof applyShadowOrder.actions.approved>,
) {
  const {
    meta: { flowId },
  } = action;

  try {
    const shadowOrder = (yield select(getShadowOrder)) as ShadowOrder;

    const { notes, cart } = shadowOrder;

    const purchaseFailures: ShadowItem[] = [];
    const ingredientFailures: ShadowItem[] = [];
    const purchases: _Purchase[] = [];

    // NOTE: this is a label, they are rare and only used here to enable breaking from an IF early
    // https://stackoverflow.com/questions/4851657/call-break-in-nested-if-statements
    cartSection: if (cart && cart.length) {
      yield put(setShadowCart(null));

      const orderItems = (yield select(getItems)) as Items;

      if (!orderItems) {
        // skip section if items are missing (no menu data)
        break cartSection;
      }

      cart.forEach(shadowPurchase =>
        constructPurchaseFromShadow(
          shadowPurchase,
          orderItems || {},
          purchases,
          purchaseFailures,
          ingredientFailures,
        ),
      );

      if (purchases.length) {
        yield put(addStagedPurchases(purchases));
      }
    }

    if (notes) {
      const currentNotes = (yield select(getNotes)) as string;
      if (!currentNotes) {
        yield put(setNotes(notes));
      }

      yield put(setShadowNotes(''));
    }

    yield put(
      applyShadowOrder.actions.succeeded(
        { ingredientFailures, purchaseFailures, purchases },
        flowId,
      ),
    );
  } catch (e) {
    yield put(
      applyShadowOrder.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(applyShadowOrder.events.REQUESTED, requested),
    takeEvery(applyShadowOrder.events.APPROVED, approved),
  ]);
}
