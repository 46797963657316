import { put, takeEvery, all, select } from 'redux-saga/effects';

import * as changeOpenPurchaseItem from '../../actionCreators/flows/changeOpenPurchaseItem';

import getUnenhancedOpenPurchase from '../../selectors/getUnenhancedOpenPurchase';
import getItems from '../../selectors/getItems';

import changePurchaseItem from '../../utils/ordering/changePurchaseItem';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';

export const requested = createFlowApprover(changeOpenPurchaseItem);

export function* approved(
  action: ReturnType<typeof changeOpenPurchaseItem.actions.approved>,
) {
  const {
    payload: { itemId },
    meta: { flowId },
  } = action;

  try {
    const openPurchase = yield select(getUnenhancedOpenPurchase);
    const items = yield select(getItems);

    const adjustedPurchase = changePurchaseItem(
      openPurchase,
      items[openPurchase.itemId],
      items[itemId],
    );

    yield put(
      changeOpenPurchaseItem.actions.succeeded(
        { purchase: adjustedPurchase },
        flowId,
      ),
    );
  } catch (e) {
    yield put(
      changeOpenPurchaseItem.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(changeOpenPurchaseItem.events.REQUESTED, requested),
    takeEvery(changeOpenPurchaseItem.events.APPROVED, approved),
  ]);
}
