import React from 'react';
import { useTranslation } from 'react-i18next';
import lodash from 'lodash';

import { OrderingSelectors, OrderingHooks } from 'polygon-ordering';

import { TEXT_PROPERTIES } from '../utils/theme';
import getThemeLookup from '../selectors/getThemeLookup';
import getShowMinExcludesSurchargeMessage from '../selectors/getShowMinExcludesSurchargeMessage';
import getMinimumOrderAmountNotMet from '../selectors/getMinimumOrderAmountNotMet';
import getHideTimes from '../selectors/getHideTimes';

import Text from './Text';
import OrderPropertySaleType from './OrderPropertySaleType';
import DeliveryDetails from './DeliveryDetails';
import OrderPropertyLocation, { Detail } from './OrderPropertyLocation';
import PickupTime from './PickupTime';
import TableNumber from './TableNumber';
import OrderOffer from './OrderOffer';
import OfferInfoButton from './OfferInfoButton';
import Cart from './Cart';
import Surcharges from './Surcharges';
import OrderMessages from './OrderMessages';
import OrderTotals from './OrderTotals';
import { useAppSelector } from '../app/hooks';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import moment from 'moment-timezone';

const { useFormattedCurrency } = OrderingHooks;

const {
  getStagedPurchaseCount,
  getSurcharges,
  getSaleType,
  getMinimumOrderInformation,
  getMaxOrderValue,
  getShowMaxValue,
  getLocation,
} = OrderingSelectors;

interface IProps {
  hideKeyOrderProperties?: boolean;
  hideTotals?: boolean;
  hideSurcharges?: boolean;
  pointsVisible?: boolean;
  useMobileScreen?: boolean | null;
}

const OrderSummaryColumn: React.FC<IProps> = ({
  hideKeyOrderProperties,
  hideTotals,
  hideSurcharges,
  pointsVisible,
  useMobileScreen,
}) => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const stagedPurchaseCount = useAppSelector(getStagedPurchaseCount);
  const surcharges = useAppSelector(getSurcharges);
  const saleType = useAppSelector(getSaleType);
  const minimumOrderAmountNotMet = useAppSelector(getMinimumOrderAmountNotMet);
  const minimumOrderInformation = useAppSelector(getMinimumOrderInformation);
  const showMinExcludesSurchargeMessage = useAppSelector(getShowMinExcludesSurchargeMessage);
  const hideTimes = useAppSelector(getHideTimes);
  const disableOffers = useAppSelector(state => state.config.disableOffers);
  const tableNumberLabel = useAppSelector(state => state.config.tableNumberLabel);
  const maxOrderValueRule = useAppSelector(getMaxOrderValue);
  const maxOrderAmountNotMet = useAppSelector(getShowMaxValue);

  const minimumAmount = lodash.get(minimumOrderInformation, 'amount', 0);

  const isDelivery = saleType === SALE_TYPE.DELIVERY;
  const isPickupOrTakeAway =
    saleType === SALE_TYPE.CATERING ||
    saleType === SALE_TYPE.PICKUP ||
    saleType === SALE_TYPE.TAKE_AWAY ||
    saleType === SALE_TYPE.WEB_ORDERING;
  const isDineIn = saleType === SALE_TYPE.DINE_IN || saleType === SALE_TYPE.TABLE_ORDER;

  const minimumAmountPrice = useFormattedCurrency(minimumAmount, { hideZeroCents: true });
  const maxAmountPrice = useFormattedCurrency(
    maxOrderValueRule ? maxOrderValueRule!.MaximumOrder : null,
    {
      hideZeroCents: true,
    },
  );

  const location = useAppSelector(getLocation);
  const deviceTimezone = moment.tz.guess();
  const locationTimezone = location?.timeZone ?? deviceTimezone;

  return (
    <>
      {!hideKeyOrderProperties && (
        <>
          <>
            <Text themeKey="sectionTitle" value={t('title.saleType')} />
            <OrderPropertySaleType />

            {isDelivery && (
              <div style={styles.deliveryDetailsSection}>
                <DeliveryDetails />
              </div>
            )}
          </>

          <>
            <Text themeKey="sectionTitle" value={t('title.location')} />
            <OrderPropertyLocation />
          </>

          {!hideTimes && isPickupOrTakeAway && (
            <>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Text themeKey="sectionTitle" value={t('title.pickupTime')} />
                {locationTimezone != deviceTimezone && (
                  <Detail label={`(${locationTimezone})`}></Detail>
                )}
              </div>
              <div style={styles.pickupTimeWrapper}>
                <PickupTime
                  themeKey="orderPickupTimeSelect"
                  containerStyle={styles.control}
                  reducedWidth={110}
                  showTimeInErrorMessage
                />
              </div>
            </>
          )}

          {isDineIn && (
            <>
              <Text themeKey="sectionTitle" value={tableNumberLabel || t('tableNumber')} />
              <TableNumber
                containerStyle={styles.control}
                themeKey="orderTableNumber"
                style={styles.tableNumber}
              />
            </>
          )}
        </>
      )}

      <div style={styles.cartTitleContainer}>
        <Text themeKey="sectionTitle">
          {t('title.cart')}

          <Text
            themeKey="sectionTitle"
            style={p('cartCount', TEXT_PROPERTIES)}
          >{` (${stagedPurchaseCount})`}</Text>
        </Text>

        {maxOrderAmountNotMet && (
          <Text themeKey="minimumOrderAmount" style={styles.minimumOrder}>
            {`${maxAmountPrice}${showMinExcludesSurchargeMessage ? '*' : ''}`}
          </Text>
        )}

        {minimumOrderAmountNotMet && (
          <Text themeKey="minimumOrderAmount" style={styles.minimumOrder}>
            {`${minimumAmountPrice} ${t('minimumOrderAmountLabel')}${
              showMinExcludesSurchargeMessage ? '*' : ''
            }`}
          </Text>
        )}
      </div>

      <Cart />

      <OrderMessages />

      {!useMobileScreen && !disableOffers && (
        <div style={styles.offerSection}>
          <div style={styles.offerTitleContainer}>
            <Text themeKey="sectionTitle">{t('title.offer')}</Text>

            <OfferInfoButton />
          </div>

          <OrderOffer />
        </div>
      )}

      {!hideSurcharges && <Surcharges />}

      {!hideTotals && (Boolean(stagedPurchaseCount) || Boolean(surcharges.length)) && (
        <OrderTotals pointsVisible={pointsVisible} useMobileScreen={useMobileScreen} />
      )}
    </>
  );
};

const styles: Styles = {
  control: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 15,
    paddingBottom: 15,
  },

  tableNumber: {
    width: 120,
  },

  cartTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5,
    marginBottom: 5,
  },

  pickupTimeWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  offerTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  minimumOrder: {
    marginRight: 9,
  },

  deliveryDetailsSection: {
    marginBottom: 15,
  },

  offerSection: {
    marginTop: 15,
    marginBottom: 15,
  },
};

export default OrderSummaryColumn;
