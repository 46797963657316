import { createCustomAction } from 'typesafe-actions';

import { standardEmptyACC, standardFailedACC } from '../../utils/sagas';

const REQUESTED = '$REMOVE_MEMBER/REQUESTED';
const BLOCKED = '$REMOVE_MEMBER/BLOCKED';
const APPROVED = '$REMOVE_MEMBER/APPROVED';
const SUCCEEDED = '$REMOVE_MEMBER/SUCCEEDED';
const FAILED = '$REMOVE_MEMBER/FAILED';

export const events = {
  REQUESTED,
  BLOCKED,
  APPROVED,
  SUCCEEDED,
  FAILED,
};

export const actions = {
  requested: createCustomAction(REQUESTED, standardEmptyACC),
  approved: createCustomAction(APPROVED, standardEmptyACC),
  blocked: createCustomAction(BLOCKED, standardEmptyACC),
  succeeded: createCustomAction(SUCCEEDED, standardEmptyACC),
  failed: createCustomAction(FAILED, standardFailedACC),
};

export default actions.requested;
