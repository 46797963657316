import { RedcatApiHandler } from 'polygon-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCurrencySymbol } from '../reducers/config/currencySymbol';
import { setOrderingCurrency } from '../operations';
import { setTaxName } from '../reducers/config/taxName';
import { setCountryCode } from '../reducers/config/countryCode';

export const fetchCurrencyDetails = createAsyncThunk(
  '$fetchCurrencyDetails',
  async (_data: undefined, { dispatch, rejectWithValue }) => {
    const path = '/api/v1/config/lookup/money';

    try {
      const currencyDetails = await RedcatApiHandler.fetch({
        method: 'GET',
        path,
      });

      const { data } = currencyDetails;

      const { tax_string, currency_code, currency_symbol, country_code } = data;

      dispatch(setTaxName(tax_string));
      dispatch(setOrderingCurrency(currency_code));
      dispatch(setCurrencySymbol(currency_symbol));
      dispatch(setCountryCode(country_code));

      return {
        tax_string,
        currency_code,
        currency_symbol,
        country_code,
      };
    } catch (e) {
      console.warn('fetchCurrencyDetails failed', { e });
      return rejectWithValue(e);
    }
  },
);
