import { ActionType, getType } from 'typesafe-actions';

import * as config from '../../actionCreators/config';
import { PAYMENT_METHOD } from '../../constants/paymentMethod';

export default function (
  state: PAYMENT_METHOD[] = [],
  action: ActionType<typeof config>,
): PAYMENT_METHOD[] {
  switch (action.type) {
    case getType(config.setEnabledPaymentMethods): {
      const { value } = action.payload;
      return value || [];
    }
    default:
      return state;
  }
}
