import processOrderingImagePath from './processOrderingImagePath';
import processImageMap from './processImageMap';

function safeTrim(str: string) {
  return (str || '').trim();
}

export default function processChoice(rawChoice: RawChoice): Choice {
  const rawImageDefault = rawChoice.ImageLarge || rawChoice.ImagePath || '';

  return {
    id: String(rawChoice.PLUCode),
    name: safeTrim(rawChoice.LongName) || safeTrim(rawChoice.PLUItem),
    description: safeTrim(rawChoice.Description),
    kilojoules: rawChoice.Kilojoules,
    baseMoneyPrice: rawChoice.Price,
    basePointsPrice: rawChoice.RedeemPoints / 100,
    basePointsAward: rawChoice.AwardPoints / 100,
    baseQuantity: rawChoice.Selected === 1 ? 1 : 0,
    images: {
      default: processOrderingImagePath(rawImageDefault),
      ...processImageMap(rawChoice.ImageMap),
    },
  };
}
