import { createCustomAction } from 'typesafe-actions';

import { standardEmptyACC, standardFailedACC } from '../../utils/sagas';

const REQUESTED = '$FETCH_BRANDS/REQUESTED';
const BLOCKED = '$FETCH_BRANDS/BLOCKED';
const APPROVED = '$FETCH_BRANDS/APPROVED';
const SUCCEEDED = '$FETCH_BRANDS/SUCCEEDED';
const FAILED = '$FETCH_BRANDS/FAILED';

export const events = {
  REQUESTED,
  BLOCKED,
  APPROVED,
  SUCCEEDED,
  FAILED,
};

export const actions = {
  requested: createCustomAction(REQUESTED, standardEmptyACC),
  approved: createCustomAction(APPROVED, standardEmptyACC),
  blocked: createCustomAction(BLOCKED, standardEmptyACC),
  succeeded: createCustomAction(SUCCEEDED, standardEmptyACC),
  failed: createCustomAction(FAILED, standardFailedACC),
};

export default actions.requested;
