import { generateTwoWayMap } from '../utils/misc';
import { SALE_TYPE } from './saleType';
import { ORDERING_PROVIDER } from './orderingProvider';
import { PAYMENT_METHOD } from './paymentMethod';
import { OFFER_TYPE } from './offerType';

export * from './boost';

export * from './failureReasons';

// TODO: work out how to export the global enums directly
export enum SALE_TYPES {
  DINE_IN = SALE_TYPE.DINE_IN,
  TAKE_AWAY = SALE_TYPE.TAKE_AWAY,
  PICKUP = SALE_TYPE.PICKUP,
  DELIVERY = SALE_TYPE.DELIVERY,
  DRIVE_THRU = SALE_TYPE.DRIVE_THRU,
  TABLE_ORDER = SALE_TYPE.TABLE_ORDER,
  WEB_ORDERING = SALE_TYPE.WEB_ORDERING,
  CATERING = SALE_TYPE.CATERING,
}

// TODO: work out how to export the global enums directly
export enum ORDERING_PROVIDERS {
  REDCAT = ORDERING_PROVIDER.REDCAT,
  DELIVEROO = ORDERING_PROVIDER.DELIVEROO,
  POS = ORDERING_PROVIDER.POS,
  UBER = ORDERING_PROVIDER.UBER,
  INDEPENDENT = ORDERING_PROVIDER.INDEPENDENT,
  FOODORA = ORDERING_PROVIDER.FOODORA,
  MENULOG = ORDERING_PROVIDER.MENULOG,
  ORDERUP = ORDERING_PROVIDER.ORDERUP,
  KIOSK = ORDERING_PROVIDER.KIOSK,
  GOOGLE = ORDERING_PROVIDER.GOOGLE,
  MOBI2GO = ORDERING_PROVIDER.MOBI2GO,
  DOORDASH = ORDERING_PROVIDER.DOORDASH,
  TABSQUARE = ORDERING_PROVIDER.TABSQUARE,
}

export enum DELIVERY_PROVIDERS {
  CLIENT_MANAGED = 1,
  DOORDASH_DRIVE = 2,
  DRIVE_YELLO = 3,
  UBER_DIRECT = 9,
}

export enum PAYMENT_METHODS {
  MEMBER_MONEY = PAYMENT_METHOD.MEMBER_MONEY,
  MEMBER_POINTS = PAYMENT_METHOD.MEMBER_POINTS,
  MEMBER_REWARDS = PAYMENT_METHOD.MEMBER_REWARDS,
  EFTPOS = PAYMENT_METHOD.EFTPOS,
  GIFT_CARD = PAYMENT_METHOD.GIFT_CARD,
  CREDIT_CARD = PAYMENT_METHOD.CREDIT_CARD,
  SAVED_CARD = PAYMENT_METHOD.SAVED_CARD,
  APPLE_PAY = PAYMENT_METHOD.APPLE_PAY,
  GOOGLE_PAY = PAYMENT_METHOD.GOOGLE_PAY,
  ALIPAY = PAYMENT_METHOD.ALIPAY,
  FREE = PAYMENT_METHOD.FREE,
  PAY_LATER = PAYMENT_METHOD.PAY_LATER,
}

export const PAYMENT_GATEWAY_PAYMENT_METHODS = [
  PAYMENT_METHOD.EFTPOS,
  PAYMENT_METHOD.CREDIT_CARD,
  PAYMENT_METHOD.SAVED_CARD,
  PAYMENT_METHOD.APPLE_PAY,
  PAYMENT_METHOD.GOOGLE_PAY,
  PAYMENT_METHOD.ALIPAY,
];

export const MEMBER_PAYMENT_METHODS = [
  PAYMENT_METHOD.MEMBER_MONEY,
  PAYMENT_METHOD.MEMBER_POINTS,
];

export const ASAP_TIME = 'ASAP';

export const ORDER_SUBMITTED = 'order-submitted';
export const RECEIVED_AT_STORE = 'received-at-store';
export const PROCESSED_AT_STORE = 'processed-at-store-';
export const READY_TO_COLLECT = 'ready-to-collect';
export const PICKED_UP_BY_DRIVER = 'picked-up';
export const DELIVERED = 'delivered';
export const SCHEDULED = 'scheduled';
export const ENROUTE_TO_PICKUP = 'enroute_to_pickup';
export const ENROUTE_TO_DROPOFF = 'enroute_to_dropoff';
export const ARRIVED_AT_CONSUMER = 'arrived_at_consumer';

export const SALE_STATUSES_MAP = generateTwoWayMap([
  { id: 0, value: ORDER_SUBMITTED },
  { id: 1, value: RECEIVED_AT_STORE },
  { id: 2, value: PROCESSED_AT_STORE },
  { id: 3, value: PICKED_UP_BY_DRIVER },
  { id: 4, value: DELIVERED },
  { id: 5, value: READY_TO_COLLECT }, // frontend only
  { id: 11, value: SCHEDULED }, // same as recieved at store
  { id: 23, value: ENROUTE_TO_PICKUP }, // ignore this cause it happens before Processed at Store
  { id: 26, value: ENROUTE_TO_DROPOFF }, // technically picked-up but it should say order in transit
  { id: 27, value: ARRIVED_AT_CONSUMER }, // there should be another status that says delivered
]);

// TODO: Factor other scanerios
// DELIVERY_STATUS_SCHEDULED = 'scheduled'
// DELIVERY_STATUS_ASSIGNED = 'assigned'
// DELIVERY_STATUS_PICKED_UP = 'picked_up'
// DELIVERY_STATUS_PENDING_RETURN = 'pending_return'
// DELIVERY_STATUS_DELIVERED = 'delivered'
// DELIVERY_STATUS_RETURNED = 'returned'
// DELIVERY_STATUS_CANCELLED = 'cancelled'
// DELIVERY_STATUS_COULD_NOT_DELIVER = 'could_not_deliver'

// DELIVERY_DRIVER_STATUS_UNASSIGNED = 'unassigned'
// DELIVERY_DRIVER_STATUS_ACCEPTED = 'accepted'
// DELIVERY_DRIVER_STATUS_ENROUTE_TO_PICKUP = 'enroute_to_pickup'
// DELIVERY_DRIVER_STATUS_ARRIVED_AT_STORE = 'arrived_at_store'
// DELIVERY_DRIVER_STATUS_PICKED_UP = 'picked_up'
// DELIVERY_DRIVER_STATUS_ENROUTE_TO_DROPOFF = 'enroute_to_dropoff'
// DELIVERY_DRIVER_STATUS_ARRIVED_AT_CONSUMER = 'arrived_at_consumer'
// DELIVERY_DRIVER_STATUS_DROPPED_OFF = 'dropped_off'
// DELIVERY_DRIVER_STATUS_ENROUTE_TO_RETURN = 'enroute_to_return'
// DELIVERY_DRIVER_STATUS_ARRIVED_AT_RETURN = 'arrived_at_return'
// DELIVERY_DRIVER_STATUS_COULD_NOT_DELIVER = 'could_not_deliver'

//minimum offer feedback
export const OFFER_PERCENTAGE = [
  OFFER_TYPE.PERCENTAGE_OFF_ITEM,
  OFFER_TYPE.PERCENTAGE_OFF_ITEM_POS_ENFORCED,
  OFFER_TYPE.PERCENTAGE_OFF_ITEM_AND_INGREDIENTS,
  OFFER_TYPE.PERCENTAGE_OFF_BOGO,
];

export const OFFER_AMOUNT = [
  OFFER_TYPE.AMOUNT_OFF_BOGO,
  OFFER_TYPE.AMOUNT_OFF_ITEM,
  OFFER_TYPE.AMOUNT_OFF_ORDER,
];

export const OFFER_FOR_PURCHASE = [
  OFFER_TYPE.AMOUNT_OFF_ITEM,
  OFFER_TYPE.PERCENTAGE_OFF_ITEM,
  OFFER_TYPE.PERCENTAGE_OFF_ITEM_POS_ENFORCED,
  OFFER_TYPE.PERCENTAGE_OFF_ITEM_AND_INGREDIENTS,
  OFFER_TYPE.PRICE_OVERRIDE_ITEM_OR_INGREDIENT,
];

export const OFFER_FOR_ENTIRE_ORDER = [
  OFFER_TYPE.AMOUNT_OFF_ORDER,
  OFFER_TYPE.PERCENTAGE_OFF_ORDER,
];

export const OFFER_FOR_ITEM = [
  OFFER_TYPE.AMOUNT_OFF_ITEM,
  OFFER_TYPE.PERCENTAGE_OFF_ITEM,
  OFFER_TYPE.PERCENTAGE_OFF_ITEM_POS_ENFORCED,
];

export const OFFER_FOR_ITEM_AND_INGREDIENTS = [
  OFFER_TYPE.PERCENTAGE_OFF_ITEM_AND_INGREDIENTS,
];

export enum MOBILE_INVALID_REASON {
  NOT_PRESENT = 'notPresent',
  TOO_SHORT = 'tooShort',
  INVALID_SHAPE = 'invalidShape',
}

export enum ORDER_NOT_READY_REASON {
  PURCHASE_INVALID = 'PURCHASE_INVALID',
  EMPTY_CART = 'EMPTY_CART',
  OFFER_INVALID = 'OFFER_INVALID',
  TABLE_REQUIRED = 'TABLE_REQUIRED',
  PURCHASER_NAME_INVALID = 'PURCHASER_NAME_INVALID',
  PURCHASER_FAMILY_NAME_INVALID = 'PURCHASER_FAMILY_NAME_INVALID',
  PURCHASER_MOBILE_INVALID = 'PURCHASER_MOBILE_INVALID',
  PURCHASER_EMAIL_INVALID = 'PURCHASER_EMAIL_INVALID',
  MINIMUM_ORDER_NOT_MET = 'MINIMUM_ORDER_NOT_MET',
  OFFER_MINIMUM_NOT_MET = 'OFFER_MINIMUM_NOT_MET',
}

export enum PAYMENT_METHOD_UNAVAILABLE_REASON {
  NOT_ENABLED = 'NOT_ENABLED',
  INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE',
  MEMBER_MISSING = 'MEMBER_MISSING',
  MEMBER_INVALID = 'MEMBER_INVALID',
  SALE_TYPE_NOT_COMPATIBLE = 'SALE_TYPE_NOT_COMPATIBLE',
  OFFER_NOT_COMPATIBLE = 'OFFER_NOT_COMPATIBLE',
  CART_NOT_COMPATIBLE = 'CART_NOT_COMPATIBLE',
  TOTAL_INVALID = 'TOTAL_INVALID',
  NO_SAVED_CARDS = 'NO_SAVED_CARDS',
  GIFT_CARD_INACTIVE = 'GIFT_CARD_INACTIVE',
  DELIVERY_PROVIDER_NOT_COMPATIBLE = 'DELIVERY_PROVIDER_NOT_COMPATIBLE',
}

export enum BUFFER_WARNING_TYPE {
  OFFER_UNAVAILABLE = 'OFFER_UNAVAILABLE',
  OFFER_INEFFECTIVE = 'OFFER_INEFFECTIVE',
  ITEM_UNAVAILABLE = 'ITEM_UNAVAILABLE',
  ITEM_CHOICES_MODIFIED = 'ITEM_CHOICES_MODIFIED',
  ITEM_INVALID = 'ITEM_INVALID',
}

//https://regexr.com/

export const MOBILE_REGEX_AU = /^(0|\+61|61)4\d{8}$/;
export const MOBILE_REGEX_INT =
  /((^[?+\+])(?!(61[\d]{1}))([\d]{1,3})([\d]{3})([\d]{3})([\d]{4})$|(^04|^\+614)([\d]{8})|(^02|^\+642|^03|^\+643)([\d]{7,10}))$/;

export const GATEWAY_REQUIRES_ACTION = 'GATEWAY_REQUIRES_ACTION';
export const EFTPOS_STATUS_UPDATE = 'EFTPOS_STATUS_UPDATE';
export const MIN_CC_AMOUNT_NOT_MET = 'MIN_CC_AMOUNT_NOT_MET';
export const PRE_FINALISE_PAYMENT_REQUEST = 'PRE_FINALISE_PAYMENT_REQUEST';

export const MIN_CC_AMOUNT_DEFAULT = 100; // Value in cents

export enum STOCK_BALANCE_THRESHOLDS {
  STOCK_BALANCE_THRESHOLD_0 = 'STOCK_BALANCE_THRESHOLD_0',
  STOCK_BALANCE_THRESHOLD_1 = 'STOCK_BALANCE_THRESHOLD_1',
  STOCK_BALANCE_THRESHOLD_2 = 'STOCK_BALANCE_THRESHOLD_2',
  STOCK_BALANCE_THRESHOLD_3 = 'STOCK_BALANCE_THRESHOLD_3',
  STOCK_BALANCE_THRESHOLD_4 = 'STOCK_BALANCE_THRESHOLD_4',
}

export const OPEN_PURCHASE_LOWEST_BALANCE_DEFAULT = 99;
