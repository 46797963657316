import React from 'react';
import { useTranslation } from 'react-i18next';
import TouchableOpacity from '../components/TouchableOpacity';
import Text from './Text';
import { RiCheckboxBlankLine, RiCheckboxFill } from 'react-icons/ri';
import lodash from 'lodash';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { setLoyaltyFilters } from '../slices/loyaltyFilters';
import getThemeLookup from '../selectors/getThemeLookup';
import { OrderingSelectors } from 'polygon-ordering';

const { getHistoricalOrders } = OrderingSelectors;

const filterSaleTypes: {
  [key: number]: string;
} = {
  [SALE_TYPE.PICKUP]: 'Pickup',
  [SALE_TYPE.DINE_IN]: 'Dine in',
  [SALE_TYPE.TAKE_AWAY]: 'Takeaway',
  [SALE_TYPE.WEB_ORDERING]: 'Web Ordering',
  [SALE_TYPE.DELIVERY]: 'Delivery',
};

const SaleTypeFilters = () => {
  const { t } = useTranslation();
  const selectedFilter = useAppSelector(state => state.loyaltyFilters);
  const dispatch = useAppDispatch();
  const p = useAppSelector(getThemeLookup);

  const pastOrdersSaleTypes = useAppSelector(getHistoricalOrders)
    ?.map(order => order.saleType)
    .filter(function (value, index, array) {
      //remove duplicates
      return array.indexOf(value) === index;
    });

  return (
    <div>
      <Text value={t('title.filterSaleType')} themeKey="dateFilterTitle" />
      <TouchableOpacity onClick={() => dispatch(setLoyaltyFilters(null))}>
        {!selectedFilter ? (
          <RiCheckboxFill style={{ color: p('filterIconColors', ['color']).color }} />
        ) : (
          <RiCheckboxBlankLine style={{ color: p('filterIconColors', ['color']).color }} />
        )}
        <Text style={{ paddingLeft: 5 }} value={t(`allKey`)} themeKey="filterSaleTypes" />
      </TouchableOpacity>
      {pastOrdersSaleTypes?.map((saleType, index) => (
        <TouchableOpacity
          key={index}
          onClick={() => {
            if (selectedFilter !== saleType) {
              dispatch(setLoyaltyFilters(saleType));
            } else {
              dispatch(setLoyaltyFilters(null));
            }
          }}
        >
          {selectedFilter === saleType ? (
            <RiCheckboxFill style={{ color: p('filterIconColors', ['color']).color }} />
          ) : (
            <RiCheckboxBlankLine style={{ color: p('filterIconColors', ['color']).color }} />
          )}

          <Text
            style={{ paddingLeft: 5 }}
            value={t(`saleType.${saleType}`)}
            themeKey="filterSaleTypes"
          />
        </TouchableOpacity>
      ))}
    </div>
  );
};
export default SaleTypeFilters;
