import { ActionType, getType } from 'typesafe-actions';

import * as buffer from '../../actionCreators/buffer';
import moment from 'moment';

// import { dollarsToCents, safeDate } from '../../utils/misc';

const initialState = null;

export default function (
  state: string | null = initialState,
  action: ActionType<typeof buffer>,
): string | null {
  switch (action.type) {
    case getType(buffer.clearBuffer): {
      return initialState;
    }
    case getType(buffer.setBufferTimeEstimateReturned): {
      let value = moment().toISOString();
      return value || initialState;
    }
    default:
      return state;
  }
}
