import { call, put, takeEvery, all } from 'redux-saga/effects';
import moment from 'moment';

import * as fetchPOSPoints from '../../actionCreators/flows/fetchPOSPoints';

import { FAILURE_REASON } from '../../constants/failureReasons';

import processMember from '../../utils/processors/processMember';
import processGiftCard from '../../utils/processors/processGiftCard';
import processOffer from '../../utils/processors/processOffer';

import Api, { FetchParams, ApiResponse } from '../../utils/Api';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';
import { CARD_TYPE } from '../../constants/cardType';

export const requested = createFlowApprover(fetchPOSPoints);

enum ENTITY_TYPE {
  GIFT_CARD,
  OFFER,
  MEMBER,
}

const ExpiredReasons = {
  [ENTITY_TYPE.GIFT_CARD]: FAILURE_REASON.EXPIRED_GIFT_CARD,
  [ENTITY_TYPE.OFFER]: FAILURE_REASON.EXPIRED_OFFER,
  [ENTITY_TYPE.MEMBER]: FAILURE_REASON.EXPIRED_MEMBER,
};

const InactiveReasons = {
  [ENTITY_TYPE.GIFT_CARD]: FAILURE_REASON.INACTIVE_GIFT_CARD,
  [ENTITY_TYPE.OFFER]: FAILURE_REASON.INACTIVE_OFFER,
  [ENTITY_TYPE.MEMBER]: FAILURE_REASON.INACTIVE_MEMBER,
};

const validateEntity = ({
  entity,
  type,
}: {
  entity: any;
  type: ENTITY_TYPE;
}) => {
  const { expiryDate, active, available } = entity;
  let reason;
  let problem = false;

  if (expiryDate && moment(expiryDate) < moment()) {
    reason = ExpiredReasons[type];
    problem = true;
  }

  if (!active && !available) {
    reason = InactiveReasons[type];
    problem = true;
  }

  return { reason, problem };
};

export function* approved(
  action: ReturnType<typeof fetchPOSPoints.actions.approved>,
) {
  const {
    payload: { barcode, locationId },
    meta: { flowId },
  } = action;

  let reason: fetchPOSPoints.FailureReason;

  try {
    const encodedBarcode = encodeURIComponent(barcode);

    const params: FetchParams = {
      path: `/api/v1/stores/${locationId}/trusted-pospoints/${encodedBarcode}`,
      method: 'GET',
    };

    const response: ApiResponse = yield call(Api.fetch, params, 'trusted');

    const {
      coupon: rawOffer,
      member: rawMember,
      plu: rawItemDetails,
    } = response.data as {
      coupon?: RawOffer;
      member?: RawMember;
      plu?: RawItemLookupResult;
    };

    let processedOffer;
    let processedMember;
    let processedItemDetails;
    let giftCard;
    let validationResult;

    if (!rawOffer && !rawMember && !rawItemDetails) {
      throw new Error('could not find anything for barcode');
    }

    const checkResult = (result: any) => {
      if (result && result.problem) {
        reason = result.reason;
        throw reason;
      }
    };

    if (rawItemDetails) {
      processedItemDetails = {
        id: String(rawItemDetails.PLUCode),
        name: rawItemDetails.PLUItem,
      };
    }

    if (rawOffer) {
      processedOffer = processOffer(rawOffer);
      validationResult = validateEntity({
        entity: processedOffer,
        type: ENTITY_TYPE.OFFER,
      });

      checkResult(validationResult);
    }

    if (rawMember) {
      if (rawMember.CardType === CARD_TYPE.GIFT) {
        giftCard = processGiftCard(rawMember);
        validationResult = validateEntity({
          entity: giftCard,
          type: ENTITY_TYPE.GIFT_CARD,
        });
      } else {
        processedMember = processMember(rawMember);
        validationResult = validateEntity({
          entity: processedMember,
          type: ENTITY_TYPE.MEMBER,
        });
      }

      checkResult(validationResult);
    }

    yield put(
      fetchPOSPoints.actions.succeeded(
        {
          member: processedMember,
          offer: processedOffer,
          itemDetails: processedItemDetails,
          giftCard,
        },
        flowId,
      ),
    );
  } catch (e) {
    yield put(
      fetchPOSPoints.actions.failed(
        { error: makeErrorSerialisable(e), reason },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(fetchPOSPoints.events.REQUESTED, requested),
    takeEvery(fetchPOSPoints.events.APPROVED, approved),
  ]);
}
