import React from 'react';
import { useAppSelector } from '../app/hooks';
import { OrderingSelectors } from 'polygon-ordering';
import Text from '../components/Text';
import moment from 'moment';
import lodash from 'lodash';
import { OrderingHooks } from 'polygon-ordering';
import TransactionDetails from '../components/TransactionDetails';

const { useFormattedCurrency } = OrderingHooks;
const { getTransactionHistory, getTaxName } = OrderingSelectors;

export const TRANSACTIONS_SCREEN = '/loyalty-history';

const descriptionMap: DescriptionMap = {
  1: 'Sale',
  2: 'Redeem Points',
  3: 'Top Up',
  4: 'Redeem Money',
  5: 'Points Increase',
  6: 'Points Decrease',
  7: 'Money Increase',
  8: 'Money Decrease',
  9: 'Sale Reward',
  10: 'Redeem Reward',
  66: 'Birthday Gift',
};

const showTotal = [1, 2, 3, 4, 9, 10, 66];

const TransactionsScreen: React.FC = () => {
  const orders = useAppSelector(getTransactionHistory);
  return (
    <div>
      {orders?.map((order, index) => (
        <Transaction key={index} order={order} />
      ))}
    </div>
  );
};

const Transaction = ({ order }: { order: TransactionHistory }) => {
  const gst = lodash.sumBy(order.Items, 'GST') || null;
  const gstDollar = useFormattedCurrency(Number(gst));
  const orderTotal =
    order?.Items?.reduce((sum, item) => {
      const price = item?.Total || item?.Price * item?.Qty || 0;
      return sum + price;
    }, 0) || 0;
  const orderTotalPriceDollar = useFormattedCurrency(orderTotal);
  const taxName = useAppSelector(getTaxName);

  return (
    <div className="p-3 my-2" style={{ backgroundColor: 'whitesmoke', borderRadius: 5 }}>
      <div style={{ paddingRight: 10 }}>
        {order.Store && (
          <>
            <Text themeKey="loyaltyStoreLocation" value={order.Store.toUpperCase()} />{' '}
          </>
        )}
        <Text
          themeKey="loyaltyOrderDate"
          value={moment(order.TxnDate).format('DD/MM/YYYY hh:mma')}
        />

        {order.TxnType && (
          <div>
            <Text themeKey="loyaltyOrderTotal" value={descriptionMap[order.TxnType]} />{' '}
          </div>
        )}

        {order.SalesID && (
          <div>
            <Text themeKey="loyaltyOrderItems" value={'ID: ' + order.SalesID.toString()} />{' '}
          </div>
        )}

        <div>
          {order.Items?.map((listItem, index) => (
            <TransactionDetails transaction={listItem} order={order} key={index} />
          ))}

          {gst && (
            <div>
              <Text themeKey="loyaltyOrderTotal" value={`${taxName}: ` + gstDollar} />
            </div>
          )}

          {showTotal.includes(order.TxnType) && (
            <div>
              <Text themeKey="loyaltyOrderTotal" value={'Total: ' + orderTotalPriceDollar} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionsScreen;
