import { all, call, fork, select, takeEvery } from 'redux-saga/effects';

import getOrdering from '../selectors/getOrdering';

import LifecycleHooks from '../utils/LifecycleHooks';
import Logger from '../utils/Logger';

import adjustOpenPurchaseChoice from './flows/adjustOpenPurchaseChoice';
import applyBarcode from './flows/applyBarcode';
import applyBuffer from './flows/applyBuffer';
import applyPromoCode from './flows/applyPromoCode';
import applyShadowOrder from './flows/applyShadowOrder';
import changeOpenPurchaseItem from './flows/changeOpenPurchaseItem';
import createFavouriteOrder from './flows/createFavouriteOrder';
import deleteFavouriteOrder from './flows/deleteFavouriteOrder';
import editFavouriteOrder from './flows/editFavouriteOrder';
import fetchBrands from './flows/fetchBrands';
import fetchDeliveryEstimate from './flows/fetchDeliveryEstimate';
import fetchFavouriteOrders from './flows/fetchFavouriteOrders';
import fetchGiftCard from './flows/fetchGiftCard';
import fetchHistoricalOrders from './flows/fetchHistoricalOrders';
import fetchTransactionHistroy from './flows/fetchTransactionHistroy';

import fetchLocations from './flows/fetchLocations';
import fetchMember from './flows/fetchMember';
import fetchMenu from './flows/fetchMenu';
import fetchOrderingProviderDetails from './flows/fetchOrderingProviderDetails';
import fetchOffers from './flows/fetchOffers';
import fetchPayLaterRules from './flows/fetchPayLaterRules';
import fetchPaymentGatewayTokens from './flows/fetchPaymentGatewayTokens';
import fetchPOSPoints from './flows/fetchPOSPoints';
import fetchSaleStatus from './flows/fetchSaleStatus';
import fetchPaymentGatewayConfig from './flows/fetchPaymentGatewayConfig';
import finaliseOrder from './flows/finaliseOrder';
import openPurchase from './flows/openPurchase';
import openStagedPurchase from './flows/openStagedPurchase';
import recordPayment from './flows/recordPayment';
// import refundPayment from './flows/refundPayment';
import removeMember from './flows/removeMember';
import removePaymentGatewayToken from './flows/removePaymentGatewayToken';
import resetOpenPurchaseChoiceSets from './flows/resetOpenPurchaseChoiceSets';
import selectOffer from './flows/selectOffer';
import setGiftCard from './flows/setGiftCard';
import setMember from './flows/setMember';
import stageOpenPurchase from './flows/stageOpenPurchase';
import stagePurchaseFromItem from './flows/stagePurchaseFromItem';
import startNewOrder from './flows/startNewOrder';
import submitSale from './flows/submitSale';
import topUpMemberMoney from './flows/topUpMemberMoney';
import tyroPayment from './flows/tyroPayment';
// import tyroRefund from './flows/tyroRefund';
import updateKeyOrderProperty from './flows/updateKeyOrderProperty';
import updateMenu from './flows/updateMenu';
import updateSelectedPaymentMethods from './flows/updateSelectedPaymentMethods';
import validateOrder from './flows/validateOrder';
import fetchStockBalances from './flows/fetchStockBalances';
import fetchMaxOrderValueRules from './flows/fetchMaxOrderValueRules';

function* lifecycle(action: { type: string }) {
  if (!action.type.endsWith('REQUESTED')) {
    const state = yield select(getOrdering);
    yield call(LifecycleHooks.get(action.type), action, state);
  }
}

export default function* root() {
  Logger.log('ordering module root saga started');
  yield all([
    // fork(refundPayment),
    // fork(tyroRefund),
    fork(adjustOpenPurchaseChoice),
    fork(applyBarcode),
    fork(applyBuffer),
    fork(applyPromoCode),
    fork(applyShadowOrder),
    fork(changeOpenPurchaseItem),
    fork(createFavouriteOrder),
    fork(deleteFavouriteOrder),
    fork(editFavouriteOrder),
    fork(fetchBrands),
    fork(fetchDeliveryEstimate),
    fork(fetchFavouriteOrders),
    fork(fetchGiftCard),
    fork(fetchHistoricalOrders),
    fork(fetchTransactionHistroy),
    fork(fetchLocations),
    fork(fetchMember),
    fork(fetchMenu),
    fork(fetchOrderingProviderDetails),
    fork(fetchOffers),
    fork(fetchPOSPoints),
    fork(fetchPayLaterRules),
    fork(fetchPaymentGatewayTokens),
    fork(fetchSaleStatus),
    fork(fetchPaymentGatewayConfig),
    fork(finaliseOrder),
    fork(openPurchase),
    fork(openStagedPurchase),
    fork(recordPayment),
    fork(removeMember),
    fork(removePaymentGatewayToken),
    fork(resetOpenPurchaseChoiceSets),
    fork(selectOffer),
    fork(setGiftCard),
    fork(setMember),
    fork(stageOpenPurchase),
    fork(stagePurchaseFromItem),
    fork(startNewOrder),
    fork(submitSale),
    fork(topUpMemberMoney),
    fork(tyroPayment),
    fork(updateKeyOrderProperty),
    fork(updateMenu),
    fork(updateSelectedPaymentMethods),
    fork(validateOrder),
    fork(fetchStockBalances),
    fork(fetchMaxOrderValueRules),
    takeEvery('*', lifecycle),
  ]);
}

// NOTE: every flow that has all 5 actions inside it will have two sagas
// one saga does approval hook checking
// another does the api request
