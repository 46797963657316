import { OrderingConstants } from 'polygon-ordering';

import { RiBroadcastLine, RiStore3Fill, RiShoppingBag2Fill, RiEBike2Fill } from 'react-icons/ri';
import { FaReceipt } from 'react-icons/fa';
import { IconType } from 'react-icons/lib';

const {
  ORDER_SUBMITTED,
  RECEIVED_AT_STORE,
  PROCESSED_AT_STORE,
  READY_TO_COLLECT,
  PICKED_UP_BY_DRIVER,
  DELIVERED,
} = OrderingConstants;

interface Icons {
  [key: string]: IconType;
}

export const STATUS_STEP_ICONS: Icons = {
  [ORDER_SUBMITTED]: RiBroadcastLine,
  [RECEIVED_AT_STORE]: RiStore3Fill,
  [PROCESSED_AT_STORE]: FaReceipt,
  [READY_TO_COLLECT]: RiShoppingBag2Fill,
  [PICKED_UP_BY_DRIVER]: RiShoppingBag2Fill,
  [DELIVERED]: RiEBike2Fill,
} as const;
