import { createSelector } from 'reselect';

import getBufferReadyToApply from './getBufferReadyToApply';
import getStagedPurchases, { $getStagedPurchases } from './getStagedPurchases';
import getSelectedOffer, { $getSelectedOffer } from './getSelectedOffer';
import getSelectedOfferHasEffect, {
  $getSelectedOfferHasEffect,
} from './getSelectedOfferHasEffect';

import { BUFFER_WARNING_TYPE } from '../constants';

// pickup time not available? // how do I even know this?, why is smoothPickupTime UNUSED???
// minimum order $ increased? (unless your new total is higher than it)

const getBufferWarningsForOffer = createSelector(
  [
    getSelectedOffer,
    $getSelectedOffer,
    getSelectedOfferHasEffect,
    $getSelectedOfferHasEffect,
  ],
  (
    prevOffer,
    newOffer,
    prevOfferhasEffect,
    newOfferHasEffect,
  ): BufferWarning[] => {
    if (prevOffer && !newOffer) {
      return [
        {
          type: BUFFER_WARNING_TYPE.OFFER_UNAVAILABLE,
          message: prevOffer.name,
        },
      ];
    }

    if (prevOffer && prevOfferhasEffect && !newOfferHasEffect) {
      return [
        {
          type: BUFFER_WARNING_TYPE.OFFER_INEFFECTIVE,
          message: prevOffer.name,
        },
      ];
    }

    return [];
  },
);

// deciding not to warn about price changes. they can see them in the cart screen
// more important to warn about availability
const getBufferWarningsForCart = createSelector(
  [getStagedPurchases, $getStagedPurchases],
  (prevPurchases, newPurchases): BufferWarning[] => {
    const warnings: BufferWarning[] = [];

    prevPurchases.forEach(purchase => {
      const newVersion = newPurchases.find(
        newPurchase => newPurchase.id === purchase.id,
      );

      // were not able to translate purchase to an acceptable available item
      if (!newVersion) {
        warnings.push({
          type: BUFFER_WARNING_TYPE.ITEM_UNAVAILABLE,
          message: purchase?.item?.name,
        });

        return;
      }

      // This could be more involved/sensitive (like checking if min/max has changed)
      const anyChoiceModified =
        (purchase.choicesWithQuantity || []).length !==
        (newVersion.choicesWithQuantity || []).length;

      if (anyChoiceModified) {
        warnings.push({
          type: BUFFER_WARNING_TYPE.ITEM_CHOICES_MODIFIED,
          message: purchase?.item?.name,
        });
      }

      // the change has broken a purchase
      if (purchase.valid && !newVersion.valid) {
        warnings.push({
          type: BUFFER_WARNING_TYPE.ITEM_INVALID,
          message: purchase.item.name,
        });
      }
    });

    return warnings;
  },
);

export default createSelector(
  [getBufferReadyToApply, getBufferWarningsForCart, getBufferWarningsForOffer],
  (
    bufferReadyToApply,
    cartWarnings,
    offerWarnings,
  ): undefined | BufferWarning[] => {
    if (!bufferReadyToApply) {
      return undefined;
    }

    return [...cartWarnings, ...offerWarnings];
  },
);
