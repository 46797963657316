import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiCheckboxBlankCircleLine, RiRecordCircleFill } from 'react-icons/ri';
import Text from './Text';
import TouchableOpacity from './TouchableOpacity';
import { useFormikContext } from 'formik';
import moment from 'moment-timezone';
import FormDateFields from './FormDateFields';
import FormSelect from './FormSelect';

const GiftCardDeliveryDetails: React.FC = () => {
  const { t } = useTranslation();
  const { values, setFieldValue, handleChange } = useFormikContext<FormikFields>();

  const { delivery } = values!;

  return (
    <div>
      <div className="my-3">
        <Text value={t('deliveryDetails')} style={{ fontSize: 15 }} themeKey="recipientDetails" />
        <div className="my-2">
          <TouchableOpacity
            onClick={() => {
              if (delivery) {
                setFieldValue('delivery', false);
              }
            }}
          >
            {delivery ? (
              <RiCheckboxBlankCircleLine style={{ fontSize: 15, marginRight: 5 }} />
            ) : (
              <RiRecordCircleFill style={{ fontSize: 15, marginRight: 5 }} />
            )}
            <Text value={'Send Now'} style={{ fontSize: 15 }} themeKey="recipientDetails" />
          </TouchableOpacity>

          <TouchableOpacity onClick={() => !delivery && setFieldValue('delivery', true)}>
            {delivery ? (
              <RiRecordCircleFill style={{ fontSize: 15, marginRight: 5 }} />
            ) : (
              <RiCheckboxBlankCircleLine style={{ fontSize: 15, marginRight: 5 }} />
            )}
            <Text value={'Send Later'} style={{ fontSize: 15 }} themeKey="recipientDetails" />
          </TouchableOpacity>
        </div>
      </div>

      <div>
        {delivery && (
          <div>
            <FormDateFields
              label={'Date & Time'}
              name="SendTime"
              value={moment(values.SendTime!).format('yyyy-MM-DDThh:mm')}
              type="datetime-local"
              onChange={(e: any) => {
                setFieldValue('SendTime', moment(e.target.value));
              }}
              validate
            />

            <FormSelect
              name="timezone"
              label="Timezone"
              onChange={handleChange}
              menuItems={moment.tz.names()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default GiftCardDeliveryDetails;
