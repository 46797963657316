import React from 'react';
import lodash from 'lodash';
import { withTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { RiArrowDownSLine } from 'react-icons/ri';
import { OrderingSelectors, OrderingOperations, OrderingConstants } from 'polygon-ordering';
import reduxConnect from '../utils/reduxConnect';
import combineStyles from '../utils/combineStyles';
import { CONTAINER_PROPERTIES } from '../utils/theme';
import getThemeLookup from '../selectors/getThemeLookup';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import setCurrentModalAction from '../actions/setCurrentModal';
import Modal from '../components/Modal';
import Text from '../components/Text';
import TouchableOpacity from '../components/TouchableOpacity';
import RedcatImage from '../components/RedcatImage';
import QuantityControl from '../components/QuantityControl';
import ChoiceSetBanner from '../components/ChoiceSetBanner';
import StagedChoiceSet from '../components/StagedChoiceSet';
import calculateStockBalanceData from '../utils/calculateStockBalanceData';
import calcMinBalanceStaged from '../utils/calcMinBalanceStaged';
import calcStagedPurchasesInCart from '../utils/calcStagedPurchasesInCart';
import StagedPurchaseEditorModalProceed from '../components/StagedPurchaseEditorModalProceed';

const { getOpenPurchase, getHideNegativeIngredients, getStagedPurchases } = OrderingSelectors;

const { OPEN_PURCHASE_LOWEST_BALANCE_DEFAULT } = OrderingConstants;

export const STAGED_PURCHASE_EDITOR_MODAL_ID = 'STAGED_PURCHASE_EDITOR_MODAL_ID';

const BOTTOM_PADDING = 170;

class StagedPurchaseEditorModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showIndicator: false,
      collapsedChoiceSets: {},
    };
  }

  componentDidUpdate() {
    this.assess();
  }

  componentDidMount() {
    this.assess();

    const {
      collapsableChoiceSetsEnabled,
      collapseOptionalChoiceSetsByDefault,
      setOpenPurchaseLowestBalance,
    } = this.props;

    setOpenPurchaseLowestBalance(OPEN_PURCHASE_LOWEST_BALANCE_DEFAULT);

    if (collapsableChoiceSetsEnabled && collapseOptionalChoiceSetsByDefault) {
      this.collapseOptionalChoiceSets();
    }
  }

  assess = () => {
    const { showIndicator } = this.state;

    if (!this.psbContainerRef) {
      return;
    }

    const reachedEnd =
      this.psbContainerRef.scrollTop >=
      this.psbContainerRef.scrollHeight - this.psbContainerRef.offsetHeight - BOTTOM_PADDING - 50;

    if (!reachedEnd && !showIndicator) {
      this.setState({ showIndicator: true });
    } else if (reachedEnd && showIndicator) {
      this.setState({ showIndicator: false });
    }
  };

  toggleCollapsed = id => {
    const { collapsedChoiceSets } = this.state;

    this.setState({
      collapsedChoiceSets: {
        ...collapsedChoiceSets,
        [id]: !collapsedChoiceSets[id],
      },
    });
  };

  collapseOptionalChoiceSets = () => {
    const { openPurchase } = this.props;

    const choiceSets = lodash.get(openPurchase, 'choiceSets', []);

    const collapsedChoiceSets = {};

    choiceSets.forEach(choiceSet => {
      if (choiceSet.valid) {
        collapsedChoiceSets[choiceSet.id] = true;
      }
    });

    this.setState({ collapsedChoiceSets });
  };

  render() {
    const {
      t,
      p,
      openPurchase,
      changeOpenPurchaseItem,
      adjustOpenPurchaseQuantity,
      adjustOpenPurchaseChoice,
      hideNegativeIngredients,
      setCurrentModal,
    } = this.props;

    const { showIndicator, collapsedChoiceSets } = this.state;

    if (!openPurchase) {
      setCurrentModal(null);
      return null;
    }

    const {
      item,
      sizes,
      multiSized,
      choiceSets = [],
      valid,
      quantity,
      choicesWithQuantity,
    } = openPurchase;

    const SIZE_CHOICE_SET = {
      id: 'SIZE_CHOICE_SET',
      name: t('title.sizeChoice'),
      free: 0,
      max: 1,
      min: 1,
      valid: true,
      quantity: 1,
      choices: (sizes || []).map(size => ({
        ...size,
        quantity: item.id === size.id ? 1 : 0,
      })),
    };

    const stockBalanceData = calculateStockBalanceData(item.id);
    const quantityMap = calcStagedPurchasesInCart(openPurchase.id);
    const minBalanceStaged = calcMinBalanceStaged(choicesWithQuantity, quantityMap);

    // Sold Out should appear only for grayed out items not current purchase editoral modal
    const revertSoldOutThresholdToLowInStock =
      stockBalanceData.stockBalanceThreshold === 'STOCK_BALANCE_THRESHOLD_0'
        ? 'STOCK_BALANCE_THRESHOLD_1'
        : stockBalanceData.stockBalanceThreshold;

    return (
      <Modal containerStyle={styles.modalContainer} desktopMinWidth={500} desktopMaxWidth={600}>
        <div style={styles.body}>
          <PerfectScrollbar
            containerRef={ref => {
              this.psbContainerRef = ref;

              if (ref) {
                // https://github.com/mdbootstrap/perfect-scrollbar/pull/934/files
                // injecting a fix for this issue
                ref._getBoundingClientRect = ref.getBoundingClientRect;

                ref.getBoundingClientRect = () => {
                  const original = ref._getBoundingClientRect();

                  return { ...original, height: Math.round(original.height) };
                };
              }
            }}
            style={combineStyles(
              styles.scrollable,
              choiceSets.length > 1 && styles.scrollableExtraPadding, // so combo checkboxes aren't right along the bottom
            )}
            onScroll={lodash.throttle(() => {
              // this.updateScroll();
              this.assess();
            }, 500)}
          >
            <div style={styles.topSection}>
              <RedcatImage
                alt={item.name}
                imagePath={item?.images?.default}
                size={p('purchaseEditorItemImage', 'height', true)}
                containerStyle={styles.image}
              />

              <Text themeKey="purchaseEditorName" style={styles.name}>
                {item.name}
              </Text>

              {Boolean(item.description) && (
                <Text themeKey="purchaseEditorDescription" style={styles.description}>
                  {item.description}
                </Text>
              )}

              {Boolean(item.kilojoules) && (
                <Text themeKey="purchaseEditorEnergy" style={styles.energy}>
                  {item.kilojoules}
                  <Text themeKey="purchaseEditorEnergySuffix" style={styles.energySuffix}>
                    kj
                  </Text>
                </Text>
              )}

              {Boolean(item.id) && (
                <QuantityControl
                  containerStyle={styles.quantityControl}
                  quantity={quantity}
                  adjustQuantity={adjustOpenPurchaseQuantity}
                  maxQuantity={
                    minBalanceStaged
                      ? minBalanceStaged
                      : quantityMap[item.id] && stockBalanceData.itemBalance
                      ? stockBalanceData.itemBalance - quantityMap[item.id]
                      : stockBalanceData.itemBalance
                  }
                />
              )}

              {stockBalanceData.stockBalanceThreshold && (
                <Text
                  themeKey={
                    revertSoldOutThresholdToLowInStock ? revertSoldOutThresholdToLowInStock : ''
                  }
                  style={styles.itemStockMessage}
                >
                  {t('stockBalanceThreshold.' + revertSoldOutThresholdToLowInStock)}
                </Text>
              )}
            </div>

            {multiSized && (
              <>
                <ChoiceSetBanner
                  choiceSet={SIZE_CHOICE_SET}
                  collapsed={collapsedChoiceSets[SIZE_CHOICE_SET.id]}
                  toggleCollapsed={this.toggleCollapsed}
                />

                {!collapsedChoiceSets[SIZE_CHOICE_SET.id] && (
                  <StagedChoiceSet
                    choiceSet={SIZE_CHOICE_SET}
                    adjustOpenPurchaseChoice={({ targetChoiceId }) =>
                      changeOpenPurchaseItem({ itemId: targetChoiceId })
                    }
                    hideNegativeIngredients={hideNegativeIngredients}
                    stagedQty={quantityMap}
                  />
                )}
              </>
            )}

            {choiceSets.map(choiceSet => {
              const collapsed = Boolean(collapsedChoiceSets[choiceSet.id]);

              return (
                <React.Fragment key={choiceSet.id}>
                  <ChoiceSetBanner
                    key={`${choiceSet.id}_banner`}
                    choiceSet={choiceSet}
                    collapsed={collapsed}
                    toggleCollapsed={this.toggleCollapsed}
                  />

                  {(choiceSet.displayType === 'checkbox' || !collapsed) && (
                    <StagedChoiceSet
                      key={`${choiceSet.id}_display`}
                      choiceSet={choiceSet}
                      adjustOpenPurchaseChoice={adjustOpenPurchaseChoice}
                      hideNegativeIngredients={hideNegativeIngredients}
                      stagedQty={quantityMap}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </PerfectScrollbar>

          <StagedPurchaseEditorModalProceed
            disabled={
              !valid ||
              quantity >
                (minBalanceStaged
                  ? minBalanceStaged
                  : quantityMap[item.id] && stockBalanceData.itemBalance
                  ? stockBalanceData.itemBalance - quantityMap[item.id]
                  : stockBalanceData.itemBalance)
            }
          />

          {showIndicator && (
            <TouchableOpacity
              style={combineStyles(
                styles.indicator,
                p('scrollForMoreIndicator', CONTAINER_PROPERTIES),
              )}
              onClick={() => this.psbContainerRef.scrollBy({ top: 200 })}
              ariaLabel="scroll to see more"
            >
              <RiArrowDownSLine style={p('scrollForMoreIndicator', ['color'])} />
            </TouchableOpacity>
          )}
        </div>
      </Modal>
    );
  }
}

const styles = {
  modalContainer: {
    padding: 0,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  body: {
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',

    flex: 1,
    overflow: 'hidden',
  },

  scrollable: {
    overflowY: 'auto',
    flex: 1,
  },

  scrollableExtraPadding: {
    paddingBottom: BOTTOM_PADDING,
  },

  topSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    padding: 20,
  },

  image: {
    marginBottom: 10,
  },
  name: {
    marginBottom: 5,
    textAlign: 'center',
  },
  description: {
    marginBottom: 8,
    textAlign: 'center',
  },
  energy: {
    marginBottom: 5,
  },
  energySuffix: {
    marginLeft: 1,
  },
  price: {
    marginTop: 10,
  },

  quantityControl: {
    marginTop: 20,
  },

  button: {
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
  },
  disabledButton: {
    opacity: 0.9,
  },

  indicator: {
    position: 'absolute',
    left: 20,
    bottom: 80,
    height: 50,
    width: 50,
    backgroundColor: 'green',
    zIndex: 900,
    borderRadius: '50%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 3,
    fontSize: 40,
  },

  itemStockMessage: {
    marginTop: 25,
    marginBottom: 5,
  },
};

export default reduxConnect({
  mapStateToProps: [
    {
      p: getThemeLookup,
      deviceTypeMobile: getDeviceTypeMobile,

      openPurchase: getOpenPurchase,
      hideNegativeIngredients: getHideNegativeIngredients,
      stagedPurchases: getStagedPurchases,
    },
    'config.collapsableChoiceSetsEnabled',
    'config.collapseOptionalChoiceSetsByDefault',
  ],
  mapDispatchToProps: {
    setCurrentModal: setCurrentModalAction,
    changeOpenPurchaseItem: OrderingOperations.changeOpenPurchaseItem,
    adjustOpenPurchaseQuantity: OrderingOperations.adjustOpenPurchaseQuantity,
    adjustOpenPurchaseChoice: OrderingOperations.adjustOpenPurchaseChoice,
    setOpenPurchaseLowestBalance: OrderingOperations.setOpenPurchaseLowestBalance,
  },
  component: withTranslation()(StagedPurchaseEditorModal),
});
