import React from 'react';
import { RedcatApiHandler } from 'polygon-utils';
import EVENTS from '../constants/events';
import combineStyles from '../utils/combineStyles';
import { logEvent } from '../utils/analytics';
import { useState, useEffect } from 'react';
import { useAppSelector } from '../app/hooks';

interface IProps {
  imagePath?: string;
  lazy?: boolean;
  size?: number;
  containerStyle?: React.CSSProperties;
  pathPrefix?: string;
  alt?: string;
  imgFluid?: boolean;
}

const RedcatImage: React.FC<IProps> = ({
  imagePath,
  lazy = true,
  size,
  containerStyle,
  pathPrefix,
  alt,
  imgFluid,
}) => {
  const apiHandlerReady = useAppSelector(state => state.apiHandlerReady);
  const [errored, setErrored] = useState(false);

  useEffect(() => {
    setErrored(false);
  }, [imagePath]);

  const onError = () => {
    if (!errored) {
      setErrored(true);

      logEvent(EVENTS.IMAGE_LOAD_FAILED, { label: imagePath });
    }
  };

  if (errored || !imagePath) {
    return null;
  }

  return (
    <div
      style={combineStyles(
        styles.mainContainer,
        size && {
          height: size,
          width: size,
        },
        containerStyle,
      )}
    >
      {apiHandlerReady && (
        <img
          className={imgFluid ? 'img-fluid' : ''}
          style={styles.image}
          src={RedcatApiHandler.determineFullLinkWithEndpoint(`${pathPrefix || ''}${imagePath}`)}
          onError={onError}
          loading={lazy ? 'lazy' : undefined}
          alt={alt || 'product image'}
        />
      )}
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
};

export default RedcatImage;
