import { createSelector } from 'reselect';

import getCurrentOrder from './getCurrentOrder';
import getBuffer from './getBuffer';

const getMenu = createSelector(
  [getCurrentOrder],
  (order: Order): Menu | undefined => order.menu || undefined,
);

export default getMenu;

export const $getMenu = createSelector(
  [getBuffer, getMenu],
  (buffer: OrderingBuffer, menuItems?: Menu) => {
    return buffer.menu || menuItems || undefined;
  },
);
