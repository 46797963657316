import { createCustomAction } from 'typesafe-actions';
import { singleValuePayload } from '../utils/actions';

const SET_MEMBER_DATA = '$SET_MEMBER_DATA';
export const setMemberData = createCustomAction(
  SET_MEMBER_DATA,
  singleValuePayload<typeof SET_MEMBER_DATA, Member | null>(),
);

const SET_PAYMENT_GATEWAY_TOKENS = '$SET_PAYMENT_GATEWAY_TOKENS';
// prettier-ignore
export const setPaymentGatewayTokens = createCustomAction(
  SET_PAYMENT_GATEWAY_TOKENS,
  singleValuePayload<typeof SET_PAYMENT_GATEWAY_TOKENS, PaymentGateWayToken[] | null>(),
);

const SET_HISTORICAL_ORDERS = '$SET_HISTORICAL_ORDERS';
export const setHistoricalOrders = createCustomAction(
  SET_HISTORICAL_ORDERS,
  singleValuePayload<typeof SET_HISTORICAL_ORDERS, HistoricalOrder[] | null>(),
);

const SET_FAVOURITE_ORDERS = '$SET_FAVOURITE_ORDERS';
export const setFavouriteOrders = createCustomAction(
  SET_FAVOURITE_ORDERS,
  singleValuePayload<typeof SET_FAVOURITE_ORDERS, FavouriteOrder[] | null>(),
);

const SET_TRANSACTION_HISTORY = '$SET_TRANSACTION_HISTORY';
export const setTransactionHistory = createCustomAction(
  SET_TRANSACTION_HISTORY,
  singleValuePayload<
    typeof SET_TRANSACTION_HISTORY,
    TransactionHistory[] | null
  >(),
);
