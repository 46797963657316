import { ActionType, getType } from 'typesafe-actions';

import * as buffer from '../../actionCreators/buffer';
import { SALE_TYPE } from '../../constants/saleType';

const initialState = null;

export default function (
  state: SALE_TYPE | null = initialState,
  action: ActionType<typeof buffer>,
): SALE_TYPE | null {
  switch (action.type) {
    case getType(buffer.clearBuffer): {
      return initialState;
    }
    case getType(buffer.setBufferSaleType): {
      const { value } = action.payload;
      return value == null ? SALE_TYPE.PICKUP : value;
    }
    default:
      return state;
  }
}
