import { createSelector } from 'reselect';
import lodash from 'lodash';

import enhanceItem from '../utils/ordering/enhanceItem';

import getMenu, { $getMenu } from './getMenu';

const get = (menu: Menu | undefined): Items | undefined => {
  if (!menu) {
    return undefined;
  }

  return lodash.mapValues(menu.items, item => {
    return enhanceItem(item, menu);
  });
};

export default createSelector([getMenu], get);

export const $getItems = createSelector([$getMenu], get);
