import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RiSearchLine, RiCloseLine } from 'react-icons/ri';

import EVENTS from '../constants/events';

import combineStyles from '../utils/combineStyles';
import { logEvent } from '../utils/analytics';

import getFilteredMenuItemCount from '../selectors/getFilteredMenuItemCount';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import { useAppSelector, useAppDispatch } from '../app/hooks';

import { setMenuSearchText } from '../slices/menuSearchText';

import StandardButton from './StandardButton';
import Text from './Text';
import TouchableOpacity from './TouchableOpacity';

interface IProps {
  toggleSearchActive: () => void;
}

const MenuSearchBar: React.FC<IProps> = ({ toggleSearchActive }) => {
  const itemCount = useAppSelector(getFilteredMenuItemCount);
  const menuSearchText = useAppSelector(state => state.menuSearchText);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // Cancel button function
  const cancelMenuSearch = () => {
    const value = ''
    dispatch(setMenuSearchText(value))
    toggleSearchActive()
  }

  useEffect(() => {
    // When search component is unmounted make search not active and clear the search bar.
    return () => { 
      cancelMenuSearch()
    }
  }, [])



  return (
    <div style={styles.mainContainer}>
      <div style={combineStyles(styles.top, !deviceTypeMobile && styles.topDesktop)}>
        <div style={styles.inputWrapper}>
          <RiSearchLine style={styles.searchIcon} />

          <input
            style={styles.input}
            type="text"
            aria-label="Search Menu"
            value={menuSearchText || ''} // always have to provide a string (or react gives up control)
            placeholder={t('placeholder.menuSearch')}
            onChange={event => {
              const value = event?.target?.value;
              dispatch(setMenuSearchText(value));
              logEvent(EVENTS.CHANGE_MENU_SEARCH_TEXT, { label: value });
            }}
            autoFocus
          />

          {Boolean(menuSearchText) && (
            <TouchableOpacity
              style={styles.clearIconContainer}
              onClick={() => {
                const value = '';
                dispatch(setMenuSearchText(value));
                logEvent(EVENTS.CHANGE_MENU_SEARCH_TEXT, { label: value });
              }}
              ariaLabel="clear search"
            >
              <RiCloseLine />
            </TouchableOpacity>
          )}
        </div>

        <StandardButton
          containerStyle={styles.cancelButton}
          themeKey="cancelMenuSearchButton"
          onClick={cancelMenuSearch}
          label={t('button.menuSearchCancel')}
        />
      </div>

      <Text
        themeKey="menuSearchResultCount"
        value={t('menuSearchResult', { count: itemCount })}
        style={styles.count}
      />
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginLeft: 10,
  },
  top: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  topDesktop: {
    // maxWidth: 1000,
    // TODO: Improve layout in the future
  },
  searchIcon: {
    position: 'absolute',
    left: 10,
    color: 'rgba(0, 0, 0, 0.8)',
  },
  clearIconContainer: {
    position: 'absolute',
    right: 10,
    color: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 20,
  },
  inputWrapper: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    flex: 1,
    borderRadius: 30,
    padding: 8,
    paddingLeft: 30,
    outline: 'none',
    fontSize: 16,

    border: '1px solid rgba(0, 0, 0, 0.2)',
    WebkitAppearance: 'none',
    width: '100%', // without this it won't compress down past a certain size
  },
  count: {
    paddingLeft: 5,
    marginTop: 7,
    marginLeft: 7,
  },

  cancelButton: {
    marginRight: 20,
    marginLeft: 20,
  },
};

export default MenuSearchBar;
