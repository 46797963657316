import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import * as fetchMaxOrderValueRules from '../../actionCreators/flows/fetchMaxOrderValueRules';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';
import Api, { ApiResponse, FetchParams } from '../../utils/Api';
import { $getLocationId } from '../../selectors/getLocationId';
import { setMaxOrderValues } from '../../actionCreators/currentOrder';

export const requested = createFlowApprover(fetchMaxOrderValueRules);

export function* approved(
  action: ReturnType<typeof fetchMaxOrderValueRules.actions.approved>,
) {
  const {
    payload: {},
    meta: { flowId },
  } = action;
  try {
    const locationId = yield select($getLocationId);

    const params: FetchParams = {
      path: `/api/v1/max_order_total/order/${locationId}`,
      method: 'GET',
    };

    const response: ApiResponse = yield call(Api.fetch, params);
    yield put(setMaxOrderValues(response.data));
    yield put(fetchMaxOrderValueRules.actions.succeeded({}, flowId));
  } catch (e) {
    yield put(
      fetchMaxOrderValueRules.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(fetchMaxOrderValueRules.events.REQUESTED, requested),
    takeEvery(fetchMaxOrderValueRules.events.APPROVED, approved),
  ]);
}
