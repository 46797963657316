import lodash from 'lodash';

export default function (offer: Offer, items: Items): Offer {
  // add the ids of any class/category matching items to each offer's targetPLUs
  const newTargetPLUs: Array<string> = [];

  lodash.values(items).forEach(item => {
    if (
      (item.class && offer.targetPLUClasses.includes(item.class)) ||
      (item.category && offer.targetPLUCategories.includes(item.category))
    ) {
      newTargetPLUs.push(item.id);
    }
  });

  return {
    ...offer,
    targetPLUs: lodash.uniq([...offer.targetPLUs, ...newTargetPLUs]),
  };
}
