import { put, takeEvery, all, select } from 'redux-saga/effects';

import * as resetOpenPurchaseChoiceSets from '../../actionCreators/flows/resetOpenPurchaseChoiceSets';

import getItems from '../../selectors/getItems';
import getUnenhancedOpenPurchase from '../../selectors/getUnenhancedOpenPurchase';

import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';
import choiceSelectionsFromBase from '../../utils/ordering/choiceSelectionsFromBase';

export const requested = createFlowApprover(resetOpenPurchaseChoiceSets);

export function* approved(
  action: ReturnType<typeof resetOpenPurchaseChoiceSets.actions.approved>,
) {
  const {
    payload: { targetChoiceSetIds },
    meta: { flowId },
  } = action;

  try {
    const openPurchase: _Purchase = yield select(getUnenhancedOpenPurchase);

    let adjustedPurchase;

    const items = yield select(getItems);
    const item = items[openPurchase.itemId] as Item;

    const defaultChoiceSelections = choiceSelectionsFromBase(item.choiceSets);

    let newChoiceSelections: ChoiceSelections = {
      ...openPurchase.choiceSelections,
    };

    targetChoiceSetIds.forEach(choiceSetId => {
      newChoiceSelections[choiceSetId] = defaultChoiceSelections[choiceSetId];
    });

    adjustedPurchase = {
      ...openPurchase,
      choiceSelections: newChoiceSelections,
    };

    yield put(
      resetOpenPurchaseChoiceSets.actions.succeeded(
        { purchase: adjustedPurchase },
        flowId,
      ),
    );
  } catch (e) {
    yield put(
      resetOpenPurchaseChoiceSets.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(resetOpenPurchaseChoiceSets.events.REQUESTED, requested),
    takeEvery(resetOpenPurchaseChoiceSets.events.APPROVED, approved),
  ]);
}
