import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiPencilFill, RiAddLine, RiAlertFill } from 'react-icons/ri';
import { OrderingSelectors } from 'polygon-ordering';
import { TEXT_PROPERTIES } from '../utils/theme';

import getThemeLookup from '../selectors/getThemeLookup';
import getInvalidOfferPresent from '../selectors/getInvalidOfferPresent';
import { OFFER_MODAL_ID } from '../modals/OfferModal';
import StandardButton from './StandardButton';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { setCurrentModal } from '../thunks/setCurrentModal';
import Text from './Text';

const { getSelectedOffer, getOffers } = OrderingSelectors;

const OrderOffer = () => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const selectedOffer = useAppSelector(getSelectedOffer);
  const offers = useAppSelector(getOffers);
  const invalidOfferPresent = useAppSelector(getInvalidOfferPresent);
  const dispatch = useAppDispatch();

  const offerPresent = Boolean(selectedOffer);

  return (
    <div>
      <StandardButton
        themeKey="orderPropertyButton"
        invisibleContainer
        label={
          offerPresent
            ? selectedOffer?.name.toUpperCase()
            : offers.length
            ? t('button.addOffer.offersPresent')
            : t('button.addOffer.offersAbsent')
        }
        RightIconComponent={offerPresent ? RiPencilFill : undefined}
        LeftIconComponent={invalidOfferPresent ? RiAlertFill : offerPresent ? undefined : RiAddLine}
        iconStyle={p('orderPropertyButtonIcon', ['color'])}
        onClick={() =>
          dispatch(setCurrentModal({ modalId: OFFER_MODAL_ID, preserveReturnModalDetails: true }))
        }
        labelStyle={p('orderOfferButton', TEXT_PROPERTIES)}
      />
      {offerPresent && (
        <div style={styles.messageContainer}>
          <Text themeKey="orderMessage" style={styles.messageText}>
            {selectedOffer!.description}
          </Text>
        </div>
      )}
    </div>
  );
};

export default OrderOffer;

const styles: Styles = {
  messageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  messageText: {
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: '0.7rem',
    fontWeight: 200,
    textTransform: 'capitalize',
  },
};
