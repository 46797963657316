import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderingOperations, OrderingSelectors, OrderingHooks } from 'polygon-ordering';

import getThemeLookup from '../selectors/getThemeLookup';

import combineStyles from '../utils/combineStyles';
import { CONTAINER_PROPERTIES } from '../utils/theme';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import Text from '../components/Text';
import TouchableOpacity from '../components/TouchableOpacity';
import RedcatImage from '../components/RedcatImage';
import calculateStockBalanceData from '../utils/calculateStockBalanceData';
import { viewProductDetailPage } from '../utils/analytics';
import lodash from 'lodash';

const { getStagedPurchases, getStockBalances, getBrands, getRootCategory } = OrderingSelectors;
const { useFormattedCurrency } = OrderingHooks;

const { openPurchase } = OrderingOperations;

interface IProps {
  item: Item;
  brandId?: string;
}
const MenuItem: React.FC<IProps> = ({ item, brandId }) => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const stagedPurchases = useAppSelector(getStagedPurchases);
  const stockBalances = useAppSelector(getStockBalances);
  const hideMenuItemSizeDescription = useAppSelector(
    state => state.config.hideMenuItemSizeDescription,
  );
  const relaxMenuItemHeightConsistency = useAppSelector(
    state => state.config.relaxMenuItemHeightConsistency,
  );
  const brands = useAppSelector(getBrands);
  const rootCategory = useAppSelector(getRootCategory);
  const dispatch = useAppDispatch();

  const categories = rootCategory?.subCategories || [];

  const brand = brands.find(brand => brand.id === brandId)?.name || null;
  const category =
    categories.find(category => lodash.map(category.items, 'id').find(id => id === item.id))
      ?.name || null;

  let stockBalanceData = calculateStockBalanceData(item.id);

  useEffect(() => {
    stockBalanceData = calculateStockBalanceData(item.id);
  }, [stockBalances, stagedPurchases]);

  const mainContainerStyle = combineStyles(
    styles.mainContainer,
    p('menuItem', CONTAINER_PROPERTIES),
    relaxMenuItemHeightConsistency && { marginBottom: 'auto' }, // enable this to not stretch every item to the row height
  );

  const checkIfDisabled = stockBalanceData.soldOut || stockBalanceData.soldOutByCartSelections;

  const itemPrice = useFormattedCurrency(item.baseMoneyPrice);
  return (
    <div
      style={combineStyles(
        styles.opacityBackstop,
        p('menuItemOpacityBackstop', ['backgroundColor']), // prevent menu background colour leaking through when item hovered
      )}
    >
      <TouchableOpacity
        ariaLabel={item.name}
        style={mainContainerStyle}
        onClick={() => {
          viewProductDetailPage(item, null, [], brand, category);
          dispatch(openPurchase({ itemId: item.id, brandId }));
        }}
        hoverStyle={p('menuItemHovered', ['outline'])}
        disabled={checkIfDisabled!}
      >
        <div style={styles.left}>
          <Text
            themeKey="menuItemName"
            value={item.name}
            style={combineStyles(styles.name, Boolean(checkIfDisabled) && styles.menuItemOpacity)}
          />

          {Boolean(item.description) && (
            <Text
              themeKey="menuItemDescription"
              value={item.description}
              style={combineStyles(
                styles.description,
                Boolean(checkIfDisabled) && styles.menuItemOpacity,
              )}
            />
          )}

          {Boolean(item.kilojoules) && (
            <Text
              themeKey="menuItemEnergy"
              style={combineStyles(
                styles.energy,
                Boolean(checkIfDisabled) && styles.menuItemOpacity,
              )}
            >
              {item.kilojoules}
              <Text themeKey="menuItemEnergySuffix" style={styles.energySuffix}>
                kj
              </Text>
            </Text>
          )}

          {Boolean(item.baseMoneyPrice) && (
            <Text
              themeKey="menuItemPrice"
              style={combineStyles(
                styles.price,
                Boolean(checkIfDisabled) && styles.menuItemOpacity,
              )}
            >
              {itemPrice}
              {!item.simpleItem &&
                !hideMenuItemSizeDescription &&
                Boolean(item.sizeDescription) && (
                  <Text
                    themeKey="menuItemSizeDescription"
                    value={` ${item.sizeDescription}`.toUpperCase()}
                  />
                )}
            </Text>
          )}

          {stockBalanceData.stockBalanceThreshold && (
            <Text themeKey={stockBalanceData.stockBalanceThreshold} style={styles.stockBalanceText}>
              {t('stockBalanceThreshold.' + stockBalanceData.stockBalanceThreshold)}
            </Text>
          )}
        </div>

        <RedcatImage
          alt={item.name}
          imagePath={item?.images?.default}
          size={p('menuItemImage', ['height']).height as number}
          containerStyle={combineStyles(
            p('menuItemImage', ['margin']),
            Boolean(checkIfDisabled) && styles.menuItemOpacity,
          )}
        />
      </TouchableOpacity>
    </div>
  );
};

const styles: Styles = {
  opacityBackstop: {
    display: 'flex',
    borderRadius: 2,
  },
  mainContainer: {
    padding: 15,
    border: '1.5px solid rgba(0, 0, 0, 0.1)',
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flex: 1,
  },

  left: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingRight: 20,
  },

  name: {
    marginBottom: 5,
  },
  description: {
    marginBottom: 8,
  },
  energy: {
    marginBottom: 5,
  },
  energySuffix: {
    marginLeft: 1,
  },
  price: {
    marginTop: 10,
  },
  stockBalanceText: {
    marginTop: 18,
  },
  menuItemOpacity: {
    // background: 'rgba(255, 255, 9, 0.3)',
    opacity: 0.5,
  },
};

export default MenuItem;
