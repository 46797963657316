// import { logEventToConsole } from 'polygon-utils';

import { makeErrorSerialisable } from './sagas';

type LogLevel = 'warning' | 'info' | 'error';

interface LogEvent {
  message: string;
  level: LogLevel;
  details?: {};
}

// TODO: work out circular dependency that makes the "logEventToConsole"
// come out as `undefined`
function logEventToConsole(event: LogEvent) {
  const { message, details, level } = event;

  const safeDetails = details || '';

  if (level === 'error') {
    console.error(message, safeDetails);
  } else if (level === 'warning') {
    console.warn(message, safeDetails);
  } else {
    console.info(message, safeDetails);
  }
}
export default class Logger {
  static handler?: any;

  static setHandler(handler: any) {
    Logger.handler = handler;
  }

  static log(message: string, level: LogLevel = 'info', details?: {}) {
    const serialisableDetails = details
      ? makeErrorSerialisable(details as Error)
      : undefined;

    if (Logger.handler) {
      Logger.handler.log(message, level, serialisableDetails);
    } else {
      logEventToConsole({ message, level, details: serialisableDetails });
    }
  }
}
