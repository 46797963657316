import React from 'react';

import { RiCheckboxBlankLine, RiCheckboxLine } from 'react-icons/ri';

import getThemeLookup from '../selectors/getThemeLookup';

import StandardButton from './StandardButton';
import { useAppSelector, useAppDispatch } from '../app/hooks';

interface IProps {
  choiceSet: ValidatedChoiceSet;
  adjustChoice: (params: AdjustChoiceParams) => void;
}
interface AdjustChoiceParams {
  clear: boolean;
  targetChoiceId: string;
  targetChoiceSetId: string;
}

const ChoiceSetCheckbox: React.FC<IProps> = ({ choiceSet, adjustChoice }) => {
  const p = useAppSelector(getThemeLookup);
  const dispatch = useAppDispatch();
  const choice = choiceSet.choices[0];

  if (!choice) {
    return null;
  }

  const checked = Boolean(choice.quantity);

  return (
    <StandardButton
      themeKey="choiceSetCheckbox"
      onClick={() =>
        dispatch(
          adjustChoice({
            targetChoiceSetId: choiceSet.key as string,
            targetChoiceId: choice.id,
            clear: checked,
          }),
        )
      }
      label={choiceSet.name.toUpperCase()}
      rightIconStyle={p('choiceSetCheckboxIcon', ['color', 'fontSize'])}
      RightIconComponent={checked ? RiCheckboxLine : RiCheckboxBlankLine}
    />
  );
};

export default ChoiceSetCheckbox;
