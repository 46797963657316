import { call, put, select } from 'redux-saga/effects';
import lodash from 'lodash';
import cookie from 'cookie';

import { OrderingOperations, OrderingSelectors, requestAndWaitForFlow } from 'polygon-ordering';

import { IN_DEVELOPMENT } from '../constants';

import debug from '../utils/debug';
import getLocalValue from '../utils/getLocalValue';
import { logUser } from '../utils/analytics';

import setCurrentModal from '../actions/setCurrentModal';

import { SIGN_IN_MODAL_ID } from '../modals/SignInModal';
import { MEMBER_MODAL_ID } from '../modals/MemberModal';
import { store } from '../store';
import { fetchOffers } from '../thunks/fetchOffers';
import { fetchProfile } from '../thunks/fetchProfile';
import { fetchLoyaltyTiers } from '../thunks/fetchLoyaltyTiers';

const { fetchMember, removeMember } = OrderingOperations;
const { getMember } = OrderingSelectors;

function checkAuthPresent() {
  if (IN_DEVELOPMENT) {
    return getLocalValue('memberAuthToken') != null;
  } else {
    const parsed = cookie.parse(document.cookie);
    return Boolean(parsed['polygon_t']);
  }
}

function* removeMemberData() {
  debug('removing member');

  logUser(null);

  yield put(removeMember({}));
}

export default function* syncMember() {
  const authPresent = checkAuthPresent();

  debug('syncing member', { authPresent });

  if (!authPresent) {
    yield call(removeMemberData);
    return;
  }

  const showExpiredCoupons = yield select(state => state.config.showExpiredCoupons);
  const showRedeemedCoupons = yield select(state => state.config.showRedeemedCoupons);

  store.dispatch(fetchOffers({ showExpiredCoupons, showRedeemedCoupons }));
  store.dispatch(fetchProfile());
  store.dispatch(fetchLoyaltyTiers());

  // if no cookie or auth token and there is member data, clear the member data and exit

  const previousMemberData = yield select(getMember);

  const result = yield call(requestAndWaitForFlow, fetchMember, { authenticationMethod: 'member' });

  const newMemberData = yield select(getMember);
  const signedIn = !previousMemberData && newMemberData;
  const changedUser =
    previousMemberData && newMemberData && previousMemberData.id !== newMemberData.id;

  if (signedIn || changedUser) {
    logUser(newMemberData.memberNumber, newMemberData);
  }

  const currentModalId = yield select(state => state.currentModalId);

  if (result.succeeded) {
    if (currentModalId === SIGN_IN_MODAL_ID) {
      yield put(setCurrentModal(null));
    }
  } else {
    debug(false, 'member sync failed', { result });

    // TODO: check logic to ensure we only remove the member if we get a 403
    if (
      result.failure?.error?.details?.type !== 'connectivity' ||
      (IN_DEVELOPMENT && !getLocalValue('memberAuthToken'))
    ) {
      if (newMemberData) {
        if (currentModalId === MEMBER_MODAL_ID) {
          yield put(setCurrentModal(null));
        }

        yield call(removeMemberData);
      }
    }
  }
}
