import { createSelector } from 'reselect';

import createCartItemFromPurchase from '../utils/ordering/createCartItemFromPurchase';

import getStagedPurchases from './getStagedPurchases';
import getRootCategory from './getRootCategory';
import getBoostIngredients from './getBoostIngredients';
import { getFreeChoiceOrderingMethod, getBrands } from './config';

export default createSelector(
  [
    getStagedPurchases,
    getFreeChoiceOrderingMethod,
    getBrands,
    getRootCategory,
    getBoostIngredients,
  ],
  (
    enhancedStagedPurchases,
    freeChoiceOrderingMethod,
    brands,
    rootCategory,
    boostIngredients,
  ) => {
    if (freeChoiceOrderingMethod === null) {
      return [];
    }

    return (enhancedStagedPurchases || []).map(p =>
      createCartItemFromPurchase(
        p,
        freeChoiceOrderingMethod,
        brands,
        rootCategory,
        boostIngredients,
      ),
    );
  },
);
