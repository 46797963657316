import { call } from 'redux-saga/effects';
import lodash from 'lodash';

import defaultMenuProvider from './defaultMenuProvider';

// import Logger from '../utils/Logger';
import { blankOrString } from '../utils/sagas';
import augmentItems from '../utils/boost/augmentItems';
import { BOOST_FAMILY_DETAILS } from '../constants/boost';
import Api, { FetchParams, ApiResponse } from '../utils/Api';
import processBoostAppMenu from '../utils/boost/processBoostAppMenu';
import createSharedChoiceSets from '../utils/boost/createSharedChoiceSets';
import createIngredientFamilies from '../utils/boost/createIngredientFamilies';
import processChoicesFromMasterItem from '../utils/boost/processChoicesFromMasterItem';

function* fetchMasterChoices(parameters: MenuProviderParams) {
  const { locationId, saleType, orderingProvider } = parameters;

  const path = [
    '/api/v1/stores/',

    locationId,

    '/menu/',

    2202,

    '?load_details=1',

    `&sale_type=${saleType}`,

    blankOrString(
      `&ordering_provider=${orderingProvider}`,
      orderingProvider !== null,
    ),
  ].join('');

  const requestParams: FetchParams = {
    path,
    method: 'GET',
  };

  const response: ApiResponse = yield call(Api.fetch, requestParams);
  const rawItem: RawSize = response.data;

  return processChoicesFromMasterItem(rawItem);
}

export function runningInElectron() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf(' electron/') > -1;
}

export default function* (parameters: MenuProviderParams) {
  const { locationId, saleType, orderingProvider, menuSource } = parameters;

  let [rootMenuNode, baseItems, baseChoiceSets, baseUpsells]: [
    MenuNode,
    _Items,
    ChoiceSets,
    string[],
  ] = yield call(defaultMenuProvider, {
    locationId,
    saleType,
    orderingProvider,
    loadDetails: true,
    defaultSelectedOnly: true,
    nonEmptyChoiceSetsOnly: true,
    hideUnavailable: true,
  });

  const masterChoices: SDict<Choice> = yield call(fetchMasterChoices, {
    locationId,
    saleType,
    orderingProvider,
  });

  const TEST_ENDPOINT = 'http://a900785e.ngrok.io//api/public/product/set/707';
  const PROD_ENDPOINT = `https://product-editor.herokuapp.com/api/public/product/set/${locationId}`;

  const productEditorEndpoint =
    menuSource === 'boost-test' ? TEST_ENDPOINT : PROD_ENDPOINT;

  // TODO: remove absolute hack to work around boost's CORS restrictions
  // proxy is only used in browser, not on the actual kiosk
  // if the proxy goes offline this will stop working
  // the proxy could also modify the data, but that shouldn't be a problem
  // as the server validates carts and this is only running in test and browser while I'm away

  // read more: https://cors-anywhere.herokuapp.com/
  const response: Response = yield call(
    fetch,
    runningInElectron()
      ? productEditorEndpoint
      : `https://cors-anywhere.herokuapp.com/${productEditorEndpoint}`,
  );

  const rawBoostAppMenu: string = yield call([response, 'json']);

  const [allBoostIngredients] = processBoostAppMenu(
    rawBoostAppMenu,
    masterChoices,
  );

  // Logger.log('boost ingredients', 'info', { allBoostIngredients });

  const ingredientFamilies = createIngredientFamilies(
    allBoostIngredients,
    BOOST_FAMILY_DETAILS,
  );

  // Logger.log('boost ingredientFamilies', 'info', { ingredientFamilies });

  const sharedChoiceSets = createSharedChoiceSets(ingredientFamilies);

  const [augmentedItems, swapChoiceSets] = augmentItems(
    lodash.omitBy(baseItems, (_, itemId) => baseUpsells.includes(itemId)),
    baseChoiceSets,
    ingredientFamilies,
    lodash.keys(sharedChoiceSets),
  );

  return [
    rootMenuNode,
    { ...baseItems, ...augmentedItems },
    {
      ...sharedChoiceSets,
      ...swapChoiceSets,
    },
    baseUpsells,
    allBoostIngredients,
  ];
}
