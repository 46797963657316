import { ActionType, getType } from 'typesafe-actions';

import { actions as stageOpenPurchase } from '../../actionCreators/flows/stageOpenPurchase';
import { actions as stagePurchaseFromItem } from '../../actionCreators/flows/stagePurchaseFromItem';

import * as currentOrder from '../../actionCreators/currentOrder';

import { replaceOrAppend } from '../../utils/misc';
import sameOrIdenticalPurchase from '../../utils/ordering/sameOrIdenticalPurchase';

function addPurchase(state: _Purchase[], purchase: _Purchase) {
  return replaceOrAppend(
    state,
    purchase,
    el => sameOrIdenticalPurchase(el, purchase),
    prev => {
      if (prev.length === 1 && prev[0].id === purchase.id) {
        // same id, we are replacing with newer version
        return purchase;
      }

      let target: _Purchase;

      if (prev.length === 1) {
        target = prev[0];
      } else {
        target = prev.find(p => p.id !== purchase.id) as _Purchase;
      }

      return {
        ...target,
        quantity: target.quantity + purchase.quantity,
      };
    },
  );
}

const initialState: _Purchase[] = [];
export default function (
  state: _Purchase[] = initialState,
  action: ActionType<
    | typeof currentOrder
    | typeof stageOpenPurchase
    | typeof stagePurchaseFromItem
  >,
): _Purchase[] {
  switch (action.type) {
    case getType(currentOrder.resetOrder): {
      return initialState;
    }
    case getType(currentOrder.setStagedPurchases): {
      const { value } = action.payload;
      return value || initialState;
    }
    case getType(currentOrder.applyBufferData): {
      const { stagedPurchases } = action.payload.value;
      return stagedPurchases === undefined
        ? state
        : stagedPurchases || initialState;
    }
    case getType(currentOrder.addStagedPurchases): {
      const { value = initialState } = action.payload;
      let newState = [...state];

      value.forEach(purchase => {
        newState = addPurchase(newState, purchase);
      });

      return newState;
    }
    case getType(stagePurchaseFromItem.succeeded):
    case getType(stageOpenPurchase.succeeded): {
      const { purchase } = action.payload;

      return addPurchase(state, purchase);
    }
    case getType(currentOrder.adjustStagedPurchaseQuantity): {
      const { purchaseId, adjustment = 0, newQuantity } = action.payload;

      return replaceOrAppend(
        state,
        undefined,
        el => el.id === purchaseId,
        prev => ({
          ...prev[0],
          quantity:
            newQuantity != null ? newQuantity : prev[0].quantity + adjustment,
        }),
      );
    }
    case getType(currentOrder.removeStagedPurchase): {
      const { value } = action.payload;

      return state.filter(purchase => purchase.id !== value);
    }
    default:
      return state;
  }
}
