import lodash from 'lodash';

export default function tallyAndValidateChoiceSets(
  choiceSets: ChoiceSetWithQuantity[],
  choiceSetDiscountConfig?: ChoiceSetQuantityDiscountConfig,
): ValidatedChoiceSet[] {
  let discount: string;
  if (choiceSetDiscountConfig) {
    discount = choiceSetDiscountConfig.discountPlu;
  }

  return choiceSets.map(choiceSet => {
    function removeDiscountPLU(choice: any) {
      return choice.id != discount;
    }

    const arrayWithoutDiscountPLU = choiceSet.choices.filter(removeDiscountPLU);

    const quantity = lodash.sumBy(arrayWithoutDiscountPLU, 'quantity');

    let valid = true;

    if (choiceSet.max && quantity > choiceSet.max) {
      valid = false;
    }

    if (choiceSet.min && quantity < choiceSet.min) {
      valid = false;
    }

    return {
      ...choiceSet,
      quantity,
      valid,
    };
  });
}
