import { createCustomAction } from 'typesafe-actions';

import { standardEmptyACC, standardFlowACC } from '../../utils/sagas';

import * as fetchDeliveryEstimate from './fetchDeliveryEstimate';
import { SALE_TYPE } from '../../constants/saleType';

const REQUESTED = '$UPDATE_KEY_ORDER_PROPERTY/REQUESTED';
const BLOCKED = '$UPDATE_KEY_ORDER_PROPERTY/BLOCKED';
const APPROVED = '$UPDATE_KEY_ORDER_PROPERTY/APPROVED';
const SUCCEEDED = '$UPDATE_KEY_ORDER_PROPERTY/SUCCEEDED';
const FAILED = '$UPDATE_KEY_ORDER_PROPERTY/FAILED';

const request = <T>(type: T) => {
  return standardFlowACC<
    T,
    {
      locationId?: string;
      saleType?: SALE_TYPE;
      isSubFlow?: boolean;
      autoApply?: boolean;
      desiredDeliveryTime?: string;
      deliveryAddress?: string;
      updateDeliveryEstimate?: boolean;
      confirmLocationDeliveryEstimate?: boolean;
      forceASAPDeliveryEstimate: boolean;
    }
  >(type);
};

export type FailureReason = undefined | fetchDeliveryEstimate.FailureReason;

const failure = <T>(type: T) => {
  return standardFlowACC<
    T,
    {
      error?: SerialisableException;
      reason?: FailureReason;
      deliveryAddress?: string;
      userReason?: string;
      matchedAddressString?: string;
    }
  >(type);
};

export const events = {
  REQUESTED,
  BLOCKED,
  APPROVED,
  SUCCEEDED,
  FAILED,
};

export const actions = {
  requested: createCustomAction(REQUESTED, request),
  approved: createCustomAction(APPROVED, request),
  blocked: createCustomAction(BLOCKED, standardEmptyACC),
  succeeded: createCustomAction(SUCCEEDED, standardEmptyACC),
  failed: createCustomAction(FAILED, failure),
};

export default actions.requested;
