import { createSelector } from 'reselect';

import getCart from './getCart';
import getLocationId from './getLocationId';
import getPurchaser from './getPurchaser';
import getMember from './getMember';
import getOrderTotals from './getOrderTotals';
import getNotes from './getNotes';
import getSelectedOffer from './getSelectedOffer';
import getPaymentDetails from './getPaymentDetails';
import { getConfig } from './config';
import getSurcharges from './getSurcharges';

import getSaleTypeSaleBodyContent from './getSaleTypeSaleBodyContent';
import getOrderUUID from './getOrderUUID';

export default createSelector(
  [
    getCart,
    getLocationId,
    getPurchaser,
    getMember,
    getOrderTotals,
    getNotes,
    getSelectedOffer,
    getPaymentDetails,
    getConfig,
    getSurcharges,
    getSaleTypeSaleBodyContent,
    getOrderUUID,
  ],
  (
    cart,
    locationId,
    purchaser,
    member,
    orderTotals,
    notes,
    selectedOffer,
    paymentDetails,
    config,
    surcharges,
    saleTypeSaleBodyContent,
    uuid,
  ) => ({
    StoreID: locationId && parseInt(locationId),

    OrderingProvider: config.orderingProvider,
    InputType: config.saleInputType || undefined,

    UUID: config.enableDuplicateSaleProtection ? uuid : undefined,

    MemberNo: member && member.memberNumber,
    Details: {
      Email: purchaser.effective.email || undefined,
      GivenNames: purchaser.effective.name || '',
      Surname: purchaser.effective.familyName || '',
      Phone: purchaser.effective.mobile || undefined,
    },

    Cart: cart,

    OrderNotes: notes,
    OrderTotal: orderTotals && orderTotals.discountedMoneyPrice,

    Coupons:
      selectedOffer && orderTotals
        ? [
            {
              Discount: orderTotals.moneyDiscount * -1,
              Hash: selectedOffer.hash,
            },
          ]
        : undefined,

    Surcharges: (surcharges || []).map(({ type, description, amount }) => ({
      Type: type,
      Name: description,
      Amount: amount,
    })),

    Payments: paymentDetails,

    ...saleTypeSaleBodyContent,
  }),
);
