/* eslint-disable require-yield */

import i18next from 'i18next';
import moment from 'moment';
import lodash from 'lodash';

import {
  OrderingLifecycleHooks,
  OrderingLifecycleEvents,
  OrderingConstants,
  OrderingOperations,
  OrderingPaymentHooks,
  OrderingSelectors,
} from 'polygon-ordering';

import EVENTS from '../constants/events';

import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from '../utils/snackbar';
import { logEvent } from '../utils/analytics';

import { setKeyOrderPropertyUpdateInProgress } from '../slices/keyOrderPropertyUpdateInProgress';
import { setOrderDate } from '../slices/orderDate';

import setCurrentModal from '../actions/setCurrentModal';
import syncMember from '../actions/syncMember';

import { PURCHASE_EDITOR_MODAL_ID } from '../modals/PurchaseEditorModal';
import { STAGED_PURCHASE_EDITOR_MODAL_ID } from '../modals/StagedPurchaseEditorModal';
import { COMPLETED_ORDER_SCREEN_ROUTE } from '../screens/CompletedOrderScreen';

import { store } from '../store';
import history from '../history';

const {
  openPurchase,
  openStagedPurchase,
  stageOpenPurchase,
  updateKeyOrderProperty,
  applyPromoCode,
  selectOffer,
  finaliseOrder,
  updateMenu,
  fetchGiftCard,
  adjustOpenPurchaseChoice,
  changeOpenPurchaseItem,
  createFavouriteOrder,
  deleteFavouriteOrder,
  editFavouriteOrder,
  removePaymentGatewayToken,
  startNewOrder,
  validateOrder,
} = OrderingLifecycleEvents;

// non-flow operations
const {
  setTable,
  setPurchaserEmail,
  setPurchaserFamilyName,
  setPurchaserMobile,
  setPurchaserName,
  setNotes,
  setDeliveryNotes,
  setPickupTime,
  removeStagedPurchase,
  adjustStagedPurchaseQuantity,
  setRememberCreditCard,
  adjustOpenPurchaseQuantity,
  setOpenPurchaseLowestBalance,
} = OrderingOperations;

const { getOpenPurchase } = OrderingSelectors;

const { FAILURE_REASON, PAYMENT_METHODS, PRE_FINALISE_PAYMENT_REQUEST } = OrderingConstants;

const { subscribe } = OrderingLifecycleHooks;

export default function* () {
  subscribe(openPurchase.SUCCEEDED, () => {
    store.dispatch(setCurrentModal({ modalId: PURCHASE_EDITOR_MODAL_ID }));
    logEvent(EVENTS.OPEN_ITEM);
  });

  subscribe(openStagedPurchase.SUCCEEDED, () => {
    store.dispatch(
      setCurrentModal({
        modalId: STAGED_PURCHASE_EDITOR_MODAL_ID,
        preserveReturnModalDetails: true,
        // params: {},
      }),
    );
    logEvent(EVENTS.OPEN_EXISTING_PURCHASE);
  });

  subscribe(updateKeyOrderProperty.REQUESTED, () => {
    store.dispatch(setKeyOrderPropertyUpdateInProgress(true));
  });

  subscribe(updateKeyOrderProperty.SUCCEEDED, () => {
    store.dispatch(setKeyOrderPropertyUpdateInProgress(false));
    logEvent(EVENTS.UPDATE_KEY_ORDER_PROPERTY);
  });

  const updateKeyOrderPropertyFailed = ({ payload: { reason } }) => {
    if (![FAILURE_REASON.DELIVERY_UNAVAILABLE, FAILURE_REASON.ADDRESS_NOT_FOUND].includes(reason)) {
      enqueueErrorSnackbar(i18next.t('problemUpdatingKeyOrderProperty'));
    }
    store.dispatch(setKeyOrderPropertyUpdateInProgress(false));

    if (reason === FAILURE_REASON.DELIVERY_UNAVAILABLE) {
      logEvent(EVENTS.DELIVERY_UNAVAILABLE);
    } else if (reason === FAILURE_REASON.ADDRESS_NOT_FOUND) {
      logEvent(EVENTS.DELIVERY_ADDRESS_NOT_FOUND);
    } else {
      logEvent(EVENTS.UPDATE_KEY_ORDER_PROPERTY_FAILED, { label: reason });
    }
  };

  subscribe(updateKeyOrderProperty.FAILED, updateKeyOrderPropertyFailed);

  subscribe(updateKeyOrderProperty.BLOCKED, updateKeyOrderPropertyFailed);

  subscribe(stageOpenPurchase.SUCCEEDED, ({ payload: { previouslyStaged } }) => {
    store.dispatch(setCurrentModal(null));

    enqueueSuccessSnackbar(
      previouslyStaged ? i18next.t('purchaseStaged.modified') : i18next.t('purchaseStaged.new'),
    );

    logEvent(previouslyStaged ? EVENTS.SAVE_PURCHASE_CHANGES : EVENTS.SAVE_NEW_PURCHASE);
  });

  subscribe(applyPromoCode.FAILED, ({ payload: { reason, error } }) => {
    if (reason === FAILURE_REASON.FETCH_FAILED) {
      enqueueErrorSnackbar(i18next.t('applyPromoCode.notFound'));
      logEvent(EVENTS.PROMOCODE_NOT_FOUND);
    } else {
      enqueueErrorSnackbar(i18next.t('applyPromoCode.failure'));
      logEvent(EVENTS.APPLY_PROMOCODE_FAILED, { label: reason });
    }
  });

  subscribe(applyPromoCode.SUCCEEDED, ({ payload: { purchaseStaged } }) => {
    if (purchaseStaged) {
      enqueueSuccessSnackbar(i18next.t('applyPromoCode.itemAdded'));
    } else {
      enqueueSuccessSnackbar(i18next.t('applyPromoCode.success'));
    }

    logEvent(EVENTS.APPLY_PROMOCODE, { label: purchaseStaged ? 'PURCHASE_STAGED' : null });
  });

  subscribe(selectOffer.SUCCEEDED, ({ payload: { purchaseStaged, offerId } }) => {
    let value;

    try {
      if (offerId) {
        value = parseInt(offerId);
      }
    } catch (e) {
      // nothing
    }

    logEvent(offerId ? EVENTS.SELECT_OFFER : EVENTS.REMOVE_OFFER, {
      label: purchaseStaged ? 'PURCHASE_STAGED' : null,
      value,
    });
  });

  subscribe(selectOffer.FAILED, ({ payload: { reason } }) => {
    let message;

    if (reason === FAILURE_REASON.OFFER_NOT_PRESENT_AND_AVAILABLE) {
      message = i18next.t('selectOfferFailed.unavailable');
    } else if (reason === FAILURE_REASON.COULD_NOT_STAGE_PURCHASE) {
      message = i18next.t('selectOfferFailed.item');
    } else {
      message = i18next.t('selectOfferFailed.default');
    }

    enqueueErrorSnackbar(message);

    logEvent(EVENTS.SELECT_OFFER_FAILED, { label: reason });
  });

  subscribe(finaliseOrder.SUCCEEDED, ({ payload: { subPayments } }) => {
    if (
      lodash.find(subPayments, ['method', PAYMENT_METHODS.APPLE_PAY]) ||
      lodash.find(subPayments, ['method', PAYMENT_METHODS.GOOGLE_PAY])
    ) {
      const hook = OrderingPaymentHooks.get(PRE_FINALISE_PAYMENT_REQUEST);

      if (!hook) {
        throw new Error('payment hook not set');
      }

      hook({ statusMsg: 'success' });
    }

    history.replace(COMPLETED_ORDER_SCREEN_ROUTE);
    store.dispatch(syncMember());
    logEvent(EVENTS.CHECKOUT);
  });

  subscribe(finaliseOrder.FAILED, ({ payload: { reason, charged, error, subPayments } }) => {
    if (
      lodash.find(subPayments, ['method', PAYMENT_METHODS.APPLE_PAY]) ||
      lodash.find(subPayments, ['method', PAYMENT_METHODS.GOOGLE_PAY])
    ) {
      const hook = OrderingPaymentHooks.get(PRE_FINALISE_PAYMENT_REQUEST);

      if (!hook) {
        throw new Error('payment hook not set');
      }

      hook({ statusMsg: 'fail' });
    }

    let chargedMessage;

    console.error('finaliseOrder FAILED', error);

    if (charged == null) {
      chargedMessage = i18next.t('chargedMessage.maybe');
    } else if (charged) {
      chargedMessage = i18next.t('chargedMessage.yes');
    } else {
      chargedMessage = i18next.t('chargedMessage.no');
    }

    if (reason === FAILURE_REASON.BAD_GIFT_CARD) {
      enqueueErrorSnackbar(
        i18next.t('problemFinalisingOrderMessage') + '\n' + i18next.t('badGiftCardMessage'),
      );
    } else if (reason === FAILURE_REASON.PAYMENT_CANCELLED) {
      // do nothing
    } else if (reason === FAILURE_REASON.PAYMENT_FAILED) {
      enqueueErrorSnackbar(
        i18next.t('problemFinalisingOrderMessage') + '\n' + i18next.t('paymentFailedMessage'),
      );
    } else {
      let lines = i18next.t('problemFinalisingOrderMessage');

      if (error?.message) {
        lines += '\n' + error?.message;
      }

      lines += '\n' + chargedMessage;
      enqueueErrorSnackbar(lines, { persist: true });
    }

    logEvent(EVENTS.CHECKOUT_FAILED, {
      label: `CHARGED: ${charged ? 'Y' : charged == null ? 'M' : 'N'} - REASON: ${reason}`,
    });
  });

  subscribe(updateMenu.SUCCEEDED, () => {
    const saved = store.getState().orderDate;
    const current = moment().dayOfYear();

    // eslint-disable-next-line eqeqeq
    if (saved != current) {
      store.dispatch(setOrderDate(current));
    }

    logEvent(EVENTS.UPDATE_MENU);
  });

  subscribe(fetchGiftCard.FAILED, ({ payload: { imported } }) => {
    enqueueErrorSnackbar(i18next.t('problemFetchingGiftCardMessage'));
    logEvent(EVENTS.FETCH_GIFT_CARD_FAILED);
  });

  subscribe(fetchGiftCard.SUCCEEDED, () => {
    enqueueSuccessSnackbar(i18next.t('successFetchingGiftCardMessage'));
    logEvent(EVENTS.FETCH_GIFT_CARD);
  });

  subscribe(fetchGiftCard.FAILED, ({ payload: { imported } }) => {
    enqueueErrorSnackbar(i18next.t('problemFetchingGiftCardMessage'));
    logEvent(EVENTS.FETCH_GIFT_CARD_FAILED);
  });

  subscribe(startNewOrder.APPROVED, () => {
    store.dispatch(setOrderDate(null));
  });

  subscribe(startNewOrder.SUCCEEDED, ({ payload: { lifecycleEventParams } }) => {
    logEvent(EVENTS.START_NEW_ORDER);

    if (lifecycleEventParams?.shouldReload) {
      setTimeout(() => window.location.reload(), 0);
    }
  });

  const additionalSimpleEventLogging = {
    [changeOpenPurchaseItem.SUCCEEDED]: EVENTS.CHANGE_OPEN_PURCHASE_ITEM,
    [createFavouriteOrder.SUCCEEDED]: EVENTS.CREATE_FAVOURITE,
    [deleteFavouriteOrder.SUCCEEDED]: EVENTS.DELETE_FAVOURITE,
    [editFavouriteOrder.SUCCEEDED]: EVENTS.EDIT_FAVOURITE,
    [removePaymentGatewayToken.SUCCEEDED]: EVENTS.REMOVE_SAVED_CARD,
    [validateOrder.REQUESTED]: EVENTS.VALIDATE_ORDER,
    [adjustOpenPurchaseQuantity.getType()]: EVENTS.CHANGE_OPEN_PURCHASE_QUANTITY,
    [setTable.getType()]: EVENTS.SET_TABLE_NUMBER,
    [setNotes.getType()]: EVENTS.CHANGE_ORDER_NOTES,
    [setDeliveryNotes.getType()]: EVENTS.CHANGE_DRIVER_NOTES,
    [removeStagedPurchase.getType()]: EVENTS.REMOVE_PURCHASE,
    [adjustStagedPurchaseQuantity.getType()]: EVENTS.CHANGE_PURCHASE_QUANTITY,

    // TODO: track value
    [setRememberCreditCard.getType()]: EVENTS.CHANGE_REMEMBER_CARD_OPTION,
    [setPickupTime.getType()]: EVENTS.CHANGE_PICKUP_TIME,

    // TODO: debounce these
    [setPurchaserEmail.getType()]: EVENTS.CHANGE_PURCHASER_DETAILS,
    [setPurchaserFamilyName.getType()]: EVENTS.CHANGE_PURCHASER_DETAILS,
    [setPurchaserMobile.getType()]: EVENTS.CHANGE_PURCHASER_DETAILS,
    [setPurchaserName.getType()]: EVENTS.CHANGE_PURCHASER_DETAILS,
  };

  lodash.toPairs(additionalSimpleEventLogging).forEach(([hook, eventType]) => {
    subscribe(hook, () => {
      logEvent(eventType);
    });
  });
}
