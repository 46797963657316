import React from 'react';
import { OrderingSelectors } from 'polygon-ordering';

import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';

import PaymentMethodCreditCard from './PaymentMethodCreditCard';
import PaymentMethodMemberMoney from './PaymentMethodMemberMoney';
import PaymentMethodMemberPoints from './PaymentMethodMemberPoints';
import PaymentMethodMemberRewards from './PaymentMethodMemberRewards';
import PaymentMethodSavedCard from './PaymentMethodSavedCard';
import PaymentMethodGiftCard from './PaymentMethodGiftCard';
import PaymentMethodPayLater from './PaymentMethodPayLater';
import PaymentMethodApplePay from './PaymentMethodApplePay';
import PaymentMethodGooglePay from './PaymentMethodGooglePay';
import { useAppSelector } from '../app/hooks';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import CheckoutStripeProvider from './CheckoutStripeProvider';

const {
  getAvailablePaymentMethods,
  getEnabledPaymentMethods,
  getPaymentGatewayTokens,
  getMember,
  getSaleType,
} = OrderingSelectors;

// TODO: use this to put the unavailable methods at the bottom?
// https://stackoverflow.com/a/55996695/8706204

const PaymentMethods: React.FC = () => {
  const p = useAppSelector(getThemeLookup);
  const availablePaymentMethods = useAppSelector(getAvailablePaymentMethods);
  const enabledPaymentMethods = useAppSelector(getEnabledPaymentMethods);
  const member = useAppSelector(getMember);
  const savedCards: PaymentGateWayToken[] = useAppSelector(getPaymentGatewayTokens);
  const saleType = useAppSelector(getSaleType);
  const isDineIn = saleType === SALE_TYPE.TABLE_ORDER;

  return (
    <div style={styles.mainContainer}>
      {!isDineIn && (
        <>
          <PaymentMethodMemberPoints />

          <PaymentMethodMemberRewards />

          {(member && enabledPaymentMethods.includes(PAYMENT_METHOD.MEMBER_POINTS)) ||
          (member && enabledPaymentMethods.includes(PAYMENT_METHOD.MEMBER_REWARDS)) ? (
            <>
              <div
                style={combineStyles(
                  styles.horizontalSeperator,
                  p('orderTotalsSeperator', ['backgroundColor']),
                )}
              />
            </>
          ) : undefined}

          <PaymentMethodGiftCard />

          <PaymentMethodMemberMoney />

          <div
            style={combineStyles(
              styles.horizontalSeperator,
              p('orderTotalsSeperator', ['backgroundColor']),
            )}
          />
          <CheckoutStripeProvider>
            {savedCards.map(savedCard => (
              <PaymentMethodSavedCard key={savedCard.token} savedCard={savedCard} />
            ))}
          </CheckoutStripeProvider>

          <PaymentMethodCreditCard />

          <PaymentMethodApplePay />
          <PaymentMethodGooglePay />
        </>
      )}

      {availablePaymentMethods.includes(PAYMENT_METHOD.PAY_LATER) ? (
        <>
          <div
            style={combineStyles(
              styles.horizontalSeperator,
              p('orderTotalsSeperator', ['backgroundColor']),
            )}
          />
        </>
      ) : undefined}

      <PaymentMethodPayLater />
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    paddingTop: 15,
  },
  horizontalSeperator: {
    height: 1,
    width: '100%',
    marginTop: 10,
    marginBottom: 20,
  },
};

export default PaymentMethods;
