import { createAsyncThunk } from '@reduxjs/toolkit';
import { RedcatApiHandler } from 'polygon-utils';
import debug from '../utils/debug';
import { storeOffers } from '../slices/offers';
interface OffersData {
  showRedeemedCoupons: boolean;
  showExpiredCoupons: boolean;
}

export const fetchOffers = createAsyncThunk(
  'fetchOffers',
  async ({ showExpiredCoupons = false, showRedeemedCoupons = false }: OffersData, { dispatch }) => {
    const path = `/api/v1/profile/coupons?hideredeemed=${
      showRedeemedCoupons ? '0' : '1'
    }&hideexpired=${showExpiredCoupons ? '0' : '1'}`;

    debug('FETCHING OFFERS');

    return RedcatApiHandler.authorisedFetch({ method: 'GET', path }).then(response => {
      dispatch(storeOffers(response.data));
    });
  },
);
