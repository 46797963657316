import { ActionType, getType } from 'typesafe-actions';

import { actions as removePaymentGatewayToken } from '../actionCreators/flows/removePaymentGatewayToken';

import * as currentOrderActions from '../actionCreators/currentOrder';
import * as memberActions from '../actionCreators/member';

const initialState: PaymentGateWayToken[] = [];

export default function (
  state: PaymentGateWayToken[] = initialState,
  action: ActionType<
    | typeof currentOrderActions
    | typeof memberActions
    | typeof removePaymentGatewayToken
  >,
): PaymentGateWayToken[] {
  switch (action.type) {
    case getType(currentOrderActions.resetOrder): {
      const { preserveMember } = action.payload;
      return preserveMember ? state : initialState;
    }
    case getType(memberActions.setPaymentGatewayTokens): {
      const { value } = action.payload;
      return value || initialState;
    }
    case getType(removePaymentGatewayToken.approved): {
      const { token } = action.payload;

      // remove the local copy of the token immediately
      // all tokens will be replaced shortly after when the
      // server response returns
      return state.filter(pgToken => pgToken.token !== token);
    }
    default:
      return state;
  }
}
