import choicesFromIngredientFamilies from './choicesFromIngredientFamilies';
export default function createSharedChoiceSet(
  name: string,
  ingredientFamilies: BoostIngredientFamilies,
  familyFilter: (family: BoostIngredientFamily) => boolean,
  displayType: string = 'grid',
) {
  const key = name.toLowerCase();

  return {
    id: `${key}-shared`,
    key,
    name,
    free: 0,
    max: 3,
    individualMax: 1,
    min: 0,
    displayType,
    choices: choicesFromIngredientFamilies(
      ingredientFamilies,
      familyFilter,
      'with',
    ),
  };
}
