import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiCheckboxBlankLine, RiCheckboxLine } from 'react-icons/ri';

import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';

import getThemeLookup from '../selectors/getThemeLookup';
import combineStyles from '../utils/combineStyles';

import Text from './Text';
import TouchableOpacity from './TouchableOpacity';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import { useAppSelector, useAppDispatch } from '../app/hooks';

const { getRememberCreditCard, getMember, getEnabledPaymentMethods } = OrderingSelectors;
const { setRememberCreditCard } = OrderingOperations;

const SaveCardCheckbox: React.FC = () => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const rememberCreditCard = useAppSelector(getRememberCreditCard);
  const member = useAppSelector(getMember);
  const enabledPaymentMethods = useAppSelector(getEnabledPaymentMethods);
  const dispatch = useAppDispatch();

  if (!member || !enabledPaymentMethods.includes(PAYMENT_METHOD.SAVED_CARD)) {
    return null;
  }

  const IconComponent = rememberCreditCard && member ? RiCheckboxLine : RiCheckboxBlankLine;

  return (
    <TouchableOpacity
      onClick={() => dispatch(setRememberCreditCard(!rememberCreditCard))}
      style={styles.saveCardContainer}
      disabled={!member}
      disabledStyle={styles.disabled}
      ariaLabel={rememberCreditCard ? 'do not remember card' : 'remember card'}
    >
      <IconComponent
        style={combineStyles(styles.saveCardIcon, p('saveCardIcon', ['color', 'fontSize']))}
      />
      <Text themeKey="saveCardLabel">{t('saveCardLabel')}</Text>
    </TouchableOpacity>
  );
};

const styles: Styles = {
  saveCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  saveCardIcon: {
    marginRight: 5,
  },
  disabled: {
    opacity: 0.5,
  },
};

export default SaveCardCheckbox;
