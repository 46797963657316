import { call, put } from 'redux-saga/effects';
import i18next from 'i18next'; // https://www.i18next.com/translation-function
import lodash from 'lodash';

import { initialiseI18next } from '../utils/i18next';
import debug from '../utils/debug';

import { setStringOverrides } from '../slices/config/stringOverrides';

export default function* applyStringConfiguration(
  overrides,
  language = 'en',
  namespace = 'translation',
) {
  try {
    debug('applyStringConfiguration', { overrides });

    if (!i18next.isInitialized) {
      debug('initialising i18next', { language });
      yield call(initialiseI18next, language);
    }

    if (i18next.language !== language) {
      debug('changing i18next language', { language });
      yield call(i18next.changeLanguage, language);
    }

    if (overrides) {
      // const previous = yield select(state => state.config?.stringOverrides);

      // if (lodash.isEqual(previous, overrides)) {
      //   return;
      // }

      debug('applying new i18next overrides', { overrides, language });

      yield call(
        i18next.addResourceBundle,
        language,
        namespace,
        lodash.cloneDeep(overrides), // stops an error when string overrides are structured incorrectly
        true,
        true,
      );
    }

    // NOTE: save overrides so we can avoid applying the same ones every sync,
    // and so we can apply the saved overrides immediately on page load
    yield put(setStringOverrides(overrides));
  } catch (e) {
    debug(false, 'problem applying string configuration', { e });
  }
}
