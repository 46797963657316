import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import StandardButton from '../components/StandardButton';
import { RedcatApiHandler } from 'polygon-utils';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../utils/snackbar';
import { RiRefreshLine } from 'react-icons/ri';
import getThemeLookup from '../selectors/getThemeLookup';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import combineStyles from '../utils/combineStyles';
import ScreenHero from '../components/ScreenHero';
import ScreenFloating from '../components/ScreenFloating';
import Text from '../components/Text';
import history from '../history';

// TODO test if this works after deploy
export const VERIFY_ACCOUNT_ROUTE = '/verify';

type VerifyAccountParams = {
  Code?: string;
  src?: string;
};

const VerifyAccountScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const p = useAppSelector(getThemeLookup);
  const { pathname, search } = useLocation();
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const [verifyAccountResponse, setVerifyAccountResponse] = useState<null | string>(null);
  const ScreenComponent = deviceTypeMobile ? ScreenHero : ScreenFloating;
  const columnStyles = combineStyles(styles.column, deviceTypeMobile && styles.columnMobile);
  const apiHandlerReady = useAppSelector(state => state.apiHandlerReady);

  useEffect(() => {
    if (apiHandlerReady) {
      submitOnLoad();
    }
  }, [apiHandlerReady]);

  const submitOnLoad = () => {
    const params: VerifyAccountParams = {};
    const linkParams: string[] = search.split('&');
    if (linkParams.length >= 1) {
      const Code: string | undefined = linkParams[0]?.split('=').pop();
      const src: string | undefined = linkParams[1]?.split('=').pop();
      params.Code = Code && decodeURIComponent(Code);
      params.src = src;
      verifyAccount(params);
    }
  };

  const verifyAccount = async (params: any) => {
    const path = '/api/v1/profile/verify';
    RedcatApiHandler.fetch({
      path,
      method: 'POST',
      body: params,
    })
      .then(response => {
        if (response.success) {
          enqueueSuccessSnackbar('Your account has now been verified...');
          setVerifyAccountResponse(response.data);
        }
      })
      .catch(err => {
        console.log({ err });
        enqueueErrorSnackbar(err);
        setVerifyAccountResponse(err.message);
      });
  };

  return (
    <ScreenComponent>
      <div
        style={combineStyles(
          deviceTypeMobile ? styles.mainContainerMobile : styles.mainContainerDesktop,
          p('screen', ['backgroundColor']),
          p(deviceTypeMobile ? 'initialScreenMobile' : 'initialScreenDesktop', [
            'backgroundColor',
            'boxShadow',
          ]),
        )}
      >
        <div style={columnStyles}>
          {!verifyAccountResponse && (
            <>
              {/* TODO: Edge Case when to cover when API return 302 or continues to have verifyAccountResponse as null ?? */}
              {/* <Text
                themeKey="sectionTitle"
                value={'Invalid link, please check the link or request a new verification link'}
              />
              <StandardButton
                label={'Home'}
                containerStyle={styles.button}
                // disabled={false}
                themeKey="signInSubmitButton"
                onClick={() => history.push('/')}
              /> */}
              <div style={{ marginBottom: 30, textAlign: 'center' }}>Talking to the system...</div>
              <div style={styles.spinnerContainer}>
                <RiRefreshLine className="spinner" style={styles.loadingSpinner} />
              </div>
            </>
          )}
          {verifyAccountResponse && (
            <>
              <Text themeKey="sectionTitle" value={t('title.verifyAccount')} />
              <div style={{ marginTop: 30 }}>{verifyAccountResponse}</div>
              <StandardButton
                label={'Home'}
                containerStyle={styles.button}
                // disabled={false}
                themeKey="signInSubmitButton"
                onClick={() => history.push('/')}
              />
            </>
          )}
        </div>
      </div>
    </ScreenComponent>
  );
};

const styles: Styles = {
  mainContainerDesktop: {
    borderRadius: 2,
    padding: 35,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',

    margin: 'auto', // https://stackoverflow.com/a/33455342/8706204
  },
  mainContainerMobile: {
    flex: 1,
    padding: '10px 25px 100px 25px',
  },
  column: {
    minWidth: 250,
  },
  columnMobile: {
    padding: '20px 0 0 0',
  },
  button: {
    // borderRadius: 5,
    // height: 40,
    margin: '20px 20px',
  },
  verticalSeperator: {
    width: 1.5,
    marginLeft: 40,
    marginRight: 40,
  },

  spinnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    fontSize: 24,
  },
};

export default VerifyAccountScreen;
