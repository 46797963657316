import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../app/hooks';
import Text from '../components/Text';
import { Progress } from 'reactstrap';
import { OrderingSelectors } from 'polygon-ordering';

const { getCurrencySymbol } = OrderingSelectors;

const LoyaltyTier = () => {
  const { t } = useTranslation();
  const deviceType = useAppSelector(state => state.deviceType);
  const profile = useAppSelector(state => state.profile);
  const loyaltyTiers = useAppSelector(state => state.loyaltyTiers);
  const tier = loyaltyTiers?.filter(tier => tier.tierName === profile?.loyaltyTierName)[0];
  const nextTier = loyaltyTiers?.filter(loyaltyTier => loyaltyTier.id === tier?.nextTier)[0];
  const thresholdRemainder = (tier?.tierThreshold || 0) - (profile?.dollarsUntilNextTier || 0);
  const creditLoyaltyEnabled = useAppSelector(state => state.config.creditLoyaltyEnabled);
  const ringView = useAppSelector(state => state.config.loyaltyPointsRing);
  const currency = useAppSelector(getCurrencySymbol);

  return tier ? (
    <div>
      <div
        style={{
          borderTopLeftRadius: deviceType === 1 ? 5 : 0,
          borderTopRightRadius: deviceType === 1 ? 5 : 0,
          backgroundColor: tier?.tierColour,
          height: 60,
          display: 'flex',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text style={{ textAlign: 'center', color: tier?.tierTextColour }}>
          {(tier?.tierName + ' Membership').toUpperCase()}
        </Text>
      </div>
      {nextTier && (
        <div>
          <Progress
            bar
            style={{
              height: '5px',
              borderRadius: 0,
              backgroundColor: tier.tierTextColour,
            }}
            value={100 - (thresholdRemainder / tier?.tierThreshold!) * 100}
          />

          {(!ringView || (ringView && creditLoyaltyEnabled)) && (
            <div className="text-center" style={{ backgroundColor: 'white' }}>
              <Text style={{ fontSize: 14 }}>
                {t('tieredLoyaltyPointsToThreshold', {
                  currency,
                  points: thresholdRemainder,
                  tierName: nextTier?.tierName,
                })}
              </Text>
            </div>
          )}
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default LoyaltyTier;
