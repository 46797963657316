import { ActionType, getType } from 'typesafe-actions';

import * as currentOrder from '../../actionCreators/currentOrder';

import { OPEN_PURCHASE_LOWEST_BALANCE_DEFAULT } from '../../constants';

const initialState = OPEN_PURCHASE_LOWEST_BALANCE_DEFAULT;

export default function (
  state: number = initialState,
  action: ActionType<typeof currentOrder>,
): number {
  switch (action.type) {
    case getType(currentOrder.setOpenPurchaseLowestBalance): {
      const { value } = action.payload;
      return value == null ? initialState : value;
    }
    default:
      return state;
  }
}
