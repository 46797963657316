import { call, put, select } from 'redux-saga/effects';

import { OrderingOperations, OrderingSelectors, requestAndWaitForFlow } from 'polygon-ordering';

import debug from '../utils/debug';

import getCurrentSavedCardToken from '../selectors/getCurrentSavedCardToken';

import { setCheckoutInProgress } from '../slices/checkoutInProgress';

const { finaliseOrder } = OrderingOperations;
const { getMember, getSelectedPaymentMethods } = OrderingSelectors;

export default function* checkoutWithPaymentRequest({ payload = {} }) {
  debug('checkoutWithPaymentRequest', { payload });

  yield put(setCheckoutInProgress(true));

  let proceedToFinalise = true;

  try {
    const memberPresent = Boolean(yield select(getMember));

    if (proceedToFinalise) {
      const currentSavedCardToken = yield select(getCurrentSavedCardToken);
      const selectedPaymentMethods = yield select(getSelectedPaymentMethods);

      yield call(requestAndWaitForFlow, finaliseOrder, {
        subPayments: selectedPaymentMethods,
        paymentGatewayToken: currentSavedCardToken,
        authenticationMethod: memberPresent ? 'member' : 'none',
      });
    }
  } catch (e) {
    debug(false, 'there was a problem during checkout', { e });
  }

  yield put(setCheckoutInProgress(false));
}
