import * as _ from 'lodash';
import adjustChoiceSetMaxForQuantityDiscount from './adjustChoiceSetMaxForQuantityDiscount';
import choiceSetDiscountPlus from './choiceSetDiscountPlus';

export default function adjustChoiceSetsForQuantityDiscountV2(
  choiceSets: ValidatedChoiceSet[],
  choiceSetDiscountConfig: ChoiceSetQuantityDiscountConfig,
): ValidatedChoiceSet[] {
  const { choiceSetKey, choiceSetDiscountMap } = choiceSetDiscountConfig;
  return choiceSets.map(choiceSet => {
    if (choiceSet.key !== choiceSetKey) {
      return choiceSet;
    }

    const { quantity: choiceSetQuantity } = choiceSet;
    const { ChoiceSetDiscountMapObj, plus } = choiceSetDiscountPlus(
      choiceSetDiscountMap || [],
    );

    const discountPlus = plus.map(e => ({ id: e, quantity: 0 }));

    for (let NumberOfChoices in ChoiceSetDiscountMapObj) {
      if (Number(NumberOfChoices) <= choiceSetQuantity) {
        if (discountPlus.length) {
          discountPlus.forEach(discountPlu => {
            if (discountPlu.id === ChoiceSetDiscountMapObj[NumberOfChoices]) {
              discountPlu.quantity++;
            } else {
              discountPlus.push({
                id: ChoiceSetDiscountMapObj[NumberOfChoices],
                quantity: 1,
              });
            }
          });
        } else {
          discountPlus.push({
            id: ChoiceSetDiscountMapObj[NumberOfChoices],
            quantity: 1,
          });
        }
      }
    }

    const adjustedChoiceSet = {
      ...choiceSet,
      choices: choiceSet.choices.map(choice => {
        const discountPlu = _.find(discountPlus, e => e.id === choice.id);

        if (!discountPlu) {
          return choice;
        } else {
          return {
            ...choice,
            quantity: discountPlu.quantity,
            moneyPrice: discountPlu.quantity * choice.baseMoneyPrice,
          };
        }
      }),
    };

    return adjustChoiceSetMaxForQuantityDiscount(
      adjustedChoiceSet,
      choiceSetKey,
      1,
    );
  });
}
