import { ActionType, getType } from 'typesafe-actions';
import * as currentOrder from '../../actionCreators/currentOrder';

const initialState = null;

export default function (
  state: Menu | null = initialState,
  action: ActionType<typeof currentOrder>,
): Menu | null {
  switch (action.type) {
    case getType(currentOrder.resetOrder): {
      return initialState;
    }
    case getType(currentOrder.setMenu): {
      const { value } = action.payload;
      return value == null ? null : value;
    }
    case getType(currentOrder.applyBufferData): {
      const { menu } = action.payload.value;
      return menu === undefined ? state : menu;
    }
    default:
      return state;
  }
}
