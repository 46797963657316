import { put } from 'redux-saga/effects';
import debug from '../utils/debug';
import history from '../history';
import { INITIAL_SCREEN_ROUTE } from '../screens/InitialScreen';
import { OrderingOperations } from 'polygon-ordering';

const { setPickupTime } = OrderingOperations;

export default function* adjustLocation({
  payload: { locationId },
}: {
  payload: { locationId: number | undefined };
}) {
  debug('adjusting location', { locationId });

  //@ts-ignore

  history.push({
    pathname: INITIAL_SCREEN_ROUTE,
    state: { locationId },
  });

  yield put(setPickupTime(null));
}
