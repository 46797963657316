import React from 'react';
import { useAppSelector } from '../app/hooks';
import { OrderingSelectors, OrderingHooks } from 'polygon-ordering';
import Text from '../components/Text';
import uppercaseInitial from '../utils/uppercaseInitial';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import MemberBalances from '../components/MemberBalances';
import Wallet from '../components/Wallet';
import RedcatBarcode from '../components/RedcatBarcode';
import RedcatQrCode from '../components/RedcatQrCode';
import SendVerificationButton from '../components/SendVerificationButton';
import RegistrationVerify from './RegistrationVerify';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import getProfile from '../selectors/getProfile';

const { useFormattedCurrency } = OrderingHooks;
const { getMember, getEnabledPaymentMethods } = OrderingSelectors;

export const REWARDS_SCREEN_ROUTE = '/rewards';

const RewardsScreen = () => {
  const member = useAppSelector(getMember);
  const loyaltyPointsRing = useAppSelector(state => state.config.loyaltyPointsRing);
  const barcodeView = useAppSelector(state => state.config.loyaltyBarcodeView);
  const isWalletEnabled = useAppSelector(state => state.config.enableLoyaltyWallet);
  const enabledPaymentMethods = useAppSelector(getEnabledPaymentMethods);
  const memberMoneyBalance = useFormattedCurrency(Number(member?.moneyBalance));
  const memberMoneyEnabled = enabledPaymentMethods.includes(PAYMENT_METHOD.MEMBER_MONEY)
    ? true
    : false;
  const profile = useAppSelector(getProfile);

  const { t } = useTranslation();

  const barcodeOptions: BarcodeOptions = {
    value: member?.barcode!,
    text: member?.memberNumber!,
  };

  return !profile?.verified ? (
    <RegistrationVerify showContinueButton={false} internalRedirect />
  ) : (
    <div style={{ width: '100%' }}>
      <div className="mx-auto" style={{ padding: 30 }}>
        <div>
          <Row>
            <Col className={loyaltyPointsRing ? '' : 'text-center'}>
              <Text
                themeKey="loyaltyGreeting"
                value={
                  loyaltyPointsRing
                    ? t('loyaltyGreeting')
                    : t('loyaltyGreeting') + ' ' + uppercaseInitial(member?.givenName!) + '!'
                }
              />
              {loyaltyPointsRing && (
                <>
                  <br />
                  <Text
                    themeKey="loyaltyGreeting"
                    value={uppercaseInitial(member?.givenName!) + '!'}
                  />
                </>
              )}
            </Col>
            {loyaltyPointsRing && (
              <Col style={{ textAlign: 'right' }}>
                <div>
                  <Text themeKey="balanceAmountMemberModal" value={memberMoneyBalance} />
                  <br />
                  <Text value={t('memberMoney')} themeKey="memberMoney" />
                </div>
              </Col>
            )}
          </Row>
        </div>
        <div className=" mx-auto  mt-2 ">
          {!loyaltyPointsRing && (
            <div className="text-center">
              <Text
                value={t(memberMoneyEnabled ? 'memberMoneyTitle' : 'memberPointsTitle')}
                themeKey="memberPointsTitle"
              />
            </div>
          )}
          <SendVerificationButton containerStyle={{ paddingTop: 50 }} />
          <div className="my-4 text-center">
            {member && <MemberBalances loyaltyView loyaltyPointsRing={loyaltyPointsRing} />}
          </div>
          {!barcodeView && <RedcatQrCode />}

          {barcodeView && (
            <div
              className="  shadow p-1 position-relative"
              style={{ borderRadius: 10, border: '2px solid lightgrey' }}
            >
              <RedcatBarcode barcodeOptions={barcodeOptions} showMemberDetails />
            </div>
          )}

          {isWalletEnabled && (
            <div>
              <div className="mt-5 text-center">
                <Text value={t('loyaltyWallet')} themeKey="loyaltyWalletTitle" />
              </div>
              <Wallet />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RewardsScreen;
