import { select, put } from 'redux-saga/effects';

import { reserveBuffer } from '../actionCreators/buffer';

import getBufferReservationId from '../selectors/getBufferReservationId';

export default function* (flowId: string, supressThrow: boolean = false) {
  const bufferReservationId = yield select(getBufferReservationId);

  if (bufferReservationId == null) {
    yield put(reserveBuffer(flowId));
    return true;
  } else if (bufferReservationId === flowId) {
    return true;
  } else if (supressThrow) {
    return false;
  } else {
    throw new Error('buffer already in use');
  }
}
