import createSharedChoiceSet from './createSharedChoiceSet';
export default function createSharedChoiceSets(
  ingredientFamilies: BoostIngredientFamilies,
) {
  const additionalsChoiceSet = createSharedChoiceSet(
    'Additionals',
    ingredientFamilies,
    family => !['supplements', 'ice'].includes(family.id),
    'familyRows',
  );

  const boostersChoiceSet = createSharedChoiceSet(
    'Boosters',
    ingredientFamilies,
    family => family.id === 'supplements',
  );

  return {
    [additionalsChoiceSet.id]: additionalsChoiceSet,
    [boostersChoiceSet.id]: boostersChoiceSet,
  };
}
