import React from 'react';
import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import Text from '../components/Text';
import ReorderButton from '../components/ReorderButton';
import { AiTwotoneHeart } from 'react-icons/ai';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import TouchableOpacity from '../components/TouchableOpacity';
import getProfile from '../selectors/getProfile';
import RegistrationVerify from './RegistrationVerify';

export const FAVOURITES_SCREEN_ROUTE = '/favourites';

const { getFavouriteOrders } = OrderingSelectors;
const { deleteFavouriteOrder } = OrderingOperations;

const FavouritesScreen = () => {
  const favouriteOrders = useAppSelector(getFavouriteOrders);
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const dispatch = useAppDispatch();
  const profile = useAppSelector(getProfile);

  return (
    <div>
      {!profile?.verified ? (
        <RegistrationVerify showContinueButton={false} internalRedirect />
      ) : (
        <>
          {favouriteOrders?.map(order => (
            <div
              key={order.id}
              className="p-3 my-2"
              style={{ backgroundColor: 'whitesmoke', borderRadius: 5 }}
            >
              <Row>
                <Col xs="9">
                  <div>
                    <Text
                      themeKey="loyaltyOrderItems"
                      value={order.name!}
                      style={{ paddingRight: 3, fontWeight: 'bold' }}
                    />
                  </div>
                  <div style={{ minHeight: 75 }}>
                    {order.items.map(item => (
                      <div key={item.id}>
                        <Text
                          themeKey="loyaltyOrderItems"
                          value={item.name!}
                          style={{ paddingRight: 3 }}
                        />
                        <Text themeKey="loyaltyOrderItems" value="x" />
                        <Text themeKey="loyaltyOrderItems" value={item.quantity.toString()} />
                      </div>
                    ))}
                  </div>
                </Col>
                <Col xs="3" className=" position-relative">
                  <div className="position-absolute" style={{ top: 0, right: 10 }}>
                    <TouchableOpacity
                      onClick={() => dispatch(deleteFavouriteOrder({ id: order.id }))}
                    >
                      <AiTwotoneHeart style={combineStyles(p('activeHeartIcon', ['color']))} />
                    </TouchableOpacity>
                  </div>
                  <ReorderButton order={order} />
                </Col>
              </Row>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default FavouritesScreen;
