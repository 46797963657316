import { createSelector } from 'reselect';
import lodash from 'lodash';

import calculateDistanceBetweenCoords from '../utils/calculateDistanceBetweenCoords';

import getDisplayableLocations from './getDisplayableLocations';
import getUserLocation from './getUserLocation';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import { RootState } from 'store';

const getModalParams = (state: RootState) => state.modalParams;

export default createSelector(
  [getDisplayableLocations, getUserLocation, getModalParams],
  (locations, userLocation, params) => {
    return lodash
      .chain(locations)
      .filter(location => (params?.saleType === SALE_TYPE.CATERING ? true : location.online))
      .map(location => ({
        ...location,
        //@ts-ignore
        distance: calculateDistanceBetweenCoords(location, userLocation),
      }))
      .orderBy(['distance', 'name'], ['asc', 'asc'])
      .value();
  },
);
