import { ActionType, getType } from 'typesafe-actions';

import * as config from '../../actionCreators/config';

export default function (
  state: boolean = false,
  action: ActionType<typeof config>,
): boolean {
  switch (action.type) {
    case getType(config.setApiHandlerReady): {
      const { value } = action.payload;
      return Boolean(value);
    }
    default:
      return Boolean(state);
  }
}
