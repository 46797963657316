import { ActionType, getType } from 'typesafe-actions';

// import * as setStockBalancesActions from '../actionCreators/member';
import * as currentOrderActions from '../../actionCreators/currentOrder';

const initialState = null;
export default function (
  state: StockBalance[] | null = initialState,
  action: ActionType<typeof currentOrderActions>,
): StockBalance[] | null {
  switch (action.type) {
    case getType(currentOrderActions.setStockBalances): {
      const { value } = action.payload;
      return value == null ? initialState : value;
    }
    default:
      return state;
  }
}
