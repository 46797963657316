import { call, put, takeEvery, all, select } from 'redux-saga/effects';

import * as fetchStockBalances from '../../actionCreators/flows/fetchStockBalances';
import { setStockBalances } from '../../actionCreators/currentOrder';

import { $getLocationId } from '../../selectors/getLocationId';

import Api, { FetchParams, ApiResponse } from '../../utils/Api';
import { makeErrorSerialisable, createFlowApprover } from '../../utils/sagas';

export const requested = createFlowApprover(fetchStockBalances);

export function* approved(
  action: ReturnType<typeof fetchStockBalances.actions.approved>,
) {
  const {
    meta: { flowId },
  } = action;

  try {
    const locationId = yield select($getLocationId);

    if (locationId == null) {
      throw new Error('missing required parameters');
    }

    const params: FetchParams = {
      path: `/api/v1/stores/${locationId}/pluqty`,
      method: 'GET',
    };

    // $$ Comment this out when using the test data
    const response: ApiResponse = yield call(Api.fetch, params);
    yield put(setStockBalances(response.data.StockBalances as StockBalance[]));

    // ------ $$ Uncomment this when using the using mock apis -----
    // const response = yield call(
    //   fetch,
    //   'http://localhost:3004/data',
    //   // 'https://catscoffee.redcatcloud.com.au/api/v1/stores/1/pluqty',
    //   // 'https://3c8039b3-b78d-4451-b389-989e6f01612f.mock.pstmn.io/api/v1/stores/1/stockbalance',
    // );

    // const body = yield call([response, 'json']);
    // // when using external mock apis we need to do the following
    // // body.data.StockBalances
    // yield put(setStockBalances(body.StockBalances as StockBalance[]));
    // --------------------------------------------------------------

    yield put(fetchStockBalances.actions.succeeded({}, flowId));
  } catch (e) {
    yield put(
      fetchStockBalances.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(fetchStockBalances.events.REQUESTED, requested),
    takeEvery(fetchStockBalances.events.APPROVED, approved),
  ]);
}
