import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import { OrderingSelectors } from 'polygon-ordering';
import { setPaymentMethod } from '../slices/paymentMethod';
import getThemeLookup from '../selectors/getThemeLookup';
import combineStyles from '../utils/combineStyles';
import Text from './Text';
import TouchableOpacity from './TouchableOpacity';
import { setSavedCardToken } from '../slices/savedCardToken';

import { RiCheckboxBlankCircleLine, RiRecordCircleFill } from 'react-icons/ri';
import { setPaymentFormInUse } from '../slices/paymentFormInUse';
import { setPaymentFormFilled } from '../slices/paymentFormFilled';
import { useFormikContext } from 'formik';

const { getEnabledPaymentMethods, getEnableStripeCvcRevalidation } = OrderingSelectors;

interface IProps {
  disabled?: boolean;
  children?: React.ReactNode;
  selected?: () => boolean;
  allowSelectionWhenUnavailable?: boolean;
  additionalBehaviour?: () => void;
  right?: React.ReactNode | null;
  subtitle?: React.ReactNode | null;
  label?: string;
  method: PAYMENT_METHOD;
}

const PaymentMethodProvider: React.FC<IProps> = ({
  method,
  label,
  right,
  subtitle,
  children,
  additionalBehaviour,
  selected,
}) => {
  const formik = useFormikContext();
  const { values } = formik;

  const enabledPaymentMethods = useAppSelector(getEnabledPaymentMethods);
  const paymentMethod = useAppSelector(state => state.paymentMethod);
  const p = useAppSelector(getThemeLookup);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const paymentFormInUse = useAppSelector(state => state.paymentFormInUse);
  const savedCardToken = useAppSelector(state => state.savedCardToken);
  const enableCvcCheck = useAppSelector(getEnableStripeCvcRevalidation);

  let alreadySelected = !selected ? paymentMethod?.method === method : selected();

  if (!enabledPaymentMethods.includes(method)) {
    return null;
  }

  const IconComponent = alreadySelected ? RiRecordCircleFill : RiCheckboxBlankCircleLine;

  return (
    <TouchableOpacity
      style={styles.mainContainer}
      onClick={() => {
        if (alreadySelected) {
          dispatch(setPaymentFormFilled(false));
          dispatch(setPaymentFormInUse(false));
          dispatch(setPaymentMethod(null));
          if (savedCardToken) {
            dispatch(setSavedCardToken(null));
            dispatch(setPaymentFormFilled(false));
          }
        } else {
          dispatch(
            setPaymentMethod({
              method,
              amount: (values as FormikFields).Amount,
            }),
          );

          if (!additionalBehaviour) {
            dispatch(setPaymentFormFilled(false));
          } else {
            if (!enableCvcCheck) {
              dispatch(setPaymentFormFilled(true));
            }
          }
        }
        if (additionalBehaviour) {
          additionalBehaviour();
        }
      }}
      disabled={paymentFormInUse}
    >
      <div style={styles.rightContainer}>
        <div style={styles.topContainer}>
          <IconComponent style={combineStyles(p('paymentMethodIcon', ['color', 'fontSize']))} />

          <Text themeKey="paymentMethod" style={{ marginRight: 'auto', paddingLeft: 3 }}>
            {label || t(`paymentMethod.${method}`)}
          </Text>
          {right == null ? <div /> : right}
        </div>

        <div>{subtitle}</div>

        <div>{alreadySelected ? children : undefined}</div>
      </div>
    </TouchableOpacity>
  );
};

const styles: Styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },

  rightContainer: {
    flex: 1,
  },

  topContainer: {
    paddingTop: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  disabled: {
    opacity: 0.5,
    filter: 'grayscale(100%)',
  },
};

export default PaymentMethodProvider;
