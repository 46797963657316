import React, { useEffect, useRef } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import { INITIAL_SCREEN_ROUTE } from './InitialScreen';
import LoyaltyWrapper from '../components/LoyaltyWrapper';
import RedirectAndPreserveSearch from '../components/RedirectAndPreserveSearch';
import PrivateRoute from './PrivateRoute';
import { getAvailableRoutes, giftcardRoutes } from '../utils/getAvailableScreens';
import { useAppSelector } from '../app/hooks';
import { ADD_LOYALTY_WALLET_ROUTE } from '../screens/AddLoyaltyWalletScreen';
//@ts-ignore
import postscribe from 'postscribe';
import TagManager from 'react-gtm-module';
import { PURCHASE_GIFT_CARD_ROUTE } from './PurchaseGiftCardScreen';
import SignUpScreen, { SIGN_UP_ROUTE, REGISTER_NEW_USER_ROUTE } from './SignUpScreen';
import { OrderingSelectors } from 'polygon-ordering';

const { getMember } = OrderingSelectors;

const { loyaltyOnlyRoutes, orderingRoutes, loyaltyRoutes, giftCardRoutes, profileRoutes } =
  getAvailableRoutes();

const Routes = () => {
  const member = useAppSelector(getMember);
  const loyalyDisabled = useAppSelector(state => state.config.disableLoyalty);
  const onlyGiftcardPurchasesEnabled = useAppSelector(
    state => state.config.enableOnlyGiftcardPurchases,
  );
  const disabledLoyaltyRoutes = useAppSelector(state => state.config.disabledLoyaltyRoutes);
  const isGiftcardPurchaseEnabled = useAppSelector(state => state.config.enableGiftcardPurchase);
  const isWalletEnabled = useAppSelector(state => state.config.enableLoyaltyWallet);
  const disableGiftcardRoutes = isGiftcardPurchaseEnabled
    ? isWalletEnabled
      ? []
      : [ADD_LOYALTY_WALLET_ROUTE]
    : isWalletEnabled
    ? giftcardRoutes.filter(item => item !== ADD_LOYALTY_WALLET_ROUTE)
    : giftcardRoutes;

  const isOnlyLoyaltyEnabled = useAppSelector(state => state.config.enableOnlyLoyalty);
  const defaultRoutes = onlyGiftcardPurchasesEnabled
    ? []
    : isOnlyLoyaltyEnabled
    ? loyaltyOnlyRoutes
    : orderingRoutes;

  // Injection scripts:
  const orderingScriptInjection = useAppSelector(state => state.config.orderingScriptInjection);
  const loyaltyScriptInjection = useAppSelector(state => state.config.loyaltyScriptInjection);
  const googleTagManagerKey = useAppSelector(state => state.config.googleTagManagerKey);
  const loyaltyHtmlHeadInjection = useAppSelector(state => state.config.loyaltyHtmlHeadInjection);

  // This is used to detect which type of page the user is on to then inject the correct scripts.
  const routeLocation = useLocation();
  // This is used to store the previous script that initially ran so it could be used to compare if it has already ran.
  const initialScript = useRef('');
  const queuedScript = useRef('');
  // This is used to allow Google Tag Manager to work whenever it's called in analytics.ts
  if (googleTagManagerKey && !window.googleTagManager) window.googleTagManager = true;

  // Upon every page route change:
  useEffect(() => {
    const isLoyalty = loyaltyRoutes.some(route => route.path == routeLocation.pathname);
    const isOrder = defaultRoutes.some(route => route.path == routeLocation.pathname);

    window.requestAnimationFrame(() => {
      // Google Tag:
      console.log('Google Tag Manager Key:', googleTagManagerKey);
      if (googleTagManagerKey && googleTagManagerKey != '') {
        const tagManagerArgs = {
          // Our own Google Tag ID for testing:
          // TEST == 'GTM-T9KP5W2',
          // Put 'TEST' in pc to see if it's working fine.

          gtmId: googleTagManagerKey != 'TEST' ? googleTagManagerKey : 'GTM-T9KP5W2',

          dataLayer: {
            page: routeLocation,
          },
        };

        TagManager.initialize(tagManagerArgs);
      }

      // Script / HTML injection from Polygon Central:
      const injectedScriptWrapper = document.querySelector('#injected-scripts');
      if (isLoyalty) {
        // Injecting into bottom of the body
        if (loyaltyScriptInjection != null) {
          queuedScript.current = loyaltyScriptInjection;
        }
        // Injecting into the head
        if (loyaltyHtmlHeadInjection != null) {
          // Stuff into the head
        }
      } else if (isOrder && orderingScriptInjection != null) {
        queuedScript.current = orderingScriptInjection;
      }

      // if #injected-scripts is not undefined and doesn't have the same code in it, delete everything in it an run new scripts
      if (
        injectedScriptWrapper != null &&
        (initialScript.current == '' || initialScript.current != queuedScript.current)
      ) {
        injectedScriptWrapper.innerHTML = '';
        postscribe('#injected-scripts', queuedScript.current);
        initialScript.current = queuedScript.current;
      }
    });
  }, [routeLocation]);

  return (
    <Switch>
      {defaultRoutes.map(route => (
        <Route
          key={route.path}
          exact={route.exact}
          path={route.path}
          render={() => <route.component />}
        />
      ))}

      {giftCardRoutes
        .filter(route => !disableGiftcardRoutes?.includes(route.path))
        .map(route => (
          <Route
            key={route.path}
            exact={route.exact}
            path={route.path}
            render={() => (
              <LoyaltyWrapper>
                <route.component />
              </LoyaltyWrapper>
            )}
          />
        ))}

      {!loyalyDisabled &&
        loyaltyRoutes
          .filter(route => !disabledLoyaltyRoutes?.includes(route.path))
          .map(route => (
            <PrivateRoute
              key={route.path}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}

      {loyalyDisabled &&
        profileRoutes.map(route => (
          <PrivateRoute
            key={route.path}
            exact={route.exact}
            path={route.path}
            component={route.component}
          />
        ))}

      {!onlyGiftcardPurchasesEnabled && !member && (
        <Route
          exact={true}
          path={SIGN_UP_ROUTE}
          render={() => (
            <LoyaltyWrapper>
              <SignUpScreen />
            </LoyaltyWrapper>
          )}
        />
      )}
      {!onlyGiftcardPurchasesEnabled && !member && (
        <Route
          exact={true}
          path={REGISTER_NEW_USER_ROUTE}
          render={() => (
            <LoyaltyWrapper>
              <SignUpScreen />
            </LoyaltyWrapper>
          )}
        />
      )}
      <RedirectAndPreserveSearch
        to={onlyGiftcardPurchasesEnabled ? PURCHASE_GIFT_CARD_ROUTE : INITIAL_SCREEN_ROUTE}
      />
    </Switch>
  );
};

export default Routes;
