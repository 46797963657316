import processDeliveryEstimateBase from './processDeliveryEstimateBase';
import processLocationDeliveryEstimate from './processLocationDeliveryEstimate';

export default function processDeliveryEstimate(
  raw: any,
  desiredDeliveryTime: string,
) {
  const estimateBase = processDeliveryEstimateBase({
    DeliveryAvailable: raw.DeliveryAvailable,
    DeliveryAddress: raw.DeliveryAddress,
    DeliveryLatitude: raw.DeliveryLatitude,
    DeliveryLongitude: raw.DeliveryLatitude,
  });

  let locationEstimates: LocationDeliveryEstimate[] = [];

  if (raw?.Estimates == null) {
    locationEstimates.push(
      processLocationDeliveryEstimate(raw, desiredDeliveryTime),
    );
  } else {
    locationEstimates = raw.Estimates.map((raw: RawLocationDeliveryEstimate) =>
      processLocationDeliveryEstimate(raw, desiredDeliveryTime),
    );
  }

  return {
    estimateBase,
    locationEstimates,
  };
}
