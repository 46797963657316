import { ActionType, getType } from 'typesafe-actions';

import * as currentOrder from '../../actionCreators/currentOrder';

import processPromoCode from '../../utils/processors/processPromoCode';

const initialState = null;

export default function (
  state: string | null = initialState,
  action: ActionType<typeof currentOrder>,
): string | null {
  switch (action.type) {
    case getType(currentOrder.resetOrder): {
      return initialState;
    }
    case getType(currentOrder.setPromoCode): {
      const { value } = action.payload;

      return processPromoCode(value);
    }
    case getType(currentOrder.adjustPromoCode): {
      const { value } = action.payload;

      switch (value) {
        case 'clear': {
          return initialState;
        }
        case 'delete': {
          return (state || '').slice(0, -1);
        }
        default:
          return `${state || ''}${value}`.trimLeft();
      }
    }
    default:
      return state;
  }
}
