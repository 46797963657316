export function singleValuePayload<T, V>() {
  return (type: T) => (value: V) => ({
    type,
    payload: {
      value,
    },
  });
}

export function noPayload<T>() {
  return (type: T) => () => ({
    type,
  });
}
