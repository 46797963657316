import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as null | MemberOffer;

const slice = createSlice({
  name: 'loyaltySelectedOffer',
  initialState,
  reducers: {
    setLoyaltySelectedOffer(state, action: PayloadAction<MemberOffer | null>) {
      return action.payload || initialState;
    },
  },
});

export const { setLoyaltySelectedOffer } = slice.actions;

export default slice.reducer;
