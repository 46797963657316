import { createSelector } from 'reselect';

import enhanceOfferTargetPLUs from '../utils/ordering/enhanceOfferTargetPLUs';

import getCurrentOrder from './getCurrentOrder';
import getBuffer from './getBuffer';
import getItems, { $getItems } from './getItems';
import getSaleType, { $getSaleType } from './getSaleType';
import { SALE_TYPE } from '../constants/saleType';

const filterOffers = (offers: Offer[], items: Items, saleType: SALE_TYPE) =>
  offers
    .filter(offer => {
      if (!offer.available) {
        return false;
      }

      if (offer.saleTypes.length && !offer.saleTypes.includes(saleType)) {
        return false;
      }

      return true;
    })
    .map(offer => enhanceOfferTargetPLUs(offer, items));

export default createSelector(
  [getCurrentOrder, getItems, getSaleType],
  (order, items, saleType): Offer[] => {
    if (!items) {
      return [];
    }

    return filterOffers(order.offers, items, saleType);
  },
);

export const $getOffers = createSelector(
  [getCurrentOrder, getBuffer, $getItems, $getSaleType],
  (order, buffer, items, saleType): Offer[] => {
    if (!items) {
      return [];
    }

    return filterOffers(buffer.offers || order.offers, items, saleType);
  },
);
