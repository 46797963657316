import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = false as boolean;

const slice = createSlice({
  name: 'checkoutInProgress',
  initialState,
  reducers: {
    setCheckoutInProgress(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
});

export const { setCheckoutInProgress } = slice.actions;

export default slice.reducer;
