import { call, put, takeEvery, all, select } from 'redux-saga/effects';

import * as removePaymentGatewayToken from '../../actionCreators/flows/removePaymentGatewayToken';

import Api, { FetchParams, ApiResponse } from '../../utils/Api';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';
import processPaymentGatewayToken from '../../utils/processors/processPaymentGatewayToken';
import { getEnableDynamicPaymentGatewayConfig } from '../../selectors';
import { setPaymentGatewayConfigPaymentMethods } from '../../actionCreators/currentOrder';

export const requested = createFlowApprover(removePaymentGatewayToken);

export function* approved(
  action: ReturnType<typeof removePaymentGatewayToken.actions.approved>,
) {
  const {
    payload: { token, authenticationMethod = 'member' },
    meta: { flowId },
  } = action;

  try {
    const params: FetchParams = {
      path: `/api/v1/profile/pgtokens/${token}`,
      method: 'DELETE',
    };

    const response: ApiResponse = yield call(
      Api.fetch,
      params,
      authenticationMethod,
    );

    const enableDynamicPaymentGatewayConfig = yield select(
      getEnableDynamicPaymentGatewayConfig,
    );

    if (enableDynamicPaymentGatewayConfig) {
      yield put(setPaymentGatewayConfigPaymentMethods(response.data));
    } else {
      const rawTokens: RawPaymentGatewayToken[] = response.data;
      const processedTokens = rawTokens.map(processPaymentGatewayToken);

      yield put(
        removePaymentGatewayToken.actions.succeeded(
          { tokens: processedTokens },
          flowId,
        ),
      );
    }
  } catch (e) {
    yield put(
      removePaymentGatewayToken.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(removePaymentGatewayToken.events.REQUESTED, requested),
    takeEvery(removePaymentGatewayToken.events.APPROVED, approved),
  ]);
}
