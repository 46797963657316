import lodash from 'lodash';

import processBoostAppIngredient from './processBoostAppIngredient';

export default function processBoostAppMenu(
  response: any,
  masterChoices: SDict<Choice>,
): [BoostIngredient[]] {
  console.groupCollapsed('processBoostAppMenu');

  const {
    ingredientFamilies,
    ingredients,
  } = (response as BoostRawAppMenuResponse).data;

  const result = lodash.compact(
    ingredients.map(rawIngredient =>
      processBoostAppIngredient(
        rawIngredient,
        masterChoices,
        ingredientFamilies,
      ),
    ),
  );

  console.groupEnd();

  return [result];
}
