import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import lodash from 'lodash';

import * as recordPayment from '../../actionCreators/flows/recordPayment';
import * as tyroPayment from '../../actionCreators/flows/tyroPayment';

import getApplePayItems from '../../selectors/getApplePayItems';
import getGooglePayOptions from '../../selectors/getGooglePayOptions';
import getPaymentGatewayConfig from '../../selectors/getPaymentGatewayConfig';
import {
  getEnableDynamicPaymentGatewayConfig,
  getMerchantConfig,
  getEftposConfig,
  getRememberCreditCard,
  getCountryCode,
  getStripeCurrency,
} from '../../selectors/config';

import getPurchaser from '../../selectors/getPurchaser';

import PaymentHooks from '../../utils/PaymentHooks';
import {
  requestAndWaitForFlow,
  createFlowApprover,
  makeErrorSerialisable,
} from '../../utils/sagas';

import { FAILURE_REASON } from '../../constants/failureReasons';
import { PAYMENT_METHOD } from '../../constants/paymentMethod';

export const requested = createFlowApprover(recordPayment);

export function* approved(
  action: ReturnType<typeof recordPayment.actions.approved>,
) {
  const {
    payload: { subPayments, paymentGatewayToken, paymentGatewayPublicKey },
    meta: { flowId },
  } = action;

  const subPaymentsToRecord: SubPayment[] = [];

  let reason: recordPayment.FailureReason;

  try {
    // Error if the amount is undefined for any payment method
    if (lodash.find(subPayments, ['amount', undefined])) {
      throw new Error('payment amount is undefined');
    }

    for (var subPayment of subPayments) {
      switch (subPayment.method) {
        case PAYMENT_METHOD.FREE:
        case PAYMENT_METHOD.PAY_LATER:
        case PAYMENT_METHOD.GIFT_CARD:
        case PAYMENT_METHOD.MEMBER_MONEY:
        case PAYMENT_METHOD.MEMBER_REWARDS:
        case PAYMENT_METHOD.MEMBER_POINTS: {
          subPaymentsToRecord.push({
            method: subPayment.method,
            amount: subPayment.amount,
          });
          break;
        }
        case PAYMENT_METHOD.EFTPOS: {
          const { provider } = yield select(getEftposConfig);
          let transactionData;

          let amount = subPayment.amount;

          switch (provider) {
            case 'tyro': {
              const payloads = yield call(
                requestAndWaitForFlow,
                tyroPayment,
                { amount },
                flowId,
              );
              transactionData = lodash.get(payloads, 'success.transactionData');

              reason = lodash.get(payloads, 'failure.reason');
              break;
            }
            default:
              reason = FAILURE_REASON.MISSING_EFTPOS_PROVIDER;
              throw new Error('no eftpos provider specified');
          }

          if (!transactionData) {
            throw new Error('eftpos payment failed');
          }

          subPaymentsToRecord.push({
            method: subPayment.method,
            amount: subPayment.amount,
            cardType: transactionData.cardType,
            receiptText: transactionData.customerReceipt,
            referenceNumber: transactionData.rrn,
            authorisationCode: transactionData.authorisationCode,
          });

          break;
        }
        case PAYMENT_METHOD.SAVED_CARD: {
          subPaymentsToRecord.push({
            method: subPayment.method,
            amount: subPayment.amount,
            token: paymentGatewayToken,
          });
          break;
        }
        case PAYMENT_METHOD.CREDIT_CARD:
        case PAYMENT_METHOD.APPLE_PAY:
        case PAYMENT_METHOD.ALIPAY:
        case PAYMENT_METHOD.GOOGLE_PAY: {
          const hook = PaymentHooks.get(subPayment.method);

          if (!hook) {
            reason = FAILURE_REASON.MISSING_PAYMENT_HOOK;
            throw new Error('payment hook not set');
          }

          const {
            effective: { email, mobile, name, familyName },
          } = (yield select(getPurchaser)) as Purchaser;

          const rememberCreditCard =
            (yield select(getRememberCreditCard)) &&
            subPayment.method === PAYMENT_METHOD.CREDIT_CARD;

          const applePayItems = yield select(getApplePayItems);
          const googlePayOptions = yield select(getGooglePayOptions);
          const paymentGatewayConfig = yield select(getPaymentGatewayConfig);
          const merchantConfig = yield select(getMerchantConfig);
          const enableDynamicPaymentGatewayConfig = yield select(
            getEnableDynamicPaymentGatewayConfig,
          );
          const countryCode = yield select(getCountryCode);
          const currencyCode = yield select(getStripeCurrency);

          const fullName = `${name || ''} ${familyName || ''}`.trim();

          let amount: number = subPayment.amount as number;

          try {
            //@ts-ignore
            const hookResult = yield call(hook, {
              currencyCode,
              countryCode,
              email,
              mobile,
              applePayItems,
              googlePayOptions,
              amount,
              name,
              familyName,
              paymentGatewayPublicKey,
              enableDynamicPaymentGatewayConfig,
              paymentGatewayConfig,
              merchantConfig,
              fullName,
            }) as PaymentHookResult;

            console.log({ hookResult });

            subPaymentsToRecord.push({
              method: subPayment.method,
              amount: subPayment.amount,
              token: hookResult.token,
              lastFour: rememberCreditCard ? hookResult.lastFour : undefined,
              sourceId: hookResult.sourceId,
              status: hookResult.status,
              type: hookResult.type,
              usage: hookResult.usage,
              clientSecret: hookResult.clientSecret,
            });
          } catch (e) {
            const errorCode = String(lodash.get(e, 'code', '')).toLowerCase();

            // apple pay and eftpos have payment processes you can start and then cancel
            if (errorCode === 'cancelled' || errorCode === 'canceled') {
              reason = FAILURE_REASON.PAYMENT_CANCELLED;
            }
            throw e;
          }
          break;
        }
        default:
          reason = FAILURE_REASON.UNKNOWN_PAYMENT_METHOD;
          throw new Error('payment method not implemented');
      }
    }

    const payment: Payment = {
      subPayments: subPaymentsToRecord,
    };

    yield put(recordPayment.actions.succeeded({ payment }, flowId));
  } catch (e) {
    yield put(
      recordPayment.actions.failed(
        { error: makeErrorSerialisable(e), reason },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(recordPayment.events.REQUESTED, requested),
    takeEvery(recordPayment.events.APPROVED, approved),
  ]);
}
