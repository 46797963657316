import React from 'react';

import combineStyles from '../utils/combineStyles';

import HeaderBar from './HeaderBar';
import { useAppSelector } from '../app/hooks';

const ScreenFloating: React.FC<{
  children: any;
  parentContainerStyle?: React.CSSProperties;
}> = ({
  children,
  parentContainerStyle,
  // noFloatingChildWrapper,
}) => {
  const floatingScreenBackgroundImagePath = useAppSelector(
    state => state.config.floatingScreenBackgroundImagePath,
  );

  return (
    <div
      style={combineStyles(
        styles.mainContainer,
        floatingScreenBackgroundImagePath && {
          backgroundImage: `url(${floatingScreenBackgroundImagePath})`,
        },
      )}
    >
      <HeaderBar themeKey="headerBarFloating" />

      <div style={combineStyles(styles.excludingHeader, parentContainerStyle)}>
        {children}
        {/* {noFloatingChildWrapper ? children : <div style={styles.floatingChild}>{children}</div>} */}
      </div>
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    backgroundImage: 'url(https://via.placeholder.com/1920x1080.png?text=BACKGROUND+IMAGE)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',

    height: '100%',
    width: '100%',
    overflow: 'hidden',

    display: 'flex',
    flexDirection: 'column',
  },
  excludingHeader: {
    flex: 1,
    display: 'flex',
    overflowY: 'auto',
    overflowX: 'auto',
  },
  // floatingChild: {
  //   margin: 'auto', // https://stackoverflow.com/a/33455342/8706204
  // },
};

export default ScreenFloating;
