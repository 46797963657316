import { call, put } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import lodash from 'lodash';

import * as OrderingLifecycleEvents from '../lifecycleEvents';
import { waitForFlow } from './sagas';

// NOTE: moved this into its own file to prevent circular dependency issues.
// This helper allows someone to start and await completion of
// a flow using only the exported `requested` operation
export default function* requestAndWaitForFlowExternal<
  F extends Function,
  P extends {},
>(requestOperation: F, requestParams: P, flowId: string = uuidv4()) {
  yield put(requestOperation(requestParams, flowId));

  // typesafe-actions adds this method to all action creators
  if (!(requestOperation as any).getType) {
    throw new Error('not a valid flow request action creator');
  }

  const requestType = (requestOperation as any).getType() as string;

  const events = lodash
    .values(OrderingLifecycleEvents)
    .find(event => event.REQUESTED === requestType);

  if (!events) {
    throw new Error(
      'could not find matching events for flow request action creator',
    );
  }

  return yield call(waitForFlow, events, flowId);
}
