import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import delay from '../utils/delay';
import { Elements } from '@stripe/react-stripe-js';
import getPaymentGatewayPublicKey from '../selectors/getPaymentGatewayPublicKey';
import { setCreditCardComplete } from '../slices/creditCardComplete';

const CheckoutStripeProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();
  const paymentGatewayPublicKey = useAppSelector(getPaymentGatewayPublicKey);
  const merchantAccount = useAppSelector(state => state.config.stripeMerchantAccount);
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    dispatch(setCreditCardComplete(false));
    readyStripe();
  }, [paymentGatewayPublicKey]);

  const readyStripe = () => {
    if (!paymentGatewayPublicKey) {
      return;
    }

    setStripe(null);

    delay(1000)
      .then(() => loadStripe(paymentGatewayPublicKey, { stripeAccount: merchantAccount! }))
      .then(stripe => setStripe(stripe));
  };

  return (
    <Elements key={paymentGatewayPublicKey} stripe={stripe}>
      {children}
    </Elements>
  );
};

export default CheckoutStripeProvider;
