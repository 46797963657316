import { createSelector } from 'reselect';

import getLocationBrandsWithMenuRepresentation from './getLocationBrandsWithMenuRepresentation';
import { RootState } from 'store';

const getCurrentMenuBrandId = (state: RootState) => state.currentMenuBrandId;
const getDisableAllBrandsMenuTab = (state: RootState) => state.config.disableAllBrandsMenuTab;

export default createSelector(
  [getCurrentMenuBrandId, getLocationBrandsWithMenuRepresentation, getDisableAllBrandsMenuTab],
  (currentMenuBrandId, locationBrandsWithMenuRepresentation, disableAllBrandsMenuTab) => {
    const currentBrandAvailable = locationBrandsWithMenuRepresentation.some(
      brand => brand.id === currentMenuBrandId,
    );
    const result = currentBrandAvailable ? currentMenuBrandId : null;

    if (result == null && disableAllBrandsMenuTab && locationBrandsWithMenuRepresentation.length) {
      return locationBrandsWithMenuRepresentation[0].id;
    }

    return result;
  },
);
