import { createAsyncThunk } from '@reduxjs/toolkit';
import { IN_DEVELOPMENT } from '../constants';

import debug from '../utils/debug';
import setLocalValue from '../utils/setLocalValue';
import { clearOffers } from '../slices/offers';
import { clearProfile } from '../slices/profile';
import syncMemberAction from '../actions/syncMember';
import { RedcatApiHandler } from 'polygon-utils';

export const signOut = createAsyncThunk('signOut', async (_data: undefined, { dispatch }) => {
  debug('sign out');

  await RedcatApiHandler.fetch({
    method: 'GET',
    path: '/auth/logout',
  }).finally(() => {
    if (IN_DEVELOPMENT) {
      setLocalValue('memberAuthToken', undefined);
    }

    dispatch(syncMemberAction());
    dispatch(clearOffers(null));
    dispatch(clearProfile({}));
  });
});
