import { put, takeEvery, all, select } from 'redux-saga/effects';

import * as adjustOpenPurchaseChoice from '../../actionCreators/flows/adjustOpenPurchaseChoice';

import getUnenhancedOpenPurchase from '../../selectors/getUnenhancedOpenPurchase';
import getItems from '../../selectors/getItems';
import { getChoiceSetDiscountConfig } from '../../selectors/config';

import adjustedSelections from '../../utils/ordering/adjustedSelections';
import { makeErrorSerialisable, createFlowApprover } from '../../utils/sagas';
import adjustChoiceSetMaxForQuantityDiscount from '../../utils/ordering/adjustChoiceSetMaxForQuantityDiscount';

export const requested = createFlowApprover(adjustOpenPurchaseChoice);

export function* approved(
  action: ReturnType<typeof adjustOpenPurchaseChoice.actions.approved>,
) {
  const {
    payload: { targetChoiceSetId, targetChoiceId, clear },
    meta: { flowId },
  } = action;

  try {
    const openPurchase: _Purchase = yield select(getUnenhancedOpenPurchase);

    let adjustedPurchase;

    if (clear) {
      const newTargetSelections = openPurchase.choiceSelections[
        targetChoiceSetId
      ].filter(id => targetChoiceId !== undefined && targetChoiceId !== id);

      adjustedPurchase = {
        ...openPurchase,
        choiceSelections: {
          ...openPurchase.choiceSelections,
          [targetChoiceSetId]: newTargetSelections,
        },
      };
    } else {
      if (!targetChoiceId) {
        throw new Error("targetChoiceId required when 'clear' param is false");
      }

      const items = yield select(getItems);
      const item = items[openPurchase.itemId] as Item;

      let targetChoiceSet = item.choiceSets.find(
        set => set.key === targetChoiceSetId,
      ) as ValidatedChoiceSet;

      const choiceSetDiscountConfig = yield select(getChoiceSetDiscountConfig);
      if (choiceSetDiscountConfig) {
        const {
          choiceSetKey: discountChoiceSetId = '',
          tierSize: discountTierSize = 1,
        } = choiceSetDiscountConfig as ChoiceSetQuantityDiscountConfig;

        targetChoiceSet = adjustChoiceSetMaxForQuantityDiscount(
          targetChoiceSet,
          discountChoiceSetId,
          discountTierSize,
        );
      }

      adjustedPurchase = {
        ...openPurchase,
        choiceSelections: adjustedSelections(
          targetChoiceSet.max,
          targetChoiceSet.individualMax,
          openPurchase.choiceSelections,
          targetChoiceSetId,
          targetChoiceId,
          choiceSetDiscountConfig,
        ),
      };
    }

    yield put(
      adjustOpenPurchaseChoice.actions.succeeded(
        {
          purchase: adjustedPurchase,
        },
        flowId,
      ),
    );
  } catch (e) {
    yield put(
      adjustOpenPurchaseChoice.actions.failed(
        {
          error: makeErrorSerialisable(e),
        },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(adjustOpenPurchaseChoice.events.REQUESTED, requested),
    takeEvery(adjustOpenPurchaseChoice.events.APPROVED, approved),
  ]);
}
