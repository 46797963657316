import { select } from 'redux-saga/effects';

import { OrderingSelectors } from 'polygon-ordering';

import debug from '../utils/debug';

import history from '../history';
import { SIGN_UP_ROUTE } from '../screens/SignUpScreen';
import { PROFILE_ROUTE } from '../screens/ProfileScreen';

const { getMember } = OrderingSelectors;

export default function* adjustSaleType({
  payload: { registrationCode: newRegistrationCode },
}: {
  payload: { registrationCode: string };
}) {
  debug('adjusting registration code', { registrationCode: newRegistrationCode });

  const member: Member = yield select(getMember);
  if (Boolean(member)) {
    //Registration code is not useful if the the user is not logged in. Just navigate to the profile screen.
    history.push(PROFILE_ROUTE);
  } else {
    //Navigate to sign up screen.
    //@ts-ignore
    history.push({
      pathname: SIGN_UP_ROUTE,
      state: { registrationCode: newRegistrationCode },
    });
  }
}
