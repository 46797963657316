import { call, put, takeEvery, all, select } from 'redux-saga/effects';

import * as selectOffer from '../../actionCreators/flows/selectOffer';
import * as stagePurchaseFromItem from '../../actionCreators/flows/stagePurchaseFromItem';

import { setOfferId } from '../../actionCreators/currentOrder';

import getOffers from '../../selectors/getOffers';
import getStagedPurchases from '../../selectors/getStagedPurchases';

import {
  requestAndWaitForFlow,
  createFlowApprover,
  makeErrorSerialisable,
} from '../../utils/sagas';

import { FAILURE_REASON } from '../../constants/failureReasons';
import { OFFER_TYPE } from '../../constants/offerType';

export const requested = createFlowApprover(selectOffer);

export function* approved(
  action: ReturnType<typeof selectOffer.actions.approved>,
) {
  const {
    payload: { offerId },
    meta: { flowId },
  } = action;

  let reason: selectOffer.FailureReason;
  let purchaseStaged;

  try {
    if (offerId != null) {
      const offers: Offer[] = yield select(getOffers);
      const targetOffer = offers.find(offer => offer.id === offerId);

      if (!targetOffer) {
        reason = FAILURE_REASON.OFFER_NOT_PRESENT_AND_AVAILABLE;
        throw new Error('no promo code');
      }

      if (targetOffer.type === OFFER_TYPE.PERCENTAGE_OFF_ITEM_POS_ENFORCED) {
        const targetItemId = targetOffer.targetPLUs[0];

        const stagedPurchases = (yield select(
          getStagedPurchases,
        )) as ReturnType<typeof getStagedPurchases>;
        const existing = stagedPurchases.find(
          purchase => purchase.item.id === targetItemId,
        );

        if (!existing) {
          const payloads = yield call(
            requestAndWaitForFlow,
            stagePurchaseFromItem,
            { itemId: targetItemId },
            flowId,
          );

          if (payloads.succeeded) {
            purchaseStaged = true;
          } else {
            // reason = FAILURE_REASON.COULD_NOT_STAGE_PURCHASE;
            // throw new Error('stage purchase failed');
          }
        }
      }
    }

    yield put(setOfferId(offerId));
    yield put(
      selectOffer.actions.succeeded({ purchaseStaged, offerId }, flowId),
    );
  } catch (e) {
    yield put(
      selectOffer.actions.failed(
        { error: makeErrorSerialisable(e), reason },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(selectOffer.events.REQUESTED, requested),
    takeEvery(selectOffer.events.APPROVED, approved),
  ]);
}
