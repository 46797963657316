import { ActionType, getType } from 'typesafe-actions';

import * as currentOrder from '../../actionCreators/currentOrder';

const initialState = null;

export default function (
  state: GPSCoordinates | null = initialState,
  action: ActionType<typeof currentOrder>,
): GPSCoordinates | null {
  switch (action.type) {
    case getType(currentOrder.setDeliveryCoordinates): {
      const { value } = action.payload;
      return value || initialState;
    }
    case getType(currentOrder.applyBufferData): {
      const { deliveryCoordinates } = action.payload.value;
      return deliveryCoordinates === undefined ? state : deliveryCoordinates;
    }
    default:
      return state;
  }
}
