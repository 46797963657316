import { call, put, takeEvery, all, fork } from 'redux-saga/effects';

import * as fetchLocations from '../../actionCreators/flows/fetchLocations';

import { normaliseArray } from '../../utils/misc';
import Api, { FetchParams, ApiResponse } from '../../utils/Api';
import processLocation from '../../utils/processors/processLocation';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';
import { Task } from 'redux-saga';
import processLocationShort from '../../utils/processors/processLocationShort';

export const requested = createFlowApprover(fetchLocations);

function* fetchReduced() {
  const params: FetchParams = {
    path: `/api/v1/stores/reduced`,
    method: 'GET',
  };

  try {
    const response: ApiResponse = yield call(Api.fetch, params);

    const processedLocations = response.data.map(processLocationShort);

    yield put(
      fetchLocations.actions.succeededShort({
        locationsShort: normaliseArray(processedLocations, 'id'),
      }),
    );
  } catch (e) {
    //console.error(e);
    console.warn(e);
  }
}

export function* approved(
  action: ReturnType<typeof fetchLocations.actions.approved>,
) {
  const {
    payload: { locationId, rawLocations },
    meta: { flowId },
  } = action;

  const fetchReducedTask: Task = yield fork(fetchReduced);

  try {
    let locationsToProcess: RawLocation[];

    if (rawLocations && rawLocations.length) {
      locationsToProcess = rawLocations;
    } else {
      const params: FetchParams = {
        path: locationId
          ? `/api/v1/stores/${locationId}?hide_invisible=0`
          : '/api/v1/stores?hide_invisible=0',
        method: 'GET',
      };

      const response: ApiResponse = yield call(Api.fetch, params);
      locationsToProcess = locationId ? [response.data] : response.data;
    }

    const processedLocations = locationsToProcess.map(processLocation);

    yield put(
      fetchLocations.actions.succeeded(
        {
          locations: normaliseArray(processedLocations, 'id'),
          merge: Boolean(locationId),
        },
        flowId,
      ),
    );
  } catch (e) {
    yield put(
      fetchLocations.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(fetchLocations.events.REQUESTED, requested),
    takeEvery(fetchLocations.events.APPROVED, approved),
  ]);
}
