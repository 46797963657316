import { call, put, takeEvery, all, select } from 'redux-saga/effects';

import * as fetchPaymentGatewayConfig from '../../actionCreators/flows/fetchPaymentGatewayConfig';

import { setPaymentGatewayConfig } from '../../actionCreators/currentOrder';
import { setBufferPaymentGatewayConfig } from '../../actionCreators/buffer';

import Api, { FetchParams } from '../../utils/Api';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';

import getLocationId from '../../selectors/getLocationId';
import getMember from '../../selectors/getMember';

export const requested = createFlowApprover(fetchPaymentGatewayConfig);

export function* approved(
  action: ReturnType<typeof fetchPaymentGatewayConfig.actions.approved>,
) {
  const {
    payload: { locationId: locationIdParam, bufferMode = false },
    meta: { flowId },
  } = action;

  try {
    const member = yield select(getMember);
    const currentLocationId = yield select(getLocationId);

    const locationId = locationIdParam ?? currentLocationId;

    if (!locationId) {
      throw new Error('no location id, skipping fetchPaymentGatewayConfig');
    }

    const params: FetchParams = {
      path: member
        ? '/api/v1/sale/payment_methods'
        : '/api/v1/sale/payment_methods/nonmember/',
      method: 'POST',
      body: {
        StoreID: parseInt(locationId),
      },
    };

    const response: PaymentGatewayConfig = yield call(
      Api.fetch,
      params,
      member ? 'member' : 'none',
    );

    if (bufferMode) {
      yield put(setBufferPaymentGatewayConfig(response));
    } else {
      yield put(setPaymentGatewayConfig(response));
    }

    yield put(fetchPaymentGatewayConfig.actions.succeeded({}, flowId));
  } catch (e) {
    yield put(
      fetchPaymentGatewayConfig.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(fetchPaymentGatewayConfig.events.REQUESTED, requested),
    takeEvery(fetchPaymentGatewayConfig.events.APPROVED, approved),
  ]);
}
