import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';

const SnackbarRefSaver: React.FC = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    window._enqueueSnackbar = enqueueSnackbar;
    window._closeSnackbar = closeSnackbar;
  });

  return null;
};
export default SnackbarRefSaver;
