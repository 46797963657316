import { call, put } from 'redux-saga/effects';
import i18next from 'i18next';

import { OrderingOperations, OrderingConstants, requestAndWaitForFlow } from 'polygon-ordering';

import debug from '../utils/debug';
import history from '../history';

import setCurrentModal from '../actions/setCurrentModal';

import { MESSAGE_MODAL_ID } from '../modals/MessageModal';

import { MENU_SCREEN_ROUTE } from '../screens/MenuScreen';

const { applyBuffer } = OrderingOperations;

const { SALE_TYPES } = OrderingConstants;

export default function* applyBufferedDeliveryEstimate({ payload = {} }) {
  debug('applying buffered delivery estimate', { payload });

  const result = yield call(requestAndWaitForFlow, applyBuffer, {});

  const popUpTitle = i18next.t(`preSaleMessage.title.${SALE_TYPES.DELIVERY}`);
  const popUpMessage = i18next.t(`preSaleMessage.message.${SALE_TYPES.DELIVERY}`);

  if (result.succeeded) {
    history.replace(MENU_SCREEN_ROUTE);
    yield put(setCurrentModal(null));

    if ((popUpTitle !== '') && (popUpMessage !== '')) {
      yield put(
        setCurrentModal({
          modalId: MESSAGE_MODAL_ID,
          params: {
            title: popUpTitle,
            message: popUpMessage,
          },
          clearReturnModalDetails: true,
        }),
      );
    }
  }
}
