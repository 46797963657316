import { ActionType, getType } from 'typesafe-actions';

import * as config from '../../actionCreators/config';

const initialState = null;

export default function (
  state: SDict<Cardset> | null = initialState,
  action: ActionType<typeof config>,
): SDict<Cardset> | null {
  switch (action.type) {
    case getType(config.setCardsets): {
      const { value } = action.payload;
      return value || initialState;
    }
    default:
      return state;
  }
}
