import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../app/hooks';
import { Message } from './OrderMessages';
import { OrderingSelectors } from 'polygon-ordering';
import toDollarString from '../utils/toDollarString';

const { getMaxOrderValue, getShowMaxValue } = OrderingSelectors;

const MaxOrderValueWarning = () => {
  const { t } = useTranslation();
  const maxOrderValueRule = useAppSelector(getMaxOrderValue);
  const showWarning = useAppSelector(getShowMaxValue);

  return (
    <div style={{ textAlign: 'center' }}>
      {showWarning && (
        <Message
          showAlertIcon
          message={
            maxOrderValueRule!.Message
              ? maxOrderValueRule!.Message
              : t('maxValueWarning', {
                  amount: `${toDollarString(maxOrderValueRule!.MaximumOrder, {
                    clipEmptyDecimals: true,
                  })}`,
                })
          }
        />
      )}
    </div>
  );
};

export default MaxOrderValueWarning;
