import { createSelector } from 'reselect';

import { centsToDollarString } from '../utils/misc';

import getGooglePayItems from './getGooglePayItems';
import { getSelectedPaymentMethods } from '.';
import { getItemPaymentTotal } from '../utils/misc';
import { getStripeCurrency } from '.';

export default createSelector(
  [getGooglePayItems, getSelectedPaymentMethods, getStripeCurrency],
  (items, getSelectedPaymentMethods: any[], currency_code) => ({
    total_price: centsToDollarString(
      getItemPaymentTotal(getSelectedPaymentMethods, 'google-pay'),
    ),
    currency_code,
    shipping_address_required: false,
    billing_address_required: false,
    line_items: items,
  }),
);
