import { createSelector } from 'reselect';

import googlePayItemFromPurchase from '../utils/ordering/googlePayItemFromPurchase';
import { getStripeCurrency } from './config';

import getStagedPurchases from './getStagedPurchases';

export default createSelector(
  [getStagedPurchases, getStripeCurrency],
  (purchases, currency_code) =>
    purchases.map(purchase =>
      googlePayItemFromPurchase(purchase, currency_code),
    ),
);
