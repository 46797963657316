import { createCustomAction } from 'typesafe-actions';

import {
  standardEmptyACC,
  standardFlowACC,
  getStandardFailedWithReasonACC,
} from '../../utils/sagas';

import { FAILURE_REASON } from '../../constants/failureReasons';

const REQUESTED = '$FETCH_POS_POINTS/REQUESTED';
const BLOCKED = '$FETCH_POS_POINTS/BLOCKED';
const APPROVED = '$FETCH_POS_POINTS/APPROVED';
const SUCCEEDED = '$FETCH_POS_POINTS/SUCCEEDED';
const FAILED = '$FETCH_POS_POINTS/FAILED';

const request = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      barcode: string;
      locationId: string;
    }>(type);
};

const succeed = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      member?: Member;
      offer?: Offer;
      giftCard?: _GiftCard;
      itemDetails?: ItemLookupResult;
    }>(type);
};

export const events = {
  REQUESTED,
  BLOCKED,
  APPROVED,
  SUCCEEDED,
  FAILED,
};

export type FailureReason =
  | undefined
  | FAILURE_REASON.FETCH_FAILED
  | FAILURE_REASON.EXPIRED_GIFT_CARD
  | FAILURE_REASON.INACTIVE_GIFT_CARD
  | FAILURE_REASON.EXPIRED_OFFER
  | FAILURE_REASON.INACTIVE_OFFER
  | FAILURE_REASON.EXPIRED_MEMBER
  | FAILURE_REASON.INACTIVE_MEMBER;

export const actions = {
  requested: createCustomAction(REQUESTED, request),
  approved: createCustomAction(APPROVED, request),
  blocked: createCustomAction(BLOCKED, standardEmptyACC),
  succeeded: createCustomAction(SUCCEEDED, succeed),
  failed: createCustomAction(
    FAILED,
    getStandardFailedWithReasonACC<FailureReason>(),
  ),
};

export default actions.requested;
