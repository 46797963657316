import { ActionType, getType } from 'typesafe-actions';

import * as buffer from '../../actionCreators/buffer';

const initialState = null;

export default function (
  state: string | null = initialState,
  action: ActionType<typeof buffer>,
): string | null {
  switch (action.type) {
    case getType(buffer.clearBuffer): {
      return initialState;
    }
    case getType(buffer.setBufferDeliveryTime): {
      const { value } = action.payload;
      return value || initialState;
    }
    default:
      return state;
  }
}
