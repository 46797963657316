import { createSelector } from 'reselect';

import enhancePurchase from '../utils/ordering/enhancePurchase';
import stagedPurchasesWithTotals from '../utils/ordering/stagedPurchasesWithTotals';

import getItems, { $getItems } from './getItems';
import getUnenhancedStagedPurchases, {
  $getUnenhancedStagedPurchases,
} from './getUnenhancedStagedPurchases';
import getSelectedOffer from './getSelectedOffer';
import {
  getCouponOrderingMethod,
  getFreeChoiceOrderingMethod,
  getChoiceSetDiscountConfig,
} from './config';

const get = (
  orderItems: Items | undefined,
  stagedPurchases: _Purchase[],
  couponOrderingMethod: CouponOrderingMethod | null,
  freeChoiceOrderingMethod: FreeChoiceOrderingMethod | null,
  selectedOffer: Offer | undefined,
  choiceSetDiscountConfig: ChoiceSetQuantityDiscountConfig | undefined,
): PurchaseWithTotals[] => {
  if (!orderItems || !couponOrderingMethod || !freeChoiceOrderingMethod) {
    return [];
  }

  const enhanced = stagedPurchases
    .map(purchase =>
      enhancePurchase(purchase, orderItems, choiceSetDiscountConfig),
    )
    .filter(purchase => Boolean(purchase)) as Purchase[];

  // we use "as" because ts isn't picking up that filter is removing undefined elements
  // https://github.com/microsoft/TypeScript/issues/16069

  return stagedPurchasesWithTotals(
    enhanced,
    freeChoiceOrderingMethod,
    selectedOffer,
    couponOrderingMethod,
  );
};

export default createSelector(
  [
    getItems,
    getUnenhancedStagedPurchases,
    getCouponOrderingMethod,
    getFreeChoiceOrderingMethod,
    getSelectedOffer,
    getChoiceSetDiscountConfig,
  ],
  get,
);

export const $getStagedPurchases = createSelector(
  [
    $getItems,
    $getUnenhancedStagedPurchases,
    getCouponOrderingMethod,
    getFreeChoiceOrderingMethod,
    getSelectedOffer,
    getChoiceSetDiscountConfig,
  ],
  get,
);
