import { createSelector } from 'reselect';

import calculateTotalsForPurchase from '../utils/ordering/calculateTotalsForPurchase';
import enhancePurchase from '../utils/ordering/enhancePurchase';

import getUnenhancedOpenPurchase from './getUnenhancedOpenPurchase';
import getItems from './getItems';
import {
  getFreeChoiceOrderingMethod,
  getChoiceSetDiscountConfig,
} from './config';

export default createSelector(
  [
    getItems,
    getUnenhancedOpenPurchase,
    getFreeChoiceOrderingMethod,
    getChoiceSetDiscountConfig,
  ],
  (
    orderItems,
    openPurchase,
    freeChoiceOrderingMethod,
    choiceSetDiscountConfig,
  ): PurchaseWithTotals | undefined => {
    if (!orderItems || !openPurchase || !freeChoiceOrderingMethod) {
      return undefined;
    }

    const enhanced = enhancePurchase(
      openPurchase,
      orderItems,
      choiceSetDiscountConfig,
    );

    if (!enhanced) {
      return undefined;
    }

    return {
      ...enhanced,
      ...calculateTotalsForPurchase(enhanced, freeChoiceOrderingMethod),
    };
  },
);
