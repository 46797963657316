export default function createIngredientFromChoice(
  choice: ChoiceWithQuantity,
): CartIngredient {
  return {
    Price: choice.baseMoneyPrice,
    PLUCode: parseInt(choice.id),
    Qty: choice.quantity,
    Ingredients: [],
  };
}
