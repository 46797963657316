import { ActionType, getType } from 'typesafe-actions';

import * as config from '../../actionCreators/config';

const initialState = null;

export default function (
  state: string[] | null = initialState,
  action: ActionType<typeof config>,
): string[] | null {
  switch (action.type) {
    case getType(config.setThresholdOrderingProviders): {
      const { value } = action.payload;

      return value || initialState;
    }
    default:
      return state;
  }
}
