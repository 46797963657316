import { ActionType, getType } from 'typesafe-actions';

import * as currentOrder from '../../actionCreators/currentOrder';

const initialState = null;

export default function (
  state: CreditCardPaymentInformation | null = initialState,
  action: ActionType<typeof currentOrder>,
): CreditCardPaymentInformation | null {
  switch (action.type) {
    case getType(currentOrder.resetOrder): {
      return initialState;
    }
    case getType(currentOrder.setCreditCardPaymentInformation): {
      const { value } = action.payload;
      return value || initialState;
    }
    case getType(currentOrder.applyBufferData): {
      const { creditCardPaymentInformation } = action.payload.value;
      return creditCardPaymentInformation === undefined
        ? state
        : creditCardPaymentInformation;
    }
    default:
      return state;
  }
}
