import { createSelector } from '@reduxjs/toolkit';
import getSaleType from './getSaleType';
import { SALE_TYPE } from '../constants/saleType';

export default createSelector(
  [
    (state: EntireFrontendState) => state.ordering.config.futureOrderingConfig,
    getSaleType,
  ],
  (futureOrderingConfig: FutureOrderingConfig, saleType) => {
    const {
      enableFutureOrdering,
      futureOrderingLimitCatering,
      futureOrderingLimit,
    } = futureOrderingConfig;
    if (!enableFutureOrdering) return 0;
    switch (saleType) {
      case SALE_TYPE.PICKUP:
        return futureOrderingLimit || 0;
      case SALE_TYPE.CATERING:
        return futureOrderingLimitCatering || futureOrderingLimit;
      default:
        return 0;
    }
  },
);
