import { createSelector } from 'reselect';

import getFilteredRootCategory from './getFilteredRootCategory';
import { RootState } from 'store';

const getCurrentCategoryId = (state: RootState) => state.currentCategoryId;

export default createSelector(
  [getCurrentCategoryId, getFilteredRootCategory],
  (currentCategoryId, rootCategory) => {
    if (currentCategoryId == null) {
      return null;
    }

    const categories = rootCategory?.subCategories || [];

    return categories.some(category => category.id === currentCategoryId)
      ? currentCategoryId
      : categories[0]
      ? categories[0].id
      : null;
  },
);
