import lodash from 'lodash';
import React from 'react';

export const TEXT_PROPERTIES: Array<keyof React.CSSProperties> = [
  'color',
  'fontFamily',
  'fontSize',
  'fontWeight',
  'letterSpacing',
  'opacity',
  'textDecoration',
  'textTransform',
];

export const CONTAINER_PROPERTIES: Array<keyof React.CSSProperties> = [
  'backgroundColor',
  'border',
  'borderRadius',
  'boxShadow',
  'margin',
  'opacity',
  'padding',
];

export const SELECT_PROPERTIES: Array<keyof React.CSSProperties> = [
  'color',
  'fontFamily',
  'fontSize',
  'fontWeight',
  'letterSpacing',
  'opacity',
  'borderRadius',
  'backgroundColor',
  'border',
  'margin',
  'opacity',
  'padding',
];

export const p = (
  theme: Themes,
  key: string,
  properties: ThemeProperties,
  { enableThemeKeyHints }: { enableThemeKeyHints: boolean },
) => {
  if (!key) {
    console.error('missing theme key');
  }

  if (!theme) {
    console.error('missing theme object');
  }

  if (!properties || properties.length === 0) {
    console.error('missing theme properties');
  }

  const result: React.CSSProperties = enableThemeKeyHints
    ? {
        // We smuggle the themeKey into the devtools using this CSS property.
        // All our other attempts to communicate custom info via inline styles get filtered out.
        // This break animations, so it is only available via a button in the localhost/DEV version.
        animationName: `themeKey_${key}`,
      }
    : {};

  if (!theme || !properties || !key) {
    return result;
  }

  if (Array.isArray(properties)) {
    properties.forEach(property => {
      const value: number | string = lodash.get(theme, `${property}.${key}`);
      if (value != null) {
        //@ts-ignore
        result[property] = value;
      }
    });
  }

  return result;
};

export default p;
