import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiArrowLeftCircleFill, RiCloseCircleFill } from 'react-icons/ri';
import combineStyles from '../utils/combineStyles';

import getThemeLookup from '../selectors/getThemeLookup';

import ModalFloatingButton from './ModalFloatingButton';
import Text from './Text';
import { useAppSelector } from '../app/hooks';

interface IProps {
  title?: string;
  deviceTypeMobile?: boolean;
  close: () => void;
  goBack: () => void;
  previousModalId: string | null;
  closeButtonLabel?: string;
  showFloatingTitle?: boolean;
  locked?: boolean;
}

const ModalFloatingHeader: React.FC<IProps> = ({
  showFloatingTitle,
  title,
  previousModalId,
  goBack,
  close,
  closeButtonLabel,
  locked,
  deviceTypeMobile,
}) => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const singleChildFloatingSection = !showFloatingTitle && !previousModalId;

  return (
    <div
      style={combineStyles(
        styles.floatingSection,
        deviceTypeMobile ? styles.mobileVerticalPadding : styles.desktopVerticalPadding,
        singleChildFloatingSection
          ? styles.singleChildFloatingSection
          : styles.multipleChildFloatingSection,
      )}
    >
      {singleChildFloatingSection ? null : showFloatingTitle ? (
        <ModalFloatingButton
          label={t('button.modalBack')}
          onClick={goBack}
          IconComponent={RiArrowLeftCircleFill}
          disabled={locked || previousModalId == null}
          deviceTypeMobile={deviceTypeMobile}
        />
      ) : (
        <div />
      )}

      {singleChildFloatingSection ? null : (
        <Text themeKey="modalFloatingTitle" style={styles.floatingTitle}>
          {showFloatingTitle ? title : ''}
        </Text>
      )}

      <ModalFloatingButton
        label={closeButtonLabel}
        onClick={close}
        IconComponent={RiCloseCircleFill}
        disabled={locked}
        deviceTypeMobile={deviceTypeMobile}
      />
    </div>
  );
};

const styles: Styles = {
  floatingSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20,
    minHeight: 'min-content', // prevent some browsers from squashing it
  },
  mobileVerticalPadding: {
    paddingTop: 0,
    paddingBottom: 7,
  },
  desktopVerticalPadding: {
    paddingTop: 10,
    paddingBottom: 20,
  },
  singleChildFloatingSection: {
    justifyContent: 'center',
  },
  multipleChildFloatingSection: {
    justifyContent: 'space-between',
  },

  floatingTitle: {
    textAlign: 'center',
    marginLeft: 20,
    marginRight: 20,
  },
};

export default ModalFloatingHeader;
