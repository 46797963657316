import { ActionType, getType } from 'typesafe-actions';

import * as currentOrder from '../../actionCreators/currentOrder';

const initialState = null;

export default function (
  state: TokenisedAddress | null = initialState,
  action: ActionType<typeof currentOrder>,
): TokenisedAddress | null {
  switch (action.type) {
    case getType(currentOrder.setDeliveryAddress): {
      const { value } = action.payload;
      return value || initialState;
    }
    case getType(currentOrder.applyBufferData): {
      const { deliveryAddress } = action.payload.value;
      return deliveryAddress === undefined ? state : deliveryAddress;
    }
    default:
      return state;
  }
}
