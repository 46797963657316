import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiMoneyDollarCircleLine, RiFundsLine } from 'react-icons/ri';
import { OrderingSelectors, OrderingHooks } from 'polygon-ordering';
import { useAppSelector } from '../app/hooks';
import Text from './Text';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import { Row, Col } from 'reactstrap';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import getThemeLookup from '../selectors/getThemeLookup';

const { useFormattedCurrency } = OrderingHooks;

const { getMember, getEnabledPaymentMethods, getCurrencySymbol } = OrderingSelectors;

const Balance = ({
  balance: { amount, label },
  loyaltyView,
  loyaltyPointsRing,
}: {
  balance: Balance;
  loyaltyView?: boolean;
  loyaltyPointsRing?: boolean;
}) => {
  return loyaltyView ? (
    <div>
      <Text themeKey={loyaltyPointsRing ? 'loyaltyRingView' : 'loyaltyBalanceDefault'}>
        {amount}
      </Text>
      <br />
      <Text themeKey="loyaltyBalanceText">{label}</Text>
    </div>
  ) : (
    <div>
      <Text themeKey={'balanceLabelMemberModal'} style={styles.balanceLabel}>
        {label}
      </Text>
      <Text themeKey={'balanceAmountMemberModal'} style={styles.balanceAmount}>
        {amount}
      </Text>
    </div>
  );
};

const MemberBalances: React.FC<{ loyaltyView?: boolean; loyaltyPointsRing?: boolean }> = ({
  loyaltyView,
  loyaltyPointsRing,
}) => {
  const { t } = useTranslation();
  const member = useAppSelector(getMember);
  const displayPointsAsWholeNumber = useAppSelector(
    state => state.config.displayPointsAsWholeNumber,
  );
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const creditTreshold = useAppSelector(state => state.config.creditLoyaltyThreshold);
  const p = useAppSelector(getThemeLookup);
  const profile = useAppSelector(state => state.profile);
  const loyaltyTiers = useAppSelector(state => state.loyaltyTiers);
  const tier = loyaltyTiers?.filter(tier => tier.tierName === profile?.loyaltyTierName)[0];
  const creditLoyaltyEnabled = useAppSelector(state => state.config.creditLoyaltyEnabled);
  const nextTier = loyaltyTiers?.filter(loyaltyTier => loyaltyTier.id === tier?.nextTier)[0];
  const thresholdRemainder = (tier?.tierThreshold || 0) - (profile?.dollarsUntilNextTier || 0);
  const enableTieredLoyalty = useAppSelector(state => state.config.enableTieredLoyalty);
  const enabledPaymentMethods = useAppSelector(getEnabledPaymentMethods);
  const moneyBalance = useFormattedCurrency(member!.moneyBalance, { hideZeroCents: true });
  const currency = useAppSelector(getCurrencySymbol);
  const balances: Balance[] = [];

  if (
    enabledPaymentMethods.includes(PAYMENT_METHOD.MEMBER_POINTS) ||
    enabledPaymentMethods.includes(PAYMENT_METHOD.MEMBER_REWARDS) ||
      loyaltyPointsRing
  ) {
    const points = displayPointsAsWholeNumber
      ? Math.floor(member!.pointsBalance)
      : member!.pointsBalance;

    balances.push({
      amount: points || 0,
      label: t('memberPoints'),
      IconComponent: RiFundsLine,
    });
  }

  if (enabledPaymentMethods.includes(PAYMENT_METHOD.MEMBER_MONEY)) {
    balances.push({
      amount: moneyBalance,
      label: t('memberMoney'),
      IconComponent: RiMoneyDollarCircleLine,
    });
  }

  if (!balances.length) {
    return null;
  }

  const awayPoints = (balances[0].amount as number) % creditTreshold!;
  const remainingPoints = Math.abs(awayPoints - creditTreshold!);

  const progressBarValue = ((creditTreshold! - remainingPoints) / creditTreshold!) * 100;

  return loyaltyView ? (
    <div className="text-center">
      <Row>
        {loyaltyPointsRing ? (
          <Col className=" mx-5 mb-3">
            <div className={!deviceTypeMobile ? 'px-2' : ''}>
              <CircularProgressbarWithChildren
                value={progressBarValue}
                strokeWidth={4}
                styles={buildStyles({
                  trailColor: 'transparent',
                  pathColor: tier?.tierColour || p('loyaltyRing', ['color']).color,
                })}
              >
                <Text value="You Have" />
                <Balance loyaltyView loyaltyPointsRing={loyaltyPointsRing} balance={balances[0]} />
              </CircularProgressbarWithChildren>
              {creditLoyaltyEnabled && (
                <div className="mt-3">
                  <Text value={t('loyaltyRewardsPointsAway', { currency, remainingPoints })} />{' '}
                  <br />
                </div>
              )}

              {enableTieredLoyalty && tier && !creditLoyaltyEnabled && (
                <div className="text-center" style={{ backgroundColor: 'white' }}>
                  <Text style={{ fontSize: 14 }}>
                    {t('tieredLoyaltyPointsToThreshold', {
                      points: thresholdRemainder,
                      tierName: nextTier?.tierName,
                      currency,
                    })}
                  </Text>
                </div>
              )}
            </div>
          </Col>
        ) : (
          <Col>
            <Balance loyaltyView balance={balances[0]} />
          </Col>
        )}
        {!loyaltyPointsRing && Boolean(balances[1]) && (
          <Col>
            <Balance loyaltyView balance={balances[1]} />
          </Col>
        )}
      </Row>
    </div>
  ) : (
    <div style={{ paddingLeft: deviceTypeMobile ? 40 : 20, paddingTop: 10, paddingBottom: 10 }}>
      <Balance loyaltyPointsRing={loyaltyPointsRing} balance={balances[0]} />
      {Boolean(balances[1]) && <Balance balance={balances[1]} />}
    </div>
  );
};

const styles: Styles = {
  balances: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'center',
  },

  balance: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  balanceAmount: {
    marginTop: 5,
    fontSize: 14,
    padding: '0px 5px ',
  },
  balanceLabel: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
};

export default MemberBalances;
