import { REWARDS_SCREEN_ROUTE } from '../screens/RewardsScreen';
import { REPLACE_CARD_ROUTE } from '../screens/ReplaceCardScreen';
import { CLAIM_MISSING_POINTS_ROUTE } from '../screens/ClaimMissingPointsScreen';
import { OFFERS_ROUTE } from '../screens/OffersScreen';
import { PROFILE_ROUTE } from '../screens/ProfileScreen';
import { MEMBER_MONEY_ROUTE } from '../screens/MemberMoneyScreen';
import { PAST_ORDERS_SCREEN_ROUTE } from '../screens/PastOrdersScreen';
import { FAVOURITES_SCREEN_ROUTE } from '../screens/FavouritesScreen';
import { TRANSACTIONS_SCREEN } from '../screens/TransactionsScreen';
import { UPDATE_PASSWORD_ROUTE } from '../screens/UpdatePasswordScreen';

export default function getDisabledLoyaltyRoutes(config: any): string[] {
  const disabledRoutes: string[] = [];

  if (config.disableClaimMissingPointsScreen) {
    disabledRoutes.push(CLAIM_MISSING_POINTS_ROUTE);
  }
  if (config.disableOffersScreen) {
    disabledRoutes.push(OFFERS_ROUTE);
  }

  if (config.disableTransactions) {
    disabledRoutes.push(TRANSACTIONS_SCREEN);
  }

  if (config.disableMemberMoneyScreen) {
    disabledRoutes.push(MEMBER_MONEY_ROUTE);
  }

  if (config.disablePastOrdersScreen) {
    disabledRoutes.push(PAST_ORDERS_SCREEN_ROUTE);
  }

  if (config.disableProfileScreen) {
    disabledRoutes.push(PROFILE_ROUTE);
  }

  if (config.disableUpdatePasswordScreen) {
    disabledRoutes.push(UPDATE_PASSWORD_ROUTE);
  }

  if (config.disableReplaceCardScreen) {
    disabledRoutes.push(REPLACE_CARD_ROUTE);
  }
  if (config.disableRewardsScreen) {
    disabledRoutes.push(REWARDS_SCREEN_ROUTE);
  }

  if (config.disableFavouritesScreen) {
    disabledRoutes.push(FAVOURITES_SCREEN_ROUTE);
  }

  return disabledRoutes;
}
