import React from 'react';
import { RiAddCircleFill, RiIndeterminateCircleFill } from 'react-icons/ri';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import Text from './Text';
import TouchableOpacity from './TouchableOpacity';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { OrderingOperations } from 'polygon-ordering';
const { adjustOpenPurchaseQuantity } = OrderingOperations;

interface IProps {
  quantity: number;
  containerStyle?: React.CSSProperties;
  maxQuantity?: number;
}

const QuantityControl: React.FC<IProps> = ({ quantity, containerStyle, maxQuantity }) => {
  const p = useAppSelector(getThemeLookup);
  const dispatch = useAppDispatch();

  const MIN_QUANTITY = 1;
  const MAX_QUANTITY = maxQuantity !== undefined ? maxQuantity : 99;

  const iconStyle = p('quantityPickerIcon', ['color', 'fontSize']);

  const internalGap = (iconStyle.fontSize as number) / 2;

  return (
    <div style={combineStyles(styles.main, containerStyle)}>
      <TouchableOpacity
        style={styles.touchable}
        onClick={() => dispatch(adjustOpenPurchaseQuantity(-1))}
        disabled={quantity <= MIN_QUANTITY}
        disabledStyle={styles.disabled}
        ariaLabel="decrease quantity"
      >
        <RiIndeterminateCircleFill style={iconStyle} />
      </TouchableOpacity>

      <Text
        themeKey="quantityPicker"
        style={combineStyles(styles.quantity, {
          marginRight: internalGap,
          marginLeft: internalGap,
        })}
      >
        {quantity}
      </Text>

      <TouchableOpacity
        style={styles.touchable}
        onClick={() => dispatch(adjustOpenPurchaseQuantity(1))}
        disabled={quantity >= MAX_QUANTITY}
        disabledStyle={styles.disabled}
        ariaLabel="increase quantity"
      >
        <RiAddCircleFill style={iconStyle} />
      </TouchableOpacity>
    </div>
  );
};

const styles: Styles = {
  main: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  touchable: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabled: {
    opacity: 0.3,
    backgroundColor: 'transparent',
  },
  quantity: {
    textAlign: 'center',
  },
};

export default QuantityControl;
