import { ActionType, getType } from 'typesafe-actions';

import * as currentOrder from '../../actionCreators/currentOrder';
import { actions as validateOrder } from '../../actionCreators/flows/validateOrder';

const initialState = false;

export default function (
  state: boolean = initialState,
  action: ActionType<typeof currentOrder | typeof validateOrder>,
): boolean {
  switch (action.type) {
    case getType(currentOrder.resetOrder): {
      return initialState;
    }
    case getType(validateOrder.requested): {
      return true;
    }
    case getType(validateOrder.failed):
    case getType(validateOrder.blocked):
    case getType(validateOrder.succeeded): {
      return false;
    }
    default:
      return state;
  }
}
