import { put, select } from 'redux-saga/effects';

import { OrderingOperations, OrderingSelectors } from 'polygon-ordering';

import debug from '../utils/debug';

const { updateMenu, clearBuffer } = OrderingOperations;

const { getRootCategory } = OrderingSelectors;

export default function* updateMenuSaga() {
  try {
    const menuPresent = Boolean(yield select(getRootCategory));

    debug('updateMenu', { menuPresent });

    if (!menuPresent) {
      return;
    }

    yield yield put(clearBuffer({ preserveReservationId: false }));

    yield yield put(
      updateMenu({
        isSubFlow: false,
        autoApply: true,
      }),
    );
  } catch (e) {
    debug(false, 'something went wrong updating the menu', { e });
  }
}
