import { ActionType, getType } from 'typesafe-actions';

import * as currentOrder from '../../actionCreators/currentOrder';
import { actions as applyBarcode } from '../../actionCreators/flows/applyBarcode';

const initialState = false;

export default function (
  state: boolean = initialState,
  action: ActionType<typeof currentOrder | typeof applyBarcode>,
): boolean {
  switch (action.type) {
    case getType(currentOrder.resetOrder): {
      return initialState;
    }
    case getType(applyBarcode.requested): {
      return true;
    }
    case getType(applyBarcode.failed):
    case getType(applyBarcode.blocked):
    case getType(applyBarcode.succeeded): {
      return false;
    }
    default:
      return state;
  }
}
