import { select, put, call } from 'redux-saga/effects';
import moment from 'moment';

import { OrderingOperations } from 'polygon-ordering';

import { setOrderDate } from '../slices/orderDate';

import { persistor } from '../store';

export default function* checkDate() {
  const orderDate = yield select(state => state.orderDate);

  if (orderDate == null) {
    return;
  }

  const today = moment().dayOfYear();

  if (today !== orderDate) {
    yield put(setOrderDate(null));

    yield call(persistor.flush);

    yield put(OrderingOperations.startNewOrder({ preserveMember: true }));
  }
}
