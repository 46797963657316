import { call, put, select } from 'redux-saga/effects';
import lodash from 'lodash';
import i18next from 'i18next';

import {
  OrderingConstants,
  OrderingOperations,
  OrderingSelectors,
  requestAndWaitForFlow,
} from 'polygon-ordering';

import debug from '../utils/debug';
import { enqueueSuccessSnackbar, enqueueErrorSnackbar } from '../utils/snackbar';

import { setCheckoutInProgress } from '../slices/checkoutInProgress';
import { setValidationInProgress } from '../slices/validationInProgress';

const { updateKeyOrderProperty, validateOrder } = OrderingOperations;
const { getSaleType, getMember } = OrderingSelectors;
const { SALE_TYPES, FAILURE_REASON } = OrderingConstants;

function alertForFailedValidation(payload) {
  let title;
  let subtitle;

  const getFailureReasonString = reason => i18next.t(`failureReason.${reason}`);

  switch (lodash.get(payload, 'reason')) {
    case FAILURE_REASON.FETCH_FAILED: {
      title = getFailureReasonString(FAILURE_REASON.FETCH_FAILED);
      break;
    }
    case FAILURE_REASON.LOCATION_OFFLINE: {
      title = getFailureReasonString(FAILURE_REASON.LOCATION_OFFLINE);
      break;
    }
    case FAILURE_REASON.LOCATION_CLOSED: {
      title = getFailureReasonString(FAILURE_REASON.LOCATION_CLOSED);
      break;
    }
    case FAILURE_REASON.UNKNOWN:
    default:
      title = getFailureReasonString(FAILURE_REASON.UNKNOWN);
      subtitle = lodash.get(payload, 'errorText');
  }

  enqueueErrorSnackbar(title + (subtitle ? '\n' + subtitle : ''));
}

export default function* validateWithPaymentRequest({ payload = {} }) {
  debug('validateWithPaymentRequest', { payload });

  function customerConfirm() {
    let value = window.confirm(
      'Your estimated delivery time has been updated. Would you like to continue?',
    );
    if (value === true) {
      return true;
    } else {
      return false;
    }
  }

  yield put(setCheckoutInProgress(true));
  yield put(setValidationInProgress(true));

  try {
    const memberPresent = Boolean(yield select(getMember));

    const firstValidationResult = yield call(requestAndWaitForFlow, validateOrder, {
      authenticationMethod: memberPresent ? 'member' : 'none',
    });

    const saleType = yield select(getSaleType);

    // TODO: enhance this to try fetching menu/order fees+surcharges for all sale types if validation fails
    // (must stop checkout and alert user if order total increases)
    if (!lodash.get(firstValidationResult, 'success.valid')) {
      if (saleType !== SALE_TYPES.DELIVERY) {
        alertForFailedValidation(firstValidationResult.success);
      } else {
        yield call(requestAndWaitForFlow, updateKeyOrderProperty, {
          autoApply: true,
          updateDeliveryEstimate: true,
        });

        const secondValidationResult = yield call(requestAndWaitForFlow, validateOrder, {
          authenticationMethod: memberPresent ? 'member' : 'none',
        });

        if (!lodash.get(secondValidationResult, 'success.valid')) {
          alertForFailedValidation(secondValidationResult.success);
        } else {
          customerConfirm();
          enqueueSuccessSnackbar('Delivery time updated to reflect cart total');
        }
      }
    }

    yield put(setValidationInProgress(false));
  } catch (e) {
    debug(false, 'there was a problem during validation', { e });
  }

  yield put(setCheckoutInProgress(false));
}
