import { ActionType, getType } from 'typesafe-actions';

import * as config from '../../actionCreators/config';

const initialState: AttemptsConfig = {
  submitSale: 1,
  validateOrder: 1,
};

export default function (
  state: AttemptsConfig = initialState,
  action: ActionType<typeof config>,
): AttemptsConfig {
  switch (action.type) {
    case getType(config.setAttemptsConfig): {
      const { value } = action.payload;
      return { ...state, ...value };
    }
    default:
      return state;
  }
}
