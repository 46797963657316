import { call, put, takeEvery, all } from 'redux-saga/effects';

import * as fetchGiftCard from '../../actionCreators/flows/fetchGiftCard';

import Api, { FetchParams, ApiResponse } from '../../utils/Api';
import processGiftCard from '../../utils/processors/processGiftCard';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';

export const requested = createFlowApprover(fetchGiftCard);

export function* approved(
  action: ReturnType<typeof fetchGiftCard.actions.approved>,
) {
  const {
    payload: { giftCard },
    meta: { flowId },
  } = action;

  try {
    const params: FetchParams = {
      path: '/api/v1/members/giftcard_balance',
      method: 'POST',
      body: {
        VerifyPIN: giftCard.pin,
        MemberNo: giftCard.number,
      },
    };

    const response: ApiResponse = yield call(Api.fetch, params);

    const processedGiftCard = processGiftCard(response.data);

    yield put(
      fetchGiftCard.actions.succeeded({ giftCard: processedGiftCard }, flowId),
    );
  } catch (e) {
    yield put(
      fetchGiftCard.actions.failed({ error: makeErrorSerialisable(e) }, flowId),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(fetchGiftCard.events.REQUESTED, requested),
    takeEvery(fetchGiftCard.events.APPROVED, approved),
  ]);
}
