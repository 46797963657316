import React, { useState, useEffect, useRef } from 'react';
import lodash from 'lodash';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { RiAddLine, RiPencilFill, RiArrowDownSLine } from 'react-icons/ri';

import {
  OrderingSelectors,
  OrderingOperations,
  OrderingConstants,
  OrderingHooks,
} from 'polygon-ordering';

import combineStyles from '../utils/combineStyles';
import { CONTAINER_PROPERTIES } from '../utils/theme';

import getThemeLookup from '../selectors/getThemeLookup';
import setCurrentModalAction from '../actions/setCurrentModal';

import Modal from '../components/Modal';
import Text from '../components/Text';
import TouchableOpacity from '../components/TouchableOpacity';
import RedcatImage from '../components/RedcatImage';
import QuantityControl from '../components/QuantityControl';
import StandardButton from '../components/StandardButton';
import ChoiceSetBanner from '../components/ChoiceSetBanner';
import ChoiceSet from '../components/ChoiceSet';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { addToCart } from '../utils/analytics';
import calculateStockBalanceData from '../utils/calculateStockBalanceData';
import calcStagedPurchasesInCart from '../utils/calcStagedPurchasesInCart';
import calcMinBalanceStaged from '../utils/calcMinBalanceStaged';
import getFilteredRootCategory from '../selectors/getFilteredRootCategory';

const { getOpenPurchase, getOpenPurchasePreviouslyStaged, getHideNegativeIngredients, getBrands } =
  OrderingSelectors;
const { OPEN_PURCHASE_LOWEST_BALANCE_DEFAULT } = OrderingConstants;
const { useFormattedCurrency } = OrderingHooks;

const {
  stageOpenPurchase,
  changeOpenPurchaseItem,
  adjustOpenPurchaseChoice,
  setOpenPurchaseLowestBalance,
} = OrderingOperations;

export const PURCHASE_EDITOR_MODAL_ID = 'PURCHASE_EDITOR_MODAL_ID';

const BOTTOM_PADDING = 170;

const PurchaseEditorModal = () => {
  const [state, setState] = useState<{
    showIndicator: boolean;
    collapsedChoiceSets: { [key: string]: boolean };
  }>({
    showIndicator: false,
    collapsedChoiceSets: {},
  });
  const psbContainerRef = useRef<any>(null);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const p = useAppSelector(getThemeLookup);
  const openPurchase = useAppSelector(getOpenPurchase);
  const previouslyStaged = useAppSelector(getOpenPurchasePreviouslyStaged);
  const hideNegativeIngredients = useAppSelector(getHideNegativeIngredients);
  const collapsableChoiceSetsEnabled = useAppSelector(
    state => state.config.collapsableChoiceSetsEnabled,
  );
  const collapseOptionalChoiceSetsByDefault = useAppSelector(
    state => state.config.collapseOptionalChoiceSetsByDefault,
  );
  const brands = useAppSelector(getBrands);
  const rootCategory = useAppSelector(getFilteredRootCategory);

  useEffect(() => {
    assess();
    dispatch(setOpenPurchaseLowestBalance(OPEN_PURCHASE_LOWEST_BALANCE_DEFAULT));
  });

  useEffect(() => {
    collapseOptionalChoiceSets();
  }, [collapsableChoiceSetsEnabled, collapseOptionalChoiceSetsByDefault]);

  const assess = () => {
    const { showIndicator } = state;

    if (!psbContainerRef.current) {
      return;
    }

    const reachedEnd =
      psbContainerRef.current.scrollTop >=
      psbContainerRef.current.scrollHeight -
        psbContainerRef.current.offsetHeight -
        BOTTOM_PADDING -
        50;

    if (!reachedEnd && !showIndicator) {
      setState({ ...state, showIndicator: true });
    } else if (reachedEnd && showIndicator) {
      setState({ ...state, showIndicator: false });
    }
  };

  const toggleCollapsed = (id: string) => {
    const { collapsedChoiceSets } = state;

    setState({
      ...state,
      collapsedChoiceSets: {
        ...collapsedChoiceSets,
        [id]: !collapsedChoiceSets[id],
      },
    });
  };

  const collapseOptionalChoiceSets = () => {
    const choiceSets = lodash.get(openPurchase, 'choiceSets', []);
    const collapsedChoiceSets: { [key: string]: boolean } = {};

    choiceSets.forEach((choiceSet: ValidatedChoiceSet) => {
      if (choiceSet.valid) {
        collapsedChoiceSets[choiceSet.id] = collapsableChoiceSetsEnabled
          ? collapseOptionalChoiceSetsByDefault
          : false;
      }
    });

    setState({ ...state, collapsedChoiceSets });
  };

  const { showIndicator, collapsedChoiceSets } = state;
  const price = useFormattedCurrency(Number(openPurchase?.moneyPrice));

  if (!openPurchase) {
    dispatch(setCurrentModalAction(null));
    return null;
  }

  const {
    item,
    sizes,
    multiSized,
    choiceSets = [],
    valid,
    quantity,
    choicesWithQuantity,
    moneyPrice,
    brandId,
  } = openPurchase;

  const stockBalanceData = calculateStockBalanceData(item.id);
  const quantityMap = calcStagedPurchasesInCart(openPurchase.id);
  const minBalanceStaged = calcMinBalanceStaged(choicesWithQuantity, quantityMap);

  const SIZE_CHOICE_SET = {
    id: 'SIZE_CHOICE_SET',
    name: t('title.sizeChoice'),
    free: 0,
    max: 1,
    min: 1,
    valid: true,
    quantity: 1,
    choices: (sizes || []).map((size: { [key: string]: any }) => ({
      ...size,
      quantity: item.id === size.id ? 1 : 0,
    })),
  };

  const categories = rootCategory?.subCategories || [];

  const brand = brands.find(brand => brand.id === brandId)?.name || null;
  const category =
    categories.find(category => lodash.map(category.items, 'id').find(id => id === item.id))
      ?.name || null;

  return (
    <Modal containerStyle={styles.modalContainer} desktopMinWidth={500} desktopMaxWidth={600}>
      <div style={styles.body}>
        <PerfectScrollbar
          containerRef={(ref: any) => {
            psbContainerRef.current = ref;

            if (ref) {
              // https://github.com/mdbootstrap/perfect-scrollbar/pull/934/files
              // injecting a fix for this issue
              ref._getBoundingClientRect = ref.getBoundingClientRect;

              ref.getBoundingClientRect = () => {
                const original = ref._getBoundingClientRect();

                return { ...original, height: Math.round(original.height) };
              };
            }
          }}
          style={combineStyles(
            styles.scrollable,
            choiceSets.length > 1 && styles.scrollableExtraPadding, // so combo checkboxes aren't right along the bottom
          )}
          onScroll={lodash.throttle(() => {
            // this.updateScroll();
            assess();
          }, 500)}
        >
          <div style={styles.topSection}>
            <RedcatImage
              alt={item.name}
              imagePath={item?.images?.default}
              size={p('purchaseEditorItemImage', ['height']).height as number}
              containerStyle={styles.image}
            />

            <Text themeKey="purchaseEditorName" style={styles.name}>
              {item.name}
            </Text>

            {Boolean(item.description) && (
              <Text themeKey="purchaseEditorDescription" style={styles.description}>
                {item.description}
              </Text>
            )}

            {Boolean(item.kilojoules) && (
              <Text themeKey="purchaseEditorEnergy" style={styles.energy}>
                {item.kilojoules}
                <Text themeKey="purchaseEditorEnergySuffix" style={styles.energySuffix}>
                  kj
                </Text>
              </Text>
            )}

            <QuantityControl
              containerStyle={styles.quantityControl}
              quantity={quantity}
              maxQuantity={
                minBalanceStaged
                  ? minBalanceStaged
                  : stockBalanceData.cartAdjustedBalance
                  ? stockBalanceData.cartAdjustedBalance
                  : undefined
              }
            />

            {stockBalanceData.stockBalanceThreshold && (
              <Text
                themeKey={
                  stockBalanceData.stockBalanceThreshold
                    ? stockBalanceData.stockBalanceThreshold
                    : ''
                }
                style={styles.itemStockMessage}
              >
                {t('stockBalanceThreshold.' + stockBalanceData.stockBalanceThreshold)}
              </Text>
            )}
          </div>

          {multiSized && (
            <>
              <ChoiceSetBanner
                choiceSet={SIZE_CHOICE_SET}
                collapsed={collapsedChoiceSets[SIZE_CHOICE_SET.id]}
                toggleCollapsed={toggleCollapsed}
              />

              {!collapsedChoiceSets[SIZE_CHOICE_SET.id] && (
                <ChoiceSet
                  choiceSet={SIZE_CHOICE_SET as ValidatedChoiceSet}
                  adjustOpenPurchaseChoice={({ targetChoiceId }) =>
                    dispatch(changeOpenPurchaseItem({ itemId: targetChoiceId }))
                  }
                  hideNegativeIngredients={hideNegativeIngredients}
                />
              )}
            </>
          )}

          {choiceSets.map((choiceSet: ValidatedChoiceSet) => {
            const collapsed = Boolean(collapsedChoiceSets[choiceSet.id]);

            return (
              <React.Fragment key={choiceSet.id}>
                <ChoiceSetBanner
                  key={`${choiceSet.id}_banner`}
                  choiceSet={choiceSet}
                  collapsed={collapsed}
                  toggleCollapsed={toggleCollapsed}
                />

                {(choiceSet.displayType === 'checkbox' || !collapsed) && (
                  <ChoiceSet
                    key={`${choiceSet.id}_display`}
                    choiceSet={choiceSet}
                    adjustOpenPurchaseChoice={adjustOpenPurchaseChoice}
                    hideNegativeIngredients={hideNegativeIngredients}
                  />
                )}
              </React.Fragment>
            );
          })}
        </PerfectScrollbar>

        <StandardButton
          themeKey="modalProceedButton"
          label={`${
            previouslyStaged ? t('button.purchaseEditor.modified') : t('button.purchaseEditor.new')
          } ${price}${valid ? '' : '*'} `}
          LeftIconComponent={previouslyStaged ? RiPencilFill : RiAddLine}
          onClick={() => {
            addToCart(item, moneyPrice, quantity, choicesWithQuantity, brand, category);
            dispatch(stageOpenPurchase({}));
          }}
          disabled={
            !valid ||
            quantity >
              (minBalanceStaged
                ? minBalanceStaged
                : stockBalanceData.cartAdjustedBalance!
                ? stockBalanceData.cartAdjustedBalance!
                : stockBalanceData.itemBalance!) ||
            minBalanceStaged === 0
          }
          disabledStyle={styles.disabledButton}
          style={styles.button}
        />

        {showIndicator && (
          <TouchableOpacity
            style={combineStyles(
              styles.indicator,
              p('scrollForMoreIndicator', CONTAINER_PROPERTIES),
            )}
            onClick={() => psbContainerRef.current!.scrollBy({ top: 200 })}
            ariaLabel="scroll to see more"
          >
            <RiArrowDownSLine style={p('scrollForMoreIndicator', ['color'])} />
          </TouchableOpacity>
        )}
      </div>
    </Modal>
  );
};

const styles: Styles = {
  modalContainer: {
    padding: 0,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  body: {
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',

    flex: 1,
    overflow: 'hidden',
  },

  scrollable: {
    overflowY: 'auto',
    flex: 1,
  },

  scrollableExtraPadding: {
    paddingBottom: BOTTOM_PADDING,
  },

  topSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    padding: 20,
  },

  image: {
    marginBottom: 10,
  },
  name: {
    marginBottom: 5,
    textAlign: 'center',
  },
  description: {
    marginBottom: 8,
    textAlign: 'center',
  },
  energy: {
    marginBottom: 5,
  },
  energySuffix: {
    marginLeft: 1,
  },
  price: {
    marginTop: 10,
  },

  quantityControl: {
    marginTop: 20,
  },

  button: {
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
  },
  disabledButton: {
    opacity: 0.9,
  },

  indicator: {
    position: 'absolute',
    left: 20,
    bottom: 80,
    height: 50,
    width: 50,
    backgroundColor: 'green',
    zIndex: 900,
    borderRadius: '50%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 3,
    fontSize: 40,
  },
  itemStockMessage: {
    marginTop: 25,
    marginBottom: 5,
  },
};

export default PurchaseEditorModal;
