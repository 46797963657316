import React from 'react';
import { useTranslation } from 'react-i18next';
import combineStyles from '../utils/combineStyles';
import { setCurrentModal } from '../thunks/setCurrentModal';
import Modal from '../components/Modal';
import StandardButton from '../components/StandardButton';
import Text from '../components/Text'; // TODO: rework this to use Text component
import { useAppDispatch, useAppSelector } from '../app/hooks';
export const MESSAGE_MODAL_ID = 'MESSAGE_MODAL_ID';

const MessageModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const modalParams = useAppSelector(state => state.modalParams);

  return (
    <Modal
      title={modalParams?.title}
      desktopMaxWidth={350}
      desktopMinWidth={350}
      desktopContainerStyle={styles.modalDesktopContainer}
      hideFloatingHeader
    >
      {modalParams?.message && (
        <Text
          block
          style={combineStyles(styles.message, {
            marginTop: modalParams?.title ? 25 : 15,
          })}
          value={modalParams?.message}
          themeKey="messageModalMessage"
        />
      )}

      <StandardButton
        label={t('button.ok')}
        onClick={() => dispatch(setCurrentModal(null))}
        containerStyle={styles.okButton}
        themeKey="messageModalButton"
      />
    </Modal>
  );
};

const styles: Styles = {
  modalDesktopContainer: {
    padding: '45px 50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  message: {
    textAlign: 'center',
  },

  okButton: {
    marginTop: 40,
  },
};

export default MessageModal;
