import { createCustomAction } from 'typesafe-actions';

import {
  standardEmptyACC,
  standardFailedACC,
  standardFlowACC,
} from '../../utils/sagas';

const REQUESTED = '$FETCH_STOCK_BALANCES/REQUESTED';
const BLOCKED = '$FETCH_STOCK_BALANCES/BLOCKED';
const APPROVED = '$FETCH_STOCK_BALANCES/APPROVED';
const SUCCEEDED = '$FETCH_STOCK_BALANCES/SUCCEEDED';
const FAILED = '$FETCH_STOCK_BALANCES/FAILED';

export const events = {
  REQUESTED,
  BLOCKED,
  APPROVED,
  SUCCEEDED,
  FAILED,
};

export const actions = {
    requested: createCustomAction(REQUESTED, standardEmptyACC),
    approved: createCustomAction(APPROVED, standardEmptyACC),
    blocked: createCustomAction(BLOCKED, standardEmptyACC),
    succeeded: createCustomAction(SUCCEEDED, standardEmptyACC),
    failed: createCustomAction(FAILED, standardFailedACC),
};

export default actions.requested;
