import { dollarsToCents } from '../misc';
import { OFFER_TYPE } from '../../constants/offerType';

const asIndividual = (p: PurchaseWithTotals): PurchaseWithTotals => ({
  ...p,
  quantity: 1,
  pointsPrice: p.pointsPrice / p.quantity,
  pointsAward: p.pointsAward / p.quantity,
  moneyPrice: p.moneyPrice / p.quantity,
  moneyDiscount: p.moneyDiscount / p.quantity,
  discountedMoneyPrice: p.discountedMoneyPrice / p.quantity,
});

export default function determineDiscountsForPurchase(
  purchase: PurchaseWithTotals,
  offer: Offer,
): DiscountDelta[] {
  const { item, id } = purchase;

  const discounts: DiscountDelta[] = [];

  const { targetPLUs, targetPLUCategories, targetPLUClasses } = offer;

  const noPLURestrictions =
    !targetPLUs.length &&
    !targetPLUCategories.length &&
    !targetPLUClasses.length;

  const offerApplicable = targetPLUs.includes(item.id) || noPLURestrictions;

  if (
    !offerApplicable &&
    offer.type !== OFFER_TYPE.PRICE_OVERRIDE_ITEM_OR_INGREDIENT
  ) {
    return discounts;
  }

  switch (offer.type) {
    case OFFER_TYPE.AMOUNT_OFF_ITEM: {
      discounts.push({
        amount: Math.min(dollarsToCents(offer.amount), item.baseMoneyPrice),
        purchaseId: id,
      });

      break;
    }
    case OFFER_TYPE.PERCENTAGE_OFF_ITEM_POS_ENFORCED:
    case OFFER_TYPE.PERCENTAGE_OFF_ITEM: {
      // 13 applies same discount as 81, only difference is behaviour at POS

      let amount = Math.round((offer.amount / 100) * item.baseMoneyPrice);
      const limit = offer.limit ? offer.limit : item.baseMoneyPrice;
      amount = Math.min(amount, limit);

      discounts.push({
        amount,
        purchaseId: id,
      });

      break;
    }
    case OFFER_TYPE.PERCENTAGE_OFF_ITEM_AND_INGREDIENTS: {
      const individualPurchase = asIndividual(purchase);

      let amount = Math.round(
        (offer.amount / 100) * individualPurchase.moneyPrice,
      );
      const limit = offer.limit ? offer.limit : individualPurchase.moneyPrice;
      amount = Math.min(amount, limit);

      discounts.push({
        amount,
        purchaseId: id,
      });

      break;
    }
    case OFFER_TYPE.PRICE_OVERRIDE_ITEM_OR_INGREDIENT: {
      if (offerApplicable) {
        discounts.push({
          amount: item.baseMoneyPrice - dollarsToCents(offer.amount),
          purchaseId: id,
        });
      }

      // NOTE: how do we handle free choices?
      // NOTE: what if the override is greater than the base price?
      purchase.choicesWithQuantity.forEach(choice => {
        if (!targetPLUs.includes(choice.id) && !noPLURestrictions) {
          return;
        }

        discounts.push({
          amount: choice.baseMoneyPrice - dollarsToCents(offer.amount),
          purchaseId: id,
        });
      });

      break;
    }
  }

  return discounts.filter(discount => discount.amount > 0);
}
