import { createCustomAction } from 'typesafe-actions';

import { AuthenticationMethod } from '../../utils/Api';

import {
  standardFlowACC,
  standardEmptyACC,
  getStandardFailedWithReasonACC,
} from '../../utils/sagas';

import { FAILURE_REASON } from '../../constants/failureReasons';

const REQUESTED = '$VALIDATE_ORDER/REQUESTED';
const BLOCKED = '$VALIDATE_ORDER/BLOCKED';
const APPROVED = '$VALIDATE_ORDER/APPROVED';
const SUCCEEDED = '$VALIDATE_ORDER/SUCCEEDED';
const FAILED = '$VALIDATE_ORDER/FAILED';

export type FailureReason =
  | undefined
  | FAILURE_REASON.UNKNOWN
  | FAILURE_REASON.LOCATION_OFFLINE
  | FAILURE_REASON.LOCATION_CLOSED
  | FAILURE_REASON.FETCH_FAILED;

const requested = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      authenticationMethod?: AuthenticationMethod;
    }>(type);
};

const succeeded = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      valid: boolean;
      reason: FailureReason;
      errorText?: string;
    }>(type);
};

export const events = {
  REQUESTED,
  BLOCKED,
  APPROVED,
  SUCCEEDED,
  FAILED,
};

export const actions = {
  requested: createCustomAction(REQUESTED, requested),
  approved: createCustomAction(APPROVED, requested),
  blocked: createCustomAction(BLOCKED, standardEmptyACC),
  succeeded: createCustomAction(SUCCEEDED, succeeded),
  failed: createCustomAction(
    FAILED,
    getStandardFailedWithReasonACC<FailureReason>(),
  ),
};

export default actions.requested;
