import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  verificationMaxAttempts: 3,
  verificationDelay: 60,
  verificationLockout: 24,
};
const slice = createSlice({
  name: 'verificationAttempts',
  initialState,
  reducers: {
    setVerificationAttempts(state, action) {
      return action.payload;
    },
  },
});

export const { setVerificationAttempts } = slice.actions;
export default slice.reducer;
