import React from 'react';

export default function HorizontalLine({ text }: { text: string }) {
  return (
    <div style={styles.horizontalLine}>
      <span style={styles.horizantalText}>{text}</span>
    </div>
  );
}

const styles: Styles = {
  horizontalLine: {
    width: '100%',
    height: '12px',
    borderBottom: '1px solid black',
    textAlign: 'center',
    marginTop: '25px',
  },
  horizantalText: {
    fontSize: 14,
    padding: '0 10px',
    marginTop: '20px',
    backgroundColor: 'white',
    fontFamily: 'Arial, Helvetica, sans-serif',
  },
};
