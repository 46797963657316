import { all, takeEvery, spawn, call } from 'redux-saga/effects';

import { OrderingRootSaga } from 'polygon-ordering';

import bootAction from '../actions/boot';
import syncMemberAction from '../actions/syncMember';
import adjustOrderAction from '../actions/adjustOrder';
import adjustSaleTypeAction from '../actions/adjustSaleType';
import adjustLocationAction from '../actions/adjustLocation';
import setCurrentModalAction from '../actions/setCurrentModal';
import performDeliveryEstimateAction from '../actions/performDeliveryEstimate';
import checkoutAction from '../actions/checkout';
import checkoutWithPaymentRequestAction from '../actions/checkoutWithPaymentRequest';
import validateWithPaymentRequestAction from '../actions/validateWithPaymentRequest';
import checkDateAction from '../actions/checkDate';
import applyServerThemeOverridesAction from '../actions/applyServerThemeOverrides';
import applyBufferedDeliveryEstimateAction from '../actions/applyBufferedDeliveryEstimate';
import confirmLocationEstimateAction from '../actions/confirmLocationEstimate';

import debug from '../utils/debug';

import bootSaga from './boot';
import syncMemberSaga from './syncMember';
import adjustOrderSaga from './adjustOrder';
import adjustSaleTypeSaga from './adjustSaleType';
import adjustLocationSaga from './adjustLocation';
import setCurrentModalSaga from './setCurrentModal';
import performDeliveryEstimateSaga from './performDeliveryEstimate';
import checkoutSaga from './checkout';
import checkoutWithPaymentRequestSaga from './checkoutWithPaymentRequest';
import validateWithPaymentRequestSaga from './validateWithPaymentRequest';
import checkDateSaga from './checkDate';
import applyServerThemeOverridesSaga from './applyServerThemeOverrides';
import applyBufferedDeliveryEstimateSaga from './applyBufferedDeliveryEstimate';
import confirmLocationEstimateSaga from './confirmLocationEstimate';

function* watcher() {
  yield all([
    takeEvery(bootAction, bootSaga),
    takeEvery(syncMemberAction, syncMemberSaga),
    takeEvery(adjustOrderAction, adjustOrderSaga),
    takeEvery(adjustSaleTypeAction, adjustSaleTypeSaga),
    takeEvery(adjustLocationAction, adjustLocationSaga),
    takeEvery(setCurrentModalAction, setCurrentModalSaga),
    takeEvery(performDeliveryEstimateAction, performDeliveryEstimateSaga),
    takeEvery(checkoutAction, checkoutSaga),
    takeEvery(checkoutWithPaymentRequestAction, checkoutWithPaymentRequestSaga),
    takeEvery(validateWithPaymentRequestAction, validateWithPaymentRequestSaga),
    takeEvery(checkDateAction, checkDateSaga),
    takeEvery(applyServerThemeOverridesAction, applyServerThemeOverridesSaga),
    takeEvery(applyBufferedDeliveryEstimateAction, applyBufferedDeliveryEstimateSaga),
    takeEvery(confirmLocationEstimateAction, confirmLocationEstimateSaga),
  ]);
}

// https://redux-saga.js.org/docs/advanced/RootSaga.html
// see: "Keeping everything alive"
export default function* () {
  const sagas = [OrderingRootSaga, watcher];

  yield all(
    sagas.map(saga =>
      spawn(function* sagaNecromancer() {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (error) {
            console.log(error);
            debug(false, 'a root saga crashed, restarting', { error });
          }
        }
      }),
    ),
  );
}
