import { ActionType, getType } from 'typesafe-actions';

import * as config from '../../actionCreators/config';

export default function (
  state: ProductConfig = {},
  action: ActionType<typeof config>,
): ProductConfig {
  switch (action.type) {
    case getType(config.setProductConfig): {
      const { value } = action.payload;
      return value;
    }
    default:
      return state;
  }
}
