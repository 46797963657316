import React, { useState, useEffect } from 'react';
import PaymentMethodProvider from './PaymentMethodProvider';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import Text from './Text';
import RedcatImage from './RedcatImage';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useFormikContext } from 'formik';
import { dollarsToCents } from '../libs/polygon-ordering/src/utils/misc';
import { useAppSelector } from '../app/hooks';
import { OrderingSelectors } from 'polygon-ordering';

const { getStripeCurrency } = OrderingSelectors;

const ApplePayProvider = () => {
  const [applePayEnabled, setApplePayEnabled] = useState<boolean | null>(null);
  const stripe = useStripe();
  const elements = useElements();
  const { values } = useFormikContext<FormikFields>();
  const stripeCurrency = useAppSelector(getStripeCurrency);

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: 'AU',
      currency: stripeCurrency,
      total: {
        label: 'Payment Total:',
        amount: dollarsToCents(values.Amount!) + Number(values.surcharge) || 0,
      },
    });

    pr.canMakePayment().then(result => {
      if (result?.applePay) {
        setApplePayEnabled(true);
      }
    });
  }, [stripe, elements]);

  return (
    <>
      {applePayEnabled && (
        <PaymentMethodProvider
          method={PAYMENT_METHOD.APPLE_PAY}
          right={
            <div style={styles.buttonContainer}>
              <div style={styles.markContainer}>
                <RedcatImage
                  imagePath="https://assets.redcatcloud.com.au/webordering/Apple_Pay_Mark_RGB_041619.svg"
                  size={50}
                />
              </div>
            </div>
          }
          subtitle={<Text themeKey="paymentMethodUnavailableReason">&nbsp;</Text>}
        />
      )}
    </>
  );
};

const styles: Styles = {
  buttonContainer: {
    position: 'relative',
  },
  markContainer: {
    position: 'absolute',
    top: -26,
    left: 'auto',
    right: 0,
  },
};

export default ApplePayProvider;
