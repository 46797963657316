import { v4 as uuidv4 } from 'uuid';

import choiceSelectionsFromBase from './choiceSelectionsFromBase';

export default function purchaseFromItem(
  item: Item,
  id: string = uuidv4(),
  choiceSelections?: ChoiceSelections,
  quantity?: number | null,
  brandId?: string,
): _Purchase {
  const emptyChoiceSelections: ChoiceSelections = {};

  item.choiceSets.forEach(choiceSet => {
    emptyChoiceSelections[choiceSet.key] = [];
  });

  const overriddenChoiceSelections = {
    ...emptyChoiceSelections,
    ...choiceSelections,
  };

  return {
    id,
    itemId: item.id,
    quantity: quantity || 1,
    choiceSelections: choiceSelections
      ? overriddenChoiceSelections
      : choiceSelectionsFromBase(item.choiceSets),
    brandId,
  };
}
