import { dollarsToCents } from '../misc';
import { OFFER_TYPE } from '../../constants/offerType';
import { OFFER_FOR_PURCHASE, OFFER_FOR_ENTIRE_ORDER } from '../../constants';
import { bogoValidation, calculateBOGODiscount } from './bogoCoupon';

export const calculateCouponDiscountedPrice = (
  selectedOffer: Offer,
  stagedPurchasesTotals: StagedPurchasesTotals,
  purchasedItems: any[],
  rewardsAccrualRate: number,
  couponOrderingMethod: any,
  freeChoiceOrderingMethod: any,
): number => {
  const { moneyPrice: purchasesMoneyPrice, discountedMoneyPrice } =
    stagedPurchasesTotals;
  let couponAmount = 0;
  let discountedAmount = purchasesMoneyPrice;
  if (OFFER_FOR_ENTIRE_ORDER.includes(selectedOffer.type)) {
    if (selectedOffer.type === OFFER_TYPE.AMOUNT_OFF_ORDER) {
      couponAmount = dollarsToCents(selectedOffer.amount);
    } else if (selectedOffer.type === OFFER_TYPE.PERCENTAGE_OFF_ORDER) {
      // NOTE: OFFER_FOR_ENTIRE_ORDER coupons don't include surcharges
      // this looks right but due to floating point limits can be wrong
      // orderMoneyDiscount = (selectedOffer.amount / 100) * purchasesMoneyPrice;

      // this seems to work
      const a = 100 - selectedOffer.amount;
      const b = purchasesMoneyPrice * a;
      const negativeOrderMoneyDiscount = b / 100.0 - purchasesMoneyPrice;

      couponAmount = negativeOrderMoneyDiscount * -1;

      if (selectedOffer.limit !== 0) {
        couponAmount = Math.min(couponAmount, selectedOffer.limit);
      }
    }
    couponAmount = Math.round(couponAmount);
    discountedAmount = purchasesMoneyPrice - couponAmount;

    return discountedAmount < 0 ? 0 : discountedAmount;
  }

  //items specfic coupons
  if (OFFER_FOR_PURCHASE.includes(selectedOffer.type)) {
    return discountedMoneyPrice;
  }

  //bogo coupons
  if (
    selectedOffer.type === OFFER_TYPE.AMOUNT_OFF_BOGO ||
    selectedOffer.type === OFFER_TYPE.PERCENTAGE_OFF_BOGO
  ) {
    if (
      bogoValidation(selectedOffer, purchasedItems, freeChoiceOrderingMethod)
    ) {
      const { discountedMoneyPrice } = calculateBOGODiscount(
        selectedOffer,
        purchasedItems,
        stagedPurchasesTotals,
        rewardsAccrualRate,
        [],
        couponOrderingMethod,
        freeChoiceOrderingMethod,
      );
      return discountedMoneyPrice;
    } else {
      return purchasesMoneyPrice;
    }
  }
  return 0;
};
