import React from 'react';
import { RiArrowLeftSLine } from 'react-icons/ri';

import { OrderingSelectors } from 'polygon-ordering';

import setCurrentModalAction from '../actions/setCurrentModal';

import getThemeLookup from '../selectors/getThemeLookup';
import getEditLocationAvailable from '../selectors/getEditLocationAvailable';

import combineStyles from '../utils/combineStyles';

import Text from './Text';
import TouchableOpacity from './TouchableOpacity';

import { LOCATION_MODAL_ID } from '../modals/LocationModal';
import { useAppSelector, useAppDispatch } from '../app/hooks';

const { getLocation, getSaleType } = OrderingSelectors;

// TODO: add store hours / other details
const MenuLocationDetails: React.FC = () => {
  const p = useAppSelector(getThemeLookup);
  const location = useAppSelector(getLocation);
  const saleType = useAppSelector(getSaleType);
  const editLocationAvailable = useAppSelector(getEditLocationAvailable);
  const enableMenuLocationDetails = useAppSelector(state => state.config.enableMenuLocationDetails);
  const dispatch = useAppDispatch();

  if (!location || !enableMenuLocationDetails) {
    return null;
  }

  return (
    <TouchableOpacity
      onClick={() =>
        dispatch(
          setCurrentModalAction({
            modalId: LOCATION_MODAL_ID,
            preserveReturnModalDetails: true,
            params: { saleType },
          }),
        )
      }
      style={combineStyles(
        styles.mainContainer,
        editLocationAvailable && styles.editable,
        p('menuLocationDetails', ['backgroundColor']),
      )}
      disabled={!editLocationAvailable}
      ariaLabel={editLocationAvailable ? 'edit location' : 'location'}
    >
      {editLocationAvailable && (
        <RiArrowLeftSLine
          style={combineStyles(
            styles.editIcon,
            {
              //@ts-ignore
              fontSize: p('menuLocationDetailsName', ['fontSize']).fontSize * 1.4,
            },
            p('menuLocationDetailsName', ['color']),
          )}
        />
      )}

      <Text themeKey="menuLocationDetailsName">{location.name}</Text>
    </TouchableOpacity>
  );
};

const styles: Styles = {
  mainContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  editable: {
    paddingLeft: 0,
    paddingBottom: 5,
  },
  editIcon: {
    opacity: 0.9,
    marginLeft: -4,
    marginRight: -4,
  },
};

export default MenuLocationDetails;
