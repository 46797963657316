import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as null | MemberOffer[];

const slice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    storeOffers(state, action: PayloadAction<MemberOffer[]>) {
      return action.payload;
    },
    clearOffers(state, action) {
      return initialState;
    },
  },
});

export const { storeOffers, clearOffers } = slice.actions;

export default slice.reducer;
