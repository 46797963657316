import React from 'react';
import Modal from '../components/Modal';
import { useTranslation } from 'react-i18next';
import Register from '../components/Register';

export const REGISTER_MODAL_ID = 'REGISTER_MODAL_ID';

const RegisterModal = () => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('title.modal.memberRegister')}
      desktopMaxWidth={500}
      desktopMinWidth={400}
      desktopContainerStyle={styles.modalDesktopContainer}
    >
      <Register modal={true} />
    </Modal>
  );
};

const styles: Styles = {
  modalDesktopContainer: {
    padding: '50px 70px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    textTransform: 'uppercase',
  },
};

export default RegisterModal;
