import { dollarsToCents, safeDate, undefinedIfNull } from '../misc';

export default function processMember(rawMember: RawMember): Member {
  return {
    id: String(rawMember.ID),
    memberNumber: rawMember.MemberNo,
    givenName: undefinedIfNull(rawMember.GivenNames),
    surname: undefinedIfNull(rawMember.Surname),
    email: undefinedIfNull(rawMember.Email),
    mobile: undefinedIfNull(rawMember.Mobile),
    verified: Boolean(rawMember.VerificationDate),
    moneyBalance: dollarsToCents(rawMember.Money),
    pointsBalance: rawMember.Points,
    rewardsBalance: dollarsToCents(rawMember.Points), // TODO: check this is the case
    active: Boolean(rawMember.Active),
    verifyPin: undefinedIfNull(rawMember.VerifyPIN),
    type: (rawMember.CardType || '').toUpperCase(),
    expiryDate: safeDate(rawMember.ExpiryDate) as string,
    registered: Boolean(rawMember.RegistrationDate),
    cardsetId: String(rawMember.CardsetID),
    barcode: String(rawMember.Barcode),
    dateOfBirth: undefinedIfNull(rawMember.DateOfBirth),
    phone: undefinedIfNull(rawMember.Phone),
  };
}
