import lodash from 'lodash';

import {
  BOOST_ICE_INGREDIENT_IDS,
  BOOST_WITHOUT_ICE_INGREDIENT_ID,
} from '../../constants/boost';

import choiceIsThisIngredient from './choiceIsThisIngredient';
import createIngredientFromChoice from '../ordering/createIngredientFromChoice';

function ingredientForThisChoice(
  boostIngredients: BoostIngredient[],
  choiceId: string,
) {
  return boostIngredients.find(ingredient =>
    choiceIsThisIngredient(ingredient, choiceId),
  );
}

function fakeSingleQuantity(choice: Choice) {
  return { ...choice, quantity: 1, moneyPrice: choice.baseMoneyPrice };
}

export default function createCartIngredientsFromBoostSwap(
  boostIngredients: BoostIngredient[],
  choiceSet: ChoiceSetWithQuantity,
): CartIngredient[] {
  const cartIngredients: CartIngredient[] = [];

  const defaultChoiceId = choiceSet.key.split(',').pop();

  const ingredientOfDefault = ingredientForThisChoice(
    boostIngredients,
    defaultChoiceId!,
  );

  const currentSelection = choiceSet.choices.find(choice =>
    Boolean(choice.quantity),
  );
  const ingredientOfCurrent = currentSelection
    ? ingredientForThisChoice(boostIngredients, currentSelection.id)
    : undefined;

  const withoutDefault =
    !currentSelection || currentSelection.id !== defaultChoiceId;

  let insteadAdded = false;
  if (withoutDefault) {
    if (currentSelection && ingredientOfCurrent) {
      const { insteadOf } = ingredientOfCurrent.choiceMap;

      if (insteadOf) {
        insteadAdded = true;
        cartIngredients.push(
          createIngredientFromChoice(fakeSingleQuantity(insteadOf)),
        );
      }
    }

    if (ingredientOfDefault) {
      const { without } = ingredientOfDefault.choiceMap;
      const skip =
        BOOST_ICE_INGREDIENT_IDS.includes(ingredientOfDefault.id) &&
        insteadAdded;

      if (without && !skip) {
        cartIngredients.push(
          createIngredientFromChoice(fakeSingleQuantity(without)),
        );
      }
    }
  }

  return lodash.uniqBy(cartIngredients, 'PLUCode');
}

// possibly cleaner refactor, but needs to be debugged:

// import lodash from 'lodash';

// import {
//   BOOST_ICE_INGREDIENT_IDS,
//   BOOST_WITHOUT_ICE_INGREDIENT_ID,
// } from '../../constants/boost';

// import choiceIsThisIngredient from './choiceIsThisIngredient';
// import createIngredientFromChoice from '../ordering/createIngredientFromChoice';

// function ingredientForThisChoice (
//   boostIngredients: BoostIngredient[],
//   choiceId: string,
// ) {
//   return boostIngredients.find(ingredient =>
//     choiceIsThisIngredient(ingredient, choiceId),
//   );
// }

// function fakeSingleQuantity (choice: Choice) {
//   return { ...choice, quantity: 1, moneyPrice: choice.baseMoneyPrice };
// }

// export default function createCartIngredientsFromBoostSwap (
//   boostIngredients: BoostIngredient[],
//   choiceSet: ChoiceSetWithQuantity,
// ): CartIngredient[] {
//   const result: CartIngredient[] = [];

//   const defaultChoiceId = choiceSet.key.split(',')[2];

//   const defaultIngredient = ingredientForThisChoice(
//     boostIngredients,
//     defaultChoiceId,
//   );

//   if (!defaultIngredient) {
//     return [];
//   }

//   const currentChoice = choiceSet.choices.find(choice => Boolean(choice.quantity));
//   const currentIngredient = currentChoice
//     ? ingredientForThisChoice(boostIngredients, currentChoice.id)
//     : undefined;

//   const defaultNotPresent =
//     !currentIngredient || currentIngredient.id !== defaultIngredient.id;

//   if (defaultNotPresent) {
//     const { without } = defaultIngredient.choiceMap;

//     const isIceSwap = BOOST_ICE_INGREDIENT_IDS.includes(defaultIngredient.id);

//     if (without && !isIceSwap) {
//       result.push(createIngredientFromChoice(fakeSingleQuantity(without)));
//     }
//   }

//   if (currentIngredient && defaultNotPresent) {
//     const { insteadOf } = currentIngredient.choiceMap;

//     if (insteadOf) {
//       result.push(createIngredientFromChoice(fakeSingleQuantity(insteadOf)));
//     }
//   }

//   return lodash.uniqBy(result, 'PLUCode');
// }
