import { createSelector } from 'reselect';

import getSelectedOffer, { $getSelectedOffer } from './getSelectedOffer';
import getOrderTotals, { $getOrderTotals } from './getOrderTotals';
import getStagedPurchaseCount, {
  $getStagedPurchaseCount,
} from './getStagedPurchaseCount';

import { getFreeChoiceOrderingMethod } from './config';
import { OFFER_TYPE } from '../constants/offerType';
import getStagedPurchases, { $getStagedPurchases } from './getStagedPurchases';
import { bogoValidation } from '../utils/ordering/bogoCoupon';

const get = (
  selectedOffer: Offer | undefined,
  orderTotals: OrderTotals | undefined,
  stagedPurchaseCount: number,
  purchasedItems: any,
  freeChoiceOrderingMethod: any,
): boolean | undefined => {
  if (
    orderTotals === undefined ||
    stagedPurchaseCount === undefined ||
    !selectedOffer
  ) {
    return undefined;
  }

  if (
    selectedOffer.type === OFFER_TYPE.AMOUNT_OFF_BOGO ||
    selectedOffer.type === OFFER_TYPE.PERCENTAGE_OFF_BOGO
  ) {
    //bogo coupon validation
    return bogoValidation(
      selectedOffer,
      purchasedItems,
      freeChoiceOrderingMethod,
    );
  } else if (orderTotals.moneyDiscount === 0 && stagedPurchaseCount > 0) {
    //other coupon validation
    return false;
  }

  return true;
};

export default createSelector(
  [
    getSelectedOffer,
    getOrderTotals,
    getStagedPurchaseCount,
    getStagedPurchases,
    getFreeChoiceOrderingMethod,
  ],
  get,
);

export const $getSelectedOfferHasEffect = createSelector(
  [
    $getSelectedOffer,
    $getOrderTotals,
    $getStagedPurchaseCount,
    $getStagedPurchases,
    getFreeChoiceOrderingMethod,
  ],
  get,
);
