import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import lodash from 'lodash';

import * as applyPromoCode from '../../actionCreators/flows/applyPromoCode';
import * as fetchOffers from '../../actionCreators/flows/fetchOffers';
import * as selectOffer from '../../actionCreators/flows/selectOffer';
import * as updateSelectedPaymentMethods from '../../actionCreators/flows/updateSelectedPaymentMethods';

import { setPromoCode } from '../../actionCreators/currentOrder';

import getPromoCode from '../../selectors/getPromoCode';

import {
  requestAndWaitForFlow,
  makeErrorSerialisable,
  createFlowApprover,
} from '../../utils/sagas';

import { FAILURE_REASON } from '../../constants/failureReasons';

export const requested = createFlowApprover(applyPromoCode);

export function* approved(
  action: ReturnType<typeof applyPromoCode.actions.approved>,
) {
  const {
    payload: { promoCode },
    meta: { flowId },
  } = action;

  let reason: applyPromoCode.FailureReason;

  try {
    let promoCodeSafe = promoCode;
    if (!promoCodeSafe) {
      promoCodeSafe = yield select(getPromoCode);

      if (!promoCodeSafe) {
        reason = FAILURE_REASON.MISSING_PARAMETER;
        throw new Error('no promo code');
      }
    }

    yield put(setPromoCode(null));

    const payloads = yield call(
      requestAndWaitForFlow,
      fetchOffers,
      { promoCode: promoCodeSafe },
      flowId,
    );

    const offers = lodash.get(payloads, 'success.offers', []) as Offer[];
    const offer = offers[0];

    if (!offer) {
      reason = FAILURE_REASON.FETCH_FAILED;
      throw new Error('fetching offer failed');
    }

    const payloads2 = yield call(
      requestAndWaitForFlow,
      selectOffer,
      { offerId: offer.id },
      flowId,
    );

    if (!payloads2.success) {
      reason = FAILURE_REASON.SELECT_OFFER_FAILED;
      throw new Error('selecting offer failed');
    }

    // Call updateSelectedPaymentMethods to update remaining total
    yield put(
      updateSelectedPaymentMethods.actions.requested({
        giftCardValidated: true,
      }),
    );

    yield put(applyPromoCode.actions.succeeded({}, flowId));
  } catch (e) {
    yield put(
      applyPromoCode.actions.failed(
        { error: makeErrorSerialisable(e), reason },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(applyPromoCode.events.REQUESTED, requested),
    takeEvery(applyPromoCode.events.APPROVED, approved),
  ]);
}
