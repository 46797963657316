import { ActionType, getType } from 'typesafe-actions';

import * as currentOrder from '../../../actionCreators/currentOrder';
import { actions as fetchMember } from '../../../actionCreators/flows/fetchMember';

const initialState = null;

export default function (
  state: string | null = initialState,
  action: ActionType<typeof currentOrder> | ActionType<typeof fetchMember>,
): string | null {
  switch (action.type) {
    case getType(currentOrder.setPurchaser): {
      const { value } = action.payload;
      return value.email;
    }
    case getType(currentOrder.setPurchaserEmail): {
      const { value } = action.payload;
      return value;
    }
    case getType(currentOrder.adjustPurchaserEmail): {
      const { value } = action.payload;

      switch (value) {
        case 'clear': {
          return initialState;
        }
        case 'delete': {
          return (state || '').slice(0, -1);
        }
        default:
          return `${state || ''}${value}`.trimLeft();
      }
    }
    default:
      return state;
  }
}
