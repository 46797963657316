import { createCustomAction } from 'typesafe-actions';

import {
  standardEmptyACC,
  standardFailedACC,
  standardFlowACC,
} from '../../utils/sagas';

const REQUESTED = '$STAGE_PURCHASE_FROM_ITEM/REQUESTED';
const BLOCKED = '$STAGE_PURCHASE_FROM_ITEM/BLOCKED';
const APPROVED = '$STAGE_PURCHASE_FROM_ITEM/APPROVED';
const SUCCEEDED = '$STAGE_PURCHASE_FROM_ITEM/SUCCEEDED';
const FAILED = '$STAGE_PURCHASE_FROM_ITEM/FAILED';

const request = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      itemId: string;
      brandId?: string;
    }>(type);
};

const succeed = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      purchase: _Purchase;
    }>(type);
};

export const events = {
  REQUESTED,
  BLOCKED,
  APPROVED,
  SUCCEEDED,
  FAILED,
};

export const actions = {
  requested: createCustomAction(REQUESTED, request),
  approved: createCustomAction(APPROVED, request),
  blocked: createCustomAction(BLOCKED, standardEmptyACC),
  succeeded: createCustomAction(SUCCEEDED, succeed),
  failed: createCustomAction(FAILED, standardFailedACC),
};

export default actions.requested;
