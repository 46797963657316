export default function createShadowPurchaseFromPurchase(
  purchase: Purchase,
): ShadowItem {
  const { item, choiceSets, quantity } = purchase;
  let ingredients: ShadowItem[] = [];

  choiceSets.forEach(choiceSet => {
    choiceSet.choices.forEach(choice => {
      if (choice.quantity) {
        ingredients.push({
          id: choice.id,
          name: choice.name,
          quantity: choice.quantity,
          ingredients: null,
        });
      }
    });
  });

  return {
    name: item.name,
    id: item.id,
    quantity,
    ingredients,
  };
}
