import { createSelector } from 'reselect';

import getPurchaser from './getPurchaser';
import getPurchaserMobileValid from './getPurchaserMobileValid';
import getPurchaserEmailValid from './getPurchaserEmailValid';
import getRequiredPurchaserDetails from './getRequiredPurchaserDetails';

import { ORDER_NOT_READY_REASON } from '../constants';

// TODO: name length and email regex validation
export default createSelector(
  [
    getPurchaser,
    getRequiredPurchaserDetails,
    getPurchaserMobileValid,
    getPurchaserEmailValid,
  ],
  (purchaser, requiredPurchaserDetails, { valid: mobileValid }, emailValid) => {
    const reasons: ORDER_NOT_READY_REASON[] = [];

    requiredPurchaserDetails.forEach(detail => {
      switch (detail) {
        case 'mobile': {
          if (!mobileValid) {
            reasons.push(ORDER_NOT_READY_REASON.PURCHASER_MOBILE_INVALID);
          }
          break;
        }
        case 'name': {
          if (!purchaser.effective[detail]) {
            reasons.push(ORDER_NOT_READY_REASON.PURCHASER_NAME_INVALID);
          }
          break;
        }
        case 'familyName': {
          if (!purchaser.effective[detail]) {
            reasons.push(ORDER_NOT_READY_REASON.PURCHASER_FAMILY_NAME_INVALID);
          }
          break;
        }
        case 'email': {
          if (!emailValid) {
            reasons.push(ORDER_NOT_READY_REASON.PURCHASER_EMAIL_INVALID);
          }
          break;
        }
      }
    });

    return reasons;
  },
);
