export default function choiceSelectionsFromBase(
  choiceSets: ChoiceSet[],
  keyProperty: 'key' | 'id' = 'key',
): ChoiceSelections {
  const result: ChoiceSelections = {};

  choiceSets.forEach(choiceSet => {
    let selections: string[] = [];

    choiceSet.choices.forEach(choice => {
      selections = [
        ...selections,
        ...Array(choice.baseQuantity).fill(choice.id),
      ];
    });

    result[choiceSet[keyProperty]] = selections;
  });

  return result;
}
