import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { OrderingSelectors, OrderingOperations, OrderingHooks } from 'polygon-ordering';
import getThemeLookup from '../selectors/getThemeLookup';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import combineStyles from '../utils/combineStyles';
import { CONTAINER_PROPERTIES, TEXT_PROPERTIES } from '../utils/theme';
import Text from './Text';
import StandardButton from './StandardButton';
import PaymentMethod from './PaymentMethod';
import { useAppSelector, useAppDispatch } from '../app/hooks';

const { useFormattedCurrency } = OrderingHooks;
const { getGiftCard, getUnavailableReasonsGiftCard, getImportedGiftCardRegex } = OrderingSelectors;
const { setGiftCard } = OrderingOperations;

function safeUpper(value: string) {
  return (value || '').toUpperCase();
}

const PaymentMethodGiftCard: React.FC = () => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const giftCard = useAppSelector(getGiftCard);
  const unavailableReasonsGiftCard = useAppSelector(getUnavailableReasonsGiftCard);
  const importedGiftCardRegex = useAppSelector(getImportedGiftCardRegex);
  const dispatch = useAppDispatch();
  const { moneyBalance, number, pin, valid } = giftCard;
  const availableBalance = useFormattedCurrency(Number(moneyBalance));
  const reason = unavailableReasonsGiftCard[0];
  const disablePINField = valid && !pin;

  const sharedInputProps = {
    InputProps: {
      style: p('input', TEXT_PROPERTIES),
    },
    InputLabelProps: {
      style: {
        color: p('inputLabel', ['color']) || p('input', ['color']),
      },
    },
  };

  return (
    <PaymentMethod
      method={PAYMENT_METHOD.GIFT_CARD}
      allowSelectionWhenUnavailable
      right={
        moneyBalance != null && (
          <Text themeKey="paymentMethodAvailableBalance">{'Available: ' + availableBalance}</Text>
        )
      }
      subtitle={
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          {reason && moneyBalance != null ? (
            <Text style={styles.disabled} themeKey="paymentMethodUnavailableReason">
              {t(`paymentMethodUnavailableReason.${reason}`)}
            </Text>
          ) : (
            <Text themeKey="paymentMethodUnavailableReason">&nbsp;</Text>
          )}
        </div>
      }
    >
      <div style={styles.fields}>
        <TextField
          style={combineStyles(
            styles.cardInputField,
            sharedInputProps,
            p('input', CONTAINER_PROPERTIES),
          )}
          variant="outlined"
          placeholder={t('field.giftCard.number')}
          value={number}
          size="small"
          inputProps={{ maxLength: importedGiftCardRegex ? 20 : 11 }}
          onChange={event =>
            dispatch(
              setGiftCard({
                //@ts-ignore
                giftCard: { number: safeUpper(event?.target?.value) },
                suppressAutoFetch: true,
              }),
            )
          }
        />

        <div style={styles.lowerSection}>
          <TextField
            style={combineStyles(
              styles.pinInputField,
              sharedInputProps,
              p('input', CONTAINER_PROPERTIES),
            )}
            variant="outlined"
            placeholder={t('field.giftCard.pin')}
            value={pin}
            size="small"
            inputProps={{ maxLength: 6 }}
            onChange={event =>
              dispatch(
                setGiftCard({
                  //@ts-ignore
                  giftCard: { pin: safeUpper(event?.target?.value) },
                  suppressAutoFetch: true,
                }),
              )
            }
            disabled={disablePINField}
          />

          {
            <StandardButton
              themeKey="giftCardApplyButton"
              label={t('button.applyGiftCard')}
              onClick={() => {
                //@ts-ignore
                dispatch(setGiftCard({ giftCard: {} })); // trigger a fetch by doing a set and not passing in suppressAutoFetch
              }}
              disabled={!valid || moneyBalance != null}
            />
          }
        </div>
      </div>
    </PaymentMethod>
  );
};

const styles: Styles = {
  fields: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 7,
    paddingBottom: 17,
  },
  cardInputField: {
    marginRight: 7,
    width: '100%',
    backgroundColor: 'white',
  },
  pinInputField: {
    backgroundColor: 'white',
    width: '35%',
  },
  lowerSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 19,
    paddingTop: 10,
  },
  applyButtonIcon: {
    marginLeft: 0,
  },
  disabled: {
    opacity: 0.5,
    filter: 'grayscale(100%)',
  },
};

export default PaymentMethodGiftCard;
