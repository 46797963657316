import { createSelector } from 'reselect';

import { OrderingSelectors, OrderingConstants } from 'polygon-ordering';

import getAdditionalDeliveryStatusesEnabled from './getAdditionalDeliveryStatusesEnabled';

const { getSaleDetails } = OrderingSelectors;

const {
  ORDER_SUBMITTED,
  RECEIVED_AT_STORE,
  PROCESSED_AT_STORE,
  READY_TO_COLLECT,
  PICKED_UP_BY_DRIVER,
  DELIVERED,
  SALE_STATUSES_MAP,
} = OrderingConstants;

const steps = [
  ORDER_SUBMITTED,
  RECEIVED_AT_STORE,
  PROCESSED_AT_STORE,
  READY_TO_COLLECT,
  PICKED_UP_BY_DRIVER,
  DELIVERED,
].map(step => ({ id: SALE_STATUSES_MAP[step], value: step }));

const getSaleStatus = createSelector([getSaleDetails], saleDetails => saleDetails?.status);

export default createSelector(
  [getSaleDetails, getAdditionalDeliveryStatusesEnabled],
  (saleDetails, additionalDeliveryStatusesEnabled) => {
    const highestCompletedStatusId = saleDetails?.StatusID;
    const filteredSteps = steps.filter(step => {
      if (additionalDeliveryStatusesEnabled) {
        return step.value !== READY_TO_COLLECT;
      } else {
        return ![PICKED_UP_BY_DRIVER, DELIVERED].includes(step.value);
      }
    });

    // TODO: If we want to get rid off the patch below then we need update the order status enumm
    const orderStatusPatch =
      highestCompletedStatusId === 11
        ? // if order is scheduled or driver is on the way to pick up then we want to force and show recieved at store
          1
        : highestCompletedStatusId === 23
        ? 2
        : [25, 26, 27, 16, 17].includes(highestCompletedStatusId as number) // if the order is technically in transit when driver in dropoff, for cancelled and return scanerio we want to jam the statuses
        ? 3
        : highestCompletedStatusId === 15
        ? 4
        : highestCompletedStatusId;

    // TODO: BE to fix the status when items are delivered. Why show 2 or 3 again?

    return filteredSteps.map(step => ({
      ...step,
      //@ts-ignore
      complete:
        (orderStatusPatch || 0) >= Number(step.id) ||
        (orderStatusPatch || 0) >= filteredSteps.length - 1,
      inProgress:
        step.value === READY_TO_COLLECT
          ? orderStatusPatch === SALE_STATUSES_MAP[READY_TO_COLLECT]
          : //@ts-ignore
            orderStatusPatch + 1 === step.id,
    }));
  },
);
