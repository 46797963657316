import { createAsyncThunk } from '@reduxjs/toolkit';
import debug from '../utils/debug';
import isValidCoordinate from '../utils/isValidCoordinate';
import { setCurrentUserLocation } from '../slices/currentUserLocation';

export const updateCurrentUserLocationAction = createAsyncThunk(
  'updateCurrentUserLocation',
  async (_data: undefined, { dispatch }) => {
    try {
      if (!navigator || !('geolocation' in navigator)) {
        const message = 'device does not support geolocation api';
        debug(message);
        throw new Error(message);
      }

      const location: Position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          location => resolve(location),
          error => reject(error),
          { timeout: 10000 },
        );
      });
      if (!location.coords) {
        throw new Error('missing coords');
      }
      if (!isValidCoordinate(location.coords)) {
        throw new Error('invalid coords');
      }

      const { latitude, longitude } = location.coords;

      debug('updateCurrentUserLocation', { location });

      dispatch(setCurrentUserLocation({ latitude, longitude }));
    } catch (e) {
      debug(false, 'updateCurrentUserLocation failed', { e });

      dispatch(setCurrentUserLocation(null));
    }
  },
);
