import { createCustomAction } from 'typesafe-actions';

import {
  standardEmptyACC,
  standardFailedACC,
  standardFlowACC,
} from '../../utils/sagas';

const REQUESTED = '$STAGE_OPEN_PURCHASE/REQUESTED';
const BLOCKED = '$STAGE_OPEN_PURCHASE/BLOCKED';
const APPROVED = '$STAGE_OPEN_PURCHASE/APPROVED';
const SUCCEEDED = '$STAGE_OPEN_PURCHASE/SUCCEEDED';
const FAILED = '$STAGE_OPEN_PURCHASE/FAILED';

const succeed = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      purchase: _Purchase;
      previouslyStaged: boolean;
    }>(type);
};

export const events = {
  REQUESTED,
  BLOCKED,
  APPROVED,
  SUCCEEDED,
  FAILED,
};

export const actions = {
  requested: createCustomAction(REQUESTED, standardEmptyACC),
  approved: createCustomAction(APPROVED, standardEmptyACC),
  blocked: createCustomAction(BLOCKED, standardEmptyACC),
  succeeded: createCustomAction(SUCCEEDED, succeed),
  failed: createCustomAction(FAILED, standardFailedACC),
};

export default actions.requested;
