import React from 'react';
import Text from './Text';
import TouchableOpacity from './TouchableOpacity';
import { RedcatApiHandler } from 'polygon-utils';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../utils/snackbar';
import { useTranslation } from 'react-i18next';

const ViewInvoice = ({ invoiceID }: { invoiceID: string }) => {
  const { t } = useTranslation();

  const fetchInvoice = () => {
    const path = `/api/v1/profile/orders/invoice/${invoiceID}`;

    RedcatApiHandler.authorisedFetch({
      path,
      method: 'GET',
    })
      .then(res => {
        enqueueSuccessSnackbar('Success');
      })
      .catch(err => enqueueErrorSnackbar(err.message));
  };
  return (
    <div style={{ position: 'absolute', bottom: -15 }}>
      <TouchableOpacity onClick={() => fetchInvoice()}>
        <Text
          value={t('button.resendInvoice')}
          style={{ fontSize: 12, textDecoration: 'underline' }}
        />
      </TouchableOpacity>
    </div>
  );
};

export default ViewInvoice;
