import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
// import { RiStarFill, RiHistoryFill } from 'react-icons/ri';

import { OrderingSelectors } from 'polygon-ordering';

import getThemeLookup from '../selectors/getThemeLookup';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import getDisplayableSaleTypes from '../selectors/getDisplayableSaleTypes';
import getOrderInProgress from '../selectors/getOrderInProgress';
import combineStyles from '../utils/combineStyles';
import { SALE_TYPE_ICONS } from '../constants/saleTypes';
import { MENU_SCREEN_ROUTE } from './MenuScreen';
import { COMPLETED_ORDER_SCREEN_ROUTE } from './CompletedOrderScreen';
import ScreenHero from '../components/ScreenHero';
import ScreenFloating from '../components/ScreenFloating';
import StandardButton from '../components/StandardButton';
import Text from '../components/Text';
import RedirectAndPreserveSearch from '../components/RedirectAndPreserveSearch';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import adjustSaleType from '../actions/adjustSaleType';

const { getOrderSubmitted } = OrderingSelectors;

export const INITIAL_SCREEN_ROUTE = '/';

const InitialScreen = () => {
  const p = useAppSelector(getThemeLookup);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const displayableSaleTypes = useAppSelector(getDisplayableSaleTypes);
  const orderInProgress = useAppSelector(getOrderInProgress);
  const orderSubmitted = useAppSelector(getOrderSubmitted);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const pathname = useLocation();

  const getLocationId = () => {
    let locationId = null;

    if (pathname.state) {
      locationId = pathname.state.locationId;
    }

    return locationId;
  };

  const ScreenComponent = deviceTypeMobile ? ScreenHero : ScreenFloating;

  const columnStyles = combineStyles(styles.column, deviceTypeMobile && styles.columnMobile);

  if (orderSubmitted) {
    return <RedirectAndPreserveSearch to={COMPLETED_ORDER_SCREEN_ROUTE} />;
  }

  if (orderInProgress) {
    return <RedirectAndPreserveSearch to={MENU_SCREEN_ROUTE} />;
  }

  return (
    <ScreenComponent>
      <div
        style={combineStyles(
          deviceTypeMobile ? styles.mainContainerMobile : styles.mainContainerDesktop,
          p('screen', ['backgroundColor']),
          p(deviceTypeMobile ? 'initialScreenMobile' : 'initialScreenDesktop', [
            'backgroundColor',
            'boxShadow',
          ]),
        )}
      >
        <div style={columnStyles}>
          <Text themeKey="sectionTitle" value={t('title.newOrder')} />

          <div>
            {(displayableSaleTypes || []).map(saleType => (
              <StandardButton
                containerStyle={styles.button}
                key={saleType}
                label={t(`saleType.${saleType}`)}
                onClick={() =>
                  dispatch(adjustSaleType({ saleType: saleType, locationId: getLocationId() }))
                }
                RightIconComponent={SALE_TYPE_ICONS[saleType]}
              />
            ))}
          </div>
        </div>
      </div>
    </ScreenComponent>
  );
};

const styles = {
  mainContainerDesktop: {
    borderRadius: 2,
    padding: 35,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',

    margin: 'auto', // https://stackoverflow.com/a/33455342/8706204
  },
  mainContainerMobile: {
    flex: 1,
    padding: '10px 25px 100px 25px',
  },
  column: {
    minWidth: 250,
  },
  columnMobile: {
    padding: '20px 0 0 0',
  },
  button: {
    margin: '20px 20px',
  },
  verticalSeperator: {
    width: 1.5,
    marginLeft: 40,
    marginRight: 40,
  },
};

export default InitialScreen;
