import lodash from 'lodash';

import purchaseFromItem from './purchaseFromItem';
import findItemOrSubstitute from './findItemOrSubstitute';

export default function (
  shadowPurchase: ShadowItem,
  allItems: Items,
  purchases: _Purchase[],
  purchaseFailures: ShadowItem[],
  ingredientFailures: ShadowItem[],
) {
  let item: Item | undefined = findItemOrSubstitute(
    allItems,
    shadowPurchase.id,
    shadowPurchase.name,
  );

  if (!item || !item.available) {
    purchaseFailures.push(shadowPurchase);
    return;
  }

  try {
    const withQuantitiesCollapsed: SDict<ShadowItem> = {};

    // collapse ingredients (squash by increasing quantity)
    (shadowPurchase.ingredients || []).forEach(ingredient => {
      const { id } = ingredient;
      const stringId = String(id);

      if (withQuantitiesCollapsed[stringId]) {
        withQuantitiesCollapsed[stringId] = {
          ...withQuantitiesCollapsed[stringId],
          quantity:
            (ingredient.quantity || 0) +
            (withQuantitiesCollapsed[stringId].quantity || 0),
        };
      } else {
        withQuantitiesCollapsed[stringId] = ingredient;
      }
    });

    const choiceSelections: ChoiceSelections = {};

    // pay attention to the max of each choiceSet to avoid overfilling them
    lodash.values(item.choiceSets).forEach(choiceSet => {
      choiceSet.choices.forEach(choice => {
        const ingredient = withQuantitiesCollapsed[choice.id];

        while (
          ingredient &&
          ingredient.quantity &&
          (!choiceSet.max ||
            (choiceSelections[choiceSet.key] || []).length < choiceSet.max)
        ) {
          choiceSelections[choiceSet.key] = [
            ...(choiceSelections[choiceSet.key] || []),
            choice.id,
          ];

          ingredient.quantity -= 1;
        }
      });
    });

    const purchase = purchaseFromItem(
      item,
      undefined,
      choiceSelections,
      shadowPurchase.quantity,
    );

    purchases.push(purchase);
  } catch (e) {
    purchaseFailures.push(shadowPurchase);
  }
}
