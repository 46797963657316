import { createSelector } from 'reselect';

import getBuffer from './getBuffer';
import getLocationDeliveryEstimates from './getLocationDeliveryEstimates';

import { SALE_TYPE } from '../constants/saleType';

export default createSelector(
  [getBuffer, getLocationDeliveryEstimates],
  (buffer, locationDeliveryEstimates) =>
    !!locationDeliveryEstimates.length &&
    buffer.saleType === SALE_TYPE.DELIVERY,
);
