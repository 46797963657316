import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderingSelectors, OrderingOperations, OrderingHooks } from 'polygon-ordering';

import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES } from '../utils/theme';
import getThemeLookup from '../selectors/getThemeLookup';
import Text from './Text';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';

const { updateSelectedPaymentMethods } = OrderingOperations;

const { getSelectedPaymentMethods, getRemainingTotal, getOrderTotals, getSaleType } =
  OrderingSelectors;
const { useFormattedCurrency } = OrderingHooks;

const Line = ({
  label,
  amount,
  deemphasised = false,
  emphasised = false,
}: {
  label?: string;
  amount?: number | string;
  emphasised?: boolean;
  deemphasised?: boolean;
}) => {
  const value = useFormattedCurrency(Number(amount));
  const p = useAppSelector(getThemeLookup);
  let additionalStyle;

  if (deemphasised) {
    additionalStyle = p('orderTotalDeemphasised', TEXT_PROPERTIES);
  } else if (emphasised) {
    additionalStyle = null;
  } else {
    additionalStyle = p('orderTotalDeemphasised', ['fontSize', 'fontWeight']);
  }

  return (
    <div style={styles.line}>
      <Text themeKey="orderTotal" style={combineStyles(styles.orderTotalLabel, additionalStyle)}>
        {label}
      </Text>

      <Text themeKey="orderTotal" style={additionalStyle}>
        {value}
      </Text>
    </div>
  );
};

interface IProps {
  useMobileScreen?: boolean;
}

const OrderTotalsPaymentSummary: React.FC<IProps> = ({ useMobileScreen }) => {
  const p = useAppSelector(getThemeLookup);
  const dispatch = useAppDispatch();
  const saleType = useAppSelector(getSaleType);
  const orderTotals = useAppSelector(getOrderTotals);
  const remainingTotal = useAppSelector(getRemainingTotal);
  const selectedPaymentMethods = useAppSelector(getSelectedPaymentMethods);

  useEffect(() => {
    dispatch(updateSelectedPaymentMethods({}));
  }, [orderTotals?.discountedMoneyPrice, orderTotals?.pointsPrice]);

  const { t } = useTranslation();

  if (!orderTotals) {
    return null;
  }

  const showTotal = useMobileScreen;

  return (
    <div style={useMobileScreen ? styles.mainContainerMobile : styles.mainContainerDesktop}>
      {useMobileScreen && (
        <div
          style={combineStyles(
            styles.horizontalSeperator,
            p('orderTotalsSeperator', ['backgroundColor']),
          )}
        />
      )}

      {selectedPaymentMethods.length
        ? selectedPaymentMethods.map(selectedPaymentMethod => (
            <Line
              key={selectedPaymentMethod.method}
              label={
                selectedPaymentMethod.method == PAYMENT_METHOD.PAY_LATER
                  ? t(`paymentMethod.${selectedPaymentMethod.method}.${saleType}`)
                  : t(`paymentMethod.${selectedPaymentMethod.method}`)
              }
              amount={Number(selectedPaymentMethod.amount)}
              deemphasised
            />
          ))
        : null}

      <Line
        label={'Remaining'}
        amount={
          selectedPaymentMethods.length > 0 ? remainingTotal : orderTotals.discountedMoneyPrice
        }
      />

      {showTotal && (
        <div style={styles.totalContainer}>
          <Line label={t('totals.total')} amount={orderTotals.discountedMoneyPrice} emphasised />
        </div>
      )}
    </div>
  );
};

const styles: Styles = {
  mainContainerDesktop: {
    paddingBottom: 20,
  },
  mainContainerMobile: {
    paddingTop: 10,
  },
  horizontalSeperator: {
    height: 1,
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  totalContainer: {
    paddingTop: 20,
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 6,
    paddingLeft: 9,
    paddingRight: 9,
  },
  orderTotalLabel: {
    paddingRight: 10,
  },
};

export default OrderTotalsPaymentSummary;
