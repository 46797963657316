import { createSelector } from 'reselect';

import changePurchaseItem from '../utils/ordering/changePurchaseItem';
import findItemOrSubstitute from '../utils/ordering/findItemOrSubstitute';

import getBuffer from './getBuffer';
import getCurrentOrder from './getCurrentOrder';
import getItems, { $getItems } from './getItems';

const getUnenhancedStagedPurchases = createSelector(
  [getCurrentOrder],
  (order): _Purchase[] => order.stagedPurchases,
);

export default getUnenhancedStagedPurchases;

export const $getUnenhancedStagedPurchases = createSelector(
  [getBuffer, getUnenhancedStagedPurchases, $getItems, getItems],
  (buffer, stagedPurchases, bufferItems, orderItems): _Purchase[] => {
    if (!buffer || !stagedPurchases || !bufferItems || !orderItems) {
      return [];
    }

    const result: Array<_Purchase | undefined> = [];

    stagedPurchases.forEach(purchase => {
      const purchaseItem = orderItems[purchase.itemId];

      if (!purchaseItem) {
        return;
      }

      let newItem = findItemOrSubstitute(
        bufferItems,
        purchase.itemId,
        purchaseItem.name,
      );

      if (!newItem) {
        return;
      }

      result.push(
        changePurchaseItem(purchase, orderItems[purchase.itemId], newItem),
      );
    });

    return result as _Purchase[];
  },
);
