import React, { useEffect, useRef, useState, RefObject } from 'react';

import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES } from '../utils/theme';

import Text from './Text';
import TouchableOpacity from './TouchableOpacity';
import RedcatImage from './RedcatImage';
import { useAppSelector } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';
interface IProps {
  item: Brand | Category;
  isCurrent: boolean;
  setCurrentItemId: (id: string) => void;
  currentIndicatorColor?: string;
  labelThemeKey: string;
  currentLabelThemeKey: string;
  iconPathPrefix: string;
  iconSize: number;
}
const HorizontalMenuCell: React.FC<IProps> = props => {
  const p = useAppSelector(getThemeLookup);
  const ref = useRef<HTMLDivElement>();
  const isCurrentRef = useRef<boolean>(false);

  const {
    item,
    isCurrent,
    setCurrentItemId,
    currentIndicatorColor,
    labelThemeKey,
    currentLabelThemeKey,
    iconPathPrefix,
    iconSize = 25,
  } = props;

  const [current, setCurrent] = useState(isCurrent);

  useEffect(() => {
    isCurrentRef.current = current;

    if (!isCurrentRef.current && isCurrent) {
      ref.current!.scrollIntoView({
        block: 'nearest', // otherwise the screen is scrolled vertially as well (to put this at the top)
        inline: 'center', // keep current item as close to the center as possible
      });
    }
    setCurrent(isCurrent);
  }, [isCurrent]);

  //@ts-ignore
  const imagePath = item.imagePath; //imagePath doesnt exist in type Category

  return (
    <TouchableOpacity
      ref={ref as RefObject<HTMLDivElement>}
      style={combineStyles(styles.main)}
      onClick={() => setCurrentItemId(item.id)}
      disabled={isCurrent}
      ariaLabel={item.name}
    >
      <div
        style={combineStyles(styles.inner, {
          borderBottom: `2px solid ${isCurrent ? currentIndicatorColor : 'transparent'}`,
        })}
      >
        {imagePath && (
          <RedcatImage
            containerStyle={combineStyles(
              styles.tabImage,
              { marginRight: iconSize / 3 },
              isCurrent && p(currentLabelThemeKey, ['opacity']),
            )}
            imagePath={imagePath}
            pathPrefix={iconPathPrefix}
            alt={item.name}
            size={iconSize}
          />
        )}

        <Text
          themeKey={labelThemeKey}
          value={item.name}
          style={combineStyles(styles.label, isCurrent && p(currentLabelThemeKey, TEXT_PROPERTIES))}
        />
      </div>
    </TouchableOpacity>
  );
};

const styles: Styles = {
  main: {
    paddingBottom: 15,
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: 10,
    marginRight: 10,
  },
  inner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 5px 5px 5px',
    height: '100%',
  },
  label: {
    textAlign: 'center',
    whiteSpace: 'pre',
  },
  tabImage: {
    borderRadius: '25%',
    overflow: 'hidden',
  },
};

export default HorizontalMenuCell;
