import { findPropertyMinAndMax } from '../misc';

export default function enhanceItem(item: _Item, menu: Menu): Item {
  const choiceSets = item.choiceSets.map(
    choiceSetId => menu.choiceSets[choiceSetId],
  );

  const sizes = item.sizes.map(sizeId => menu.items[sizeId]);

  const minMaxBaseMoneyPrice = findPropertyMinAndMax(sizes, 'baseMoneyPrice');

  const minMaxKilojoules = findPropertyMinAndMax(sizes, 'kilojoules');

  const result: Item = {
    ...item,

    choiceSets,

    simpleItem: item.sizes.length === 1 && choiceSets.length === 0,

    minSizeBaseMoneyPrice: minMaxBaseMoneyPrice.min,
    maxSizeBaseMoneyPrice: minMaxBaseMoneyPrice.max,

    minSizeKilojoules: minMaxKilojoules.min,
    maxSizeKilojoules: minMaxKilojoules.max,
  };

  return result;
}
