import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import lodash from 'lodash';
import moment from 'moment';

import * as fetchDeliveryEstimate from '../../actionCreators/flows/fetchDeliveryEstimate';
import {
  setBufferDeliveryAddress,
  setBufferDeliveryCoordinates,
  setBufferLocationDeliveryEstimates,
} from '../../actionCreators/buffer';

import { $getOrderTotals } from '../../selectors/getOrderTotals';
import { $getDeliveryTime } from '../../selectors/getDeliveryTime';
import { $getDeliveryAddressString } from '../../selectors/getDeliveryAddressString';
import { $getStagedPurchaseCount } from '../../selectors/getStagedPurchaseCount';

import { ASAP_TIME } from '../../constants';
import { FAILURE_REASON } from '../../constants/failureReasons';

import Api, { FetchParams, ApiResponse } from '../../utils/Api';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';
import processDeliveryEstimate from '../../utils/processors/processDeliveryEstimate';
import processAddress from '../../utils/processors/processAddress';

import combineTokenisedAddress from '../../utils/ordering/combineTokenisedAddress';
import { getOrderingProvider } from '../../selectors/config';

import applyLocationEstimate from '../applyLocationEstimate';

export const requested = createFlowApprover(fetchDeliveryEstimate);

function* handleIndividualEstimate(
  estimateBase: DeliveryEstimateBase,
  locationEstimate: LocationDeliveryEstimate,
) {
  yield all([
    put(setBufferDeliveryAddress(estimateBase.address)),
    put(setBufferDeliveryCoordinates(estimateBase.coordinates)),
    call(applyLocationEstimate, locationEstimate),
  ]);
}

function* handleMultipleEstimate(
  estimateBase: DeliveryEstimateBase,
  locationEstimates: LocationDeliveryEstimate[],
) {
  yield all([
    put(setBufferDeliveryAddress(estimateBase.address)),
    put(setBufferDeliveryCoordinates(estimateBase.coordinates)),

    put(setBufferLocationDeliveryEstimates(locationEstimates)),
  ]);
}

export function* approved(
  action: ReturnType<typeof fetchDeliveryEstimate.actions.approved>,
) {
  const {
    payload: {
      deliveryAddress,
      desiredDeliveryTime,
      locationId,
      multiple = false,
      forceASAPDeliveryEstimate = false,
    },
    meta: { flowId },
  } = action;

  // no multi estimate if location given
  const performMultiple = locationId != null ? false : multiple;

  let reason: fetchDeliveryEstimate.FailureReason = FAILURE_REASON.UNKNOWN;
  let userReason: string | undefined;
  let systemReason: string | undefined;
  let matchedAddressString: string | undefined;

  try {
    let addressString =
      deliveryAddress || (yield select($getDeliveryAddressString));

    if (!addressString) {
      reason = FAILURE_REASON.MISSING_PARAMETER;
      throw new Error('address required');
    }

    let deliveryTime =
      desiredDeliveryTime || (yield select($getDeliveryTime)) || ASAP_TIME;

    if (deliveryTime != ASAP_TIME) {
      deliveryTime = moment(deliveryTime).format();
    }

    if (forceASAPDeliveryEstimate) {
      deliveryTime = ASAP_TIME;
    }

    const orderTotals = yield select($getOrderTotals);
    const total = lodash.get(orderTotals, 'purchasesMoneyPrice', 0);
    const stagedPurchaseCount = (yield select($getStagedPurchaseCount)) || 0;
    const orderingProvider = yield select(getOrderingProvider);

    const urlParams = new URLSearchParams(
      lodash.pickBy(
        {
          desiredTime: deliveryTime,
          address: addressString,
          itemTotal: total,
          qtyTotal: stagedPurchaseCount,
          orderingProvider,
          store: locationId,
          multiple: performMultiple,
        },
        value => value != null,
      ),
    );

    const params: FetchParams = {
      path: `/api/v1/delivery/best-estimate?${urlParams.toString()}`,
      method: 'GET',
    };

    const response: ApiResponse = yield call(Api.fetch, params);

    console.log('fetched delivery estimate', { response, params });

    const rawEstimate = response?.data as RawDeliveryEstimateBase;

    if (!rawEstimate.DeliveryAvailable) {
      if (rawEstimate.DeliveryAddress) {
        reason = FAILURE_REASON.DELIVERY_UNAVAILABLE;
        matchedAddressString = combineTokenisedAddress(
          processAddress(rawEstimate.DeliveryAddress),
        );
      } else {
        reason = FAILURE_REASON.ADDRESS_NOT_FOUND;
      }

      userReason = rawEstimate.UserNotPossibleReason;
      systemReason = rawEstimate.NotPossibleReason;

      throw new Error(
        `delivery not available: ${rawEstimate.NotPossibleReason}`,
      );
    }

    const { estimateBase, locationEstimates } = processDeliveryEstimate(
      rawEstimate,
      deliveryTime,
    );

    if (performMultiple) {
      yield call(handleMultipleEstimate, estimateBase, locationEstimates);
    } else {
      yield call(handleIndividualEstimate, estimateBase, locationEstimates[0]);
    }

    yield put(
      fetchDeliveryEstimate.actions.succeeded(
        { multiple: performMultiple },
        flowId,
      ),
    );
  } catch (e) {
    yield put(
      fetchDeliveryEstimate.actions.failed(
        {
          error: makeErrorSerialisable(e),
          reason,
          userReason,
          systemReason,
          matchedAddressString,
        },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(fetchDeliveryEstimate.events.REQUESTED, requested),
    takeEvery(fetchDeliveryEstimate.events.APPROVED, approved),
  ]);
}
