import { call, put, takeEvery, all, select } from 'redux-saga/effects';

import * as fetchOffers from '../../actionCreators/flows/fetchOffers';

import { addOffers, setOffers } from '../../actionCreators/currentOrder';
import { setBufferOffers } from '../../actionCreators/buffer';

import getLocationId, { $getLocationId } from '../../selectors/getLocationId';
import getSelectedOffer from '../../selectors/getSelectedOffer';

import Api, { FetchParams, ApiResponse } from '../../utils/Api';
import processOffer from '../../utils/processors/processOffer';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';

export const requested = createFlowApprover(fetchOffers);

// NOTE: have approval to add a promo code URL param to this fetch that will add an offer to the result (if it works)
// https://trello.com/c/jEaqPtiY/2301-add-param-to-member-offers-api-call-to-merge-in-result-of-promocode-lookup

// NOTE: inludepromocode should instead be `attemptToIncludeOffer=hash`
// because its possible to have a non-member offer selected (that has no barcode)

// TODO: support trusted auth (including memberno amongst incoming payload values)
export function* approved(
  action: ReturnType<typeof fetchOffers.actions.approved>,
) {
  const {
    payload: { promoCode, authenticationMethod = 'member', bufferMode = false },
    meta: { flowId },
  } = action;

  try {
    const locationId = yield select(
      bufferMode ? $getLocationId : getLocationId,
    );
    const selectedOffer = (yield select(getSelectedOffer)) as ReturnType<
      typeof getSelectedOffer
    >;

    if (!locationId) {
      throw new Error('no location id');
    }

    let pathSuffix = `?hideexpired=1&hideredeemed=1&storeid=${locationId}`;

    if (promoCode) {
      pathSuffix = `/${promoCode}?storeid=${locationId}`;
    } else if (selectedOffer) {
      pathSuffix += `&include_extra=${encodeURIComponent(selectedOffer.hash)}`;
    }

    const params: FetchParams = {
      path: `/api/v1/profile/coupons/ordering${pathSuffix}`,
      method: 'GET',
    };

    const response: ApiResponse = yield call(
      Api.fetch,
      params,
      authenticationMethod,
    );

    const rawOffers: RawOffer[] = response.data;
    const processedOffers = rawOffers.map(processOffer);

    if (bufferMode) {
      yield put(setBufferOffers(processedOffers));
    } else if (promoCode) {
      yield put(addOffers(processedOffers));
    } else {
      yield put(setOffers(processedOffers));
    }

    yield put(
      fetchOffers.actions.succeeded({ offers: processedOffers }, flowId),
    );
  } catch (e) {
    yield put(
      fetchOffers.actions.failed({ error: makeErrorSerialisable(e) }, flowId),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(fetchOffers.events.REQUESTED, requested),
    takeEvery(fetchOffers.events.APPROVED, approved),
  ]);
}
