import React, { useState, useRef, useEffect } from 'react';
import { RiSettings2Line } from 'react-icons/ri';
import json5 from 'json5'; // more forgiving json parser

import { OrderingOperations } from 'polygon-ordering';

import { promptForDevEndpoint } from '../utils/endpoint';

import { setThemeOverrides } from '../slices/config/themeOverrides';
import { setEnableThemeKeyHints } from '../slices/config/enableThemeKeyHints';
import applyServerThemeOverridesAction from '../actions/applyServerThemeOverrides';

import TouchableOpacity from './TouchableOpacity';
import { useAppSelector, useAppDispatch } from '../app/hooks';

const SIZE = 40;

function fallbackCopyTextToClipboard(text: string) {
  var textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log('Async: Copying to clipboard was successful!');
    },
    function (err) {
      console.error('Async: Could not copy text: ', err);
    },
  );
}

const DevTools: React.FC = () => {
  const { startNewOrder, setShowHiddenLocations } = OrderingOperations;
  const [state, setState] = useState<{ showThemeTool: boolean; value: string }>({
    showThemeTool: false,
    value: '',
  });
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const dispatch = useAppDispatch();
  const enableThemeKeyHints = useAppSelector(state => state.config.enableThemeKeyHints);
  const showHiddenLocations = useAppSelector(state => state.ordering.config.showHiddenLocations);

  const loadValueFromStorage = () => {
    setState({ ...state, value: localStorage.getItem('orderingDevThemeOverrides') || '' });
  };

  useEffect(() => {
    loadValueFromStorage();
  }, []);

  const changeThemeValue = (value: string) => {
    localStorage.setItem('orderingDevThemeOverrides', value);
    setState({ ...state, value });
  };

  const { showThemeTool, value } = state;
  const themeToolRef = useRef<any>(showThemeTool);

  useEffect(() => {
    if (!themeToolRef.current && showThemeTool) {
      inputRef.current?.focus();
    }
    themeToolRef.current = showThemeTool;
  }, [showThemeTool]);

  const close = () => setState({ ...state, showThemeTool: false });

  const apply = () => {
    if (!value) {
      dispatch(setThemeOverrides({}));
      return;
    }

    try {
      const themeOverrides = json5.parse(value);
      dispatch(setThemeOverrides(themeOverrides));
      changeThemeValue(JSON.stringify(themeOverrides, null, 2));
    } catch (e) {
      alert('syntax error');
      return;
    }
  };

  return (
    <>
      <TouchableOpacity
        style={styles.main}
        onClick={() => setState({ ...state, showThemeTool: !showThemeTool })}
      >
        <RiSettings2Line color="white" size={SIZE - 15} />
      </TouchableOpacity>

      {showThemeTool && (
        <div
          style={styles.overlay}
          onClick={event => {
            if (event.target === event.currentTarget) {
              setState({ ...state, showThemeTool: !showThemeTool });
            }
          }}
        >
          <textarea
            value={value}
            ref={inputRef}
            onChange={event => changeThemeValue(event?.target?.value)}
            style={styles.input}
            placeholder="Enter theme overrides"
            onFocus={event => event.target.select()}
          />

          <div style={styles.buttons}>
            <TouchableOpacity onClick={close} style={styles.button}>
              CLOSE
            </TouchableOpacity>

            <TouchableOpacity onClick={apply} style={styles.button}>
              APPLY
            </TouchableOpacity>

            <TouchableOpacity
              onClick={() => {
                apply();
                close();
              }}
              style={styles.button}
            >
              APPLY+CLOSE
            </TouchableOpacity>

            <TouchableOpacity
              onClick={() => {
                changeThemeValue('');
                dispatch(setThemeOverrides({}));
              }}
              style={styles.button}
            >
              CLEAR
            </TouchableOpacity>

            <TouchableOpacity onClick={() => copyTextToClipboard(value)} style={styles.button}>
              COPY
            </TouchableOpacity>

            <TouchableOpacity
              onClick={() => {
                dispatch(applyServerThemeOverridesAction());
                setTimeout(() => loadValueFromStorage(), 1000);
              }}
              style={styles.button}
            >
              LOAD SERVER
            </TouchableOpacity>

            <TouchableOpacity onClick={() => promptForDevEndpoint()} style={styles.button}>
              CHANGE ENDPOINT
            </TouchableOpacity>

            <TouchableOpacity
              onClick={() => dispatch(startNewOrder({ preserveMember: true }))}
              style={styles.button}
            >
              NEW ORDER
            </TouchableOpacity>

            <TouchableOpacity
              onClick={() => dispatch(setShowHiddenLocations(!showHiddenLocations))}
              style={styles.button}
            >
              {showHiddenLocations ? 'SHOW HIDDEN: T' : 'SHOW HIDDEN: F'}
            </TouchableOpacity>

            <TouchableOpacity
              onClick={() => dispatch(setEnableThemeKeyHints(!enableThemeKeyHints))}
              style={styles.button}
            >
              {enableThemeKeyHints ? 'THEME HINTS: T' : 'THEME HINTS: F'}
            </TouchableOpacity>
          </div>
        </div>
      )}
    </>
  );
};

const styles: Styles = {
  main: {
    position: 'absolute',

    bottom: 10,
    left: 10,

    height: SIZE,
    width: SIZE,

    borderRadius: SIZE / 2,

    backgroundColor: 'black',

    zIndex: 999999,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  overlay: {
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 999998,
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    padding: 100,
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
  },
  input: {
    flex: 1,
    padding: 10,
    resize: 'none',
  },
  buttons: {
    width: '100%',
    height: 50,
    backgroundColor: '#1B1F22',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  button: {
    color: 'white',
    marginLeft: 15,
    marginRight: 15,
  },
};

export default DevTools;
