import React from 'react';

import getThemeLookup from '../selectors/getThemeLookup';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import Text from './Text';
import { HEADER_BRANDING_TYPES } from '../constants';
import combineStyles from '../utils/combineStyles';
import { useAppSelector } from '../app/hooks';

const HeaderBranding = () => {
  const p = useAppSelector(getThemeLookup);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const headerBrandImagePath = useAppSelector(state => state.config.headerBrandImagePath);
  const headerBrandName = useAppSelector(state => state.config.headerBrandName);
  const headerBrandingType = useAppSelector(state => state.config.headerBrandingType);

  return (
    <div style={styles.mainContainer}>
      {headerBrandingType === HEADER_BRANDING_TYPES.LONG ? (
        Boolean(headerBrandImagePath) &&
        headerBrandImagePath && (
          <img
            className="imageLong"
            style={combineStyles(styles.imageLong, p('headerBrandingImage', ['maxHeight']))}
            src={headerBrandImagePath}
            alt="logo"
          />
        )
      ) : (
        <>
          {Boolean(headerBrandImagePath) && headerBrandImagePath && (
            <img
              className="ImageSquare"
              style={combineStyles(styles.imageSquare, p('headerBrandingImage', ['maxHeight']))}
              src={headerBrandImagePath}
              alt="logo"
            />
          )}

          <Text
            themeKey="headerBrandingName"
            style={p(deviceTypeMobile ? 'headerBrandingNameMobile' : 'headerBrandingNameDesktop', [
              'fontSize',
            ])}
          >
            {headerBrandName}
          </Text>
        </>
      )}
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    flex: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingRight: 20,
  },
  imageLong: {
    width: '100%',
    objectFit: 'contain',
    objectPosition: '0 0',
  },
  imageSquare: {
    maxHeight: '150%',
    marginRight: 10,
    objectFit: 'contain',
  },
};

export default HeaderBranding;
