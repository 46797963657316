import { call, put, takeEvery, all } from 'redux-saga/effects';

import * as fetchBrands from '../../actionCreators/flows/fetchBrands';
import { setBrands } from '../../actionCreators/config';

import Api, { FetchParams, ApiResponse } from '../../utils/Api';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';
import processBrand from '../../utils/processors/processBrand';

export const requested = createFlowApprover(fetchBrands);

export function* approved(
  action: ReturnType<typeof fetchBrands.actions.approved>,
) {
  const {
    meta: { flowId },
  } = action;

  try {
    const params: FetchParams = {
      path: '/api/v1/brands',
      method: 'GET',
    };

    const response: ApiResponse = yield call(Api.fetch, params);

    const rawOrders: RawBrand[] = response.data;
    const processedBrands = rawOrders
      .map(processBrand)
      .filter(brand => brand.active);

    yield put(setBrands(processedBrands));

    yield put(fetchBrands.actions.succeeded({}, flowId));
  } catch (e) {
    yield put(
      fetchBrands.actions.failed({ error: makeErrorSerialisable(e) }, flowId),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(fetchBrands.events.REQUESTED, requested),
    takeEvery(fetchBrands.events.APPROVED, approved),
  ]);
}
