import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { sendVerificationMedium } from '../thunks/sendVerificationMedium';
import getConfig from '../selectors/getConfig';
import getProfile from '../selectors/getProfile';
import { OrderingSelectors } from 'polygon-ordering';
import ReCAPTCHA from 'react-google-recaptcha';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import StandardButton, { IProps as StandardButtonProps } from '../components/StandardButton';
import { enqueueWarningSnackbar, enqueueErrorSnackbar } from '../utils/snackbar';
import combineStyles from '../utils/combineStyles';

const { getMember } = OrderingSelectors;

interface IProps extends StandardButtonProps {
  containerStyle?: React.CSSProperties;
}

const SendVerificationButton: React.FC<IProps> = ({
  containerStyle,
  label = 'Resend Verification',
  disabled: disabledProp,
  onClick,
  ...rest
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(getProfile);
  const config = useAppSelector(getConfig);
  const [clicked, setClicked] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const reCaptchaSiteKey = useAppSelector(state => state.config.reCaptchaSiteKey);
  const [reCaptchaToken, setReCaptchaToken] = useState<null | string>(null);
  const enableReCaptcha = useAppSelector(state => state.config.enableReCaptcha);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);

  const { loyaltySendVerificationEmail, loyaltyEnableSmsVerification } = config;

  // A hacky way to disable button so unders do not spam the verification
  const changeClickedState = () => setClicked(false);

  // TODO: Test SMS
  // console.log({ loyaltySendVerificationEmail, loyaltyEnableSmsVerification });
  const mediumSelect = (emailText = 'Email', smsText = 'SMS', icon = false) => {
    if (icon && loyaltySendVerificationEmail && loyaltyEnableSmsVerification) {
      return emailText;
    }

    const email = (loyaltySendVerificationEmail && emailText) || '';
    const sms = (loyaltyEnableSmsVerification && smsText) || '';
    const both = (loyaltySendVerificationEmail && loyaltyEnableSmsVerification && `/`) || '';

    return `${email}${both}${sms}`;
  };

  const verificationPrompt = () => {
    if (onClick) onClick();
    setIsSending(true);
    dispatch(sendVerificationMedium(reCaptchaToken))
      .then((response: any) => {
        // enqueueWarningSnackbar(`${response?.payload?.data}`);
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        setIsSending(false); // Reset the flag
        setReCaptchaToken(null); // Clear the token
      });
  };

  return (
    <div>
      <div
        style={combineStyles(
          {
            display: 'flex',
            justifyContent: 'center',
            alignSelf: 'center',
          },
          containerStyle,
        )}
      >
        {!profile?.verified ? (
          <StandardButton
            label={label}
            {...rest}
            disabled={
              (enableReCaptcha && reCaptchaToken == null ? true : isSending) || disabledProp
            }
            onClick={() => {
              if (!enableReCaptcha || reCaptchaToken != null) {
                verificationPrompt();
              }
            }}
            themeKey="loyaltyButton"
            containerStyle={styles.button}
            showSpinner={isSending}
          />
        ) : null}
      </div>
      <div
        style={combineStyles({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          marginTop: 15,
          justifyContent: 'center',
        })}
      >
        {!profile?.verified && enableReCaptcha && !reCaptchaToken && !disabledProp ? (
          <ReCAPTCHA
            sitekey={reCaptchaSiteKey!}
            onChange={token => setReCaptchaToken(token)}
            onErrored={() => {
              setReCaptchaToken(null);
              enqueueErrorSnackbar(t('problemWithReCaptchaMessage'));
            }}
            size={deviceTypeMobile ? 'compact' : 'normal'}
          />
        ) : null}
      </div>
    </div>
  );
};
const styles = {
  button: {
    borderRadius: 5,
    height: 40,
  },
};

export default SendVerificationButton;
