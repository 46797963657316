import React, { useEffect, useState } from 'react';
import lodash from 'lodash';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import { OrderingConstants } from 'polygon-ordering';

import generateMomentsInRange from '../utils/generateMomentsInRange';
import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES, SELECT_PROPERTIES } from '../utils/theme';
import getThemeLookup from '../selectors/getThemeLookup';
import Text from './Text';
import { useAppSelector } from '../app/hooks';

const { ASAP_TIME } = OrderingConstants;

const REFRESH_INTERVAL_DURATION = 1000 * 60; // 1 minute

const TimeSelector: React.FC<{
  themeKey: string;
  reducedWidth?: number;

  minuteStep?: number;
  timePadding?: number;

  earliestTime?: string | null;
  latestTime?: string | null;

  value?: string;

  setValue: (value: string | null) => void;

  suppressEstimate?: boolean;
  readonly?: boolean;
  lockASAP?: boolean;

  showTimeInErrorMessage?: boolean;
  timeInErrorMessage?: string;

  timezone?: string;
}> = props => {
  const [, forceUpdate] = useState(0);
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  useEffect(() => {
    const refreshInterval = setInterval(() => {
      forceUpdate(x => x + 1);
    }, REFRESH_INTERVAL_DURATION);
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  const {
    themeKey,
    reducedWidth,

    minuteStep = 5,
    timePadding = 0,

    earliestTime,
    latestTime,

    value: valueProp,

    setValue,

    suppressEstimate,
    readonly,
    lockASAP,

    showTimeInErrorMessage,
    timeInErrorMessage,
    timezone = moment.tz.guess(),
  } = props;

  let currentValue: string | undefined = valueProp;

  if (!currentValue || currentValue.toUpperCase() === ASAP_TIME || lockASAP) {
    // console.log('BONGOBGON !877657468492345784937');
    currentValue = undefined;
  }

  const currentValueMoment = moment(currentValue);

  const earliestTimeMoment = moment(earliestTime).startOf('minute');

  const nowToNearestMinute = moment.tz(timezone).startOf('minute').add(timePadding, 'minutes');

  const earliestSelectableMoment = lodash.orderBy(
    [earliestTimeMoment, nowToNearestMinute],
    lodash.identity,
    'desc',
  )[0];

  const latestSelectableMoment = moment(latestTime);

  const currentValueAfterLatestTime = currentValueMoment > latestSelectableMoment;

  const noValidTime = latestSelectableMoment <= earliestSelectableMoment;

  const currentValueBeforeEarliestTime = currentValueMoment < earliestSelectableMoment;

  const estimatedASAP = earliestSelectableMoment.tz(timezone).startOf('minute').format('h:mm a');

  const estimatedASAPValue = earliestSelectableMoment.toISOString();

  if (currentValueBeforeEarliestTime || currentValueAfterLatestTime || noValidTime) {
    currentValue = undefined;
  }

  // Determine nearest minuteStep (ceil) for time range
  const earliestSelectableToNearestMinuteStep = moment(
    earliestSelectableMoment
      .minutes(Math.ceil((earliestSelectableMoment.minute() + 1) / minuteStep) * minuteStep)
      .startOf('minute'),
  );

  const times = generateMomentsInRange(
    earliestSelectableToNearestMinuteStep, // was 'nowToNearestMinuteStep' (formerly 'nowWithPaddingMoment'), but this allowed for time selections before what was constrained by 'earliestTime'
    latestSelectableMoment,
    minuteStep,
  );

  const value = currentValue ? moment(currentValue).toISOString() : ASAP_TIME;

  const problemExists = noValidTime || currentValueAfterLatestTime;

  return (
    <div style={problemExists && showTimeInErrorMessage ? styles.mainContainer : undefined}>
      <select
        key={String(value !== ASAP_TIME)} // force a full rerender to prevent warning about mixing padding and paddingLeft/Right
        value={value}
        onChange={event => {
          const newValue = event?.target?.value;
          setValue(newValue === ASAP_TIME ? estimatedASAPValue : newValue);
        }}
        disabled={readonly || lockASAP || noValidTime}
        style={combineStyles(
          { textAlignLast: 'center' },
          p('defaultText', TEXT_PROPERTIES),
          p('input', SELECT_PROPERTIES),
          p(themeKey, SELECT_PROPERTIES),
          reducedWidth &&
            value !== ASAP_TIME && {
              width: reducedWidth, // horrible hack to shrink the select to keep the arrow closer
              paddingLeft: 0,
              paddingRight: 0,
            },
        )}
      >
        {problemExists ? (
          <option value={ASAP_TIME}>{noValidTime ? t('noValidTime') : t('invalidTime')}</option>
        ) : (
          <>
            <option value={ASAP_TIME}>{`${t('asap')}${
              suppressEstimate ? '' : ` (${estimatedASAP})`
            }`}</option>

            {times.map(time => {
              const optionValue = time.toISOString();
              const formatted = time.tz(timezone).format('h:mm a');

              return (
                <option key={optionValue} value={optionValue}>
                  {formatted}
                </option>
              );
            })}
          </>
        )}
      </select>

      {problemExists && showTimeInErrorMessage && (
        <Text themeKey="timeInErrorMessage" block style={styles.timeInErrorMessage}>
          {timeInErrorMessage}
        </Text>
      )}
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  timeInErrorMessage: {
    marginTop: 7,
    textAlign: 'center',
    maxWidth: 130,
  },
};

export default TimeSelector;
