import React from 'react';
import { useTranslation } from 'react-i18next';

import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';

import getThemeLookup from '../selectors/getThemeLookup';

import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES, CONTAINER_PROPERTIES } from '../utils/theme';
import { useAppSelector, useAppDispatch } from '../app/hooks';

const { getDeliveryNotes } = OrderingSelectors;
const { setDeliveryNotes } = OrderingOperations;

interface IProps {
  style?: React.CSSProperties;
}

const DriverNotes: React.FC<IProps> = ({ style }) => {
  const p = useAppSelector(getThemeLookup);
  const deliveryNotes = useAppSelector(getDeliveryNotes);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <textarea
      value={deliveryNotes || ''}
      onChange={event => dispatch(setDeliveryNotes(event?.target?.value))}
      style={combineStyles(
        styles.notes,
        style,
        p('defaultText', TEXT_PROPERTIES),
        p('input', CONTAINER_PROPERTIES),
      )}
      placeholder={t('placeholder.deliveryNotes')}
    />
  );
};

const styles: Styles = {
  notes: {
    minHeight: 50,
    marginBottom: 35,
    padding: 5,
    width: '100%',
    resize: 'vertical',
  },
};

export default DriverNotes;
