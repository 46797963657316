import React from 'react';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { useTranslation } from 'react-i18next';
import history from '../history';
import getThemeLookup from '../selectors/getThemeLookup';
import getOrderInProgress from '../selectors/getOrderInProgress';
import StandardButton from './StandardButton';
import { MENU_SCREEN_ROUTE } from '../screens/MenuScreen';
import { INITIAL_SCREEN_ROUTE } from '../screens/InitialScreen';
import '../styles/HeaderBar.css';
import { useAppSelector } from '../app/hooks';

const HeaderBackButton: React.FC = () => {
  const p = useAppSelector(getThemeLookup);
  const orderInProgress = useAppSelector(getOrderInProgress);
  const { t } = useTranslation();

  const destination = orderInProgress ? MENU_SCREEN_ROUTE : INITIAL_SCREEN_ROUTE;

  return (
    <StandardButton
      invisibleContainer
      label={t(`button.headerBack.${destination}`)}
      LeftIconComponent={RiArrowLeftSLine}
      onClick={() => {
        history.push(destination);
      }}
      themeKey="headerBackButton"
      iconStyle={p('headerBackButtonIcon', ['color', 'fontSize'])}
    />
  );
};

export default HeaderBackButton;
