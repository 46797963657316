import { createCustomAction } from 'typesafe-actions';

import {
  standardEmptyACC,
  standardFlowACC,
  standardFailedACC,
} from '../../utils/sagas';

const REQUESTED = '$APPLY_SHADOW_ORDER/REQUESTED';
const BLOCKED = '$APPLY_SHADOW_ORDER/BLOCKED';
const APPROVED = '$APPLY_SHADOW_ORDER/APPROVED';
const SUCCEEDED = '$APPLY_SHADOW_ORDER/SUCCEEDED';
const FAILED = '$APPLY_SHADOW_ORDER/FAILED';

const success = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      purchaseFailures: ShadowItem[];
      ingredientFailures: ShadowItem[];
      purchases: _Purchase[];
    }>(type);
};

export const events = {
  REQUESTED,
  BLOCKED,
  APPROVED,
  SUCCEEDED,
  FAILED,
};

export const actions = {
  requested: createCustomAction(REQUESTED, standardEmptyACC),
  approved: createCustomAction(APPROVED, standardEmptyACC),
  blocked: createCustomAction(BLOCKED, standardEmptyACC),
  succeeded: createCustomAction(SUCCEEDED, success),
  failed: createCustomAction(FAILED, standardFailedACC),
};

export default actions.requested;
