import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import lodash from 'lodash';

import * as fetchMember from '../../actionCreators/flows/fetchMember';
import * as setMember from '../../actionCreators/flows/setMember';

import getLocationId from '../../selectors/getLocationId';
import getMember from '../../selectors/getMember';

import Api, { FetchParams, ApiResponse } from '../../utils/Api';
import {
  createFlowApprover,
  makeErrorSerialisable,
  requestAndWaitForFlow,
} from '../../utils/sagas';

export const requested = createFlowApprover(fetchMember);

export function* approved(
  action: ReturnType<typeof fetchMember.actions.approved>,
) {
  const {
    payload: { memberNumber, locationId, authenticationMethod },
    meta: { flowId },
  } = action;

  try {
    let locationIdSafe = locationId;
    if (authenticationMethod === 'trusted') {
      if (!locationIdSafe) {
        locationIdSafe = yield select(getLocationId);

        if (!locationIdSafe) {
          throw new Error('no location id');
        }
      }
    }

    let memberNumberSafe = memberNumber;
    if (!memberNumberSafe && authenticationMethod !== 'member') {
      const member = yield select(getMember);
      memberNumberSafe = lodash.get(member, 'memberNumber', undefined);

      if (!memberNumberSafe) {
        throw new Error('no member number');
      }
    }

    const params: FetchParams = {
      path:
        authenticationMethod === 'trusted'
          ? `/api/v1/pos-points?StoreID=${locationIdSafe}&Member=${encodeURIComponent(
              memberNumberSafe || '',
            )}`
          : '/api/v1/profile',
      method: 'GET',
    };

    const response: ApiResponse = yield call(
      Api.fetch,
      params,
      authenticationMethod,
    );

    yield call(
      requestAndWaitForFlow,
      setMember,
      { rawMember: response.data },
      flowId,
    );

    yield put(fetchMember.actions.succeeded({}, flowId));
  } catch (e) {
    yield put(
      fetchMember.actions.failed({ error: makeErrorSerialisable(e) }, flowId),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(fetchMember.events.REQUESTED, requested),
    takeEvery(fetchMember.events.APPROVED, approved),
  ]);
}
