import lodash from 'lodash';

import processSize from '../processors/processSize';

export default function processChoicesFromMasterItem(
  rawSize: RawSize,
): SDict<Choice> {
  const [_, choiceSets] = processSize(rawSize);

  return lodash.keyBy(
    lodash.flatMap(lodash.values(choiceSets), choiceSet => choiceSet.choices),
    choice => choice.id,
  );
}
