export default function (
  template: string | null | undefined,
  data: { [key: string]: string } | null | undefined,
  fallback: string,
) {
  console.log(template, data, fallback, 'HERE');
  return (template || '').replace(/\${(.*?)}/g, (x, g) => {
    const result = (data || {})[g] || fallback;

    return result == null ? fallback : result;
  });
}

// e.g. "${address1}" or "${address1} - ${state}"
