import { ActionType, getType } from 'typesafe-actions';

import * as currentOrder from '../../actionCreators/currentOrder';

const initialState = 0;

export default function (
  state: number | 0 = initialState,
  action: ActionType<typeof currentOrder>,
): number {
  switch (action.type) {
    case getType(currentOrder.setRemainingTotal): {
      const { value } = action.payload;
      return value == null ? initialState : value;
    }
    default:
      return state;
  }
}
