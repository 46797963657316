import { ActionType, getType } from 'typesafe-actions';

import * as buffer from '../../actionCreators/buffer';

const initialState = null;

export default function (
  state: string | null = null,
  action: ActionType<typeof buffer>,
): string | null {
  switch (action.type) {
    case getType(buffer.clearBuffer): {
      const { preserveReservationId } = action.payload;
      return preserveReservationId ? state : initialState;
    }
    case getType(buffer.reserveBuffer): {
      const { value } = action.payload;
      return value == null ? initialState : value;
    }
    case getType(buffer.setBufferReadyToApply): {
      return initialState;
    }
    default:
      return state;
  }
}
