import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import { ThunkAction, Action } from '@reduxjs/toolkit';
import rootReducer, { PERSIST_KEYS } from './slices';
import rootSaga from './sagas';
import { PERSIST_KEY } from './constants';

const orderingTransform = createTransform(
  null,
  (outboundState: any, key) => {
    const newState = { ...outboundState };
    // Clear out selectedPaymentMethods on refresh & new orders
    newState.currentOrder.selectedPaymentMethods = [];
    return newState;
  },
  { whitelist: ['ordering'] },
);

const persistConfig = {
  key: PERSIST_KEY,
  storage,
  whitelist: PERSIST_KEYS,
  transforms: [orderingTransform],
};

export type RootState = ReturnType<typeof rootReducer>;

const resettableRootReducer = (state: RootState, action: Action) => {
  if (action.type === '$RESET_ROOT_STATE') {
    storage.removeItem('persist:' + PERSIST_KEY);
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

const persistedReducer = persistReducer<RootState>(
  persistConfig,
  resettableRootReducer as typeof rootReducer,
);

const sagaMiddleware = createSagaMiddleware();

// NOTE: need to do a few extra steps to combine RTK with redux-persist
// https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const persistor = persistStore(store);

// make the redux store easily accessible via the window global
// (including the dispatchInjector script)

window.redux_store = store;

sagaMiddleware.run(rootSaga);
