import { put, takeEvery, all } from 'redux-saga/effects';

import * as startNewOrder from '../../actionCreators/flows/startNewOrder';

import {
  resetOrder,
  setLocationId,
  setSaleType,
} from '../../actionCreators/currentOrder';

import { makeErrorSerialisable, createFlowApprover } from '../../utils/sagas';

export const requested = createFlowApprover(startNewOrder);

export function* approved(
  action: ReturnType<typeof startNewOrder.actions.approved>,
) {
  const {
    payload: {
      preserveMember = false,
      saleType,
      locationId,
      uuid,
      lifecycleEventParams,
    },
    meta: { flowId },
  } = action;

  try {
    yield put(resetOrder({ preserveMember, uuid }));

    if (saleType != null) {
      yield put(setSaleType(saleType));
    }

    if (locationId != null) {
      yield put(setLocationId(locationId));
    }

    yield put(
      startNewOrder.actions.succeeded({ lifecycleEventParams }, flowId),
    );
  } catch (e) {
    yield put(
      startNewOrder.actions.failed({ error: makeErrorSerialisable(e) }, flowId),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(startNewOrder.events.REQUESTED, requested),
    takeEvery(startNewOrder.events.APPROVED, approved),
  ]);
}
