import React, { useState, useEffect } from 'react';
import { RiRefreshLine } from 'react-icons/ri';
import {
  Elements,
  PaymentRequestButtonElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {
  loadStripe,
  PaymentRequest,
  PaymentRequestCompleteStatus,
  Stripe,
} from '@stripe/stripe-js';
import lodash from 'lodash';

import {
  OrderingPaymentHooks,
  OrderingConstants,
  OrderingSelectors,
  OrderingOperations,
} from 'polygon-ordering';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';

import getPaymentGatewayPublicKey from '../selectors/getPaymentGatewayPublicKey';

import { setCheckoutInProgress } from '../slices/checkoutInProgress';
import checkoutWithPaymentRequestAction from '../actions/checkoutWithPaymentRequest';
import validateWithPaymentRequestAction from '../actions/validateWithPaymentRequest';

import debug from '../utils/debug';
import { useAppSelector, useAppDispatch } from '../app/hooks';
const { PRE_FINALISE_PAYMENT_REQUEST } = OrderingConstants;
const { updateKeyOrderProperty } = OrderingOperations;

const {
  getSelectedPaymentMethods,
  getPaymentGatewayConfig,
  getSaleType,
  getOrderTotals,
  getStripeCurrency,
} = OrderingSelectors;

const PaymentButton = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<PAYMENT_METHOD | null>(null);
  const [total, setTotal] = useState<number>(0);
  const selectedPaymentMethods = useAppSelector(getSelectedPaymentMethods);
  const saleType = useAppSelector(getSaleType);
  const orderTotals = useAppSelector(getOrderTotals);
  const validationInProgress = useAppSelector(state => state.validationInProgress);
  const dispatch = useAppDispatch();
  const stripeCurrency = useAppSelector(getStripeCurrency);

  /**
   * Initialise the PaymentRequest when the button loads.
   * Re-initialise if stripe or elements update.
   */

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const method = lodash.find(selectedPaymentMethods, (selectedPaymentMethod: SubPayment) => {
      if (
        selectedPaymentMethod.method === PAYMENT_METHOD.APPLE_PAY ||
        selectedPaymentMethod.method === PAYMENT_METHOD.GOOGLE_PAY
      ) {
        return true;
      }
    }) as SubPayment;

    const amount = method ? method.amount : 0;

    setTotal(amount!);

    const pr = stripe.paymentRequest({
      country: 'AU',
      currency: stripeCurrency,
      total: {
        label: 'Payment Total:',
        amount: amount!,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    pr.canMakePayment().then(result => {
      if (result) {
        if (result.applePay) {
          setPaymentMethod(PAYMENT_METHOD.APPLE_PAY);
        } else if (result.googlePay) {
          setPaymentMethod(PAYMENT_METHOD.GOOGLE_PAY);
        }
        setPaymentRequest(pr);
      }
    });
  }, [stripe, elements]);

  /**
   * Update the PaymentRequest amount when selectedPaymentMethods changes.
   * This will also happen when the cart contents change.
   */
  useEffect(() => {
    const method = lodash.find(selectedPaymentMethods, selectedPaymentMethod => {
      if (
        selectedPaymentMethod.method === PAYMENT_METHOD.APPLE_PAY ||
        selectedPaymentMethod.method === PAYMENT_METHOD.GOOGLE_PAY
      ) {
        return true;
      }
    }) as SubPayment;

    const amount = method ? method.amount : 0;

    setTotal(amount!);

    if (paymentRequest && total) {
      paymentRequest.update({
        total: {
          label: 'Payment Total:',
          amount: amount!,
        },
      });
      setPaymentRequest(paymentRequest);
    }
  }, [selectedPaymentMethods]);

  /**
   * Perform a validation when the button loads to see if delivery time needs updating.
   * Also validate when order total changes.
   */
  useEffect(() => {
    if (saleType === SALE_TYPE.DELIVERY) {
      dispatch(validateWithPaymentRequestAction());
    }
  }, [orderTotals]);

  return paymentRequest ? (
    <>
      <PaymentRequestButtonElement
        options={{ paymentRequest }}
        onClick={() => {
          dispatch(checkoutWithPaymentRequestAction());
        }}
      />
      <PaymentHookSubscriber paymentRequest={paymentRequest} />
    </>
  ) : (
    <div style={styles.spinnerContainer}>
      <RiRefreshLine className="spinner" style={styles.loadingSpinner} />
    </div>
  );
};

const PaymentHookSubscriber = ({ paymentRequest }: { paymentRequest: PaymentRequest }) => {
  const saleType = useAppSelector(getSaleType);
  const [paymentInProgress, setPaymentInProgress] = useState(false);
  const dispatch = useAppDispatch();
  //@ts-ignore  Hook doesnt accept promise type
  OrderingPaymentHooks.subscribe(PAYMENT_METHOD.APPLE_PAY, () => {
    if (paymentRequest) {
      return new Promise(resolve => {
        try {
          paymentRequest.on('cancel', () => {
            if (!paymentInProgress) {
              dispatch(setCheckoutInProgress(false));
            }
          });
          paymentRequest.on('paymentmethod', e => {
            setPaymentInProgress(true);
            resolve({
              token: e?.paymentMethod?.id,
            });
            //@ts-ignore
            OrderingPaymentHooks.subscribe(PRE_FINALISE_PAYMENT_REQUEST, ({ statusMsg }) => {
              e.complete(statusMsg as PaymentRequestCompleteStatus);
              if (statusMsg === 'fail' && saleType === SALE_TYPE.DELIVERY) {
                dispatch(
                  updateKeyOrderProperty({
                    autoApply: true,
                    updateDeliveryEstimate: true,
                    forceASAPDeliveryEstimate: false,
                  }),
                );
                window.alert('Your estimated delivery time has been updated. Please try again.');
              }
            });
          });
        } catch (e) {
          debug('ap handle action hook catch', e);
          throw e;
        }
      });
    }
  });
  //@ts-ignore
  OrderingPaymentHooks.subscribe(PAYMENT_METHOD.GOOGLE_PAY, () => {
    if (paymentRequest) {
      return new Promise(resolve => {
        try {
          paymentRequest.on('cancel', () => {
            if (!paymentInProgress) {
              dispatch(setCheckoutInProgress(false));
            }
          });
          paymentRequest.on('paymentmethod', e => {
            setPaymentInProgress(true);
            resolve({
              token: e?.paymentMethod?.id,
            });
            //@ts-ignore
            OrderingPaymentHooks.subscribe(PRE_FINALISE_PAYMENT_REQUEST, ({ statusMsg }) => {
              //@ts-ignore
              e.complete(statusMsg);
              if (statusMsg === 'fail' && saleType === SALE_TYPE.DELIVERY) {
                dispatch(
                  updateKeyOrderProperty({
                    autoApply: true,
                    updateDeliveryEstimate: true,
                    forceASAPDeliveryEstimate: false,
                  }),
                );
                window.alert('Your estimated delivery time has been updated. Please try again.');
              }
            });
          });
        } catch (e) {
          debug('gp handle action hook catch', e);
          throw e;
        }
      });
    }
  });

  return null;
};

const PaymentRequestButton: React.FC = () => {
  const paymentGatewayPublicKey = useAppSelector(getPaymentGatewayPublicKey);
  const paymentGatewayConfig = useAppSelector(getPaymentGatewayConfig);

  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>(() =>
    loadStripe(paymentGatewayPublicKey!, {
      stripeAccount: paymentGatewayConfig?.merchantAccount,
    }),
  );

  return (
    <Elements stripe={stripePromise}>
      <PaymentButton />
    </Elements>
  );
};

const styles: Styles = {
  spinnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  loadingSpinner: {
    fontSize: 24,
    marginTop: 16,
  },
};

export default PaymentRequestButton;
