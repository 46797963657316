import React from 'react';
import { BiTargetLock } from 'react-icons/bi';
import { RiSearchLine } from 'react-icons/ri';

import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES } from '../utils/theme';

import Text from './Text';
import TouchableOpacity from './TouchableOpacity';
import { useAppSelector } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';
import { useTranslation } from 'react-i18next';

interface IProps {
  locationCount: number;
  searchText: string;
  setSearchText: (value: string) => void;
  centerOnUserLocation: () => void;
}
const LocationSearchBar: React.FC<IProps> = ({
  locationCount = 0,
  searchText,
  setSearchText,
  centerOnUserLocation,
}) => {
  const p = useAppSelector(getThemeLookup);
  const { t } = useTranslation();
  return (
    <div style={combineStyles(styles.mainContainer, p('locationSearchBar', ['backgroundColor']))}>
      <div style={styles.top}>
        <RiSearchLine style={styles.searchIcon} />

        <input
          style={combineStyles(styles.input, p('defaultText', TEXT_PROPERTIES))}
          type="text"
          value={searchText || ''} // always have to provide a string (or react gives up control)
          placeholder={t('placeholder.locationSearch')}
          onChange={event => setSearchText(event?.target?.value)}
        />

        <TouchableOpacity style={styles.right} onClick={() => centerOnUserLocation()}>
          <BiTargetLock
            style={combineStyles(
              styles.userLocationIcon,
              p('centerOnUserLocationIcon', TEXT_PROPERTIES),
            )}
          />
        </TouchableOpacity>
      </div>

      <Text
        themeKey="locationSearchResultCount"
        value={t('locationSearchResult', { count: locationCount })}
        style={styles.count}
      />
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    minHeight: 'min-content',

    padding: 10,
    paddingTop: 15,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },

  top: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchIcon: {
    position: 'absolute',
    left: 10,
    color: 'rgba(0, 0, 0, 0.8)',
  },
  input: {
    width: '100%',
    borderRadius: 30,
    padding: 5,
    paddingLeft: 30,
    outline: 'none',

    border: '1px solid rgba(0, 0, 0, 0.2)',

    WebkitAppearance: 'none',
  },
  count: {
    paddingTop: 8,
    paddingLeft: 5,
  },

  right: {
    marginLeft: 10,
    marginRight: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  userLocationIcon: {
    fontSize: 24,
  },
};

export default LocationSearchBar;
