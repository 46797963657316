import sortChoices from './sortChoices';
import createIngredientFromChoice from './createIngredientFromChoice';

export default function createIngredientsFromChoiceSet(
  choiceSet: ChoiceSetWithQuantity,
  freeChoiceOrderingMethod: FreeChoiceOrderingMethod,
): CartIngredient[] {
  let freeChoicesRemaining = choiceSet.free;
  const ingredients: CartIngredient[] = [];

  const sortedChoices = sortChoices(
    choiceSet.choices,
    freeChoiceOrderingMethod,
  );

  sortedChoices.forEach(choice => {
    let remainingQuantity = choice.quantity;
    let freeQuantity = 0;

    if (freeChoicesRemaining && choice.quantity) {
      freeQuantity = Math.min(freeChoicesRemaining, choice.quantity);
      freeChoicesRemaining -= freeQuantity;
      remainingQuantity = choice.quantity - freeQuantity;
    }

    if (freeQuantity) {
      ingredients.push(
        createIngredientFromChoice({
          ...choice,
          quantity: freeQuantity,
          baseMoneyPrice: 0,
        }),
      );
    }

    if (remainingQuantity) {
      ingredients.push(
        createIngredientFromChoice({
          ...choice,
          quantity: remainingQuantity,
        }),
      );
    }
  });

  return ingredients;
}
