import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import Modal from '../components/Modal';
import Text from '../components/Text';
import StandardButton from '../components/StandardButton';
import { OrderingOperations } from 'polygon-ordering';
import moment from 'moment';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { enqueueSuccessSnackbar } from '../utils/snackbar';

export const FAVOURITE_NAME_MODAL = 'FAVOURITE_NAME_MODAL';

const { createFavouriteOrder } = OrderingOperations;

const FavvouriteNameModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const order = useAppSelector(state => state.modalParams)?.historicalOrder;

  const items = order?.items;

  return (
    <Modal
      clickBackgroundToClose
      desktopMaxWidth={400}
      desktopMinWidth={400}
      closeButtonLabel={t('button.modalClose.dismiss')}
      containerStyle={styles.containerStyle}
      desktopMinHeight={300}
      title={t('title.createNewFavourite')}
    >
      <div style={styles.messageContainer}>
        <Text
          themeKey="orderMessage"
          style={styles.messageText}
          value={'Do you want to name this favourite? A name is optional.'}
        />
      </div>
      <div style={styles.inputSection} className="w-100">
        <input className="w-100" value={name} onChange={e => setName(e.target.value)} />
      </div>
      <div className=" w-100">
        <StandardButton
          containerStyle={styles.button}
          themeKey="createNewFavourite"
          label={'Create'}
          onClick={() => {
            dispatch(
              createFavouriteOrder({
                //@ts-ignore
                items: items || [],
                name: name || moment().format('DD/MM/YYYY hh:mma'),
              }),
            );

            dispatch(setCurrentModal(null));
            enqueueSuccessSnackbar('Success');
          }}
        />
      </div>
    </Modal>
  );
};

const styles: Styles = {
  containerStyle: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputSection: {
    marginTop: 30,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  button: {
    marginTop: 20,
    height: 40,
  },
};

export default FavvouriteNameModal;
