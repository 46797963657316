import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import isValidCoordinate from '../utils/isValidCoordinate';

const initialState = null as Partial<Coordinates> | null;

const slice = createSlice({
  name: 'currentUserLocation',
  initialState,
  reducers: {
    setCurrentUserLocation(state, action: PayloadAction<Partial<Coordinates> | null>) {
      if (action.payload && !isValidCoordinate(action.payload)) {
        return initialState;
      }

      return action.payload;
    },
  },
});

export const { setCurrentUserLocation } = slice.actions;

export default slice.reducer;
