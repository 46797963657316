export default function (
  str: string = '',
  num: number = Number.MAX_SAFE_INTEGER,
  suffix: string = '…',
): string {
  if (str.length <= num) {
    return str;
  }

  return str.slice(0, num) + (suffix || '');
}
