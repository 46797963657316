import translateChoiceSelectionsBetweenItems from './translateChoiceSelectionsBetweenItems';

export default function changePurchaseItem(
  purchase: _Purchase,
  previousItem: Item,
  nextItem: Item,
): _Purchase {
  return {
    ...purchase,
    itemId: nextItem.id,
    choiceSelections: translateChoiceSelectionsBetweenItems(
      purchase.choiceSelections,
      previousItem,
      nextItem,
    ),
  };
}
