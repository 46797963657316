import { ActionType, getType } from 'typesafe-actions';
import * as config from '../../actionCreators/config';
export default function (
  state: boolean = false,
  action: ActionType<typeof config>,
): boolean {
  switch (action.type) {
    case getType(config.setIncludeDiscountMinSpend):
      return Boolean(action.payload.value);
    default:
      return state;
  }
}
