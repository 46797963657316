import { createSelector } from 'reselect';

import getDeliveryProvider from './getDeliveryProvider';
import getDeliveryAddress from './getDeliveryAddress';
import getDeliveryTime from './getDeliveryTime';
import getDeliveryNotes from './getDeliveryNotes';

import combineAddressParts from '../utils/ordering/combineAddressParts';
import getDesiredDeliveryTime from './getDesiredDeliveryTime';
import moment from 'moment';
import { getDeliveryTimeAsap } from './config';
import getBestEstId from './getBestEstId';

function createSubmissionAddress(address: TokenisedAddress | null) {
  if (!address) {
    return undefined;
  }

  const { unit, streetNumber, streetName, suburb, state, postcode } = address;

  return {
    StreetName: combineAddressParts([
      ...(streetNumber != null && unit != null
        ? [`${unit}/${streetNumber}`]
        : [unit, streetNumber]),
      streetName,
    ]),
    Suburb: suburb,
    State: state,
    Postcode: postcode,
  };
}

export default createSelector(
  [
    getDeliveryProvider,
    getDeliveryAddress,
    getDeliveryTime,
    getDeliveryNotes,
    getDesiredDeliveryTime,
    getDeliveryTimeAsap,
    getBestEstId,
  ],
  (
    deliveryProvider,
    deliveryAddress,
    deliveryTime,
    deliveryNotes,
    desiredDeliveryTime,
    deliveryTimeAsap,
    bestEstId,
  ) => {
    const timeAhead = moment().add(deliveryTimeAsap, 'minutes').format();
    const difference = moment(timeAhead).diff(desiredDeliveryTime, 'minutes');

    const _deliveryTime =
      desiredDeliveryTime === 'ASAP' || desiredDeliveryTime === null
        ? 'ASAP'
        : difference > 0
        ? 'ASAP'
        : deliveryTime;

    return {
      DeliveryTime: _deliveryTime,
      Address: createSubmissionAddress(deliveryAddress),
      DeliveryProvider: deliveryProvider?.id,
      DeliveryNotes: deliveryNotes,
      BestEstID: bestEstId,
    };
  },
);
