import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderingSelectors, OrderingOperations, determineLocationSessions } from 'polygon-ordering';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import combineStyles from '../utils/combineStyles';
import TimeSelector from './TimeSelector';
import DateSelector from './DateSelector';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import DateTimeSelector from './DateTimeSelector';

const { setPickupTime } = OrderingOperations;

const {
  getSaleType,
  getLocation,
  getOrderingWindowPadding,
  getPickupTime,
  getFutureOrderingLimitBysaleType,
} = OrderingSelectors;

interface IProps {
  className?: string;
  containerStyle?: React.CSSProperties;
  reducedWidth?: number;
  themeKey: string;
  showTimeInErrorMessage?: boolean;
  suppressEstimate?: boolean;
  // duration?: number;
  minuteStep?: number;
}

const PickupTime: React.FC<IProps> = ({
  className,
  containerStyle,
  reducedWidth,
  showTimeInErrorMessage,
  suppressEstimate,
  themeKey,
  minuteStep = 5,
}) => {
  const duration = useAppSelector(getFutureOrderingLimitBysaleType);

  const disablePickupTimeSelection = useAppSelector(
    state => state.config.disablePickupTimeSelection,
  );
  const orderingWindowPadding = useAppSelector(getOrderingWindowPadding);
  const pickupTime = useAppSelector(getPickupTime);
  const saleType = useAppSelector(getSaleType);
  const unenhancedLocation = useAppSelector(getLocation);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const [enhancedLocation, setEnhancedLocation] = useState<any>({ enhancedLocation: null });

  useEffect(() => {
    const enhancedLocation = {
      ...unenhancedLocation,
      ...determineLocationSessions(
        unenhancedLocation,
        orderingWindowPadding,
        saleType,
        duration,
        minuteStep,
        pickupTime,
      ),
    };

    setEnhancedLocation(enhancedLocation);
  }, [saleType, pickupTime]);

  if (!unenhancedLocation || !enhancedLocation) {
    return null;
  }

  return (
    <div className={className} style={combineStyles(styles.mainContainer, containerStyle)}>
      {duration > 0 || saleType === SALE_TYPE.CATERING ? (
        <>
          <DateSelector
            themeKey={themeKey}
            determineLocationSessions={determineLocationSessions}
            location={unenhancedLocation as POSLocation}
            orderingWindowPadding={orderingWindowPadding}
            minuteStep={minuteStep}
            timePadding={enhancedLocation.averageOrderWaitTime}
          />
          <DateTimeSelector
            themeKey={themeKey}
            earliestTime={enhancedLocation.orderingWindowStart}
            latestTime={enhancedLocation.orderingWindowEnd}
            storeTimeSlots={enhancedLocation.storeTimeSlots}
            timePadding={enhancedLocation.averageOrderWaitTime}
            reducedWidth={reducedWidth}
            suppressEstimate={suppressEstimate}
            showTimeInErrorMessage={showTimeInErrorMessage}
            timeInErrorMessage={t('noValidTimeMessage')}
            lockASAP={disablePickupTimeSelection}
            minuteStep={minuteStep}
            value={pickupTime}
            setValue={(newTime: string | null) => dispatch(setPickupTime(newTime))}
          />
        </>
      ) : (
        <TimeSelector
          themeKey={themeKey}
          earliestTime={enhancedLocation.orderingWindowStart}
          latestTime={enhancedLocation.orderingWindowEnd}
          timePadding={enhancedLocation.averageOrderWaitTime}
          value={pickupTime}
          setValue={(newTime: string | null) => dispatch(setPickupTime(newTime))}
          reducedWidth={reducedWidth}
          suppressEstimate={suppressEstimate}
          showTimeInErrorMessage={showTimeInErrorMessage}
          timeInErrorMessage={t('noValidTimeMessage')}
          lockASAP={disablePickupTimeSelection}
          timezone={enhancedLocation.timeZone}
        />
      )}
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 10,
  },
};

export default PickupTime;
