import lodash from 'lodash';

export default function enhanceMenuNode(
  node: MenuNode,
  allItems: Items,
): Category {
  return {
    ...lodash.pick(node, [
      'id',
      'shortName',
      'colour',
      'name',
      'images',
      'brandId',
    ]),

    items: node.items.map(itemId => allItems[itemId]),

    subCategories: node.subMenus
      .map(m => enhanceMenuNode(m, allItems))
      .filter(c => c.items.length || c.subCategories.length),
  };
}
