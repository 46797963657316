import { RAW_TYPE_TO_VARIETY_MAP } from '../../constants/boost';

import Logger from '../Logger';

export default function choiceForVariety(
  rawIngredient: BoostRawIngredient,
  variety: BoostChoiceVariety,
  masterChoices: SDict<Choice>,
  description: string,
) {
  const pluForVariety = rawIngredient.plus.find(
    plu => plu.type === RAW_TYPE_TO_VARIETY_MAP[variety],
  );

  if (!pluForVariety) {
    Logger.log('no plu specified for variety', 'info', {
      variety,
    });

    return undefined;
  }

  const masterChoice = masterChoices[pluForVariety.id];

  if (!masterChoice) {
    Logger.log('matching BOH choice not found', 'info', {
      pluForVariety,
      variety,
    });

    return undefined;
  }

  return {
    ...masterChoice,
    id: String(pluForVariety.id),
    name: rawIngredient.name,
    description, // used to split additionals visually
  };
}
