import { put, takeEvery, all, select } from 'redux-saga/effects';

import * as openStagedPurchase from '../../actionCreators/flows/openStagedPurchase';

import getUnenhancedStagedPurchases from '../../selectors/getUnenhancedStagedPurchases';

import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';

export const requested = createFlowApprover(openStagedPurchase);

export function* approved(
  action: ReturnType<typeof openStagedPurchase.actions.approved>,
) {
  const {
    payload: { purchaseId },
    meta: { flowId },
  } = action;

  try {
    const stagedPurchases: _Purchase[] = yield select(
      getUnenhancedStagedPurchases,
    );

    const purchase = stagedPurchases.find(
      p => p.id === purchaseId,
    ) as _Purchase;

    yield put(openStagedPurchase.actions.succeeded({ purchase }, flowId));
  } catch (e) {
    yield put(
      openStagedPurchase.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(openStagedPurchase.events.REQUESTED, requested),
    takeEvery(openStagedPurchase.events.APPROVED, approved),
  ]);
}
