import { call } from 'redux-saga/effects';

import redcatApiFetch from '../api';

import debug from '../utils/debug';
import processConfig from '../utils/processors/processConfig';

export default function* fetchConfig() {
  try {
    const response = yield call(redcatApiFetch, {
      path: `/api/v1/config/web-ordering`,
    });

    return processConfig(response.data);
  } catch (e) {
    debug(false, 'fetchConfig failed', { e });
  }
}
