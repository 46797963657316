import { createCustomAction } from 'typesafe-actions';

import { AuthenticationMethod } from '../../utils/Api';

import {
  standardEmptyACC,
  standardFailedACC,
  standardFlowACC,
} from '../../utils/sagas';

const REQUESTED = '$FETCH_MEMBER/REQUESTED';
const BLOCKED = '$FETCH_MEMBER/BLOCKED';
const APPROVED = '$FETCH_MEMBER/APPROVED';
const SUCCEEDED = '$FETCH_MEMBER/SUCCEEDED';
const FAILED = '$FETCH_MEMBER/FAILED';

const request = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      memberNumber?: string;
      locationId?: string;
      authenticationMethod?: AuthenticationMethod;
    }>(type);
};

export const events = {
  REQUESTED,
  BLOCKED,
  APPROVED,
  SUCCEEDED,
  FAILED,
};

export const actions = {
  requested: createCustomAction(REQUESTED, request),
  approved: createCustomAction(APPROVED, request),
  blocked: createCustomAction(BLOCKED, standardEmptyACC),
  succeeded: createCustomAction(SUCCEEDED, standardEmptyACC),
  failed: createCustomAction(FAILED, standardFailedACC),
};

export default actions.requested;
