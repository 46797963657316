import { ActionType, getType } from 'typesafe-actions';

import * as currentOrder from '../../actionCreators/currentOrder';
import moment from 'moment';

import { dollarsToCents, safeDate } from '../../utils/misc';

const initialState = null;

export default function (
  state: string | null = null,
  action: ActionType<typeof currentOrder>,
): string | null {
  switch (action.type) {
    case getType(currentOrder.resetOrder): {
      return initialState;
    }
    case getType(currentOrder.setTimeEstimateReturned): {
      let value = safeDate(moment().toISOString());
      return value || initialState;
    }
    case getType(currentOrder.applyBufferData): {
      const { timeEstimateReturned } = action.payload.value;
      return timeEstimateReturned === undefined ? state : timeEstimateReturned;
    }
    default:
      return state;
  }
}
