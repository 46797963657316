import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = null as null | string;

const slice = createSlice({
  name: 'menuSearchText',
  initialState,
  reducers: {
    setMenuSearchText(state, action: PayloadAction<string>) {
      return action.payload || initialState;
    },
  },
});

export const { setMenuSearchText } = slice.actions;

export default slice.reducer;
