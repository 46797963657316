import { combineReducers, Reducer, AnyAction } from 'redux';

import cart from './cart';
import notes from './notes';

//@ts-ignore
const rootReducer: Reducer<ShadowOrder, AnyAction> = combineReducers({
  cart,
  notes,
});

export default rootReducer;
