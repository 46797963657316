import { PAYMENT_METHOD } from '../constants/paymentMethod';

type Hook = (params: PaymentHookParams) => PaymentHookResult;
type HookKey =
  | PAYMENT_METHOD
  | 'GATEWAY_REQUIRES_ACTION'
  | 'EFTPOS_STATUS_UPDATE'
  | 'MIN_CC_AMOUNT_NOT_MET'
  | 'PRE_FINALISE_PAYMENT_REQUEST';
type HookStore = Record<HookKey, Hook | undefined>;

export default class PaymentHooks {
  static hooks: HookStore = {} as HookStore;

  static subscribe(hookName: HookKey, handler: Hook) {
    PaymentHooks.hooks[hookName] = handler;
  }

  static clear(hookName: HookKey) {
    PaymentHooks.hooks[hookName] = undefined;
  }

  static get(hookName: HookKey) {
    return PaymentHooks.hooks[hookName];
  }
}
