import { ActionType, getType } from 'typesafe-actions';

import * as config from '../../actionCreators/config';

export default function (
  state: number | null = null,
  action: ActionType<typeof config>,
): number | null {
  switch (action.type) {
    case getType(config.setSaleInputType): {
      const { value } = action.payload;
      return value;
    }
    default:
      return state;
  }
}
