import React from 'react';
import Modal from '../components/Modal';
import { useTranslation } from 'react-i18next';
import SignInForm from '../components/SignInForm';

export const SIGN_IN_MODAL_ID = 'SIGN_IN_MODAL_ID';

const SignInModal: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t('title.modal.signIn')}
      desktopMaxWidth={800}
      desktopMinWidth={400}
      desktopContainerStyle={styles.modalDesktopContainer}
      mobileContainerStyle={styles.modalMobileContainer}
    >
      <div
        style={{
          marginTop: 40,
        }}
      >
        <SignInForm />
      </div>
    </Modal>
  );
};

const styles: Styles = {
  modalDesktopContainer: {
    padding: '70px 70px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
};

export default SignInModal;
