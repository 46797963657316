import { ActionType, getType } from 'typesafe-actions';

import * as currentOrder from '../../actionCreators/currentOrder';

const initialState = null;

export default function (
  state: MinimumOrderInformation | null = initialState,
  action: ActionType<typeof currentOrder>,
): MinimumOrderInformation | null {
  switch (action.type) {
    case getType(currentOrder.resetOrder): {
      return initialState;
    }
    case getType(currentOrder.setMinimumOrderInformation): {
      const { value } = action.payload;
      return value || initialState;
    }
    case getType(currentOrder.applyBufferData): {
      const { minimumOrderInformation } = action.payload.value;
      return minimumOrderInformation === undefined
        ? state
        : minimumOrderInformation;
    }
    default:
      return state;
  }
}
