import { ActionType, getType } from 'typesafe-actions';

import * as config from '../../actionCreators/config';

export default function (
  state: CouponOrderingMethod | null = null,
  action: ActionType<typeof config>,
): CouponOrderingMethod | null {
  switch (action.type) {
    case getType(config.setCouponOrderingMethod): {
      const { value } = action.payload;
      return value || null;
    }
    default:
      return state || null;
  }
}
