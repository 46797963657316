import { ActionType, getType } from 'typesafe-actions';

import * as config from '../../actionCreators/config';

export default function (
  state: string | null = null,
  action: ActionType<typeof config>,
): string | null {
  switch (action.type) {
    case getType(config.setImportedGiftCardRegex): {
      const { value } = action.payload;
      return value == null ? null : value;
    }
    default:
      return state;
  }
}
