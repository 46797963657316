import { createCustomAction } from 'typesafe-actions';

import {
  standardEmptyACC,
  standardFailedACC,
  standardFlowACC,
} from '../../utils/sagas';

const REQUESTED = '$SET_GIFT_CARD/REQUESTED';
const BLOCKED = '$SET_GIFT_CARD/BLOCKED';
const APPROVED = '$SET_GIFT_CARD/APPROVED';
const SUCCEEDED = '$SET_GIFT_CARD/SUCCEEDED';
const FAILED = '$SET_GIFT_CARD/FAILED';

const requestOrSucceed = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      giftCard: _GiftCard | null;
      suppressAutoFetch?: boolean | null;
    }>(type);
};

export const events = {
  REQUESTED,
  BLOCKED,
  APPROVED,
  SUCCEEDED,
  FAILED,
};

export const actions = {
  requested: createCustomAction(REQUESTED, requestOrSucceed),
  approved: createCustomAction(APPROVED, requestOrSucceed),
  blocked: createCustomAction(BLOCKED, standardEmptyACC),
  succeeded: createCustomAction(SUCCEEDED, requestOrSucceed),
  failed: createCustomAction(FAILED, standardFailedACC),
};

export default actions.requested;
