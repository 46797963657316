import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import StandardButton from '../components/StandardButton';
import { RedcatApiHandler } from 'polygon-utils';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../utils/snackbar';
import syncMember from '../actions/syncMember';
import { Formik, Form, FormikHelpers } from 'formik';

import * as Yup from 'yup';
import setLocalValue from '../utils/setLocalValue';
import { IN_DEVELOPMENT } from '../constants';
import ReCAPTCHA from 'react-google-recaptcha';
import getProfileFields from '../selectors/getProfileFields';
import getRegisterProfileFields from '../selectors/getRegisterProfileFields';
import getProfile from '../selectors/getProfile';

import getThemeLookup from '../selectors/getThemeLookup';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import RegistrationFields from '../components/RegistrationFields';
import Text from '../components/Text';
import SendVerificationButton from '../components/SendVerificationButton';
import RegistrationVerify from './RegistrationVerify';

export const UPDATE_PASSWORD_ROUTE = '/update-password';

type ResetYourPasswordParams = {
  Code?: string;
  Password: string;
  ConfirmPassword: string;
  reCaptchaToken?: string;
};
const UpdatePasswordScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const p = useAppSelector(getThemeLookup);
  const profileFields = useAppSelector(getProfileFields);
  const registrationFields = useAppSelector(getRegisterProfileFields);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const profile = useAppSelector(getProfile);

  const confirmPasswordMode = profileFields?.find(
    ({ id }) => id === 'ConfirmPassword',
  )?.registerMode;

  const schemas: ResetYourPasswordSchema = {
    Password: Yup.string().required('Required').min(2, 'Too Short'),
    ConfirmPassword: Yup.string()
      .test('required', 'Required', function (value) {
        if (confirmPasswordMode !== 'required') return true;
        return Boolean(value?.trim());
      })
      .test('too-short', 'Too Short', function (value) {
        if (confirmPasswordMode !== 'required') return true;
        return Boolean(value && value.length > 1);
      })
      .test('passwords-match', 'Passwords do not match', function (value) {
        if (confirmPasswordMode !== 'required') return true;
        return value === this.parent.Password;
      }),
  };

  const validationSchema = Yup.object().shape(schemas);

  const submit = (values: FormikFields, formikHelpers: FormikHelpers<FormikFields>) => {
    const { setSubmitting, resetForm } = formikHelpers;
    console.log(values);
    const path = '/api/v1/profile';
    const body = { Password: values.Password } as { Password: string; ConfirmPassword?: string };
    // 'ConfirmPassword' should not be included when that field's mode is 'disabled'
    if (confirmPasswordMode === 'required') body.ConfirmPassword = values.ConfirmPassword;
    if (confirmPasswordMode === 'optional') body.ConfirmPassword = values.Password;
    RedcatApiHandler.authorisedFetch({
      path,
      method: 'PUT',
      body,
    })
      .then(response => {
        if (IN_DEVELOPMENT && response.token) {
          setLocalValue('memberAuthToken', response.token);
        }

        dispatch(syncMember());

        if (response.success) {
          enqueueSuccessSnackbar(t('updateSuccess'));
          setSubmitting(false);
          resetForm({ values });
        }
      })
      .catch(err => {
        enqueueErrorSnackbar(err);
      });
  };

  return !profile?.verified ? (
    <RegistrationVerify showContinueButton={false} internalRedirect />
  ) : (
    <div style={{ margin: '0 20px' }}>
      {!profile?.verified ? (
        <div style={{ textAlign: 'center', padding: '20px 0' }}>
          <Text value={t('profileVerificationText')} style={{ fontSize: 14 }} />
          <SendVerificationButton containerStyle={{ paddingTop: 20 }} />
        </div>
      ) : null}
      <Formik
        validationSchema={validationSchema}
        initialValues={{ Password: '', ConfirmPassword: '' }}
        onSubmit={submit}
        enableReinitialize={true}
        validateOnMount
      >
        {({ handleSubmit, isSubmitting, submitForm, handleChange, setFieldValue, isValid }) => {
          return (
            <Form onSubmit={handleSubmit} style={styles.registrationForm}>
              <>
                {registrationFields!.visible
                  .filter((field: FormikFields) => field.id === 'Password')
                  .map((field: RegisterFields, index: any) => (
                    <RegistrationFields key={index} field={field} />
                  ))}

                {confirmPasswordMode !== 'disabled' &&
                  profileFields!
                    .filter((field: FormikFields) => field.id === 'ConfirmPassword')
                    .map((field: RegisterFields, index: any) => (
                      <RegistrationFields key={index} field={field} />
                    ))}

                <div className="position-relative" style={{ margin: '20px' }}>
                  <StandardButton
                    label={t('button.submit')}
                    containerStyle={styles.button}
                    disabled={Boolean(isSubmitting || !isValid)}
                    themeKey="signInSubmitButton"
                    onClick={handleSubmit}
                  />
                </div>
              </>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const styles: Styles = {
  button: {
    borderRadius: 5,
    height: 40,
  },

  column: {
    minWidth: 250,
  },
  columnMobile: {
    padding: '20px 0 0 0',
  },

  verticalSeperator: {
    width: 1.5,
    marginLeft: 40,
    marginRight: 40,
  },
  registrationForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'strech',
    justifyContent: 'space-between',
    paddingTop: 40,
  },
};

export default UpdatePasswordScreen;
