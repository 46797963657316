import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import StandardButton from '../components/StandardButton';
import { useLocation } from 'react-router-dom';
import { setCurrentModal } from '../thunks/setCurrentModal';
import getThemeLookup from '../selectors/getThemeLookup';
import combineStyles from '../utils/combineStyles';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import history from '../history';
import EVENTS from '../constants/events';
import { logEvent } from '../utils/analytics';
import { signOut } from '../thunks/signOut';
import { OrderingOperations } from 'polygon-ordering';
import { getDefaultDropdownMenu, getLoyaltyDropdownMenu } from '../utils/getAvailableScreens';
import { removeUserSession } from '../utils/analytics';

const { updateSelectedPaymentMethods } = OrderingOperations;

const HeaderDropdownButton = ({ signoutActive }: { signoutActive: boolean }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const { pathname } = useLocation();
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const loyaltyDisabled = useAppSelector(state => state.config.disableLoyalty);
  const menus = loyaltyDisabled ? getDefaultDropdownMenu() : getLoyaltyDropdownMenu();

  return (
    <div>
      {menus.map((screen, index) => (
        <div key={index}>
          <StandardButton
            label={t(screen.label)}
            themeKey={
              pathname === screen.path ||
              (screen.subRoutes &&
                screen.subRoutes?.filter(route => route.path === pathname).length !== 0)
                ? 'activeMemberButton'
                : 'defaultDropdownMenuButton'
            }
            containerStyle={combineStyles(
              styles.button,
              deviceTypeMobile ? styles.buttonMobile : styles.buttonDesktop,
              pathname === screen.path && p('activeMemberButton', ['borderLeft']),
              screen.subRoutes?.filter(route => route.path === pathname).length !== 0 &&
                screen.subRoutes &&
                p('activeMemberButton', ['borderLeft']),
            )}
            onClick={() => {
              history.push(screen.path);
              dispatch(setCurrentModal(null));
            }}
          />

          {deviceTypeMobile &&
            screen.subRoutes?.filter(route => route.path === pathname).length !== 0 &&
            screen.subRoutes?.map(route => (
              <div key={route.label} style={{ marginLeft: 10 }}>
                <StandardButton
                  key={index}
                  label={t(route.label)}
                  themeKey={pathname === route.path ? 'activeSubButton' : 'defaultSubButton'}
                  containerStyle={combineStyles(
                    styles.button,
                    deviceTypeMobile ? styles.buttonMobile : styles.buttonDesktop,
                  )}
                  onClick={() => {
                    history.push(route.path);
                    dispatch(setCurrentModal(null));
                  }}
                />
              </div>
            ))}
        </div>
      ))}

      {signoutActive && (
        <div>
          <StandardButton
            invisibleContainer
            containerStyle={combineStyles(
              deviceTypeMobile ? styles.buttonMobile : styles.buttonDesktop,
              styles.button,
            )}
            label={t('button.signOut')}
            onClick={() => {
              if (window.confirm(t('confirm.signOut'))) {
                logEvent(EVENTS.SIGN_OUT);
                removeUserSession();
                dispatch(signOut());
                dispatch(setCurrentModal(null));
                history.push('/');
                dispatch(updateSelectedPaymentMethods({ clearPaymentMethods: true }));
              }
            }}
            themeKey="defaultDropdownMenuButton"
          />
        </div>
      )}
    </div>
  );
};

const styles = {
  buttonMobile: {
    paddingLeft: 40,
  },
  buttonDesktop: {
    paddingLeft: 20,
    borderTop: '1px solid lightgrey',
  },

  button: {
    justifyContent: 'left',
    margin: 0,
  },
};

export default HeaderDropdownButton;
