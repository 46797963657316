import { call, put, takeEvery, all } from 'redux-saga/effects';

import * as editFavouriteOrder from '../../actionCreators/flows/editFavouriteOrder';
import * as fetchFavouriteOrders from '../../actionCreators/flows/fetchFavouriteOrders';

import Api, { FetchParams } from '../../utils/Api';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';
import createFavouriteItemFromShadowItem, {
  filterSystemPLUs,
} from '../../utils/ordering/createFavouriteItemFromShadowItem';

export const requested = createFlowApprover(editFavouriteOrder);

export function* approved(
  action: ReturnType<typeof editFavouriteOrder.actions.approved>,
) {
  const {
    payload: { id, name, items },
    meta: { flowId },
  } = action;

  try {
    const body: any = {};

    if (!name && !items) {
      throw new Error('invalid arguments');
    }

    if (name) {
      body.Description = name;
    }

    if (items) {
      body.Items = filterSystemPLUs(
        items.map(shadowItem => createFavouriteItemFromShadowItem(shadowItem)),
      );
    }

    const params: FetchParams = {
      path: `/api/v1/profile/favourites/${id}`,
      method: 'PUT',
      body,
    };

    yield call(Api.fetch, params, 'member');

    yield put(fetchFavouriteOrders.actions.requested({}, flowId));

    yield put(editFavouriteOrder.actions.succeeded({}, flowId));
  } catch (e) {
    yield put(
      editFavouriteOrder.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(editFavouriteOrder.events.REQUESTED, requested),
    takeEvery(editFavouriteOrder.events.APPROVED, approved),
  ]);
}
