import React from 'react';
import combineStyles from '../utils/combineStyles';
import { CONTAINER_PROPERTIES, TEXT_PROPERTIES } from '../utils/theme';
import { useAppSelector } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';
import Text from './Text';
import TouchableOpacity from './TouchableOpacity';
import { IconType } from 'react-icons/lib';

interface IProps {
  label?: string;
  leftLabel?: string;
  rightLabel?: string;
  leftLabelStyle?: React.CSSProperties;
  rightLabelStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  problem?: boolean;
  labelStyle?: React.CSSProperties;
  sticky?: boolean;
  centerLabelStyle?: React.CSSProperties;
  LabelIconComponent?: IconType;
  onClick?: () => void;
}

const Banner: React.FC<IProps> = ({
  label,
  LabelIconComponent,
  leftLabel,
  rightLabel,
  sticky,
  labelStyle,
  centerLabelStyle,
  leftLabelStyle,
  rightLabelStyle,
  containerStyle,
  problem = false,
  onClick,
}) => {
  const p = useAppSelector(getThemeLookup);
  const bannerTextStyle = combineStyles(
    p('banner', TEXT_PROPERTIES),
    problem && p('bannerWithProblem', TEXT_PROPERTIES),
  );

  const combinedCenterLabelStyle = combineStyles(
    styles.center,
    bannerTextStyle,
    labelStyle,
    centerLabelStyle,
  );

  return (
    <TouchableOpacity
      style={combineStyles(
        styles.main,
        p('banner', CONTAINER_PROPERTIES),
        problem && p('bannerWithProblem', CONTAINER_PROPERTIES),
        containerStyle,
      )}
      className={sticky ? 'sticky' : undefined}
      onClick={onClick}
      isScrollChild
      ariaLabel={label}
    >
      <Text
        value={leftLabel}
        style={combineStyles(
          styles.left,
          bannerTextStyle,
          p('bannerLeft', TEXT_PROPERTIES),
          labelStyle,
          leftLabelStyle,
        )}
      />

      <span style={styles.centerPair}>
        <Text value={label} style={combinedCenterLabelStyle} />
        {LabelIconComponent && (
          <LabelIconComponent style={{ color: `${combinedCenterLabelStyle.color}` }} />
        )}
      </span>

      <Text
        value={rightLabel}
        style={combineStyles(
          styles.right,
          bannerTextStyle,
          p('bannerRight', TEXT_PROPERTIES),
          labelStyle,
          rightLabelStyle,
        )}
      />
    </TouchableOpacity>
  );
};

const styles: Styles = {
  main: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  left: {
    minWidth: '15%', // needed or middle label isn't centered
    textAlign: 'left',
  },
  center: {
    textAlign: 'center',
  },
  right: {
    minWidth: '15%', // needed or middle label isn't centered
    textAlign: 'right',
  },
  centerPair: {
    display: 'inline-flex',
    alignItems: 'center',
  },
};

export default Banner;
