import { call, put, takeEvery, all } from 'redux-saga/effects';

import * as fetchPaymentGatewayTokens from '../../actionCreators/flows/fetchPaymentGatewayTokens';
import { setPaymentGatewayTokens } from '../../actionCreators/member';

import Api, { FetchParams, ApiResponse } from '../../utils/Api';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';
import processPaymentGatewayToken from '../../utils/processors/processPaymentGatewayToken';

export const requested = createFlowApprover(fetchPaymentGatewayTokens);

export function* approved(
  action: ReturnType<typeof fetchPaymentGatewayTokens.actions.approved>,
) {
  const {
    payload: { authenticationMethod = 'member' },
    meta: { flowId },
  } = action;

  try {
    const params: FetchParams = {
      path: '/api/v1/profile/pgtokens',
      method: 'GET',
    };

    const response: ApiResponse = yield call(
      Api.fetch,
      params,
      authenticationMethod,
    );

    const rawTokens: RawPaymentGatewayToken[] = response.data;

    const processedTokens = rawTokens.map(processPaymentGatewayToken);

    yield put(setPaymentGatewayTokens(processedTokens));

    yield put(fetchPaymentGatewayTokens.actions.succeeded({}, flowId));
  } catch (e) {
    yield put(
      fetchPaymentGatewayTokens.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(fetchPaymentGatewayTokens.events.REQUESTED, requested),
    takeEvery(fetchPaymentGatewayTokens.events.APPROVED, approved),
  ]);
}
