import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment-timezone';
import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES, SELECT_PROPERTIES } from '../utils/theme';
import { generateMomentsInRangeFDO as generateMomentsInRange } from '../utils/generateMomentsInRange';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';
import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';

/* 
Helper function to find utc off set string from utcoffset seconds 
like if you pass 34200 to this function which is adelaide offset 
this function will return +09:30
*/

const getTimeFromUTCOffset = (utcOffset: number) => {
  // Calculate the total offset in hours (including minutes)
  const totalOffsetHours = utcOffset / 3600;

  // Calculate the hours and minutes
  const hours = Math.floor(totalOffsetHours);
  const minutes = Math.floor((totalOffsetHours - hours) * 60);

  // Determine the sign of the offset
  const sign = totalOffsetHours >= 0 ? '+' : '-';

  // Format the time string
  const formattedTime = `${sign}${Math.abs(hours).toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;

  return formattedTime;
};

const { getPickupTime, getSaleType, getCateringEarliestOrderTime } = OrderingSelectors;

const { setPickupTime, fetchOrderingProviderDetails } = OrderingOperations;

const DateSelector = ({
  themeKey,
  determineLocationSessions,
  location,
  orderingWindowPadding,
  minuteStep,
  timePadding,
}: {
  themeKey: string;
  determineLocationSessions: any;
  location: POSLocation;
  orderingWindowPadding: any;
  minuteStep: number;
  timePadding: number;
}) => {
  const storeUtc = getTimeFromUTCOffset(location.utcOffset) || moment().format('Z');

  const pickupTime = useAppSelector(getPickupTime);
  const p = useAppSelector(getThemeLookup);

  const dispatch = useAppDispatch();
  const [selectedDate, setSelectedDate] = useState(
    moment(pickupTime).utcOffset(storeUtc).startOf('day').toISOString(true),
  );
  const saleType = useAppSelector(getSaleType);
  const { futureOrderingLimit, futureOrderingLimitCatering } = useAppSelector(
    state => state.ordering.config.futureOrderingConfig,
  );
  const duration =
    saleType === SALE_TYPE.CATERING
      ? futureOrderingLimitCatering >= 1
        ? futureOrderingLimitCatering
        : 1
      : futureOrderingLimit;

  const earliestCateringOrderTime = useAppSelector(getCateringEarliestOrderTime);

  const dates = generateDateInRange(duration!, saleType, earliestCateringOrderTime, storeUtc);

  useEffect(() => {
    setSelectedDate(moment(pickupTime).utcOffset(storeUtc).startOf('day').toISOString(true));

    if (
      saleType === SALE_TYPE.CATERING &&
      moment(pickupTime).utcOffset(storeUtc).isBefore(dates[0].utcOffset(storeUtc), 'day')
    ) {
      syncDate();
    }
  }, [pickupTime]);

  const handleDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDate(e.target.value);
    syncDate(e.target.value);
  };

  const syncDate = (value?: HTMLSelectElement['value']) => {
    const newDate = {
      y: moment(value).utcOffset(storeUtc, true).year(),
      M: moment(value).utcOffset(storeUtc, true).month(),
      d: moment(value).utcOffset(storeUtc, true).date(),
    };

    if (
      saleType === SALE_TYPE.CATERING &&
      moment(pickupTime).utcOffset(storeUtc).isBefore(dates[0].utcOffset(storeUtc), 'day')
    ) {
      newDate.d = dates[0].date(); // update day
      newDate.M = dates[0].month();
      newDate.y = dates[0].year();
    }

    // Finding enhanced location to get the correct earliest and latest time
    const enhancedLocation = {
      ...location,
      ...determineLocationSessions(
        location,
        orderingWindowPadding,
        saleType,
        duration,
        minuteStep,
        moment(newDate).utcOffset(storeUtc, true).format(),
      ),
    };

    // Earliest time moment
    const earliestTimeMoment = moment(enhancedLocation.orderingWindowStart).utcOffset(storeUtc);

    // User current time + avg order wait time
    const estimatedOrderCompletionTime = moment().utcOffset(storeUtc).add(timePadding, 'minutes');

    // Earliest selectable moment time by user
    const earliestSelectableMoment = estimatedOrderCompletionTime.isAfter(earliestTimeMoment)
      ? estimatedOrderCompletionTime.clone()
      : earliestTimeMoment.clone();

    // Latest selectable moment time by user
    const latestSelectableMoment = moment(enhancedLocation.orderingWindowEnd).utcOffset(storeUtc);

    const currentTime = generateMomentsInRange(
      earliestSelectableMoment,
      latestSelectableMoment,
      minuteStep,
      enhancedLocation?.storeTimeSlots,
    )[0];

    const newTime = {
      h: moment(saleType === SALE_TYPE.CATERING ? earliestTimeMoment : currentTime).hour(),
      m: moment(saleType === SALE_TYPE.CATERING ? earliestTimeMoment : currentTime).minute(),
      s: moment(saleType === SALE_TYPE.CATERING ? earliestTimeMoment : currentTime).second(),
    };

    //@ts-ignore
    dispatch(setPickupTime(moment({ ...newDate, ...newTime }).utcOffset(storeUtc, true)));
    dispatch(
      fetchOrderingProviderDetails({
        date: moment({ ...newDate, ...newTime })
          .utcOffset(storeUtc, true)
          .format('l'),
      }),
    );
  };

  return (
    <select
      id="pickup_date"
      name="pickupdate"
      value={selectedDate}
      onChange={handleDateChange}
      style={combineStyles(
        { textAlignLast: 'center' },
        p('defaultText', TEXT_PROPERTIES),
        p('input', SELECT_PROPERTIES),
        p(themeKey, SELECT_PROPERTIES),
        { width: 170 },
      )}
    >
      {dates.map((day, index) => {
        return (
          <option key={index} value={moment(day).startOf('day').toISOString(true)}>
            {moment(day).format('ddd, D MMM, YY')}
          </option>
        );
      })}
    </select>
  );
};

const generateDateInRange = (
  duration: number,
  saleType: SALE_TYPE,
  requiredTime: Moment,
  storeUtc: string,
) => {
  const results = [];
  for (let i = 0; i < duration + 1; i++) {
    if (saleType === SALE_TYPE.CATERING) {
      const futureDate = moment()
        .utcOffset(storeUtc)
        .clone()
        .add(i, 'd')
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }); // reset timer to compare only dates

      if (requiredTime.diff(futureDate, 'day') <= 0) {
        results.push(moment().utcOffset(storeUtc).clone().add(i, 'd'));
      }
    } else {
      results.push(moment().utcOffset(storeUtc).clone().add(i, 'd'));
    }
  }

  return results;
};

export default DateSelector;
