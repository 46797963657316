import { createSelector } from 'reselect';

import applePayItemFromPurchase from '../utils/ordering/applePayItemFromPurchase';
import { centsToDollarString } from '../utils/misc';

import getStagedPurchases from './getStagedPurchases';
import getOrderTotals from './getOrderTotals';
import { getMerchantConfig } from './config';
import { getSelectedPaymentMethods } from '.';
import { getItemPaymentTotal } from '../utils/misc';

export default createSelector(
  [getStagedPurchases, getOrderTotals, getMerchantConfig, getSelectedPaymentMethods],
  (
    purchases = [],
    totals = {} as OrderTotals,
    merchantConfig,
    getSelectedPaymentMethods: any[],
  ): ApplePayItems => [
    ...purchases.map(applePayItemFromPurchase),
    {
      label: merchantConfig.merchantName || 'Total',
      amount: centsToDollarString(getItemPaymentTotal(getSelectedPaymentMethods, 'apple-pay')),
    },
  ],
);
