import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RiCloseCircleFill } from 'react-icons/ri';

import { OrderingOperations, OrderingSelectors } from 'polygon-ordering';

import getThemeLookup from '../selectors/getThemeLookup';
import { setCurrentSavedCardToken } from '../slices/currentSavedCardToken';

import Text from './Text';
import PaymentMethod from './PaymentMethod';
import StandardButton from './StandardButton';
import getCurrentSavedCardToken from '../selectors/getCurrentSavedCardToken';

import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { removeSavedCard } from '../slices/savedCards';
import { CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { setCvcFilled } from '../slices/cvcFilled';
import handleCVCChange from '../utils/handleCVCChange';

const { getSelectedPaymentMethods, getEnableStripeCvcRevalidation } = OrderingSelectors;

function padCardDigits(last4: number, d = 'X'): string {
  const four = `${d}${d}${d}${d}`;
  return `${four}-${four}-${four}-${last4}`;
}

const { removePaymentGatewayToken, setCvcToken } = OrderingOperations;

interface IProps {
  savedCard: PaymentGateWayToken;
}

const PaymentMethodSavedCard: React.FC<IProps> = ({ savedCard }) => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const currentSavedCardToken = useAppSelector(getCurrentSavedCardToken);
  const selectedPaymentMethods = useAppSelector(getSelectedPaymentMethods);
  const enableCvcCheck = useAppSelector(getEnableStripeCvcRevalidation);
  const cvcFilled = useAppSelector(state => state.cvcFilled);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (cvcFilled) {
      dispatch(setCvcFilled(false));
    }
  }, []);

  const stripe = useStripe();
  const elements = useElements();

  return (
    <PaymentMethod
      method={PAYMENT_METHOD.SAVED_CARD}
      additionalBehaviour={() => dispatch(setCurrentSavedCardToken(savedCard.token))}
      right={
        <Text themeKey="savedCardNumber">{padCardDigits(Number(savedCard.displayValue))}</Text>
      }
      selected={() =>
        selectedPaymentMethods.some(selectedPaymentMethod => {
          if (selectedPaymentMethod.method === PAYMENT_METHOD.SAVED_CARD) {
            return true;
          }
          return false;
        }) && currentSavedCardToken === savedCard.token
      }
      subtitle={
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <StandardButton
              invisibleContainer
              label={t('button.removeSavedCard')}
              themeKey="removeSavedCardButton"
              LeftIconComponent={RiCloseCircleFill}
              iconStyle={p('removeSavedCardButtonIcon', ['color', 'fontSize'])}
              onClick={() => {
                if (window.confirm(`Remove saved card ending in ${savedCard.displayValue}?`)) {
                  dispatch(removePaymentGatewayToken({ token: savedCard.token }));
                  dispatch(removeSavedCard(savedCard));
                }
              }}
            />
          </div>
          {enableCvcCheck && currentSavedCardToken === savedCard.token && (
            <div style={styles.contentContainer}>
              <div style={styles.input}>
                <CardCvcElement
                  options={{
                    style: {
                      base: {
                        backgroundColor: 'transparent',
                      },
                    },
                  }}
                  onChange={event => {
                    if (event.complete) {
                      handleCVCChange({
                        stripe,
                        elements,
                        CardCvcElement,
                        onSuccess: token => dispatch(setCvcToken(token)),
                      });
                      dispatch(setCvcFilled(true));
                    } else {
                      if (cvcFilled) {
                        dispatch(setCvcFilled(false));
                      }
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
      }
    />
  );
};

export default PaymentMethodSavedCard;

const styles = {
  contentContainer: {
    minHeight: 57,
  },
  input: {
    borderRadius: 4,
    border: '1px solid #c4c4c4',
  },
};
