import { all, call, select, put } from 'redux-saga/effects';

import { OrderingOperations, OrderingSelectors } from 'polygon-ordering';

import EVENTS from '../constants/events';
import { IS_PRE_RELEASE_VERSION } from '../constants';

import { logEvent, logUser } from '../utils/analytics';
import debug, { debugEnabled } from '../utils/debug';
import locationDebugger from '../utils/locationDebugger';

import { cleanAddressCache } from '../slices/addressCache';

import configureApiHandler from './configureApiHandler';
import registerLifecycleHooks from './registerLifecycleHooks';
import syncMember from './syncMember';
import applyStringConfiguration from './applyStringConfiguration';
import checkDate from './checkDate';
import sync from './sync';
import { sync as syncThunk } from '../thunks/sync';

import { store } from '../store';

const { clearBuffer, validateOrder } = OrderingOperations;
const { getMember } = OrderingSelectors;

export default function* boot() {
  debug('application boot started');
  console.log('expected hosting path:', process.env.PUBLIC_URL);
  console.log(
    'version:',
    `${process.env.REACT_APP_VERSION_ALIAS || process.env.REACT_APP_VERSION} (${
      IS_PRE_RELEASE_VERSION ? 'PRE-RELEASE' : 'RELEASED'
    })`,
  );

  if (!debugEnabled()) {
    console.log('Set window.DEBUG or localStorage.DEBUG flag to enable debugging');
  }

  // window.addEventListener('error', ({ error }) => {
  //   debug(false, 'global error catcher', { error });
  //   return false;
  // });

  // setup location helper for Redcat staff
  window.locations = locationDebugger; //not being used
  window.stores = locationDebugger; //not being used
  window.validate = () => {
    const memberPresent = Boolean(getMember(store.getState()));

    store.dispatch(
      validateOrder({
        authenticationMethod: memberPresent ? 'member' : 'none',
      }),
    );
  };

  // set any known string overrides ASAP (instead of waiting for sync to complete)
  // this is to avoid having text change while the user can see it
  const stringOverrides = yield select(state => state.config?.stringOverrides);
  const member = yield select(getMember);

  if (!member) {
    logUser(null);
  }

  yield all([
    call(applyStringConfiguration, stringOverrides),
    call(configureApiHandler),
    call(registerLifecycleHooks),
  ]);

  yield call(checkDate);

  yield all([
    call(syncMember),
    put(clearBuffer({ preserveReservationId: false })),
    put(cleanAddressCache()),
    call(sync),
    put(yield call(syncThunk)),
  ]);

  debug('application boot complete');
  logEvent(EVENTS.BOOT);
}
