import { take, call, put } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import lodash from 'lodash';

import reserveOrCheckBuffer from '../sagas/reserveOrCheckBuffer';

import LifecycleHooks from '../utils/LifecycleHooks';

export function createFlowApprover({ events, actions }: Flow) {
  return function* requested(action: ReturnType<typeof actions.requested>) {
    const flowId = lodash.get(action, 'meta.flowId', uuidv4()) as string;

    try {
      const { payload } = action;

      const proceed = yield call(LifecycleHooks.get(events.REQUESTED), action);

      if (trueOrUndefined(proceed)) {
        yield put(actions.approved({ ...payload }, flowId));
      } else {
        yield put(actions.blocked({}, flowId));
      }
    } catch (e) {
      yield put(actions.failed({ error: makeErrorSerialisable(e) }, flowId));
    }
  };
}

export function createBufferFlowApprover({ events, actions }: Flow) {
  return function* requested(action: ReturnType<typeof actions.requested>) {
    const { payload } = action;

    const flowId = lodash.get(action, 'meta.flowId', uuidv4()) as string;

    try {
      const controlsBuffer = yield call(reserveOrCheckBuffer, flowId, true);

      const proceed = yield call(LifecycleHooks.get(events.REQUESTED), action);

      if (controlsBuffer && trueOrUndefined(proceed)) {
        yield put(actions.approved({ ...payload }, flowId));
      } else {
        yield put(actions.blocked({}, flowId));
      }
    } catch (e) {
      yield put(actions.failed({ error: makeErrorSerialisable(e) }, flowId));
    }
  };
}

export function standardFlowACC<T, Z>(type: T) {
  return (params: Z, flowId: string = uuidv4()) => ({
    type,
    payload: { ...params },
    meta: {
      flowId,
    },
  });
}

export function standardEmptyACC<T>(type: T) {
  return standardFlowACC<T, {}>(type);
}

export function standardFailedACC<T>(type: T) {
  return standardFlowACC<T, { error?: SerialisableException }>(type);
}

export function getStandardFailedWithReasonACC<R>() {
  return function <T>(type: T) {
    return standardFlowACC<T, { error?: SerialisableException; reason?: R }>(
      type,
    );
  };
}

// when R is an enum:
// { error?: SerialisableException; reason?: R[keyof R] }

// TODO: work out how to preserve all the typing info with this to simplify flow creation
// interface Events {
//   REQUESTED: string;
//   BLOCKED: string;
//   APPROVED: string;
//   SUCCEEDED: string;
//   FAILED: string;
// }

// export function createFlowActions<
//   R extends typeof standardFlowACC,
//   A extends typeof standardFlowACC,
//   B extends typeof standardFlowACC,
//   S extends typeof standardFlowACC,
//   F extends typeof standardFlowACC
// >(
//   events: Events,
//   overrides: {
//     requested: R;
//     approved?: A;
//     blocked?: B;
//     succeeded?: S;
//     failed?: F;
//   },
// ) {
//   return {
//     requested: createCustomAction(events.REQUESTED, overrides.requested || standardEmptyACC),
//     approved: createCustomAction(events.APPROVED, overrides.approved || standardEmptyACC),
//     blocked: createCustomAction(events.BLOCKED, overrides.blocked || standardEmptyACC),
//     succeeded: createCustomAction(events.SUCCEEDED, overrides.succeeded || standardEmptyACC),
//     failed: createCustomAction(events.FAILED, overrides.failed || standardFailedACC),
//   };
// }

// TODO: rework this with generics and ReturnType<> to give the result valid typings
export function* waitForFlow<T extends Flow['events']>(
  events: T,
  currentFlowId: string,
) {
  let success;
  let failure;

  let done = false;
  let succeeded = false;

  while (!done) {
    const step = yield take([events.SUCCEEDED, events.BLOCKED, events.FAILED]);

    if (step.meta.flowId === currentFlowId) {
      if (step.type === events.SUCCEEDED) {
        succeeded = true;
        success = step.payload;
      } else {
        failure = step.payload;
      }

      done = true;
    }
  }

  return { success, failure, succeeded };
}

export function* requestAndWaitForFlow<
  F extends Flow,
  P extends {} // Parameters<F['actions']['requested']>[0]
>(flow: F, requestParams: P, flowId: string = uuidv4()) {
  yield put(flow.actions.requested(requestParams, flowId));

  return yield call(waitForFlow, flow.events, flowId);
}

// TODO: improve

// TODO: move to utils
export const trueOrUndefined = (value: boolean | undefined) =>
  value || value === undefined;

// TODO: move to utils
export const makeErrorSerialisable = (e: Error): SerialisableException => ({
  ...e,
  message: e.message,
  stack: e.stack,
  name: e.name,
});

export function mapBooleanToNumber(v: boolean) {
  return v ? 1 : 0;
}

export function blankOrString(s: string, blank: boolean) {
  return blank ? '' : s;
}
