import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import lodash from 'lodash';
import delay from '../utils/delay';
import setLocalValue from '../utils/setLocalValue';
import combineStyles from '../utils/combineStyles';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../utils/snackbar';
import { logEvent, registerUser } from '../utils/analytics';
import { RESET_PASSWORD_MODAL_ID } from '../modals/ResetPasswordModal';
import { IN_DEVELOPMENT } from '../constants';
import EVENTS from '../constants/events';
import redcatApiFetch from '../api';
import getThemeLookup from '../selectors/getThemeLookup';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import syncMember from '../actions/syncMember';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { useTranslation } from 'react-i18next';
import StandardButton from '../components/StandardButton';
import { REGISTER_MODAL_ID } from '../modals/RegisterModal';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import FormTextField from '../components/FormTextField';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { BsFacebook, BsApple } from 'react-icons/bs';
import HorizontalLine from '../components/HorizontalLine';
import AppleLogin from 'react-apple-login';
import { OrderingSelectors } from 'polygon-ordering';
import history from '../history';
import { REWARDS_SCREEN_ROUTE } from '../screens/RewardsScreen';

export const SIGN_IN_MODAL_ID = 'SIGN_IN_MODAL_ID';
const { getOrderTotals, getStagedPurchases } = OrderingSelectors;

const login = (
  { email, password, reCaptchaToken, mfaToken, mfaCode }: LoginParams,
  successCallback: (result: any) => void,
  finallyCallback: () => void,
  mfaCallback: (token: string) => void,
) => {
  let skipFinally = false;

  redcatApiFetch({
    path: '/api/v1/login',
    method: 'POST',
    body: {
      username: email,
      psw: password,
      'g-recaptcha-response': reCaptchaToken,
      tfa_pin: mfaCode,
      tfa_token: mfaToken,
      auth_type: 'M',
      save_session: '1',
      next: '/',
    },
  })
    .then(result => {
      skipFinally = true;
      logEvent(EVENTS.SIGN_IN);
      successCallback(result);
    })
    .catch(e => {
      const data = lodash.get(e, 'details.json', {});
      if (data.error_code === 3) {
        enqueueSuccessSnackbar('Verification PIN required');
        mfaCallback(data.additional_info);
      } else {
        logEvent(EVENTS.SIGN_IN_FAILED);
        enqueueErrorSnackbar(e);
      }
    })
    .then(() => {
      if (!skipFinally) {
        finallyCallback();
      }
    });
};

const Link = ({ label, actionLabel, url, openInNewTab, onClick }: LinkTypes) => {
  const p = useAppSelector(getThemeLookup);
  return (
    <a
      href={url}
      style={combineStyles(styles.link, p('signInLink', ['fontFamily', 'fontWeight', 'fontSize']))}
      target={openInNewTab ? '_blank' : undefined}
      onClick={onClick}
    >
      <span style={combineStyles(styles.linkLeft, p('signInLink', ['color']))}>{`${label} `}</span>
      <span style={combineStyles(styles.linkRight, p('signInLinkRight', ['color']))}>
        {actionLabel}
      </span>
    </a>
  );
};

const SignInForm: React.FC = () => {
  const [reCaptchaToken, setReCaptchaToken] = useState<null | string>(null);
  const [mfaToken, setMfaToken] = useState<null | string>(null);
  const { t } = useTranslation();
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const enableReCaptcha = useAppSelector(state => state.config.enableReCaptcha);
  const reCaptchaSiteKey = useAppSelector(state => state.config.reCaptchaSiteKey);
  const isOnlyLoyaltyEnabled = useAppSelector(state => state.config.enableOnlyLoyalty);
  const googleLoginButtonClientKey = useAppSelector(
    state => state.config.googleLoginButtonClientKey,
  );
  const facebookLoginButtonClientId = useAppSelector(
    state => state.config.facebookLoginButtonClientId,
  );
  const appleLoginButtonClientKey = useAppSelector(state => state.config.appleLoginButtonClientKey);

  const dispatch = useAppDispatch();

  const orderTotals = useAppSelector(getOrderTotals);
  const stagedPurchases = useAppSelector(getStagedPurchases);

  const schemas: SchemaType = {
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Required').min(2, 'Too Short'),
  };

  if (mfaToken) {
    schemas.mfaCode = Yup.string().required('Required').min(4, 'Too Short');
  }

  const validationSchema = Yup.object().shape(schemas);

  const onSubmit = (
    { email, password, mfaCode }: { email: string; password: string; mfaCode: string },
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    const params: LoginParams = {
      email,
      password,
      mfaToken,
      mfaCode,
    };

    if (enableReCaptcha && reCaptchaToken) {
      params.reCaptchaToken = reCaptchaToken;
    }

    login(
      params,
      async result => {
        if (IN_DEVELOPMENT && result.token) {
          setLocalValue('memberAuthToken', result.token);
        }

        dispatch(syncMember());
        await delay(2000);
        dispatch(setCurrentModal(null));
        if (isOnlyLoyaltyEnabled) {
          history.push(REWARDS_SCREEN_ROUTE);
        }
      },
      () => {
        setReCaptchaToken(null);
        setSubmitting(false);
      },
      mfaToken => setMfaToken(mfaToken),
    );
  };

  const googleLogin = (response: any) => {
    redcatApiFetch({
      path: '/sso/google/redirect',
      method: 'POST',
      body: { response },
    })
      .then(res => {
        if (res.data.NewUser) {
          registerUser(
            res.data.Provider,
            res.success,
            orderTotals,
            stagedPurchases.map(purchaseItem => purchaseItem.item),
          );
        }
        dispatch(setCurrentModal(null));
        //@ts-ignore
        if (IN_DEVELOPMENT && res.token) {
          //@ts-ignore
          setLocalValue('memberAuthToken', res.token);
        }
        dispatch(syncMember());
      })
      .catch(err => console.log(err));
  };

  const facebookLogin = (response: any) => {
    if (response.status !== 'unknown') {
      redcatApiFetch({
        path: '/sso/facebook/redirect',
        method: 'POST',
        body: { response },
      })
        .then(res => {
          if (res.data.NewUser) {
            registerUser(
              res.data.Provider,
              res.success,
              orderTotals,
              stagedPurchases.map(purchaseItem => purchaseItem.item),
            );
          }
          dispatch(setCurrentModal(null));
          //@ts-ignore
          if (IN_DEVELOPMENT && res.token) {
            //@ts-ignore
            setLocalValue('memberAuthToken', res.token);
          }
          dispatch(syncMember());
        })
        .catch(err => console.log(err));
    }
  };

  const responseAppleLogin = (response: any) => {
    if (response.authorization) {
      response.authorization.redirectURI =
        window.location.protocol + '//' + window.location.host + window.location.pathname;
    }

    if (!response.error) {
      redcatApiFetch({
        path: '/sso/apple/redirect',
        method: 'POST',
        body: response,
      })
        .then(res => {
          if (res.data.NewUser) {
            registerUser(
              res.data.Provider,
              res.success,
              orderTotals,
              stagedPurchases.map(purchaseItem => purchaseItem.item),
            );
          }

          dispatch(setCurrentModal(null));
          //@ts-ignore
          if (IN_DEVELOPMENT && res.token) {
            //@ts-ignore
            setLocalValue('memberAuthToken', res.token);
          }
          dispatch(syncMember());
        })
        .catch(err => console.log(err));
    }
  };

  const showHorizantalLine =
    appleLoginButtonClientKey || facebookLoginButtonClientId || googleLoginButtonClientKey !== null;

  return (
    <div style={styles.innerContainer}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ email: '', password: '', mfaCode: '' }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, submitForm, handleChange, setFieldValue, isValid }) => {
          return (
            <Form onSubmit={handleSubmit} style={styles.form}>
              <FormTextField
                name="email"
                type="email"
                required
                label={t('field.signIn.email')}
                onChange={e => {
                  setMfaToken(null);
                  setFieldValue('mfaCode', '');
                  handleChange(e);
                }}
              />

              <FormTextField
                name="password"
                type="password"
                required
                label={t('field.signIn.password')}
                onChange={e => {
                  setMfaToken(null);
                  setFieldValue('mfaCode', '');
                  handleChange(e);
                }}
              />
              {Boolean(mfaToken) && (
                <FormTextField
                  id="mfaCode"
                  name="mfaCode"
                  type="mfaCode"
                  label={t('field.signIn.mfaCode')}
                  required
                  onChange={handleChange}
                />
              )}

              <div>
                <Link
                  label={t('link.forgotPassword.prefix')}
                  actionLabel={t('link.forgotPassword.action')}
                  url="#"
                  onClick={() => dispatch(setCurrentModal(RESET_PASSWORD_MODAL_ID))}
                />
              </div>

              {enableReCaptcha && !reCaptchaToken && (
                <div style={styles.captchaContainer}>
                  <ReCAPTCHA
                    sitekey={reCaptchaSiteKey!}
                    onChange={token => setReCaptchaToken(token)}
                    onErrored={() => {
                      setReCaptchaToken(null);
                      enqueueErrorSnackbar(t('problemWithReCaptchaMessage'));
                    }}
                    size={deviceTypeMobile ? 'compact' : 'normal'}
                  />
                </div>
              )}

              <StandardButton
                label={mfaToken ? t('button.signInWithCode') : t('button.signIn')}
                onClick={() => submitForm()}
                disabled={!isValid || isSubmitting || (enableReCaptcha && !reCaptchaToken)}
                showSpinner={isSubmitting}
                containerStyle={styles.loginButton}
                themeKey="signInSubmitButton"
              />

              <input type="submit" style={styles.hiddenSubmitButton} />
            </Form>
          );
        }}
      </Formik>

      <div style={styles.linkSection}>
        <Link
          label={t('link.register.prefix')}
          actionLabel={t('link.register.action')}
          url="#"
          onClick={() => dispatch(setCurrentModal(REGISTER_MODAL_ID))}
        />
      </div>
      {showHorizantalLine && <HorizontalLine text="or" />}

      {googleLoginButtonClientKey && (
        <div style={styles.googleButtonContainer}>
          <GoogleLogin
            className="googleLoginButton"
            clientId={googleLoginButtonClientKey}
            buttonText="Continue with Google"
            cookiePolicy={'single_host_origin'}
            onSuccess={googleLogin}
          />
        </div>
      )}

      {facebookLoginButtonClientId && (
        <div style={styles.facebookButtonContainer}>
          <FacebookLogin
            appId={facebookLoginButtonClientId}
            // autoLoad={true}
            fields="name,email"
            callback={facebookLogin}
            textButton="Continue with Facebook"
            cssClass="facebookLoginButton"
            icon={<BsFacebook style={styles.facebookIcon} />}
          />
        </div>
      )}
      {appleLoginButtonClientKey && (
        <div style={styles.appleButtonContainer}>
          <AppleLogin
            scope="email name"
            {...{
              clientId: appleLoginButtonClientKey,
              redirectURI:
                window.location.protocol + '//' + window.location.host + window.location.pathname,
            }}
            usePopup={true}
            responseType="code id_token"
            responseMode="form_post"
            render={(renderProps: any) => (
              <button style={styles.appleButton} onClick={renderProps.onClick}>
                <BsApple style={styles.appleIcon} />
                Continue with Apple
              </button>
            )}
            callback={responseAppleLogin}
          />
        </div>
      )}
    </div>
  );
};

export default SignInForm;

const styles: Styles = {
  // do this otherwise any children without a minSize will
  // be squashed if there isn't room (instead of overflow scroll)
  innerContainer: {
    minHeight: 'min-content',
  },
  linkSection: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  link: {
    textDecoration: 'none',
    marginTop: 10,
    textAlign: 'center',
  },
  linkLeft: {
    fontFamily: 'inherit',
  },
  linkRight: {
    fontFamily: 'inherit',
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  loginButton: {
    marginTop: 20,
    height: 40,
    borderRadius: 5,
  },

  // required to stop iOS from adding a broken "GO" button to the keyboard
  hiddenSubmitButton: {
    visibility: 'hidden',
    position: 'absolute',
  },

  captchaContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 260,
    marginTop: 15,
  },
  googleButtonContainer: {
    marginTop: 35,
    width: '100%',
  },
  googleButton: {
    width: '100%',
    backgroundColor: 'white',
    fontSize: 16,
    borderRadius: '4px',
  },
  facebookButtonContainer: {
    marginTop: 10,
  },
  facebookIcon: {
    color: 'blue',
    marginRight: 10,
    fontSize: '18px',
    verticalAlign: 'middle',
  },
  appleButtonContainer: {
    marginTop: 10,
    width: '100%',
  },
  appleButton: {
    width: '100%',
    backgroundColor: 'white',
    padding: 10,
    border: '1px solid black',
    borderRadius: '4px',
    fontSize: '16px',
    lineHeight: '16px',
    cursor: 'pointer',
  },
  appleIcon: {
    marginRight: 10,
    fontSize: '18px',
    marginBottom: -2,
  },
};
