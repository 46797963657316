// TODO: review all reasons

// TODO: convert to string enum
// TODO: find a way to export
export enum FAILURE_REASON {
  FETCH_FAILED = 'FETCH_FAILED',
  MISSING_PARAMETER = 'MISSING_PARAMETER',
  SELECT_OFFER_FAILED = 'SELECT_OFFER_FAILED', // bad
  EXPIRED_GIFT_CARD = 'EXPIRED_GIFT_CARD',
  INACTIVE_GIFT_CARD = 'INACTIVE_GIFT_CARD',
  EXPIRED_OFFER = 'EXPIRED_OFFER',
  INACTIVE_OFFER = 'INACTIVE_OFFER',
  EXPIRED_MEMBER = 'EXPIRED_MEMBER',
  INACTIVE_MEMBER = 'INACTIVE_MEMBER',
  UNVERIFIED_MEMBER = 'UNVERIFIED_MEMBER',
  DELIVERY_UNAVAILABLE = 'DELIVERY_UNAVAILABLE', // split this into different reasons
  ADDRESS_NOT_FOUND = 'ADDRESS_NOT_FOUND', // split this into different reasons
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  SALE_FAILED = 'SALE_FAILED',
  UNKNOWN_PAYMENT_METHOD = 'UNKNOWN_PAYMENT_METHOD',
  MISSING_PAYMENT_HOOK = 'MISSING_PAYMENT_HOOK',
  MISSING_EFTPOS_PROVIDER = 'MISSING_EFTPOS_PROVIDER',
  PAYMENT_CANCELLED = 'PAYMENT_CANCELLED',
  OFFER_NOT_PRESENT_AND_AVAILABLE = 'OFFER_NOT_PRESENT_AND_AVAILABLE',
  COULD_NOT_STAGE_PURCHASE = 'COULD_NOT_STAGE_PURCHASE',
  BAD_GIFT_CARD = 'BAD_GIFT_CARD',
  PAYMENT_HOOK_FAILED = 'PAYMENT_HOOK_FAILED',
  TOP_UP_FAILED = 'TOP_UP_FAILED',
  SIGNATURE_REQUIRED = 'SIGNATURE_REQUIRED',
  UNKNOWN = 'UNKNOWN',
  LOCATION_OFFLINE = 'LOCATION_OFFLINE',
  LOCATION_CLOSED = 'LOCATION_CLOSED',
  PAYMENT_AUTHENTICATION_FAILED = 'PAYMENT_AUTHENTICATION_FAILED',
  PLU_NOT_FOUND = 'PLU_NOT_FOUND',
}
