import moment from 'moment';

import { dollarsToCents } from '../misc';
import processSaleItem from './processSaleItem';

// pull ingredients of ingredients up 1 level
function flattenIngredients(rawItem: RawSaleItem): RawSaleItem {
  const flattenedIngredients: RawSaleItem[] = [];

  (rawItem.Ingredients || []).forEach(rawIngredient => {
    flattenedIngredients.push({ ...rawIngredient, Ingredients: [] });

    (rawIngredient.Ingredients || []).forEach(rawNestedIngredient => {
      flattenedIngredients.push({ ...rawNestedIngredient, Ingredients: [] });
    });
  });

  return {
    ...rawItem,
    Ingredients: flattenedIngredients,
  };
}

export default function processHistoricalOrder(
  rawOrder: RawHistoricalOrder,
): HistoricalOrder {
  const items = rawOrder.Items.map(flattenIngredients).map(item =>
    processSaleItem(item),
  );

  return {
    id: rawOrder.ID,
    folioID:
      rawOrder.FolioID || rawOrder.FolioID === 0 ? rawOrder.FolioID : undefined,
    moneyPrice: dollarsToCents(rawOrder.Total),
    locationId: String(rawOrder.StoreID),
    items,
    orderDate: moment(rawOrder.OrderDate).format('M/D/YYYY'),
    pickupTime: moment(rawOrder.PickupTime).format('MMMM Do YYYY, h:mm:ss a'),
    saleType: rawOrder.SaleType,
    orderTime: moment(rawOrder.OrderDate).format('h:mma'),
    posSaleID: rawOrder.PosSaleID,
  };
}
