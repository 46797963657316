import { createCustomAction } from 'typesafe-actions';

import {
  standardEmptyACC,
  standardFailedACC,
  standardFlowACC,
} from '../../utils/sagas';
import { SALE_TYPE } from '../../constants/saleType';

const REQUESTED = '$START_NEW_ORDER/REQUESTED';
const BLOCKED = '$START_NEW_ORDER/BLOCKED';
const APPROVED = '$START_NEW_ORDER/APPROVED';
const SUCCEEDED = '$START_NEW_ORDER/SUCCEEDED';
const FAILED = '$START_NEW_ORDER/FAILED';

const request = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      preserveMember?: boolean;
      saleType?: SALE_TYPE;
      locationId?: string;
      uuid?: string;
      lifecycleEventParams?: any;
    }>(type);
};

const succeed = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      lifecycleEventParams?: any;
    }>(type);
};

export const events = {
  REQUESTED,
  BLOCKED,
  APPROVED,
  SUCCEEDED,
  FAILED,
};

export const actions = {
  requested: createCustomAction(REQUESTED, request),
  approved: createCustomAction(APPROVED, request),
  blocked: createCustomAction(BLOCKED, standardEmptyACC),
  succeeded: createCustomAction(SUCCEEDED, succeed),
  failed: createCustomAction(FAILED, standardFailedACC),
};

export default actions.requested;
