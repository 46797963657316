import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';
import { makeStyles } from '@material-ui/core';

const { getNotes } = OrderingSelectors;
const { setNotes } = OrderingOperations;

const OrderNotes: React.FC = () => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const orderNotes = useAppSelector(getNotes);
  const dispatch = useAppDispatch();
  const placeholder = t('placeholder.orderNotes');

  const useStyles = makeStyles(() => ({
    root: {
      '& ::-webkit-calendar-picker-indicator': {
        filter: `invert(${p('fieldIcons', ['color']).color})`,
      },
      //label - placeholder background color
      '& .MuiFormLabel-root': {
        backgroundColor: p('labelBackgroundColor', ['color']).color,
        borderRadius: 5,
        paddingLeft: 5,
        paddingRight: 5,
      },

      //text field errors
      '&  .MuiFormHelperText-root.Mui-error': {
        backgroundColor: p('helperBackground', ['color']).color,
        color: p('helperColor', ['color']).color,
      },
      // focused color for textfield with variant='outlined'
      '& .MuiOutlinedInput-root': {
        //textfield background color
        background: p('fieldBackground', ['color']).color,

        //text field border unfocused
        '& fieldset': {
          borderColor: p('fieldDefaultBorder', ['color']).color,
        },
        //text field border focused
        '&.Mui-focused fieldset': {
          borderColor: p('fieldBorderFocus', ['color']).color,
        },
        //text field border hover
        '&:hover fieldset': {
          borderColor: p('fieldHoverBorderColor', ['color']).color,
        },
      },
      //checkbox
      '&$checked': {
        color: p('checkboxCheckedColor', ['color']).color,
      },
    },

    checked: {},
  }));

  const muiStyles = useStyles();

  const sharedInputProps = {
    InputProps: {
      style: {
        //textfield text filled colors
        color: p('textColor', ['color']).color,
      },
    },
    InputLabelProps: {
      style: {
        //textfield label - placeholder colors
        color: p('labelColor', ['color']).color,
      },
    },
  };

  return (
    <div className="my-2">
      <TextField
        multiline
        minRows={2}
        type="text"
        variant="outlined"
        size="small"
        value={orderNotes || ''}
        fullWidth
        onChange={event => dispatch(setNotes(event?.target?.value))}
        InputProps={sharedInputProps.InputProps}
        InputLabelProps={sharedInputProps.InputLabelProps}
        className={muiStyles.root}
        label={placeholder}
      />
    </div>
  );
};

export default OrderNotes;
