import { put, takeEvery, all, select } from 'redux-saga/effects';
import { PAYMENT_METHOD } from '../../constants/paymentMethod';
import lodash from 'lodash';

import * as updateSelectedPaymentMethods from '../../actionCreators/flows/updateSelectedPaymentMethods';

import {
  getOrderTotals,
  getSelectedPaymentMethods,
  getMember,
  getGiftCard,
  getCreditCardPaymentInformation,
} from '../../selectors';

import { makeErrorSerialisable, createFlowApprover } from '../../utils/sagas';
import PaymentHooks from '../../utils/PaymentHooks';
import {
  MIN_CC_AMOUNT_NOT_MET,
  MIN_CC_AMOUNT_DEFAULT,
  PAYMENT_GATEWAY_PAYMENT_METHODS,
} from '../../constants';

import {
  setRemainingTotal,
  addSelectedPaymentMethod,
  removeSelectedPaymentMethod,
  setSelectedPaymentMethods,
} from '../../actionCreators/currentOrder';

export const requested = createFlowApprover(updateSelectedPaymentMethods);

export function* approved(
  action: ReturnType<typeof updateSelectedPaymentMethods.actions.approved>,
) {
  const {
    payload: {
      paymentMethod,
      alreadySelected,
      giftCardValidated,
      clearPaymentMethods,
    },
    meta: { flowId },
  } = action;

  try {
    // Use creditCardPaymentInformation to see what the minimum credit card charge is
    const creditCardPaymentInformation = yield select(
      getCreditCardPaymentInformation,
    );

    let selectedPaymentMethods = yield select(getSelectedPaymentMethods);

    // If we're adding a PG method, and there is already a PG method in the list, clear it out
    if (paymentMethod) {
      for (let selectedPaymentMethod of selectedPaymentMethods) {
        if (
          PAYMENT_GATEWAY_PAYMENT_METHODS.includes(paymentMethod) &&
          PAYMENT_GATEWAY_PAYMENT_METHODS.includes(selectedPaymentMethod.method)
        ) {
          yield put(removeSelectedPaymentMethod(selectedPaymentMethod.method));
        }
      }
    }

    // This block only runs if we're passing in a paymentMethod
    if (paymentMethod) {
      if (alreadySelected) {
        yield put(removeSelectedPaymentMethod(paymentMethod));
      } else {
        yield put(
          addSelectedPaymentMethod({ method: paymentMethod, amount: 0 }),
        );
      }
    }

    // Methods can now be retrieved and processed.
    // If points, rewards or pay later are selected, we clear out all other methods.
    selectedPaymentMethods = clearPaymentMethods
      ? []
      : (paymentMethod === PAYMENT_METHOD.MEMBER_POINTS && !alreadySelected) ||
        (paymentMethod === PAYMENT_METHOD.MEMBER_REWARDS && !alreadySelected) ||
        (paymentMethod === PAYMENT_METHOD.PAY_LATER && !alreadySelected)
      ? [{ method: paymentMethod, amount: 0 }]
      : yield select(getSelectedPaymentMethods);

    // Create 2 empty lists, nonPG and PG
    const nonPaymentGatewayMethods: SubPayment[] = [];
    const paymentGatewayMethods: SubPayment[] = [];

    // The final list containing calculated methods and amounts
    const updatedSelectedPaymentMethods = [];

    // Assign selected methods to either nonPG or PG list
    selectedPaymentMethods.forEach((subPayment: any) => {
      if (PAYMENT_GATEWAY_PAYMENT_METHODS.includes(subPayment.method)) {
        paymentGatewayMethods.push(subPayment);
      } else {
        nonPaymentGatewayMethods.push(subPayment);
      }
    });

    // Get order totals before assigning amounts to each method
    const orderTotals = yield select(getOrderTotals);

    // Need to use pointsPrice if paying with points, otherwise discountedMoneyPrice
    let remainingTotal = lodash.find(selectedPaymentMethods, [
      'method',
      PAYMENT_METHOD.MEMBER_POINTS,
    ])
      ? orderTotals.pointsPrice
      : orderTotals.discountedMoneyPrice;

    const minAmount = creditCardPaymentInformation
      ? creditCardPaymentInformation.minAmount
        ? creditCardPaymentInformation.minAmount
        : 0
      : 0;

    const minPercent = creditCardPaymentInformation
      ? creditCardPaymentInformation.minPercent
        ? creditCardPaymentInformation.minPercent
        : 0
      : 0;

    const MIN_CC_AMOUNT = creditCardPaymentInformation
      ? minAmount + remainingTotal * (minPercent / 100)
      : MIN_CC_AMOUNT_DEFAULT;

    // Perform calculations for nonPG list
    for (const nonPaymentGatewayMethod of nonPaymentGatewayMethods) {
      let balance;
      let amount;
      // Get balance based on payment method
      switch (nonPaymentGatewayMethod.method) {
        case PAYMENT_METHOD.MEMBER_MONEY: {
          const member = yield select(getMember);
          balance = member.moneyBalance;
          break;
        }
        case PAYMENT_METHOD.MEMBER_REWARDS: {
          const member = yield select(getMember);
          balance = member.rewardsBalance;
          break;
        }
        case PAYMENT_METHOD.GIFT_CARD: {
          const giftCard = yield select(getGiftCard);
          // If a valid gift card is found, we get the balance, otherwise balance is 0
          balance = giftCard.moneyBalance || 0;
          break;
        }
        case PAYMENT_METHOD.MEMBER_POINTS: {
          const member = yield select(getMember);
          balance = member.pointsBalance;
          break;
        }
        case PAYMENT_METHOD.PAY_LATER: {
          balance = remainingTotal;
          break;
        }
        default:
          break;
      }
      // Calculate amount to charge based on balance and remainingTotal
      if (balance >= remainingTotal) {
        amount = remainingTotal;
      } else {
        // Balance not enough to cover remainingTotal, so points, rewards and pay later cannot be used
        amount = lodash.find(selectedPaymentMethods, [
          (selectedPaymentMethod: any) => {
            return (
              selectedPaymentMethod.method === PAYMENT_METHOD.MEMBER_POINTS ||
              selectedPaymentMethod.method === PAYMENT_METHOD.MEMBER_REWARDS ||
              selectedPaymentMethod.method === PAYMENT_METHOD.PAY_LATER
            );
          },
        ])
          ? 0
          : balance;
      }

      // Add new object with updated amount to temp list
      updatedSelectedPaymentMethods.push({
        method: nonPaymentGatewayMethod.method,
        amount: amount,
        giftCardValidated: giftCardValidated ? true : undefined,
      });

      // Update local remainingTotal
      remainingTotal -= amount;
    }

    // Calculate remaining charge for PG method (should be only 1 item in list)
    if (paymentGatewayMethods.length > 1) {
      throw new Error('more than 1 payment gateway method selected');
    } else if (paymentGatewayMethods.length === 1) {
      let paymentGatewayAmount = remainingTotal;
      let difference = 0;

      const hook: any = PaymentHooks.get(MIN_CC_AMOUNT_NOT_MET);

      if (remainingTotal < MIN_CC_AMOUNT) {
        if (updatedSelectedPaymentMethods.length === 0) {
          // No other payment methods to deduct from
          paymentGatewayAmount = 0;
          if (paymentMethod && hook) {
            hook({});
          }
        } else {
          if (orderTotals.discountedMoneyPrice < MIN_CC_AMOUNT) {
            // Order total is too low
            paymentGatewayAmount = 0;
            if (paymentMethod && hook) {
              hook({});
            }
          } else {
            if (remainingTotal === 0) {
              // User has at least one non-payment gateway method selected and it's balance is enough to cover the entire order
              paymentGatewayAmount = 0;
            } else {
              // Deduct from other methods to bring payment gateway method above MIN_CC_AMOUNT
              difference = MIN_CC_AMOUNT - remainingTotal;
              paymentGatewayAmount += difference;
              let amountToDeduct = difference;
              let currentIndex = updatedSelectedPaymentMethods.length - 1;
              while (amountToDeduct > 0) {
                let originalAmount =
                  updatedSelectedPaymentMethods[currentIndex].amount;
                if (originalAmount < amountToDeduct) {
                  updatedSelectedPaymentMethods[currentIndex].amount = 0;
                  amountToDeduct -= originalAmount;
                  currentIndex--;
                } else {
                  updatedSelectedPaymentMethods[currentIndex].amount =
                    originalAmount - amountToDeduct;
                  amountToDeduct = 0;
                }
              }
            }
          }
        }
      } else {
        // Charge remaining to payment gateway method
        paymentGatewayAmount = remainingTotal;
      }

      updatedSelectedPaymentMethods.push({
        method: paymentGatewayMethods[0].method,
        amount: paymentGatewayAmount,
        giftCardValidated: undefined,
      });

      remainingTotal -= paymentGatewayAmount - difference;
    }

    // If any methods have a zero or negative charge, remove them before adding
    yield put(
      setSelectedPaymentMethods(
        clearPaymentMethods
          ? []
          : updatedSelectedPaymentMethods.filter(
              updatedSelectedPaymentMethod => {
                if (updatedSelectedPaymentMethod.amount <= 0) {
                  if (
                    updatedSelectedPaymentMethod.method ===
                      PAYMENT_METHOD.GIFT_CARD &&
                    !updatedSelectedPaymentMethod.giftCardValidated
                  ) {
                    return true;
                  }
                  return false;
                }
                return true;
              },
            ),
      ),
    );
    yield put(setRemainingTotal(remainingTotal));

    yield put(updateSelectedPaymentMethods.actions.succeeded({}, flowId));
  } catch (e) {
    yield put(
      updateSelectedPaymentMethods.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(updateSelectedPaymentMethods.events.REQUESTED, requested),
    takeEvery(updateSelectedPaymentMethods.events.APPROVED, approved),
  ]);
}
