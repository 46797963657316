import { createCustomAction } from 'typesafe-actions';

import {
  standardEmptyACC,
  standardFailedACC,
  standardFlowACC,
} from '../../utils/sagas';

import { PAYMENT_METHOD } from '../../constants/paymentMethod';

const REQUESTED = '$UPDATE_SELECTED_PAYMENT_METHODS/REQUESTED';
const BLOCKED = '$UPDATE_SELECTED_PAYMENT_METHODS/BLOCKED';
const APPROVED = '$UPDATE_SELECTED_PAYMENT_METHODS/APPROVED';
const SUCCEEDED = '$UPDATE_SELECTED_PAYMENT_METHODS/SUCCEEDED';
const FAILED = '$UPDATE_SELECTED_PAYMENT_METHODS/FAILED';

const request = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      paymentMethod?: PAYMENT_METHOD | null;
      alreadySelected?: boolean | null;
      giftCardValidated?: boolean | undefined;
      clearPaymentMethods?: boolean | undefined;
    }>(type);
};

export const events = {
  REQUESTED,
  BLOCKED,
  APPROVED,
  SUCCEEDED,
  FAILED,
};

export const actions = {
  requested: createCustomAction(REQUESTED, request),
  approved: createCustomAction(APPROVED, request),
  blocked: createCustomAction(BLOCKED, standardEmptyACC),
  succeeded: createCustomAction(SUCCEEDED, standardEmptyACC),
  failed: createCustomAction(FAILED, standardFailedACC),
};

export default actions.requested;
