import React, { useState, useEffect, useRef } from 'react';
import lodash from 'lodash';
import { RiArrowRightFill } from 'react-icons/ri';
import { FiAlertTriangle } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import GoogleMapReact from 'google-map-react';

import { OrderingSelectors, determineLocationSessions, OrderingOperations } from 'polygon-ordering';

import combineStyles from '../utils/combineStyles';
import { convertCoordsToShortKeys } from '../utils/maps';
import getLocalValue from '../utils/getLocalValue';
import { logEvent } from '../utils/analytics';
import { CONTAINER_PROPERTIES } from '../utils/theme';

import { MOBILE_DISPLAY_WIDTH_THRESHOLD, IN_DEVELOPMENT } from '../constants';
import EVENTS from '../constants/events';

import { updateCurrentUserLocationAction } from '../thunks/updateCurrentUserLocation';
import adjustOrderAction from '../actions/adjustOrder';

import getThemeLookup from '../selectors/getThemeLookup';
import getLocationsForPicker from '../selectors/getLocationsForPicker';
import getUserLocation from '../selectors/getUserLocation';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import getUsingFallbackLocation from '../selectors/getUsingFallbackLocation';
import getExtremelyShortScreen from '../selectors/getExtremelyShortScreen';

import Modal from '../components/Modal';
import LocationMapMarker from '../components/LocationMapMarker';
import LocationBrandBar from '../components/LocationBrandBar';
import UserMapMarker from '../components/UserMapMarker';
import LocationListElement from '../components/LocationListElement';
import LocationSearchBar from '../components/LocationSearchBar';
import StandardButton from '../components/StandardButton';
import Text from '../components/Text';
import { useAppSelector, useAppDispatch } from '../app/hooks';

const { getLocations, getOrderingWindowPadding, getBrands } = OrderingSelectors;

function safeComparisonString(str: string) {
  return (str || '').toLowerCase().trim();
}

export const LOCATION_MODAL_ID = 'LOCATION_MODAL_ID';

const { fetchLocations } = OrderingOperations;

// https://developers.google.com/web/fundamentals/native-hardware/user-location
// to increase performance, try using something like getBounds
// to skip rendering locations over x distance from the current view port

const useCurrentUserLocation = (callback: () => void) => {
  const [userLocationUpdated, setUserLocationUpdated] = useState<boolean>(false);
  if (userLocationUpdated) return;
  callback();
  setUserLocationUpdated(true);
};

const LocationModal: React.FC<any> = props => {
  const [state, setState] = useState<{ [key: string]: any }>({
    center: null,
    selectedLocationId: null,
    hoveredLocationId: null,
    tilesLoaded: false,
    searchText: '',
    brandId: null,
    initalCenterComplete: false,
  });
  const { params } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const googleMapsApiKey = useAppSelector(state => state.config.googleMapsApiKey) as string;
  const initialZoom = useAppSelector(state => state.config.initialZoom) as number | undefined;
  const hideLocationHours = useAppSelector(state => state.config.hideLocationHours);
  const locationAddressTemplate = useAppSelector(state => state.config.locationAddressTemplate);
  const enableAutoselectFirstLocation = useAppSelector(
    state => state.config.enableAutoselectFirstLocation,
  );
  const keyOrderPropertyUpdateInProgress = useAppSelector(
    state => state.keyOrderPropertyUpdateInProgress,
  );
  const p = useAppSelector(getThemeLookup);
  const allLocations = useAppSelector(getLocations);
  const locationsList = useAppSelector(getLocationsForPicker);
  const usingFallbackLocation = useAppSelector(getUsingFallbackLocation);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const userLocation = useAppSelector(getUserLocation);
  const orderingWindowPadding = useAppSelector(getOrderingWindowPadding);
  const brands = useAppSelector(getBrands);
  const extremelyShortScreen = useAppSelector(getExtremelyShortScreen);

  useCurrentUserLocation(() => {
    dispatch(updateCurrentUserLocationAction());
  });

  useEffect(() => {
    dispatch(fetchLocations({}));
  }, []);

  const preExtremelyShortScreen = useRef<boolean | null>(extremelyShortScreen);
  useEffect(() => {
    const { center, selectedLocationId, initalCenterComplete } = state;

    if (
      !usingFallbackLocation &&
      selectedLocationId == null &&
      center == null &&
      !initalCenterComplete
    ) {
      centerOnUserLocation(true);
    }

    if (extremelyShortScreen && !preExtremelyShortScreen.current) {
      setSearchText(null);
    }
    preExtremelyShortScreen.current = extremelyShortScreen;
  });

  const selectLocation = (selectedLocationId: string, selectedByMarker: boolean = false) => {
    const location = allLocations![String(selectedLocationId)];

    let center = null;

    if (location) {
      center = convertCoordsToShortKeys(location);
    }

    setState({ ...state, selectedLocationId, center, selectedByMarker });
  };

  const hoverLocationStart = (hoveredLocationId: string | null) =>
    setState({ ...state, hoveredLocationId });

  const hoverLocationEnd = () => setState({ ...state, hoveredLocationId: null });

  const setSearchText = (searchText: string | null) => {
    setState({ ...state, searchText: searchText || '' });
    logEvent(EVENTS.CHANGE_LOCATION_SEARCH_TEXT, { label: searchText });
  };

  const centerOnUserLocation = (skipUpdate = false) => {
    if (!skipUpdate) {
      dispatch(updateCurrentUserLocationAction());
      logEvent(EVENTS.CENTER_MAP_ON_USER_LOCATION);
    }

    setState({ ...state, center: getUserCoords(), initalCenterComplete: true });
  };

  const onChange = () => {
    const { center } = state;

    if (center) {
      setState({ ...state, center: null });
    }
  };

  const getUserCoords = () => {
    return convertCoordsToShortKeys(userLocation);
  };

  // this can't be done in a selector because it is sensitive to the render time
  const getLocationsForSaleType = () => {
    const { saleType } = params;

    const result: any[] = [];

    (locationsList || []).forEach(location => {
      const enhanced = {
        ...location,
        ...determineLocationSessions(location, orderingWindowPadding, saleType),
      };

      if (enhanced.takingOrders.includes(saleType)) {
        result.push(enhanced);
      }
    });

    return result;
  };

  const filterLocationsForSearchAndBrand = (locationsList: (POSLocation | POSLocationShort)[]) => {
    const { searchText, brandId } = state;

    const result: (POSLocation | POSLocationShort)[] = [];

    (locationsList || []).forEach((location: POSLocation | POSLocationShort) => {
      if (
        searchText &&
        !safeComparisonString(location.name).includes(safeComparisonString(searchText))
      ) {
        return;
      }

      if (brandId != null && !location.brandIds.includes(brandId)) {
        return;
      }

      result.push(location as POSLocation | POSLocationShort);
    });

    return result;
  };

  const {
    selectedLocationId,
    hoveredLocationId,
    tilesLoaded,
    searchText,
    center,
    selectedByMarker,
    brandId,
  } = state;

  const { saleType } = params;

  if (!allLocations) {
    return null;
  }

  const locationsForSaleType = getLocationsForSaleType();
  const locations = filterLocationsForSearchAndBrand(locationsForSaleType);

  let selectedLocation = allLocations[selectedLocationId] as
    | POSLocation
    | POSLocationShort
    | undefined;

  if (selectedLocation && !locations.some(location => location.id === selectedLocation?.id)) {
    selectedLocation = undefined;
  }

  if (!selectedLocation && locations.length && enableAutoselectFirstLocation) {
    selectedLocation = locations[0];
  }

  const selectedLocationPresent = selectedLocation?.id != null && selectedLocation?._isFullData;

  const userCoords = getUserCoords();

  const proceed = () => {
    if (!selectedLocation) {
      return;
    }

    dispatch(adjustOrderAction({ saleType, locationId: selectedLocation.id }));
    logEvent(EVENTS.PICK_LOCATION_AND_SALE_TYPE, {
      label: `ST:${saleType} - LOC:${selectedLocation.id}`,
    });
  };

  const markerColor = p('locationMapMarker', ['color']).color as React.CSSProperties;

  const selectedMarkerColor = p('locationMapMarkerSelected', ['color'])
    .color as React.CSSProperties;

  const brandRepresentedMap: { [key: string]: boolean } = {};

  locationsForSaleType.forEach(location => {
    (location.brandIds || []).forEach((locationBrandId: string) => {
      brandRepresentedMap[locationBrandId] = true;
    });
  });

  const representedBrands = brands.filter((brand: Brand) => brandRepresentedMap[brand.id]);

  const disableMap = IN_DEVELOPMENT && getLocalValue('disableMap');

  return (
    <Modal
      title={t(`saleType.${saleType}`)}
      desktopMaxWidth={600}
      desktopMinWidth={MOBILE_DISPLAY_WIDTH_THRESHOLD}
      containerStyle={styles.modalContainer}
      mobileContainerStyle={styles.modalMobileContainer}
      tallestPossible
      expandToMaxWidth
      forceFloatingTitle
      locked={keyOrderPropertyUpdateInProgress}
    >
      {!extremelyShortScreen && (
        <div
          style={combineStyles(styles.mapContainer, { height: deviceTypeMobile ? '25%' : '35%' })}
        >
          <GoogleMapReact
            bootstrapURLKeys={disableMap ? undefined : { key: googleMapsApiKey }}
            defaultCenter={userCoords as GoogleMapReact.Coords}
            defaultZoom={initialZoom}
            center={center}
            options={maps => ({
              fullscreenControl: false,
              clickableIcons: false, // disable clicking on PT icons
            })}
            onTilesLoaded={() => setState({ ...state, tilesLoaded: true })}
            onChange={onChange}
            onZoomAnimationEnd={() => logEvent(EVENTS.CHANGE_MAP_ZOOM)}
            onDragEnd={() => logEvent(EVENTS.PAN_MAP)}
          >
            {tilesLoaded &&
              locations.map(location => (
                <LocationMapMarker
                  key={location.id}
                  selected={location.id === selectedLocation?.id}
                  hovered={location.id === hoveredLocationId}
                  lat={location.latitude}
                  lng={location.longitude}
                  location={location}
                  selectLocation={selectLocation}
                  hoverLocationStart={hoverLocationStart}
                  hoverLocationEnd={hoverLocationEnd}
                  color={markerColor}
                  selectedColor={selectedMarkerColor}
                />
              ))}

            {!usingFallbackLocation && <UserMapMarker {...userCoords} />}
          </GoogleMapReact>
        </div>
      )}

      <LocationBrandBar
        brandId={brandId}
        setBrandId={newBrandId => {
          setState({ ...state, brandId: newBrandId });
          logEvent(EVENTS.CHANGE_LOCATION_BRAND_FILTER, { label: newBrandId });
        }}
        brands={representedBrands}
      />

      <LocationSearchBar
        locationCount={locations.length}
        searchText={searchText}
        setSearchText={lodash.throttle(setSearchText)}
        centerOnUserLocation={centerOnUserLocation}
      />

      {usingFallbackLocation && (
        <div
          style={combineStyles(
            styles.noKnownUserLocationContainer,
            p('noKnownUserLocationMessage', CONTAINER_PROPERTIES),
          )}
        >
          <FiAlertTriangle
            style={combineStyles(
              styles.noKnownUserLocationIcon,
              p('noKnownUserLocationMessage', ['color', 'fontSize']),
              p('noKnownUserLocationMessageIcon', ['color', 'fontSize']),
            )}
          />
          <Text
            themeKey="noKnownUserLocationMessage"
            style={styles.noKnownUserLocationMessage}
            value={t('noKnownUserLocationMessage')}
          />
        </div>
      )}

      <div
        style={combineStyles(
          styles.locationList,
          extremelyShortScreen ? { borderTop: '1px solid rgba(0,0,0,0.1)' } : { minHeight: 180 },
        )}
        key={`${brandId}-${searchText}`}
      >
        {locations.length ? (
          locations.map(location => (
            <LocationListElement
              p={p}
              t={t}
              deviceTypeMobile={deviceTypeMobile}
              key={location.id}
              location={location}
              selected={location.id === selectedLocation?.id}
              selectedByMarker={selectedByMarker}
              selectLocation={selectLocation}
              hovered={location.id === hoveredLocationId}
              hoverLocationStart={hoverLocationStart}
              hoverLocationEnd={hoverLocationEnd}
              proceed={keyOrderPropertyUpdateInProgress ? undefined : proceed}
              hideDistance={usingFallbackLocation}
              brands={representedBrands}
              hideLocationHours={hideLocationHours}
              locationAddressTemplate={locationAddressTemplate}
              disabled={keyOrderPropertyUpdateInProgress}
            />
          ))
        ) : (
          <div style={styles.noLocationsContainer}>
            <Text themeKey="noLocationsMessage" value={t('noLocationsMessage')} />
          </div>
        )}
      </div>

      <StandardButton
        label={
          selectedLocationPresent
            ? t('button.selectLocation.ready')
            : t('button.selectLocation.notReady')
        }
        disabled={keyOrderPropertyUpdateInProgress || !selectedLocationPresent}
        onClick={proceed}
        themeKey="modalProceedButton"
        RightIconComponent={selectedLocationPresent ? RiArrowRightFill : undefined}
        containerStyle={styles.button}
        showSpinner={keyOrderPropertyUpdateInProgress}
      />
    </Modal>
  );
};

const styles: Styles = {
  modalContainer: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  modalMobileContainer: {
    // https://stackoverflow.com/a/30523342/8706204
    WebkitMaskImage: '-webkit-radial-gradient(white, black)',
  },

  mapContainer: {
    overflow: 'hidden',
  },

  locationList: {
    flex: 1,
    overflowY: 'auto',

    paddingBottom: 100,
  },

  noLocationsContainer: {
    paddingTop: 60,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },

  button: {
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
  },

  noKnownUserLocationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 10,
    borderBottom: '1px solid rgba(0,0,0,0.1)',
    alignItems: 'center',
  },
  noKnownUserLocationIcon: {
    marginRight: 5,
    minWidth: '5%',
  },
  noKnownUserLocationMessage: {
    textAlign: 'center',
  },
};

export default LocationModal;
