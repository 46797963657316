import { ActionType, getType } from 'typesafe-actions';

import * as buffer from '../../actionCreators/buffer';

const initialState = null;

export default function (
  state: Offer[] | null = initialState,
  action: ActionType<typeof buffer>,
): Offer[] | null {
  switch (action.type) {
    case getType(buffer.clearBuffer): {
      return initialState;
    }
    case getType(buffer.setBufferOffers): {
      const { value } = action.payload;
      return value == null ? initialState : value;
    }
    default:
      return state;
  }
}
