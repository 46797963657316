import React from 'react';
import { getDefaultDropdownMenu, getLoyaltyDropdownMenu } from '../utils/getAvailableScreens';
import StandardButton from '../components/StandardButton';
import combineStyles from '../utils/combineStyles';
import { useAppSelector } from '../app/hooks';
import { useTranslation } from 'react-i18next';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import history from '../history';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import getThemeLookup from '../selectors/getThemeLookup';
import { OrderingSelectors } from 'polygon-ordering';

const { getMember } = OrderingSelectors;

interface SubScreens {
  label: string;
  path: string;
  subTitle?: string;
}
interface Screen {
  label: string;
  path: string;
  private: boolean;
  title?: string;
  subTitle?: string;
  subRoutes?: SubScreens[];
}

const SideBar = ({ screen }: { screen: Screen }) => {
  const { t } = useTranslation();
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const { pathname } = useLocation();

  return (
    <div>
      <StandardButton
        label={t(screen.label)}
        themeKey={
          pathname === screen.path ||
          (screen.subRoutes &&
            screen.subRoutes?.filter(route => route.path === pathname).length !== 0)
            ? 'activeMemberButtonLoyaltySideBar'
            : 'defaultMemberButtonLoyaltySideBar'
        }
        containerStyle={combineStyles(
          styles.button,
          deviceTypeMobile ? styles.buttonMobile : styles.buttonDesktop,
        )}
        onClick={() => {
          history.push(screen.path);
        }}
      />
      {screen.subRoutes?.filter(route => route.path === pathname).length !== 0 && (
        <div style={{ marginLeft: 10 }}>
          {screen.subRoutes?.map(route => (
            <StandardButton
              key={uuid()}
              label={t(route.label)}
              themeKey={pathname === route.path ? 'activeSubRoute' : 'defaultSubRoute'}
              containerStyle={combineStyles(
                styles.button,
                deviceTypeMobile ? styles.buttonMobile : styles.buttonDesktop,
              )}
              onClick={() => {
                history.push(route.path);
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const LoyaltySideBar: React.FC = () => {
  const p = useAppSelector(getThemeLookup);
  const member = useAppSelector(getMember);
  const loyaltyDisabled = useAppSelector(state => state.config.disableLoyalty);

  const menus = loyaltyDisabled ? getDefaultDropdownMenu() : getLoyaltyDropdownMenu();

  return (
    <div
      className="py-3"
      style={{
        backgroundColor: p('loyaltyModals', ['backgroundColor']).backgroundColor,
        borderRadius: 5,
      }}
    >
      {menus.map(screen => (
        <div key={uuid()}>
          {screen.private && member ? (
            <SideBar screen={screen} />
          ) : (
            !screen.private && <SideBar screen={screen} />
          )}
        </div>
      ))}
    </div>
  );
};

const styles: Styles = {
  modalDesktopContainer: {
    borderRadius: 2,
  },
  modalMobileContainer: {
    padding: 0,
    height: '100vh',
  },

  bodyMobile: {},
  buttonMobile: {
    paddingLeft: 40,
  },
  buttonDesktop: {
    paddingLeft: 20,
  },

  button: {
    justifyContent: 'left',
    marginLeft: 20,
  },
};

export default LoyaltySideBar;
