import React, { useRef, useEffect } from 'react';
import moment from 'moment-timezone';
import { RiMapPin2Fill, RiPhoneFill, RiTimeFill } from 'react-icons/ri';

import { OrderingConstants } from 'polygon-ordering';

import { BRAND_ICON_PREFIX } from '../constants';
import { SALE_TYPE_ICONS } from '../constants/saleTypes';

import combineStyles from '../utils/combineStyles';
import fillTemplateString from '../utils/fillTemplateString';

import TouchableOpacity from './TouchableOpacity';
import Text from './Text';
import RedcatImage from './RedcatImage';
import { IconType } from 'react-icons/lib';
import { useAppSelector } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';

const { SALE_TYPES } = OrderingConstants;

const Detail: React.FC<{
  IconComponent: IconType;
  text: string;
  labelStyle?: React.CSSProperties | { [key: string]: string | number };
}> = ({ IconComponent, text, labelStyle }) => {
  const p = useAppSelector(getThemeLookup);
  return (
    <div style={styles.detail}>
      <IconComponent
        style={combineStyles(
          styles.detailIcon,
          p('locationListElementDetailIcon', ['color', 'fontSize']),
        )}
      />
      <Text themeKey="locationListElementDetail" value={text} style={labelStyle} />
    </div>
  );
};

function orderingWindowString(location: any) {
  const orderingWindowStart = location.orderingWindowStart || location.openingTime;
  const orderingWindowEnd = location.orderingWindowEnd || location.closingTime;

  if (!orderingWindowStart || !orderingWindowEnd) {
    return 'UNKNOWN';
  }

  const { timeZone } = location;

  const start = Intl.DateTimeFormat('en-AU', {
    hour: 'numeric',
    minute: 'numeric',
    timeZone,
  }).format(new Date(orderingWindowStart));
  const end = Intl.DateTimeFormat('en-AU', { hour: 'numeric', minute: 'numeric', timeZone }).format(
    new Date(orderingWindowEnd),
  );

  return `${start} - ${end}` + (timeZone !== moment.tz.guess() ? ` (${timeZone})` : '');
}

function distanceString(location: any) {
  if (location?.distance == null) {
    return '';
  }

  return `${location.distance.toFixed(location.distance <= 20 ? 1 : 0)}km`;
}

function determineAddressString(locationAddressTemplate: any, addressData: any) {
  if (!locationAddressTemplate) {
    return addressData?.combinedLong;
  }

  if (typeof locationAddressTemplate === 'string' && locationAddressTemplate.trim()) {
    return fillTemplateString(locationAddressTemplate, addressData, '');
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
// https://github.com/bvaughn/react-window

// Redo this component
const LocationListElement: React.FC<any> = props => {
  const {
    p,
    location,
    selectLocation,
    selected,
    hovered,
    hoverLocationStart,
    hoverLocationEnd,
    deviceTypeMobile,
    proceed,
    hideDistance,
    brands = [],
    hideLocationHours,
    locationAddressTemplate,
    disabled,
    hideAddress,
    alwaysShowBrands,
    estimatedDeliveryTime,
    hideLeft,
    selectedByMarker,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const selectedRef = useRef<boolean>(selected);
  useEffect(() => {
    if (!selectedRef.current && selected && selectedByMarker) {
      ref.current!.scrollIntoView({
        behavior: 'smooth', // does not work with PerfectScrollbar
        block: 'center',
      });
    }
  }, [selected, selectedByMarker]);

  const details = (
    <>
      {Boolean(location.phone) && <Detail IconComponent={RiPhoneFill} text={location.phone} />}

      {true && <Detail IconComponent={RiTimeFill} text={orderingWindowString(location)} />}

      {Boolean(estimatedDeliveryTime) && (
        <Detail
          IconComponent={SALE_TYPE_ICONS[SALE_TYPES.DELIVERY]}
          text={estimatedDeliveryTime}
          labelStyle={{ fontWeight: '600' }}
        />
      )}
    </>
  );

  const showBrands = (alwaysShowBrands && brands.length) || brands.length >= 2;

  const addressString = determineAddressString(locationAddressTemplate, location.address);

  return (
    <TouchableOpacity
      ref={ref}
      style={combineStyles(
        styles.mainContainer,
        p('locationListElement', ['backgroundColor']),
        selected && p('locationListElementSelected', ['backgroundColor']),
        hovered && !selected && styles.hovered,
      )}
      onClick={selected && proceed ? proceed : () => selectLocation && selectLocation(location.id)}
      onMouseEnter={() => hoverLocationStart && hoverLocationStart(location.id)}
      onMouseLeave={() => hoverLocationEnd && hoverLocationEnd(null)}
      enableHoverEffect={false}
      isScrollChild
      disabled={disabled}
    >
      {!hideLeft && (
        <div style={styles.left}>
          <RiMapPin2Fill
            style={combineStyles(
              styles.distanceIcon,
              p('locationListElementDistanceIcon', 'color'),
            )}
          />

          {!hideDistance && (
            <Text themeKey="locationListElementDistance" value={distanceString(location)} />
          )}
        </div>
      )}

      <div style={styles.middle}>
        <Text themeKey="locationListElementName" style={styles.name} value={location.name} />

        {!hideAddress && (
          <Text
            themeKey="locationListElementAddress"
            style={styles.address}
            value={addressString}
          />
        )}

        {deviceTypeMobile && <div style={styles.middleDetails}>{details}</div>}

        {showBrands && (
          <div style={styles.brandsContainer}>
            {(location.brands || []).map((brand: any) => (
              <RedcatImage
                key={brand.id}
                containerStyle={styles.brandImage}
                imagePath={brand.imagePath}
                alt={brand.name}
                size={25}
                pathPrefix={BRAND_ICON_PREFIX}
              />
            ))}
          </div>
        )}
      </div>

      {!deviceTypeMobile && <div style={styles.right}>{details}</div>}
    </TouchableOpacity>
  );
};

const styles: Styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    padding: 15,
  },

  left: {
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    minWidth: 50,
  },
  distanceIcon: {
    marginBottom: 9,
  },

  middle: {
    flex: 1,
    paddingLeft: 5,
    paddingRight: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  name: {
    marginBottom: 5,
  },
  address: {
    marginBottom: 5,
  },
  middleDetails: {
    marginTop: 5,
    marginBottom: 5,
  },

  right: {
    paddingLeft: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },

  hovered: {
    opacity: 0.6,
  },

  detail: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 5,
  },
  detailIcon: {
    marginRight: 4,
  },

  brandsContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
  },

  brandImage: {
    marginRight: 10,
    borderRadius: 5,
    overflow: 'hidden',
  },
};

export default LocationListElement;
