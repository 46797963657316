import lodash from 'lodash';

import processSize from './processSize';
export default function processItem(
  rawItem: RawItem,
  excludedChoiceSets?: string[],
): [_Items, ChoiceSets] {
  const processedSizes: _Size[] = [];
  const processedItems: _Items = {};

  let allChoiceSets: ChoiceSets = {};

  const otherSizes = [...rawItem.OtherSizes];
  const rawSizes = otherSizes.length ? otherSizes : [rawItem];
  rawSizes.forEach(rawSize => {
    const [processedSize, processedSizeChoiceSets] = processSize(
      rawSize,
      excludedChoiceSets,
    );

    processedSizes.push(processedSize);
    allChoiceSets = { ...allChoiceSets, ...processedSizeChoiceSets };
  });

  let availableSizes = processedSizes.filter(item => item.available);

  if (availableSizes.every(size => Boolean(size.kilojoules))) {
    availableSizes = lodash.orderBy(
      availableSizes,
      ['kilojoules', 'name'],
      ['desc', 'asc'],
    );
  } else {
    availableSizes = lodash.orderBy(
      availableSizes,
      ['baseMoneyPrice', 'name'],
      ['desc', 'asc'],
    );
  }

  const availableSizeIds = availableSizes.map(size => size.id);

  availableSizes.forEach(size => {
    processedItems[size.id] = {
      ...size,
      sizes: [...availableSizeIds],
    };
  });

  return [processedItems, allChoiceSets];
}
