import { createCustomAction } from 'typesafe-actions';

import { singleValuePayload } from '../utils/actions';

const CLEAR_BUFFER = '$CLEAR_BUFFER';
export const clearBuffer = createCustomAction(
  CLEAR_BUFFER,
  (type: typeof CLEAR_BUFFER) =>
    ({ preserveReservationId }: { preserveReservationId?: boolean }) => ({
      type,
      payload: {
        preserveReservationId: Boolean(preserveReservationId),
      },
    }),
);

const RESERVE_BUFFER = '$RESERVE_BUFFER';
export const reserveBuffer = createCustomAction(
  RESERVE_BUFFER,
  singleValuePayload<typeof RESERVE_BUFFER, string>(),
);

const SET_BUFFER_READY_TO_APPLY = '$SET_BUFFER_READY_TO_APPLY';
export const setBufferReadyToApply = createCustomAction(
  SET_BUFFER_READY_TO_APPLY,
  singleValuePayload<typeof SET_BUFFER_READY_TO_APPLY, boolean>(),
);

const SET_BUFFER_LOCATION_ID = '$SET_BUFFER_LOCATION_ID';
export const setBufferLocationId = createCustomAction(
  SET_BUFFER_LOCATION_ID,
  singleValuePayload<typeof SET_BUFFER_LOCATION_ID, string | null>(),
);

const SET_BUFFER_MENU = '$SET_BUFFER_MENU';
export const setBufferMenu = createCustomAction(
  SET_BUFFER_MENU,
  singleValuePayload<typeof SET_BUFFER_MENU, Menu | null>(),
);

const SET_BUFFER_OFFERS = '$SET_BUFFER_OFFERS';
export const setBufferOffers = createCustomAction(
  SET_BUFFER_OFFERS,
  singleValuePayload<typeof SET_BUFFER_OFFERS, Offer[] | null>(),
);

const SET_BUFFER_SALE_TYPE = '$SET_BUFFER_SALE_TYPE';
export const setBufferSaleType = createCustomAction(
  SET_BUFFER_SALE_TYPE,
  singleValuePayload<typeof SET_BUFFER_SALE_TYPE, number | null>(),
);

const SET_BUFFER_DELIVERY_TIME = '$SET_BUFFER_DELIVERY_TIME';
export const setBufferDeliveryTime = createCustomAction(
  SET_BUFFER_DELIVERY_TIME,
  singleValuePayload<typeof SET_BUFFER_DELIVERY_TIME, string | null>(),
);

const SET_BUFFER_TIME_ESTIMATE_RETURNED = '$SET_BUFFER_TIME_ESTIMATE_RETURNED';
export const setBufferTimeEstimateReturned = createCustomAction(
  SET_BUFFER_TIME_ESTIMATE_RETURNED,
  singleValuePayload<typeof SET_BUFFER_TIME_ESTIMATE_RETURNED, string | null>(),
);

const SET_BUFFER_DELIVERY_ADDRESS = '$SET_BUFFER_DELIVERY_ADDRESS';
export const setBufferDeliveryAddress = createCustomAction(
  SET_BUFFER_DELIVERY_ADDRESS,
  singleValuePayload<
    typeof SET_BUFFER_DELIVERY_ADDRESS,
    TokenisedAddress | null
  >(),
);

const SET_BUFFER_DELIVERY_COORDINATES = '$SET_BUFFER_DELIVERY_COORDINATES';
export const setBufferDeliveryCoordinates = createCustomAction(
  SET_BUFFER_DELIVERY_COORDINATES,
  singleValuePayload<
    typeof SET_BUFFER_DELIVERY_COORDINATES,
    GPSCoordinates | null
  >(),
);

const SET_BUFFER_DELIVERY_PROVIDER = '$SET_BUFFER_DELIVERY_PROVIDER';
export const setBufferDeliveryProvider = createCustomAction(
  SET_BUFFER_DELIVERY_PROVIDER,
  singleValuePayload<
    typeof SET_BUFFER_DELIVERY_PROVIDER,
    DeliveryProvider | null
  >(),
);

const SET_BUFFER_MINIMUM_ORDER_INFORMATION =
  '$SET_BUFFER_MINIMUM_ORDER_INFORMATION';
export const setBufferMinimumOrderInformation = createCustomAction(
  SET_BUFFER_MINIMUM_ORDER_INFORMATION,
  singleValuePayload<
    typeof SET_BUFFER_MINIMUM_ORDER_INFORMATION,
    MinimumOrderInformation | null
  >(),
);

const SET_BUFFER_CREDIT_CARD_PAYMENT_INFORMATION =
  '$SET_BUFFER_CREDIT_CARD_PAYMENT_INFORMATION';
export const setBufferCreditCardPaymentInformation = createCustomAction(
  SET_BUFFER_CREDIT_CARD_PAYMENT_INFORMATION,
  singleValuePayload<
    typeof SET_BUFFER_CREDIT_CARD_PAYMENT_INFORMATION,
    CreditCardPaymentInformation | null
  >(),
);

const SET_BUFFER_DELIVERY_SURCHARGES = '$SET_BUFFER_DELIVERY_SURCHARGES';
export const setBufferDeliverySurcharges = createCustomAction(
  SET_BUFFER_DELIVERY_SURCHARGES,
  singleValuePayload<
    typeof SET_BUFFER_DELIVERY_SURCHARGES,
    Surcharge[] | null
  >(),
);

const SET_BUFFER_ORDERING_PROVIDER_SURCHARGE_INSTRUCTIONS =
  '$SET_BUFFER_ORDERING_PROVIDER_SURCHARGE_INSTRUCTIONS';
export const setBufferOrderingProviderSurchargeInstructions =
  createCustomAction(
    SET_BUFFER_ORDERING_PROVIDER_SURCHARGE_INSTRUCTIONS,
    singleValuePayload<
      typeof SET_BUFFER_ORDERING_PROVIDER_SURCHARGE_INSTRUCTIONS,
      SurchargeInstruction[] | null
    >(),
  );

const SET_BUFFER_LOCATION_DELIVERY_ESTIMATES =
  '$SET_BUFFER_LOCATION_DELIVERY_ESTIMATES';
export const setBufferLocationDeliveryEstimates = createCustomAction(
  SET_BUFFER_LOCATION_DELIVERY_ESTIMATES,
  singleValuePayload<
    typeof SET_BUFFER_LOCATION_DELIVERY_ESTIMATES,
    LocationDeliveryEstimate[]
  >(),
);

const SET_BUFFER_PAYMENT_GATEWAY_CONFIG = '$SET_BUFFER_PAYMENT_GATEWAY_CONFIG';
export const setBufferPaymentGatewayConfig = createCustomAction(
  SET_BUFFER_PAYMENT_GATEWAY_CONFIG,
  singleValuePayload<
    typeof SET_BUFFER_PAYMENT_GATEWAY_CONFIG,
    PaymentGatewayConfig | null
  >(),
);
