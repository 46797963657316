import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiAlertFill } from 'react-icons/ri';
import { FaIdCard } from 'react-icons/fa';

import { OrderingSelectors, OrderingConstants, OrderingOperations } from 'polygon-ordering';

import getThemeLookup from '../selectors/getThemeLookup';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import getCurrentPaymentMethod from '../selectors/getCurrentPaymentMethod';
import getExtremelyShortScreen from '../selectors/getExtremelyShortScreen';
import getOrderInProgress from '../selectors/getOrderInProgress';
import getProfile from '../selectors/getProfile';
import setCurrentModalAction from '../actions/setCurrentModal';

import combineStyles from '../utils/combineStyles';
import getRegistrationLinkWithReturnPath from '../utils/getRegistrationLinkWithReturnPath';

import { INITIAL_SCREEN_ROUTE } from './InitialScreen';
import { COMPLETED_ORDER_SCREEN_ROUTE } from './CompletedOrderScreen';

import Text from '../components/Text';
import TouchableOpacity from '../components/TouchableOpacity';

import ScreenHero from '../components/ScreenHero';
import ScreenFloating from '../components/ScreenFloating';

import { SIGN_IN_MODAL_ID } from '../modals/SignInModal';

import OrderSummaryColumn from '../components/OrderSummaryColumn';
import DesktopReviewOrderSideSection from '../components/DesktopReviewOrderSideSection';
import OrderNotes from '../components/OrderNotes';
import DriverNotes from '../components/DriverNotes';
import PurchaserDetails from '../components/PurchaserDetails';
import PaymentMethods from '../components/PaymentMethods';
import CheckoutButton from '../components/CheckoutButton';
import RedirectAndPreserveSearch from '../components/RedirectAndPreserveSearch';
import OrderTotalsPaymentSummary from '../components/OrderTotalsPaymentSummary';
import OrderOffer from '../components/OrderOffer';
import OfferInfoButton from '../components/OfferInfoButton';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import CheckoutStripeProvider from '../components/CheckoutStripeProvider';
import SendVerificationButton from '../components/SendVerificationButton';

import MaxOrderValueWarning from '../components/MaxOrderValueWarning';

const {
  getOrderSubmitted,
  getMember,
  getPurchaserInvalidReasons,
  getSaleType,
  getGuestOrderingAvailable,
  getShowMaxValue,
} = OrderingSelectors;

const { fetchStockBalances } = OrderingOperations;

const { PAYMENT_METHODS, SALE_TYPES } = OrderingConstants;

export const REVIEW_ORDER_SCREEN_ROUTE = '/review-order';

const ReviewOrderScreen = () => {
  const touchableRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const orderInProgress = useAppSelector(getOrderInProgress);
  const currentPaymentMethod = useAppSelector(getCurrentPaymentMethod);
  const orderSubmitted = useAppSelector(getOrderSubmitted);
  const enableOrderNotes = useAppSelector(state => state.config.enableOrderNotes);
  const enableDeliveryNotes = useAppSelector(state => state.config.enableDeliveryNotes);
  const member = useAppSelector(getMember);
  const purchaserInvalidReasons = useAppSelector(getPurchaserInvalidReasons);
  const saleType = useAppSelector(getSaleType);
  const extremelyShortScreen = useAppSelector(getExtremelyShortScreen);
  const guestOrderingAvailable = useAppSelector(getGuestOrderingAvailable);
  const enableFraudPreventionMessage = useAppSelector(
    state => state.config.enableFraudPreventionMessage,
  );
  const disableOffers = useAppSelector(state => state.config.disableOffers);
  const enableStockPolling = useAppSelector(state => state.config.enableStockPolling);
  const stockPollingInterval = useAppSelector(state => state.config.stockPollingInterval);
  const interval = useRef<NodeJS.Timeout | null>(null);
  const [checkPLUQuantityOnLoad, setCheckPLUQuantityOnLoad] = useState(true);
  const profile = useAppSelector(getProfile);

  const showMaxValueMessage = useAppSelector(getShowMaxValue);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (checkPLUQuantityOnLoad) {
      dispatch(fetchStockBalances({}));
      setCheckPLUQuantityOnLoad(false);
    }

    if (enableStockPolling && stockPollingInterval) {
      interval.current = setInterval(
        () => dispatch(fetchStockBalances({})),
        stockPollingInterval * 1000,
      );
      return () => {
        clearInterval(interval.current as NodeJS.Timeout);
      };
    }
  }, []);

  const useMobileScreen = deviceTypeMobile || extremelyShortScreen;

  const ScreenComponent = useMobileScreen || extremelyShortScreen ? ScreenHero : ScreenFloating;

  if (orderSubmitted) {
    return <RedirectAndPreserveSearch to={COMPLETED_ORDER_SCREEN_ROUTE} />;
  }

  if (!orderInProgress) {
    return <RedirectAndPreserveSearch to={INITIAL_SCREEN_ROUTE} />;
  }

  const mustSignIn = !guestOrderingAvailable && !member;

  const mustBeVerifiedMember = !profile?.verified && !guestOrderingAvailable;

  const isDelivery = saleType.valueOf() === SALE_TYPES.DELIVERY;

  const fraudPreventionMessage = enableFraudPreventionMessage
    ? isDelivery
      ? 'fraudPreventionMessage.delivery'
      : 'fraudPreventionMessage.collection'
    : null;

  return (
    <ScreenComponent
      parentContainerStyle={useMobileScreen ? undefined : styles.desktopParentContainer}
    >
      <div
        style={combineStyles(
          !useMobileScreen && styles.mainContainerDesktop,
          useMobileScreen && styles.mainContainerMobile,

          p('screen', ['backgroundColor']),
          p('reviewOrderScreen', ['backgroundColor']),

          !useMobileScreen && p('reviewOrderScreenDesktop', ['backgroundColor', 'boxShadow']),
          useMobileScreen && p('reviewOrderScreenMobile', ['backgroundColor']),
        )}
      >
        <OrderSummaryColumn
          hideKeyOrderProperties={!useMobileScreen}
          hideTotals={!useMobileScreen}
          hideSurcharges={!useMobileScreen}
          pointsVisible
          useMobileScreen={useMobileScreen}
        />

        {enableOrderNotes && (
          <>
            <Text themeKey="sectionTitle" style={styles.notesTitle} block>
              {t('title.notes')}
            </Text>
            <OrderNotes />
          </>
        )}

        {isDelivery && enableDeliveryNotes && (
          <>
            <Text themeKey="sectionTitle" block>
              {t('title.deliveryNotes')}
            </Text>
            <DriverNotes style={styles.driverNotes} />
          </>
        )}

        {mustSignIn ? (
          <div style={styles.mustSignInSection}>
            <TouchableOpacity
              onClick={() => dispatch(setCurrentModalAction({ modalId: SIGN_IN_MODAL_ID }))}
              ariaLabel="sign in"
              ref={touchableRef}
            >
              <Text>
                You must
                <Text themeKey="noGuestOrderingLink">{' Sign In '}</Text>
                or
                <Text
                  themeKey="noGuestOrderingLink"
                  onClick={() => {
                    window.location.href = getRegistrationLinkWithReturnPath();
                  }}
                >
                  {' Register '}
                </Text>
                to place an order
              </Text>
            </TouchableOpacity>
          </div>
        ) : member && mustBeVerifiedMember ? (
          <>
            <div style={styles.mustSignInSection}>
              <Text>{'You must be a verified member in order to place an order'}</Text>
            </div>
            <SendVerificationButton />
          </>
        ) : (
          <>
            <Text themeKey="sectionTitle">
              {t('title.purchaserDetails')}
              {Boolean(purchaserInvalidReasons.length) && (
                <RiAlertFill
                  style={combineStyles(
                    p('sectionTitle', ['color', 'fontSize']),
                    p('purchaserInvalidIcon', ['color', 'fontSize']),
                  )}
                />
              )}
            </Text>
            <PurchaserDetails />
          </>
        )}

        {showMaxValueMessage && <MaxOrderValueWarning />}

        {Boolean(fraudPreventionMessage) && (
          <div style={styles.fraudPreventionMessageContainer}>
            <FaIdCard style={p('fraudPreventionMessageIcon', ['color', 'fontSize'])} />

            <Text themeKey="fraudPreventionMessage" style={styles.fraudPreventionMessage}>
              {t(`${fraudPreventionMessage}`)}
            </Text>
          </div>
        )}

        {useMobileScreen && !disableOffers && (
          <div style={styles.offerSection}>
            <div style={styles.offerTitleContainer}>
              <Text themeKey="sectionTitle">{t('title.offer')}</Text>

              <OfferInfoButton />
            </div>

            <OrderOffer />
          </div>
        )}

        {currentPaymentMethod !== PAYMENT_METHODS.FREE.toString() &&
          !mustSignIn &&
          !showMaxValueMessage &&
          !mustBeVerifiedMember && (
            <>
              <Text themeKey="sectionTitle">{t('title.paymentMethod')}</Text>
              <PaymentMethods />
            </>
          )}

        {useMobileScreen && <OrderTotalsPaymentSummary useMobileScreen={useMobileScreen} />}

        {useMobileScreen && (
          <CheckoutStripeProvider>
            <CheckoutButton containerStyle={styles.mobileCheckoutButton} />
          </CheckoutStripeProvider>
        )}
      </div>

      {!useMobileScreen && <DesktopReviewOrderSideSection useMobileScreen={useMobileScreen} />}
    </ScreenComponent>
  );
};

const styles: Styles = {
  mainContainerMobile: {
    flex: 1,
    padding: 25,
    paddingBottom: 100,

    display: 'flex',
    flexDirection: 'column',
  },

  desktopParentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 60,
  },

  mainContainerDesktop: {
    borderRadius: 2,
    padding: 20,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',

    minWidth: 410,
    minHeight: 650,

    // required to break out of the parent flex row
    // when height exceeds 100% of the container
    marginBottom: 'auto',
  },

  mobileCheckoutButton: {
    marginTop: 20,
  },

  mustSignInSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 30,
  },

  notesTitle: {
    marginTop: 10,
  },

  mobileBalances: {
    marginBottom: 15,
  },

  driverNotes: {
    marginTop: 15,
  },

  fraudPreventionMessageContainer: {
    alignSelf: 'center',
    marginBottom: 50,
    maxWidth: 320,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  fraudPreventionMessage: {
    textAlign: 'center',
    marginTop: 10,
  },

  offerTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  offerSection: {
    marginTop: 0,
    marginBottom: 30,
  },
};

export default ReviewOrderScreen;
