import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiCheckboxCircleFill } from 'react-icons/ri';

import { OrderingSelectors, OrderingConstants } from 'polygon-ordering';

import getThemeLookup from '../selectors/getThemeLookup';
import getSaleStatusSteps from '../selectors/getSaleStatusSteps';

import combineStyles from '../utils/combineStyles';
import { useAppSelector } from '../app/hooks';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';

import { STATUS_STEP_ICONS } from '../constants/statusSteps';

import Text from './Text';

const { getSaleType } = OrderingSelectors;

const { READY_TO_COLLECT } = OrderingConstants;

interface Step {
  id: number | string;
  value: string;
  complete: boolean;
  inProgress: boolean;
}

const StatusStep = ({ step, saleType }: { step: Step; saleType: SALE_TYPE }) => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);

  const IconComponent = step.complete ? RiCheckboxCircleFill : STATUS_STEP_ICONS[step.value];

  let labelKey = `statusSteps.${step.value}.${step.complete ? 'complete' : 'incomplete'}`;

  if (step.value === READY_TO_COLLECT && step.complete) {
    labelKey = `${labelKey}.${saleType}`;
  }

  return (
    <div
      style={combineStyles(
        styles.stepContainer,
        !step.complete && !step.inProgress && styles.futureStep,
      )}
    >
      <IconComponent
        className={step.inProgress ? 'pulseOpacity' : undefined}
        style={combineStyles(
          styles.icon,
          p('orderStatusStepIcon', ['color', 'fontSize']),
          step.complete && p('orderStatusStepIconCompleted', ['color', 'fontSize']),
        )}
      />

      <Text
        style={combineStyles(
          step.inProgress ? styles.inProgressLabel : undefined,
          p('orderStatusStepLabel', ['color', 'fontSize']),
        )}
      >
        {t(labelKey)}
      </Text>
    </div>
  );
};

const OrderStatus: React.FC = () => {
  const saleType = useAppSelector(getSaleType);
  const saleStatusSteps = useAppSelector(getSaleStatusSteps);
  console.log({ saleStatusSteps });

  return (
    <div style={styles.mainContainer}>
      {saleStatusSteps.map(step => (
        <StatusStep key={step.id} step={step} saleType={saleType} />
      ))}
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    paddingLeft: 30,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  stepContainer: {
    margin: '7px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    width: 220,
  },
  icon: {
    marginRight: 15,
  },
  inProgressLabel: {
    fontWeight: 500,
  },
  futureStep: {
    opacity: 0.5,
  },
};

export default OrderStatus;
