import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: Partial<Coordinates> = { latitude: -37.8136, longitude: 144.9631 }; // melbourne

const slice = createSlice({
  name: 'fallbackLocation',
  initialState: initialState,
  reducers: {
    setFallbackLocation(state, action: PayloadAction<Partial<Coordinates>>) {
      return action.payload || initialState;
    },
  },
});

export const { setFallbackLocation } = slice.actions;

export default slice.reducer;
