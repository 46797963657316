import lodash from 'lodash';
import React from 'react';

import { apiErrorToString } from './errors';

export function enqueueSnackbar(message: string, options: {}, maxLength: number = 150) {
  if (window._enqueueSnackbar) {
    window._enqueueSnackbar(lodash.truncate(message || '', { length: maxLength }), {
      style: { whiteSpace: 'pre-line' },
      ...options,
      action: (key: any) => (
        <button
          style={{
            backgroundColor: 'transparent',
            color: 'white',
            fontFamily: 'Roboto',
            border: 'none',
            outline: 'none',
            fontWeight: 500,
          }}
          onClick={() => window._closeSnackbar(key)}
        >
          DISMISS
        </button>
      ),
    });
  }
}

export function enqueueErrorSnackbar(e: Error | string, params?: {}) {
  const message = typeof e === 'string' ? e : apiErrorToString(e);

  // debug('error snackbar', { e, message });

  enqueueSnackbar(
    message,
    {
      variant: 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      ...params,
    },
    300,
  );
}

export function enqueueSuccessSnackbar(message: string, params?: {}) {
  // debug('success snackbar', { message, params });

  enqueueSnackbar(
    message,
    {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      ...params,
    },
    300,
  );
}

export function enqueueWarningSnackbar(message: string, params?: {}) {
  // debug('warning snackbar', { message, params });

  enqueueSnackbar(
    message,
    {
      variant: 'warning',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      ...params,
    },
    300,
  );
}
