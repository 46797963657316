import { createSelector } from 'reselect';
import getOrderTotals from './getOrderTotals';
import getMaxOrderValue from './getMaxOrderValueRule';
import { centsToDollars } from '../utils/misc';

export default createSelector(
  [getOrderTotals, getMaxOrderValue],
  (orderTotals, rule) => {
    const discountedPrice = centsToDollars(orderTotals?.discountedMoneyPrice);
    return rule ? (discountedPrice || 0) > rule.MaximumOrder : false;
  },
);
