import { combineReducers, Reducer, AnyAction } from 'redux';

import buffer from './buffer';
import config from './config';
import currentOrder from './currentOrder';
import favouriteOrders from './favouriteOrders';
import historicalOrders from './historicalOrders';
import locations from './locations';
import member from './member';
import paymentGatewayTokens from './paymentGatewayTokens';
import shadowOrder from './shadowOrder';
import transactionHistory from './transactionHistory';
import giftcardSurcharge from './giftcardSurcharge';

// @ts-ignore
const rootReducer: Reducer<OrderingState, AnyAction> = combineReducers({
  buffer,
  config,
  currentOrder,
  favouriteOrders,
  historicalOrders,
  locations,
  member,
  paymentGatewayTokens,
  shadowOrder,
  transactionHistory,
  giftcardSurcharge,
});

export default rootReducer;
