import { call, put, takeEvery, all } from 'redux-saga/effects';

import * as deleteFavouriteOrder from '../../actionCreators/flows/deleteFavouriteOrder';
import * as fetchFavouriteOrders from '../../actionCreators/flows/fetchFavouriteOrders';

import Api, { FetchParams } from '../../utils/Api';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';

export const requested = createFlowApprover(deleteFavouriteOrder);

export function* approved(
  action: ReturnType<typeof deleteFavouriteOrder.actions.approved>,
) {
  const {
    payload: { id },
    meta: { flowId },
  } = action;

  try {
    const params: FetchParams = {
      path: `/api/v1/profile/favourites/${id}`,
      method: 'DELETE',
    };

    yield call(Api.fetch, params, 'member');

    yield put(fetchFavouriteOrders.actions.requested({}, flowId));

    yield put(deleteFavouriteOrder.actions.succeeded({}, flowId));
  } catch (e) {
    yield put(
      deleteFavouriteOrder.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(deleteFavouriteOrder.events.REQUESTED, requested),
    takeEvery(deleteFavouriteOrder.events.APPROVED, approved),
  ]);
}
