import { call, put } from 'redux-saga/effects';

import { RedcatApiHandler } from 'polygon-utils';
import { OrderingOperations, OrderingApi } from 'polygon-ordering';

import debug from '../utils/debug';
import { determineEndpoint } from '../utils/endpoint';

import { store } from '../store';
import { clearCookies } from '../thunks/clearCookies';

import { setApiHandlerReady } from '../slices/apiHandlerReady';

export default function* configureApiHandler() {
  // won't change during runtime, so we can always use the same value
  const endpoint = yield call(determineEndpoint);

  debug('api endpoint', { endpoint });

  RedcatApiHandler.endpointDeterminer = () => endpoint;

  RedcatApiHandler.additionalParamsDeterminer = () => {
    const memberAuthTokenOverridePresent = localStorage.getItem('memberAuthToken') != null;

    // don't send auth cookie if an override exists
    return { credentials: memberAuthTokenOverridePresent ? 'omit' : 'same-origin' };
  };

  RedcatApiHandler.authTokenDeterminer = () => {
    // if override not present, cookies will be used instead
    return localStorage.getItem('memberAuthToken');
  };

  RedcatApiHandler.badAuthTokenHook = error => {
    debug(false, 'badAuthTokenHook');
    console.log('403 Unauthorised.', { error });

    if (!(error?.details?.request?.url || '').endsWith('/api/v1/profile')) {
      // the sync calls the profile endpoint, so we need this to avoid an infinite loop
      console.log('CLEARING COOKIES');
      store.dispatch(clearCookies());
    }
  };

  RedcatApiHandler.requestErrorHook = e => {
    // debug(false, 'requestErrorHook', { e });

    console.error(e);

    // enqueueErrorSnackbar(e);
  };

  OrderingApi.setHandler(RedcatApiHandler);

  yield put(OrderingOperations.setApiHandlerReady(true)); // TODO: remove this from OM
  yield put(setApiHandlerReady(true));
}
