import React from 'react';

import combineStyles from '../utils/combineStyles';

import getThemeLookup from '../selectors/getThemeLookup';

import TouchableOpacity from './TouchableOpacity';
import Text from './Text';
import { useAppSelector } from '../app/hooks';
import { IconType } from 'react-icons/lib';

interface IProps {
  label?: string;
  onClick: () => void;
  disabled?: boolean;
  deviceTypeMobile?: boolean;
  ariaLabel?: string;
  IconComponent: IconType;
}

const ModalFloatingButton: React.FC<IProps> = ({
  label = ' ',
  onClick,
  IconComponent,
  disabled,
  deviceTypeMobile,
  ariaLabel,
}) => {
  const p = useAppSelector(getThemeLookup);

  return (
    <TouchableOpacity
      style={combineStyles(styles.mainContainer, deviceTypeMobile && { transform: `scale(0.8)` })}
      disabledStyle={styles.disabled}
      onClick={onClick}
      disabled={disabled}
      ariaLabel={ariaLabel || label}
    >
      {Boolean(IconComponent) && (
        <IconComponent style={p('modalButtonIcon', ['color', 'fontSize'])} />
      )}

      <Text themeKey="modalButtonLabel" style={styles.label} value={label} />
    </TouchableOpacity>
  );
};

const styles: Styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  label: {
    marginTop: 7,
  },
  disabled: {
    opacity: 0, // I'm not sure what looks better / is less confusing
  },
};

export default ModalFloatingButton;
