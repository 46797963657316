import { call } from 'redux-saga/effects';
import lodash from 'lodash';
import json5 from 'json5'; // more forgiving json parser

import { THEME_CLIENT_ID } from '../constants';

import { withParams } from '../utils/url';

import redcatApiFetch from '../api';

export default function* fetchThemeOverrides() {
  try {
    const response = yield call(redcatApiFetch, {
      path: withParams('/api/v1/public/themes', {
        ClientId: THEME_CLIENT_ID,
        Version: process.env.REACT_APP_VERSION,
      }),
    });

    let themeOverrides = lodash.get(response, 'data.Body') || {};

    if (typeof themeOverrides === 'string') {
      themeOverrides = json5.parse(themeOverrides);
    }

    return themeOverrides;
  } catch (e) {
    console.error(e);
  }
}
