import React, { useEffect } from 'react';
import { RiShoppingBasketFill } from 'react-icons/ri';
import Badge from '@material-ui/core/Badge';
import { createStyles, withStyles } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { OrderingSelectors } from 'polygon-ordering';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import getThemeLookup from '../selectors/getThemeLookup';
import combineStyles from '../utils/combineStyles';
import determineCalloutOffsets from '../utils/determineCalloutOffsets';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { ORDER_SUMMARY_MODAL_ID } from '../modals/OrderSummaryModal';
import TouchableOpacity from './TouchableOpacity';

const {
  getStagedPurchaseCount,
  getCartContainsSoldOutItems,
  getStockBalances,
  getCartContainsInvalidQuantities,
} = OrderingSelectors;

const HeaderCartButton: React.FC = () => {
  const selfRef = React.useRef<HTMLDivElement>(null);
  const p = useAppSelector(getThemeLookup);
  const currentModalId = useAppSelector(state => state.currentModalId);
  const stagedPurchaseCount = useAppSelector(getStagedPurchaseCount);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const cartContainsSoldOutItems = useAppSelector(getCartContainsSoldOutItems);
  const stockBalances = useAppSelector(getStockBalances);
  const cartHasInvalidItems = useAppSelector(getCartContainsInvalidQuantities);
  const dispatch = useAppDispatch();

  const badgeStyles = () =>
    createStyles({
      //@ts-ignore
      badge: cartContainsSoldOutItems
        ? combineStyles(
            styles.styledBadgeAlert,
            p('headerQuantity', ['color']),
            p('cartItemErrorAlert', ['backgroundColor']),
          )
        : //@ts-ignore
        cartHasInvalidItems.cartContainsInvalidQuantities
        ? combineStyles(
            styles.styledBadgeAlert,
            p('headerQuantity', ['color']),
            p('cartItemWarningAlert', ['backgroundColor']),
          )
        : p('headerQuantity', ['color', 'backgroundColor']),
    });
  const StyledBadge = withStyles(badgeStyles)(Badge);
  const modalOpen = currentModalId === ORDER_SUMMARY_MODAL_ID;

  let newPurchaseCount = stagedPurchaseCount;

  useEffect(() => {
    newPurchaseCount = stagedPurchaseCount;
  }, [stockBalances, stagedPurchaseCount]);

  return (
    <TouchableOpacity
      style={combineStyles(
        styles.mainContainer,
        modalOpen && styles.modalOpen,
        p('headerCartIcon', ['padding']),
      )}
      onClick={() => {
        dispatch(
          setCurrentModal(
            modalOpen
              ? null
              : {
                  modalId: ORDER_SUMMARY_MODAL_ID,
                  calloutOffsets: determineCalloutOffsets(
                    (
                      selfRef as React.MutableRefObject<HTMLDivElement>
                    ).current.getBoundingClientRect(),
                  ),
                },
          ),
        );
      }}
      ref={selfRef}
      ariaLabel="cart"
    >
      <StyledBadge
        badgeContent={
          //@ts-ignore
          cartContainsSoldOutItems || cartHasInvalidItems.cartContainsInvalidQuantities
            ? '!'
            : newPurchaseCount
        }
        overlap="circular"
      >
        {deviceTypeMobile ? (
          <RiShoppingBasketFill
            style={combineStyles(
              p('headerCartIcon', ['color', 'fontSize']),
              p('dynamicCartIcon', ['fontSize']),
            )}
          />
        ) : (
          <RiShoppingBasketFill style={p('headerCartIcon', ['color', 'fontSize'])} />
        )}
      </StyledBadge>
    </TouchableOpacity>
  );
};

const styles: Styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  modalOpen: {
    opacity: 0.5,
  },
  styledBadgeAlert: {
    fontWeight: 1000,
  },
};

export default HeaderCartButton;
