import isNumeric from './isNumeric';

export default function isValidCoordinate(value: Partial<Coordinates>) {
  try {
    if (!value) {
      return false;
    }

    if (!isNumeric(value.latitude)) {
      return false;
    }

    if (!isNumeric(value.longitude)) {
      return false;
    }
  } catch (e) {
    // nothing
    return false;
  }

  return true;
}
