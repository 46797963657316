import React, { useEffect, useRef } from 'react';

import { logModal } from '../utils/analytics';

import { useAppSelector } from '../app/hooks';

// import CategoriesModal, { CATEGORIES_MODAL_ID } from '../modals/CategoriesModal';
import DeliveryDetailsModal, { DELIVERY_DETAILS_MODAL_ID } from './DeliveryDetailsModal';
import LocationModal, { LOCATION_MODAL_ID } from './LocationModal';
import MemberModal, { MEMBER_MODAL_ID } from './MemberModal';
import MessageModal, { MESSAGE_MODAL_ID } from './MessageModal';
import OfferModal, { OFFER_MODAL_ID } from './OfferModal';
import OrderSummaryModal, { ORDER_SUMMARY_MODAL_ID } from './OrderSummaryModal';
import OrderTypeModal, { ORDER_TYPE_MODAL_ID } from './OrderTypeModal';
import PurchaseEditorModal, { PURCHASE_EDITOR_MODAL_ID } from './PurchaseEditorModal';
import StagedPurchaseEditorModal, {
  STAGED_PURCHASE_EDITOR_MODAL_ID,
} from '../modals/StagedPurchaseEditorModal';

import SignInModal, { SIGN_IN_MODAL_ID } from './SignInModal';
import ForgetPassword, { RESET_PASSWORD_MODAL_ID } from './ResetPasswordModal';
import RegisterModal, { REGISTER_MODAL_ID } from './RegisterModal';
import FiltersModal, { FILTERS_MODAL_ID } from './FiltersModal';
import NonMemberModal, { NON_MEMBER_MODAL_ID } from './NonMemberModal';
import LoyaltyOfferModal, { LOYALTY_OFFER_MODAL_ID } from './LoyaltyOfferModal';
import FavouriteNameModal, { FAVOURITE_NAME_MODAL } from './FavouriteNameModal';
import GiftcardSuccessModal, { GIFTCARD_PURCHASE_SUCCESSFUL_MODAL } from './GiftcardSuccessModal';
interface ModalsType {
  [key: string]: React.ElementType;
}

const MODALS: ModalsType = {
  [DELIVERY_DETAILS_MODAL_ID]: DeliveryDetailsModal,
  [LOCATION_MODAL_ID]: LocationModal,
  [MEMBER_MODAL_ID]: MemberModal,
  [MESSAGE_MODAL_ID]: MessageModal,
  [OFFER_MODAL_ID]: OfferModal,
  [ORDER_SUMMARY_MODAL_ID]: OrderSummaryModal,
  [ORDER_TYPE_MODAL_ID]: OrderTypeModal,
  [PURCHASE_EDITOR_MODAL_ID]: PurchaseEditorModal,
  [STAGED_PURCHASE_EDITOR_MODAL_ID]: StagedPurchaseEditorModal,
  [SIGN_IN_MODAL_ID]: SignInModal,
  [RESET_PASSWORD_MODAL_ID]: ForgetPassword,
  [REGISTER_MODAL_ID]: RegisterModal,
  [FILTERS_MODAL_ID]: FiltersModal,
  [NON_MEMBER_MODAL_ID]: NonMemberModal,
  [LOYALTY_OFFER_MODAL_ID]: LoyaltyOfferModal,
  [FAVOURITE_NAME_MODAL]: FavouriteNameModal,
  [GIFTCARD_PURCHASE_SUCCESSFUL_MODAL]: GiftcardSuccessModal,
};

const Modals = () => {
  const currentModalId = useAppSelector(state => state.currentModalId);
  // remove this after all modals are migrated, do not pass it down
  const modalParams = useAppSelector(state => state.modalParams);

  useEffect(() => {
    logModal(currentModalId);
  }, [currentModalId]);

  if (!currentModalId) {
    return null;
  }

  const Component = MODALS[currentModalId];

  if (!Component) {
    return null;
  }

  return <Component params={modalParams || {}} />;
};

export default Modals;
