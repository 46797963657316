import { combineReducers, Reducer, AnyAction } from 'redux';
import { getType } from 'typesafe-actions';

import { resetOrder } from '../../../actionCreators/currentOrder';

import name from './name';
import familyName from './familyName';
import email from './email';
import mobile from './mobile';

const rootReducer: Reducer<_Purchaser, AnyAction> = combineReducers({
  name,
  familyName,
  email,
  mobile,
});

const resetWrapper = (state: any, action: any) => {
  return rootReducer(
    action.type !== getType(resetOrder) ? state : undefined,
    action,
  );
};

export default resetWrapper;
