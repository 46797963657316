import React from 'react';
import { useTranslation } from 'react-i18next';

import { RiSearchLine, RiDeleteBin7Fill } from 'react-icons/ri';

import { OrderingSelectors, OrderingOperations } from 'polygon-ordering';

import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES, SELECT_PROPERTIES } from '../utils/theme';

import getThemeLookup from '../selectors/getThemeLookup';

import Modal from '../components/Modal';
import Text from '../components/Text';
import StandardButton from '../components/StandardButton';
import OfferInfoButton from '../components/OfferInfoButton';
import { useAppSelector, useAppDispatch } from '../app/hooks';

const { getSelectedOffer, getOffers, getPromoCode } = OrderingSelectors;
const { selectOffer, setPromoCode, applyPromoCode, updateSelectedPaymentMethods } =
  OrderingOperations;

export const OFFER_MODAL_ID = 'OFFER_MODAL_ID';

const OfferModal = () => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const offers = useAppSelector(getOffers);
  const selectedOffer = useAppSelector(getSelectedOffer);
  const promoCode = useAppSelector(getPromoCode);
  const dispatch = useAppDispatch();
  const noOfferPlaceholder = t('placeholder.memberOffers');

  return (
    <Modal
      clickBackgroundToClose
      title={
        selectedOffer
          ? t('title.modal.offer.offerPresent')
          : offers.length
          ? t('title.modal.offer.offerAbsentOffersPresent')
          : t('title.modal.offer.offerAbsentOffersAbsent')
      }
      desktopMaxWidth={400}
      desktopMinWidth={400}
      closeButtonLabel={t('button.modalClose.dismiss')}
      containerStyle={styles.containerStyle}
      desktopMinHeight={300}
    >
      {selectedOffer ? (
        <>
          <div style={styles.nameContainer}>
            <Text themeKey="offerName" style={styles.name}>
              {selectedOffer.name.toUpperCase()}
            </Text>

            <OfferInfoButton />
          </div>

          <StandardButton
            themeKey="removeOfferButton"
            label={t('button.removeOffer')}
            onClick={() => {
              dispatch(selectOffer({ offerId: null }));
              dispatch(updateSelectedPaymentMethods({}));
            }}
            RightIconComponent={RiDeleteBin7Fill}
          />
          <div style={styles.messageContainer}>
            <Text themeKey="orderMessage" style={styles.messageText}>
              {selectedOffer.description}
            </Text>
          </div>
        </>
      ) : (
        <>
          <div style={styles.promoCodeSection}>
            <input
              value={promoCode || ''}
              onChange={event => dispatch(setPromoCode(event?.target?.value))}
              maxLength={15}
              placeholder={t('placeholder.promoCode')}
              style={combineStyles(
                styles.promoCodeInput,
                p('defaultText', TEXT_PROPERTIES),
                p('input', SELECT_PROPERTIES),
              )}
            />

            <StandardButton
              themeKey="lookupPromoCodeButton"
              RightIconComponent={RiSearchLine}
              onClick={() => {
                dispatch(applyPromoCode({}));
              }}
              disabled={!promoCode}
              containerStyle={styles.applyButton}
            />
          </div>

          {Boolean(offers.length) && (
            <>
              <div style={styles.orContainer}>
                <Text>{t('offerTypeDivider')}</Text>
              </div>

              <select
                onChange={event => {
                  let offerId: string | null = event?.target?.value;

                  if (offerId === noOfferPlaceholder) {
                    offerId = null;
                  }

                  dispatch(selectOffer({ offerId }));
                  dispatch(updateSelectedPaymentMethods({}));
                }}
                style={combineStyles(
                  styles.offerSelector,
                  p('defaultText', SELECT_PROPERTIES),
                  p('input', SELECT_PROPERTIES),
                  p('memberOffers', SELECT_PROPERTIES),
                )}
              >
                <option>{noOfferPlaceholder}</option>

                {offers.map(offer => (
                  <option key={offer.id} value={offer.id}>
                    {offer.name}
                  </option>
                ))}
              </select>
            </>
          )}
        </>
      )}
    </Modal>
  );
};

const styles: Styles = {
  containerStyle: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  nameContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    marginTop: 60,
    marginBottom: 30,
  },

  name: {
    textAlign: 'center',
    fontStyle: 'italic',
  },

  promoCodeSection: {
    marginTop: 30,

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },

  promoCodeInput: {
    textAlign: 'center',
    minHeight: '100%',
    borderRadius: 0,
    outline: 'none',
    border: '1px solid silver',
    borderRightWidth: 0,
  },

  applyButton: {
    borderRadius: 0,
  },

  orContainer: {
    marginTop: 20,
    marginBottom: 20,
  },

  offerSelector: {
    textAlignLast: 'center',
    minWidth: 220,
  },
  messageContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',
  },
  messageText: {
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: '0.7rem',
    fontWeight: 200,
    textTransform: 'capitalize',
  },
};

export default OfferModal;
