import { createCustomAction } from 'typesafe-actions';

import { AuthenticationMethod } from '../../utils/Api';

import {
  standardEmptyACC,
  standardFlowACC,
  getStandardFailedWithReasonACC,
} from '../../utils/sagas';

import { FAILURE_REASON } from '../../constants/failureReasons';
import { PAYMENT_METHOD } from '../../constants/paymentMethod';

const REQUESTED = '$TOP_UP_MEMBER_MONEY/REQUESTED';
const BLOCKED = '$TOP_UP_MEMBER_MONEY/BLOCKED';
const APPROVED = '$TOP_UP_MEMBER_MONEY/APPROVED';
const SUCCEEDED = '$TOP_UP_MEMBER_MONEY/SUCCEEDED';
const FAILED = '$TOP_UP_MEMBER_MONEY/FAILED';

const request = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      authenticationMethod?: AuthenticationMethod;
      paymentGatewayToken?: string;
      paymentMethod: PAYMENT_METHOD;
      amount: number;
      memberId: string;
      paymentGatewayPublicKey?: string;
    }>(type);
};

const succeed = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      paymentMethod?: PAYMENT_METHOD;
      paymentGatewayClientSecret?: string;
    }>(type);
};

export const events = {
  REQUESTED,
  BLOCKED,
  APPROVED,
  SUCCEEDED,
  FAILED,
};

export type FailureReason =
  | undefined
  | FAILURE_REASON.PAYMENT_HOOK_FAILED
  | FAILURE_REASON.MISSING_PAYMENT_HOOK
  | FAILURE_REASON.TOP_UP_FAILED
  | FAILURE_REASON.PAYMENT_CANCELLED
  | FAILURE_REASON.PAYMENT_AUTHENTICATION_FAILED;

export const actions = {
  requested: createCustomAction(REQUESTED, request),
  approved: createCustomAction(APPROVED, request),
  blocked: createCustomAction(BLOCKED, standardEmptyACC),
  succeeded: createCustomAction(SUCCEEDED, succeed),
  failed: createCustomAction(
    FAILED,
    getStandardFailedWithReasonACC<FailureReason>(),
  ),
};

export default actions.requested;
