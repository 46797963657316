import { createCustomAction } from 'typesafe-actions';

import { standardEmptyACC, standardFailedACC } from '../../utils/sagas';

const REQUESTED = '$APPLY_BUFFER/REQUESTED';
const BLOCKED = '$APPLY_BUFFER/BLOCKED';
const APPROVED = '$APPLY_BUFFER/APPROVED';
const SUCCEEDED = '$APPLY_BUFFER/SUCCEEDED';
const FAILED = '$APPLY_BUFFER/FAILED';

export const events = {
  REQUESTED,
  BLOCKED,
  APPROVED,
  SUCCEEDED,
  FAILED,
};

export const actions = {
  requested: createCustomAction(REQUESTED, standardEmptyACC),
  approved: createCustomAction(APPROVED, standardEmptyACC),
  blocked: createCustomAction(BLOCKED, standardEmptyACC),
  succeeded: createCustomAction(SUCCEEDED, standardEmptyACC),
  failed: createCustomAction(FAILED, standardFailedACC),
};

export default actions.requested;
