import { call, put, takeEvery, all, select } from 'redux-saga/effects';

import * as updateKeyOrderPropertyFlow from '../../actionCreators/flows/updateKeyOrderProperty';
import * as updateMenuFlow from '../../actionCreators/flows/updateMenu';
import * as applyBufferFlow from '../../actionCreators/flows/applyBuffer';
import * as fetchOffersFlow from '../../actionCreators/flows/fetchOffers';
import * as fetchDeliveryEstimateFlow from '../../actionCreators/flows/fetchDeliveryEstimate';
import * as fetchOrderingProviderDetailsFlow from '../../actionCreators/flows/fetchOrderingProviderDetails';
import * as fetchPaymentGatewayConfigFlow from '../../actionCreators/flows/fetchPaymentGatewayConfig';

import {
  clearBuffer,
  setBufferLocationId,
  setBufferSaleType,
  setBufferReadyToApply,
} from '../../actionCreators/buffer';

import getMember from '../../selectors/getMember';
import { $getLocationId } from '../../selectors/getLocationId';
import {
  getEnableMultipleDeliveryEstimate,
  getEnableDynamicPaymentGatewayConfig,
} from '../../selectors/config';
// import { $getStagedPuchasesTotals } from '../../selectors/getStagedPuchasesTotals';

import {
  requestAndWaitForFlow,
  makeErrorSerialisable,
  createBufferFlowApprover,
} from '../../utils/sagas';

import Logger from '../../utils/Logger';

import reserveOrCheckBuffer from '../reserveOrCheckBuffer';
import applyLocationEstimate from '../applyLocationEstimate';
import { getLocationDeliveryEstimates } from '../../selectors';
import { SALE_TYPE } from '../../constants/saleType';

export const requested = createBufferFlowApprover(updateKeyOrderPropertyFlow);

// TODO: kick off another delivery estimate if the purchaseMoneyAmount
// changes after updating the menu (and doing delivery)
// const foo = yield select($getStagedPuchasesTotals);

function* updateMenu(flowId: string) {
  const updateMenuResult = yield call(
    requestAndWaitForFlow,
    updateMenuFlow,
    { autoApply: false, isSubFlow: true },
    flowId,
  );

  if (!updateMenuResult.succeeded) {
    throw new Error('updateMenu subflow did not finish successfully');
  }
}

function* updateOffers(flowId: string) {
  const memberPresent = Boolean(yield select(getMember));

  try {
    if (memberPresent) {
      yield call(
        requestAndWaitForFlow,
        fetchOffersFlow,
        { bufferMode: true },
        flowId,
      );
    }
  } catch (e) {
    Logger.log('fetch offers failed', 'error', e);
  }
}

function* applyBuffer(flowId: string) {
  const applyBufferResult = yield call(
    requestAndWaitForFlow,
    applyBufferFlow,
    {},
    flowId,
  );

  if (!applyBufferResult.succeeded) {
    throw new Error('applyBuffer subflow did not finish successfully');
  }
}

function* reactToLocation(
  flowId: string,
  performDeliveryEstimate: boolean,
  initialBufferLocationId?: string,
  updateDeliveryEstimate?: boolean,
  saleType?: SALE_TYPE,
) {
  const bufferLocationId = yield select($getLocationId);

  if (bufferLocationId == null) {
    return;
  }

  const bufferLocationIdChanged = bufferLocationId !== initialBufferLocationId;

  if (bufferLocationIdChanged || !updateDeliveryEstimate) {
    yield call(updateMenu, flowId);
  }

  if (bufferLocationIdChanged) {
    yield call(updateOffers, flowId);
  }

  if (
    !performDeliveryEstimate && // results of this call already included in estimates
    saleType != null
  ) {
    yield call(
      requestAndWaitForFlow,
      fetchOrderingProviderDetailsFlow,
      { bufferMode: true },
      flowId,
    );
  }

  const enableDynamicPaymentGatewayConfig = yield select(
    getEnableDynamicPaymentGatewayConfig,
  );

  if (enableDynamicPaymentGatewayConfig) {
    yield call(
      requestAndWaitForFlow,
      fetchPaymentGatewayConfigFlow,
      { bufferMode: true, locationId: bufferLocationId },
      flowId,
    );
  }
}

function* fetchDeliveryEstimate(
  flowId: string,
  enableMultipleDeliveryEstimate: boolean,
  initialBufferLocationId?: string,
  updateDeliveryEstimate?: boolean,
  deliveryAddress?: string,
  desiredDeliveryTime?: string,
  locationId?: string,
  forceASAPDeliveryEstimate?: boolean,
) {
  let error: Error | undefined;

  if (locationId != null) {
    throw new Error('cannot provide locationId with delivery saleType');
  }

  let fetchParams: any = { forceASAPDeliveryEstimate };

  if (updateDeliveryEstimate) {
    if (enableMultipleDeliveryEstimate) {
      fetchParams = {
        ...fetchParams,
        locationId: initialBufferLocationId,
      };
    }
  } else {
    fetchParams = {
      ...fetchParams,
      deliveryAddress,
      desiredDeliveryTime,
      multiple: enableMultipleDeliveryEstimate,
    };
  }

  const fetchDeliveryEstimateResult = yield call(
    requestAndWaitForFlow,
    fetchDeliveryEstimateFlow,
    fetchParams,
    flowId,
  );

  if (!fetchDeliveryEstimateResult.succeeded) {
    error = new Error(
      'fetchDeliveryEstimate subflow did not finish successfully',
    );
  }

  return {
    ...fetchDeliveryEstimateResult.failure,
    ...fetchDeliveryEstimateResult.success,
    error,
  };
}

function* confirmLocationDeliveryEstimate(locationId?: string) {
  if (locationId == null) {
    throw new Error('missing locationId');
  }

  const locationEstimates = (yield select(
    getLocationDeliveryEstimates,
  )) as ReturnType<typeof getLocationDeliveryEstimates>;

  const locationEstimate = locationEstimates.find(
    estimate => estimate.locationId === locationId,
  );

  if (!locationEstimate) {
    throw new Error('no matching estimate for locationId');
  }

  yield call(applyLocationEstimate, locationEstimate);
}

export function* approved(
  action: ReturnType<typeof updateKeyOrderPropertyFlow.actions.approved>,
) {
  const {
    payload: {
      autoApply,
      locationId,
      saleType,
      deliveryAddress,
      desiredDeliveryTime,
      updateDeliveryEstimate = false, // for when already in delivery mode
      forceASAPDeliveryEstimate,
      confirmLocationDeliveryEstimate: shouldconfirmLocationDeliveryEstimate,
    },
    meta: { flowId },
  } = action;

  let controlsBuffer;
  let reason: updateKeyOrderPropertyFlow.FailureReason;
  let userReason: string | undefined;
  let matchedAddressString: string | undefined;
  let receivedMultipleEstimates: boolean = false;

  try {
    // START BUFFER SETUP

    yield call(reserveOrCheckBuffer, flowId);

    controlsBuffer = true;

    if (!confirmLocationDeliveryEstimate) {
      yield put(clearBuffer({ preserveReservationId: true }));
    }

    const initialBufferLocationId = yield select($getLocationId);

    // END BUFFER SETUP

    const enableMultipleDeliveryEstimate = yield select(
      getEnableMultipleDeliveryEstimate,
    );

    const performDeliveryEstimate =
      !shouldconfirmLocationDeliveryEstimate &&
      (saleType === SALE_TYPE.DELIVERY || updateDeliveryEstimate);

    if (saleType != null) {
      yield put(setBufferSaleType(saleType));
    }

    if (!performDeliveryEstimate && locationId != null) {
      yield put(setBufferLocationId(locationId));
    }

    if (performDeliveryEstimate) {
      const result = yield call(
        fetchDeliveryEstimate,
        flowId,
        enableMultipleDeliveryEstimate,
        initialBufferLocationId,
        updateDeliveryEstimate,
        deliveryAddress,
        desiredDeliveryTime,
        locationId,
        forceASAPDeliveryEstimate,
      );

      if (result.error) {
        ({ reason, userReason, matchedAddressString } = result);

        throw result.error;
      } else if (result.multiple) {
        receivedMultipleEstimates = true;
      }
    }

    if (shouldconfirmLocationDeliveryEstimate) {
      yield call(confirmLocationDeliveryEstimate, locationId);
    }

    if (!receivedMultipleEstimates) {
      // fill in the buffer with location specific order information
      yield call(
        reactToLocation,
        flowId,
        performDeliveryEstimate,
        initialBufferLocationId,
        updateDeliveryEstimate,
        saleType,
      );
    }

    // START BUFFER FINALISING

    // always has to run to unlock the buffer for subsequent flows
    yield put(setBufferReadyToApply(!receivedMultipleEstimates));

    if (
      !receivedMultipleEstimates &&
      (autoApply ||
        updateDeliveryEstimate ||
        shouldconfirmLocationDeliveryEstimate)
    ) {
      yield call(applyBuffer, flowId);
    }

    // END BUFFER FINALISING

    yield put(
      updateKeyOrderPropertyFlow.actions.succeeded(
        { multiple: receivedMultipleEstimates },
        flowId,
      ),
    );
  } catch (e) {
    if (controlsBuffer) {
      yield put(clearBuffer({}));
    }

    yield put(
      updateKeyOrderPropertyFlow.actions.failed(
        {
          error: makeErrorSerialisable(e),
          reason,
          userReason,
          matchedAddressString,
        },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(updateKeyOrderPropertyFlow.events.REQUESTED, requested),
    takeEvery(updateKeyOrderPropertyFlow.events.APPROVED, approved),
  ]);
}
