import lodash from 'lodash';

export default function choicesFromIngredientFamilies(
  ingredientFamilies: BoostIngredientFamilies,
  familyFilter: (family: BoostIngredientFamily) => boolean,
  choiceType: BoostChoiceVariety,
) {
  return lodash
    .values(ingredientFamilies)
    .filter(familyFilter)
    .reduce(
      (acc: Choice[], current: BoostIngredientFamily) => [
        ...acc,
        ...lodash.compact(
          current.ingredients.map(
            ingredient => ingredient.choiceMap[choiceType],
          ),
        ),
      ],
      [],
    );
}
