import { createSelector } from 'reselect';
import lodash from 'lodash';

import { OrderingSelectors } from 'polygon-ordering';

import getCurrentMenuBrandId from './getCurrentMenuBrandId';
import { RootState } from 'store';

const { getRootCategory } = OrderingSelectors;

const getMenuSearchText = (state: RootState) => state.menuSearchText;

export default createSelector(
  [getRootCategory, getMenuSearchText, getCurrentMenuBrandId],
  (rootCategory, menuSearchText, menuBrandId) => {
    if (!menuSearchText && menuBrandId == null) {
      return rootCategory;
    }

    const lowerCaseSearchText = (menuSearchText || '').toLowerCase();

    const subCategoriesWithItemsFiltered: Category[] = lodash
      .get(rootCategory, 'subCategories', [])
      //@ts-ignore
      .map((subCategory: Category) => {
        return {
          ...subCategory,
          items: subCategory.items.filter(
            item =>
              !menuSearchText ||
              item.name.toLowerCase().includes(lowerCaseSearchText) ||
              (item.description || '').toLowerCase().includes(lowerCaseSearchText),
          ),
        };
      });

    return {
      ...rootCategory,
      subCategories: subCategoriesWithItemsFiltered.filter((subCategory: Category) => {
        const hasItems = Boolean(subCategory.items.length);
        const matchesBrandID = menuBrandId == null || subCategory.brandId === menuBrandId;

        return hasItems && matchesBrandID;
      }),
    };
  },
);
