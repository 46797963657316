import { call, put, takeEvery, all } from 'redux-saga/effects';

import * as fetchFavouriteOrders from '../../actionCreators/flows/fetchFavouriteOrders';
import { setFavouriteOrders } from '../../actionCreators/member';

import Api, { FetchParams, ApiResponse } from '../../utils/Api';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';
import processFavouriteOrder from '../../utils/processors/processFavouriteOrder';

export const requested = createFlowApprover(fetchFavouriteOrders);

export function* approved(
  action: ReturnType<typeof fetchFavouriteOrders.actions.approved>,
) {
  const {
    payload: { authenticationMethod = 'member' },
    meta: { flowId },
  } = action;

  try {
    const params: FetchParams = {
      path: '/api/v1/profile/favourites',
      method: 'GET',
    };

    const response: ApiResponse = yield call(
      Api.fetch,
      params,
      authenticationMethod,
    );

    const rawOrders: RawFavouriteOrder[] = response.data;
    const processedOrders = rawOrders.map(processFavouriteOrder);

    yield put(setFavouriteOrders(processedOrders));

    yield put(fetchFavouriteOrders.actions.succeeded({}, flowId));
  } catch (e) {
    yield put(
      fetchFavouriteOrders.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(fetchFavouriteOrders.events.REQUESTED, requested),
    takeEvery(fetchFavouriteOrders.events.APPROVED, approved),
  ]);
}
