import React, { useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { RiArrowRightFill } from 'react-icons/ri';

import { OrderingSelectors, OrderingConstants, OrderingOperations } from 'polygon-ordering';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';

import getThemeLookup from '../selectors/getThemeLookup';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import getExtremelyShortScreen from '../selectors/getExtremelyShortScreen';
import getAdditionalDeliveryStatusesEnabled from '../selectors/getAdditionalDeliveryStatusesEnabled';
import getHideTimes from '../selectors/getHideTimes';

import combineStyles from '../utils/combineStyles';
import { logEvent } from '../utils/analytics';

import { SALE_TYPE_ICONS } from '../constants/saleTypes';
import EVENTS from '../constants/events';

import { INITIAL_SCREEN_ROUTE } from './InitialScreen';

import ScreenHero from '../components/ScreenHero';
import ScreenFloating from '../components/ScreenFloating';
import NewOrderButton from '../components/NewOrderButton';
import Text from '../components/Text';
import OrderStatus from '../components/OrderStatus';
import OrderPropertyLocation, { Detail } from '../components/OrderPropertyLocation';
import OrderTotals from '../components/OrderTotals';
import PoweredByDoordashDriveBadge from '../components/PoweredByDoordashDriveBadge';
import PoweredByUberDirectBadge from '../components/PoweredByUberDirectBadge';
import StagedPurchase from '../components/StagedPurchase';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

const {
  getSaleDetails,
  getSaleType,
  getReceiptItems,
  getTable,
  getDeliveryProvider,
  getMember,
  getLocation,
} = OrderingSelectors;

const { SALE_STATUSES_MAP, PROCESSED_AT_STORE, DELIVERED, DELIVERY_PROVIDERS, READY_TO_COLLECT } =
  OrderingConstants;
const { fetchSaleStatus, setSaleStatus } = OrderingOperations;
export const COMPLETED_ORDER_SCREEN_ROUTE = '/completed-order';

const CompletedOrderScreen = () => {
  const FETCH_STATUS_INTERVAL_DURATION = 10000; // should this be configurable?
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const p = useAppSelector(getThemeLookup);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const saleDetails = useAppSelector(getSaleDetails);
  const saleType = useAppSelector(getSaleType);
  const receiptItems = useAppSelector(getReceiptItems);
  const table = useAppSelector(getTable);
  const deliveryProvider = useAppSelector(getDeliveryProvider);
  const member = useAppSelector(getMember);
  const additionalDeliveryStatusesEnabled = useAppSelector(getAdditionalDeliveryStatusesEnabled);
  const extremelyShortScreen = useAppSelector(getExtremelyShortScreen);
  const hideTimes = useAppSelector(getHideTimes);
  const tableNumberLabel = useAppSelector(state => state.config.tableNumberLabel);
  const location = useAppSelector(getLocation);

  console.log({ SALE_STATUSES_MAP });

  const fetchStatusInterval = useRef<NodeJS.Timeout | null>(null);
  const readyInterval = useRef<NodeJS.Timeout | null>(null);
  const fetchStatus = () => {
    if (!saleDetails) {
      return;
    }

    if (saleType === SALE_TYPE.CATERING && saleDetails.items?.length !== 0) {
      return;
    }

    if (
      saleDetails.status <
        Number(
          SALE_STATUSES_MAP[additionalDeliveryStatusesEnabled ? DELIVERED : PROCESSED_AT_STORE],
        ) ||
      saleType === SALE_TYPE.CATERING
    ) {
      dispatch(fetchSaleStatus({ authenticationMethod: Boolean(member) ? 'member' : 'none' }));
    } else {
      clearInterval(fetchStatusInterval.current as NodeJS.Timeout);

      // TODO: Why is this commented and not checked for delivery?

      if (
        !additionalDeliveryStatusesEnabled &&
        saleDetails.status !== SALE_STATUSES_MAP[READY_TO_COLLECT]
      ) {
        readyInterval.current = setInterval(checkIfReady, 1000);
      }
    }
  };

  useEffect(() => {
    fetchStatusInterval.current = setInterval(fetchStatus, FETCH_STATUS_INTERVAL_DURATION);
    fetchStatus();
    return () => {
      clearInterval(fetchStatusInterval.current as NodeJS.Timeout);
      clearInterval(readyInterval.current as NodeJS.Timeout);
    };
  }, []);

  const checkIfReady = () => {
    if (saleDetails!.pickupTime && moment(saleDetails!.pickupTime) <= moment()) {
      dispatch(setSaleStatus(SALE_STATUSES_MAP[READY_TO_COLLECT]));
      clearInterval(readyInterval.current as NodeJS.Timeout);
    }
  };

  if (!saleDetails) {
    return <Redirect to={INITIAL_SCREEN_ROUTE} />;
  }

  const isDelivery = saleType.valueOf() === SALE_TYPE.DELIVERY;
  const isPickupOrTakeAway =
    saleType.valueOf() === SALE_TYPE.PICKUP ||
    saleType.valueOf() === SALE_TYPE.TAKE_AWAY ||
    saleType.valueOf() === SALE_TYPE.WEB_ORDERING ||
    saleType === SALE_TYPE.CATERING;
  const isDineIn = saleType.valueOf() === SALE_TYPE.DINE_IN || saleType === SALE_TYPE.TABLE_ORDER;

  const ScreenComponent = deviceTypeMobile || extremelyShortScreen ? ScreenHero : ScreenFloating;

  const DeliveryIconComponent = SALE_TYPE_ICONS[SALE_TYPE.DELIVERY];
  const deliveryTrackingLinkIconStyle = combineStyles(
    p('deliveryTrackingLink', ['color', 'fontSize']),
    styles.deliveryTrackingLinkIcon,
  );

  const completedOrderMessage = t(`completedOrderMessage.${saleType}`);

  const deviceTimezone = moment.tz.guess();
  const locationTimezone = location?.timeZone ?? deviceTimezone;

  return (
    <ScreenComponent
      parentContainerStyle={deviceTypeMobile ? undefined : styles.desktopParentContainer}
    >
      <div
        style={combineStyles(
          styles.mainContainer,
          !deviceTypeMobile && styles.mainContainerDesktop,

          p('screen', ['backgroundColor']),
          p('completedOrderScreen', ['backgroundColor']),

          !deviceTypeMobile && p('completedOrderScreenDesktop', ['backgroundColor', 'boxShadow']),
          deviceTypeMobile && p('completedOrderScreenMobile', ['backgroundColor']),
        )}
      >
        {deviceTypeMobile && <NewOrderButton containerStyle={styles.newOrderButtonMobile} />}

        {saleType !== SALE_TYPE.CATERING && <OrderStatus />}

        {Boolean(completedOrderMessage) && (
          <div style={styles.completedOrderMessageContainer}>
            <Text themeKey="completedOrderMessage" style={styles.completedOrderMessage}>
              {completedOrderMessage}
            </Text>
          </div>
        )}

        <div style={styles.detailsSection}>
          {isDelivery && Boolean(saleDetails.deliveryTrackingUrl) && (
            <>
              <Text
                themeKey="deliveryTrackerAccuracyMessage"
                style={styles.deliveryTrackerAccuracyMessage}
              >
                {t('deliveryTrackerAccuracyMessage')}
              </Text>

              <Text
                themeKey="deliveryTrackingLink"
                style={styles.deliveryTrackingUrl}
                href={saleDetails.deliveryTrackingUrl}
                openInNewTab
                onClick={() => logEvent(EVENTS.OPEN_DELIVERY_TRACKER)}
              >
                <DeliveryIconComponent style={deliveryTrackingLinkIconStyle} />
                {t('deliveryTrackingLink')}
                <RiArrowRightFill style={deliveryTrackingLinkIconStyle} />
              </Text>

              {deliveryProvider?.id === DELIVERY_PROVIDERS.DOORDASH_DRIVE && (
                <PoweredByDoordashDriveBadge />
              )}

              {deliveryProvider?.id === DELIVERY_PROVIDERS.UBER_DIRECT && (
                <PoweredByUberDirectBadge />
              )}
            </>
          )}

          <>
            <Text themeKey="completedOrderTitle" style={styles.title} block>
              {t('title.location')}
            </Text>

            <OrderPropertyLocation readOnly containerStyle={styles.location} />
          </>

          {Boolean(saleDetails.folioId) && (
            <>
              <Text themeKey="completedOrderTitle" style={styles.title} block>
                {t('title.orderNumber')}
              </Text>

              <Text themeKey="completedOrderDetail">{`#${saleDetails.folioId}`}</Text>
            </>
          )}

          {!hideTimes && isPickupOrTakeAway && saleDetails.pickupTime && (
            <>
              <Text themeKey="completedOrderTitle" style={styles.title} block>
                {t('title.pickupTime')}
              </Text>

              <Text themeKey="completedOrderDetail">
                {Intl.DateTimeFormat('en-AU', {
                  month: 'short',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                  timeZone: locationTimezone,
                }).format(new Date(saleDetails.pickupTime))}
              </Text>

              {locationTimezone != deviceTimezone && (
                <Detail label={`(${locationTimezone})`}></Detail>
              )}
            </>
          )}

          {isDineIn && Boolean(table) && (
            <>
              <Text themeKey="completedOrderTitle" style={styles.title} block>
                {tableNumberLabel || t('tableNumber')}
              </Text>

              <Text themeKey="completedOrderDetail">{table}</Text>
            </>
          )}

          {!hideTimes && isDelivery && Boolean(saleDetails.estimatedDeliveryTime) && (
            <>
              <Text themeKey="completedOrderTitle" style={styles.title} block>
                {t('eta')}
              </Text>
              <Text themeKey="completedOrderDetail">
                {moment(saleDetails.estimatedDeliveryTime).format('MMM D, h:mm A')}
              </Text>
            </>
          )}

          {Boolean(receiptItems?.length) && (
            <>
              <Text themeKey="completedOrderTitle" style={styles.title} block>
                {t('title.items')}
              </Text>

              <div style={styles.cartContainer}>
                {receiptItems.map((purchase, index) => (
                  <StagedPurchase
                    key={index}
                    purchase={purchase}
                    readOnly
                    showIngredientAmount
                    hideQuantityWhenSingular
                    qtyUpdates={{}}
                  />
                ))}

                {saleDetails.total != null && (
                  <OrderTotals totalOverride={saleDetails.total} orderCompleted />
                )}
              </div>
            </>
          )}
        </div>

        {!deviceTypeMobile && <NewOrderButton />}
      </div>
    </ScreenComponent>
  );
};

const styles: Styles = {
  desktopParentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 60,
  },

  mainContainer: {
    padding: 20,
  },
  mainContainerDesktop: {
    width: 430,
  },

  detailsSection: {
    marginTop: 10,
    marginBottom: 40,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  title: {
    marginTop: 40,
    marginBottom: 10,
  },

  deliveryTrackerAccuracyMessage: {
    marginTop: 30,
    marginBottom: 20,

    textAlign: 'center',
    maxWidth: 330,
  },

  deliveryTrackingUrl: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  location: {
    paddingTop: 0,
  },

  newOrderButtonMobile: {
    margin: '10px 0 30px 0',
  },

  cartContainer: {
    width: '90%',
  },

  deliveryTrackingLinkIcon: {
    marginRight: 10,
    marginLeft: 5,
  },

  completedOrderMessage: {
    marginTop: 15,
  },

  completedOrderMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

export default CompletedOrderScreen;
