import { put, takeEvery, all, select, call } from 'redux-saga/effects';

import * as removeMember from '../../actionCreators/flows/removeMember';
import * as fetchPaymentGatewayConfig from '../../actionCreators/flows/fetchPaymentGatewayConfig';

import { setOfferId, setOffers } from '../../actionCreators/currentOrder';
import {
  setPaymentGatewayTokens,
  setHistoricalOrders,
  setFavouriteOrders,
  setMemberData,
  setTransactionHistory,
} from '../../actionCreators/member';

import { getEnableDynamicPaymentGatewayConfig } from '../../selectors/config';

import {
  createFlowApprover,
  makeErrorSerialisable,
  requestAndWaitForFlow,
} from '../../utils/sagas';

export const requested = createFlowApprover(removeMember);

export function* approved(
  action: ReturnType<typeof removeMember.actions.approved>,
) {
  const {
    meta: { flowId },
  } = action;

  try {
    yield all([
      put(setOfferId(null)),
      put(setOffers(null)),
      put(setPaymentGatewayTokens(null)),
      put(setHistoricalOrders(null)),
      put(setFavouriteOrders(null)),
      put(setMemberData(null)),
      put(setTransactionHistory(null)),
    ]);

    const enableDynamicPaymentGatewayConfig = yield select(
      getEnableDynamicPaymentGatewayConfig,
    );

    if (enableDynamicPaymentGatewayConfig) {
      yield call(requestAndWaitForFlow, fetchPaymentGatewayConfig, {}, flowId);
    }

    yield put(removeMember.actions.succeeded({}, flowId));
  } catch (e) {
    yield put(
      removeMember.actions.failed({ error: makeErrorSerialisable(e) }, flowId),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(removeMember.events.REQUESTED, requested),
    takeEvery(removeMember.events.APPROVED, approved),
  ]);
}
