import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiPencilFill } from 'react-icons/ri';
import { OrderingSelectors } from 'polygon-ordering';
import getThemeLookup from '../selectors/getThemeLookup';
import getDisplayableSaleTypes from '../selectors/getDisplayableSaleTypes';
import { ORDER_TYPE_MODAL_ID } from '../modals/OrderTypeModal';
import StandardButton from './StandardButton';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { setCurrentModal } from '../thunks/setCurrentModal';

const { getSaleType } = OrderingSelectors;

const OrderPropertySaleType = () => {
  const { t } = useTranslation();
  const p = useAppSelector(getThemeLookup);
  const saleType = useAppSelector(getSaleType);
  const displayableSaleTypes = useAppSelector(getDisplayableSaleTypes);
  const dispatch = useAppDispatch();

  if (saleType == null) {
    return null;
  }

  const saleTypeEditable = displayableSaleTypes.length > 1;

  return (
    <StandardButton
      themeKey="orderPropertyButton"
      invisibleContainer
      label={t(`saleType.${saleType}`)}
      RightIconComponent={saleTypeEditable ? RiPencilFill : undefined}
      iconStyle={p('orderPropertyButtonIcon', ['color'])}
      onClick={() =>
        dispatch(
          setCurrentModal({
            modalId: ORDER_TYPE_MODAL_ID,
            preserveReturnModalDetails: true,
          }),
        )
      }
      disabled={!saleTypeEditable}
    />
  );
};

export default OrderPropertySaleType;
