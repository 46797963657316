import React from 'react';

import combineStyles from '../utils/combineStyles';

import getThemeLookup from '../selectors/getThemeLookup';
import { useAppSelector } from '../app/hooks';

interface IProps {
  desktopMaxWidth?: number;
  desktopMinWidth?: number;
  desktopMaxHeight?: number;
  desktopMinHeight?: number;
  clickBackgroundToClose: boolean;
  containerStyle?: React.CSSProperties;
  setCurrentModal: (params: ModalParams) => void;
  desktopContainerStyle?: React.CSSProperties;
  calloutOffsets?: CallOutOffSets | null;
}

const ModalCallout: React.FC<IProps> = ({
  clickBackgroundToClose,
  children,
  setCurrentModal,
  calloutOffsets,
  desktopMaxWidth,
  desktopMinWidth,
  desktopMaxHeight,
  desktopMinHeight,
  containerStyle,
  desktopContainerStyle,
}) => {
  const p = useAppSelector(getThemeLookup);

  return (
    <div
      onClick={clickBackgroundToClose ? () => setCurrentModal(null) : undefined}
      style={combineStyles(
        styles.overlay,
        p('modalOverlay', ['backgroundColor']),
        p('modalOverlayCallout', ['backgroundColor']),
        {
          paddingTop: calloutOffsets?.topOffset || 0,
          paddingRight: calloutOffsets?.rightOffset || 0,
        },
      )}
    >
      <div
        style={combineStyles(
          styles.modal,
          containerStyle,
          desktopContainerStyle,
          Boolean(desktopMaxWidth) && { maxWidth: desktopMaxWidth },
          Boolean(desktopMinWidth) && { minWidth: desktopMinWidth },
          Boolean(desktopMaxHeight) && { maxHeight: desktopMaxHeight },
          Boolean(desktopMinHeight) && { minHeight: desktopMinHeight },
          p('screen', ['backgroundColor']),
          p('modalDesktop', ['backgroundColor']),
          p('modalCallout', ['backgroundColor', 'boxShadow']),
        )}
        onClick={event => event.stopPropagation()} // don't trigger the overlay's onClick event
      >
        {children}
      </div>
    </div>
  );
};

const styles: Styles = {
  overlay: {
    zIndex: 200,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    maxHeight: '100%',

    // overflowY: 'auto',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },

  modal: {
    overflowY: 'auto',
    maxHeight: '100%',
    marginBottom: 10,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',

    // border: '1px solid lightgray', // not needed now that I have a box shadow
  },
};

export default ModalCallout;
