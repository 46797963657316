import { ActionType, getType } from 'typesafe-actions';

import * as currentOrderActions from '../actionCreators/currentOrder';
import * as memberActions from '../actionCreators/member';

const initialState = null;

export default function (
  state: FavouriteOrder[] | null = initialState,
  action: ActionType<typeof currentOrderActions | typeof memberActions>,
): FavouriteOrder[] | null {
  switch (action.type) {
    case getType(currentOrderActions.resetOrder): {
      const { preserveMember } = action.payload;
      return preserveMember ? state : initialState;
    }
    case getType(memberActions.setFavouriteOrders): {
      const { value } = action.payload;
      return value == null ? initialState : value;
    }
    default:
      return state;
  }
}
