import lodash from 'lodash';

import createCartIngredientsFromBoostSwap from '../boost/createCartIngredientsFromBoostSwap';
import createIngredientsFromChoiceSet from './createIngredientsFromChoiceSet';

export default function (
  purchase: Purchase,
  freeChoiceOrderingMethod: FreeChoiceOrderingMethod,
  brands: Brand[],
  rootCategory?: Category,
  boostIngredients?: BoostIngredient[],
): CartItem {
  const { item, choiceSets, quantity } = purchase;

  let { brandId } = purchase;

  if (!brandId && brands.length) {
    // TODO: handle !shouldFlattenCategories
    const categories = lodash.get(rootCategory, 'subCategories', []);

    const firstMatchingCategory = categories.find(
      category =>
        category.items.some(
          catItem => catItem.id === item.id || catItem.sizes.includes(item.id),
        ) && category.brandId,
    );

    if (firstMatchingCategory) {
      brandId = firstMatchingCategory.brandId;
    }
  }

  const brandAvailable = brandId && brands.some(brand => brand.id === brandId);

  // turn the nested chocie sets into ingredients
  const nestedIngredientsByConditionalPlu = lodash.mapValues(
    item.conditionalChoiceSets,
    nestedChoiceSetIds =>
      lodash
        .chain(nestedChoiceSetIds)
        .map(id => choiceSets.find(choiceSet => id === choiceSet.id)) // map id into actual choice set
        .filter(choiceSet => Boolean(choiceSet)) // in case the find fails
        .map(choiceSet =>
          createIngredientsFromChoiceSet(
            choiceSet as ValidatedChoiceSet, // typescript doesn't understand we filtered out missing sets
            freeChoiceOrderingMethod,
          ),
        )
        .flatten()
        .value(),
  );

  // build a list of nested choice set ids
  const allNestedChoiceSetIds = lodash
    .chain(item.conditionalChoiceSets)
    .values()
    .flatten()
    .value();

  // build a list of immediate choice sets
  const immediateChoiceSets: ValidatedChoiceSet[] = [];

  choiceSets.forEach(choiceSet => {
    if (!allNestedChoiceSetIds.includes(choiceSet.id)) {
      immediateChoiceSets.push(choiceSet);
    }
  });

  // build a list of ingredients from all of the immediate choice sets
  let ingredients: CartIngredient[] = [];

  immediateChoiceSets.forEach(choiceSet => {
    ingredients = [
      ...ingredients,
      ...(boostIngredients && choiceSet.key.includes('swap')
        ? createCartIngredientsFromBoostSwap(boostIngredients, choiceSet)
        : createIngredientsFromChoiceSet(choiceSet, freeChoiceOrderingMethod)),
    ];
  });

  // add nested ingredients (if any)
  ingredients = ingredients.map(ingredient => ({
    ...ingredient,
    Ingredients: nestedIngredientsByConditionalPlu[ingredient.PLUCode] || [],
  }));

  return {
    Price: item.baseMoneyPrice,
    PLUCode: parseInt(item.id),
    Qty: quantity,
    Ingredients: ingredients,
    BrandID: brandAvailable ? Number(brandId) : undefined,
  };
}
