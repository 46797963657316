import { createCustomAction } from 'typesafe-actions';

import {
  standardEmptyACC,
  getStandardFailedWithReasonACC,
  standardFlowACC,
} from '../../utils/sagas';

import { FAILURE_REASON } from '../../constants/failureReasons';

const REQUESTED = '$SELECT_OFFER/REQUESTED';
const BLOCKED = '$SELECT_OFFER/BLOCKED';
const APPROVED = '$SELECT_OFFER/APPROVED';
const SUCCEEDED = '$SELECT_OFFER/SUCCEEDED';
const FAILED = '$SELECT_OFFER/FAILED';

const request = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      offerId: string | null;
    }>(type);
};

const succeed = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      purchaseStaged?: boolean;
      offerId: string | null;
    }>(type);
};

export const events = {
  REQUESTED,
  BLOCKED,
  APPROVED,
  SUCCEEDED,
  FAILED,
};

export type FailureReason =
  | undefined
  | FAILURE_REASON.OFFER_NOT_PRESENT_AND_AVAILABLE
  | FAILURE_REASON.COULD_NOT_STAGE_PURCHASE;

export const actions = {
  requested: createCustomAction(REQUESTED, request),
  approved: createCustomAction(APPROVED, request),
  blocked: createCustomAction(BLOCKED, standardEmptyACC),
  succeeded: createCustomAction(SUCCEEDED, succeed),
  failed: createCustomAction(
    FAILED,
    getStandardFailedWithReasonACC<FailureReason>(),
  ),
};

export default actions.requested;
