import { createCustomAction } from 'typesafe-actions';

import {
  standardEmptyACC,
  standardFlowACC,
  getStandardFailedWithReasonACC,
} from '../../utils/sagas';

import { FAILURE_REASON } from '../../constants/failureReasons';

const REQUESTED = '$APPLY_PROMO_CODE/REQUESTED';
const BLOCKED = '$APPLY_PROMO_CODE/BLOCKED';
const APPROVED = '$APPLY_PROMO_CODE/APPROVED';
const SUCCEEDED = '$APPLY_PROMO_CODE/SUCCEEDED';
const FAILED = '$APPLY_PROMO_CODE/FAILED';

const request = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      promoCode?: string;
    }>(type);
};

export const events = {
  REQUESTED,
  BLOCKED,
  APPROVED,
  SUCCEEDED,
  FAILED,
};

export type FailureReason =
  | undefined
  | FAILURE_REASON.FETCH_FAILED
  | FAILURE_REASON.MISSING_PARAMETER
  | FAILURE_REASON.SELECT_OFFER_FAILED;

export const actions = {
  requested: createCustomAction(REQUESTED, request),
  approved: createCustomAction(APPROVED, request),
  blocked: createCustomAction(BLOCKED, standardEmptyACC),
  succeeded: createCustomAction(SUCCEEDED, standardEmptyACC),
  failed: createCustomAction(
    FAILED,
    getStandardFailedWithReasonACC<FailureReason>(),
  ),
};

export default actions.requested;
