import React from 'react';
import appleWallet from '../images/apple.svg';
import googleWallet from '../images/google.svg';
import { RedcatApiHandler } from 'polygon-utils';
import { enqueueErrorSnackbar } from '../utils/snackbar';
import { OrderingSelectors } from 'polygon-ordering';
import { useAppSelector } from '../app/hooks';

const { getMember } = OrderingSelectors;

interface IProps {
  MemberNo?: string;
  VerifyPIN?: string;
  giftCard?: boolean;
}

interface Packet {
  WalletType: number;
  CardType: string;
  MemberNo?: string;
  VerifyPIN?: string;
}

const Wallet: React.FC<IProps> = ({ MemberNo, VerifyPIN, giftCard }) => {
  const member = useAppSelector(getMember);

  const handleWalletClick = (type: number) => {
    //Apple type:1
    //Google type:2
    //Gift card : gc
    //default: hc

    let packet: Packet = {
      WalletType: type,
      CardType: giftCard ? 'gc' : 'hc',
    };
    if (giftCard) {
      packet.MemberNo = MemberNo;
      packet.VerifyPIN = VerifyPIN;
    }

    const path = '/api/v1/wallet/generate_wallet_card';

    const fetch = member ? RedcatApiHandler.authorisedFetch : RedcatApiHandler.fetch;

    fetch({
      method: 'POST',
      path,
      body: packet,
    })
      .then(res => {
        if (type === 1) {
          //@ts-ignore
          const blob = new Blob([res], { type: 'application/vnd.apple.pkpass' });

          var URL = window.URL || window.webkitURL;
          var downloadUrl = URL.createObjectURL(blob);
          var a = document.createElement('a');
          const filename = 'card.pkpass';
          //@ts-ignore
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
            //@ts-ignore
            window.navigator.msSaveBlob(blob, filename);
          } else {
            // safari doesn't support this yet
            if (typeof a.download === 'undefined') {
              //@ts-ignore
              window.location = downloadUrl;
            } else {
              a.href = downloadUrl;
              a.setAttribute('download', filename);
              document.body.appendChild(a);
              a.click();
              a.remove();
            }
          }
        }
        if (res.success) {
          if (type === 2) {
            //@ts-ignore
            window.open(res.redirect_url);
          }
        }
      })
      .catch(err => {
        enqueueErrorSnackbar(err);
      });
  };
  return (
    <div className="text-center">
      <img
        src={appleWallet}
        style={{ height: 40, cursor: 'pointer' }}
        className="img-fluid my-2"
        onClick={() => handleWalletClick(1)}
      />
      <img
        src={googleWallet}
        className="img-fluid my-2"
        onClick={() => handleWalletClick(2)}
        style={{ cursor: 'pointer' }}
      />
    </div>
  );
};

export default Wallet;
