import { call, put, takeEvery, all } from 'redux-saga/effects';

import * as createFavouriteOrder from '../../actionCreators/flows/createFavouriteOrder';
import * as fetchFavouriteOrders from '../../actionCreators/flows/fetchFavouriteOrders';

import Api, { FetchParams } from '../../utils/Api';
import { createFlowApprover, makeErrorSerialisable } from '../../utils/sagas';
import createFavouriteItemFromShadowItem, {
  filterSystemPLUs,
} from '../../utils/ordering/createFavouriteItemFromShadowItem';

export const requested = createFlowApprover(createFavouriteOrder);

export function* approved(
  action: ReturnType<typeof createFavouriteOrder.actions.approved>,
) {
  const {
    payload: { items, name },
    meta: { flowId },
  } = action;

  try {
    const params: FetchParams = {
      path: '/api/v1/profile/favourites',
      method: 'POST',
      body: {
        Description: name,
        Items: filterSystemPLUs(
          items.map(shadowItem =>
            createFavouriteItemFromShadowItem(shadowItem),
          ),
        ),
      },
    };

    yield call(Api.fetch, params, 'member');

    yield put(fetchFavouriteOrders.actions.requested({}, flowId));

    yield put(createFavouriteOrder.actions.succeeded({}, flowId));
  } catch (e) {
    yield put(
      createFavouriteOrder.actions.failed(
        { error: makeErrorSerialisable(e) },
        flowId,
      ),
    );
  }
}

export default function* watcher() {
  yield all([
    takeEvery(createFavouriteOrder.events.REQUESTED, requested),
    takeEvery(createFavouriteOrder.events.APPROVED, approved),
  ]);
}
