import { createSelector } from 'reselect';

import getMenu from './getMenu';

export default createSelector([getMenu], (orderMenu): _Item[] | undefined => {
  if (!orderMenu) {
    return undefined;
  }

  const { items = {}, upsells = [] } = orderMenu;

  return upsells.map(upsell => items[upsell]);
});
