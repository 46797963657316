import React from 'react';
import { useTranslation } from 'react-i18next';

import { BRAND_ICON_PREFIX } from '../constants';

import combineStyles from '../utils/combineStyles';
import { useAppSelector } from '../app/hooks';

import getThemeLookup from '../selectors/getThemeLookup';

import HorizontalScrollableMenu from './HorizontalScrollableMenu';

const LocationBrandBar: React.FC<{
  brandId: string;
  setBrandId: (id: string) => void;
  brands: Brand[];
}> = ({ brandId, setBrandId, brands }) => {
  const p = useAppSelector(getThemeLookup);
  const { t } = useTranslation();

  if (brands.length < 2) {
    return null;
  }

  const tabs = [{ name: t('allBrandsLabel'), id: null }, ...brands]; //reason for ts-ignore below

  return (
    <div
      style={combineStyles(
        styles.mainContainer,
        p('locationBrandBar', ['backgroundColor', 'boxShadow']),
      )}
    >
      <HorizontalScrollableMenu
        //@ts-ignore
        items={tabs}
        currentItemId={brandId}
        setCurrentItemId={id => setBrandId(id)}
        labelThemeKey={'locationBrandBarLabel'}
        currentLabelThemeKey={'locationBrandBarCurrent'}
        currentIndicatorThemeKey={'locationBrandBarCurrentIndicator'}
        iconPathPrefix={BRAND_ICON_PREFIX}
        containerBackgroundColor={p('locationBrandBar', ['backgroundColor']).backgroundColor}
      />
    </div>
  );
};

const styles = {
  mainContainer: {
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    minHeight: 'min-content',
  },
};

export default LocationBrandBar;
