import { dollarsToCents } from '../misc';

export default function processSaleItem(
  rawSaleItem: RawSaleItem,
  alreadyInCents = false,
  ingredient = false,
): SaleItem {
  let ingredients;

  if (!ingredient) {
    ingredients = (rawSaleItem.Ingredients || []).map(ingredient => {
      return processSaleItem(ingredient, alreadyInCents, true);
    });
  }

  return {
    name: rawSaleItem.DisplayName || rawSaleItem.Name || rawSaleItem.Item,
    pointsPrice: rawSaleItem.Points,
    moneyPrice: alreadyInCents
      ? rawSaleItem.Price
      : dollarsToCents(rawSaleItem.Price),
    quantity: rawSaleItem.Qty,
    ingredients,
    id: rawSaleItem.PLUCode,
    gst: rawSaleItem.GST,
    brandId: rawSaleItem.BrandID ? String(rawSaleItem.BrandID) : undefined,
    redeemPoints: rawSaleItem.RedeemPoints,
  };
}
