import i18next, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';

import DEFAULT_STRINGS from '../constants/strings';

export function initialiseI18next(language: string = 'en') {
  return (
    i18next
      // pass the i18n instance to react-i18next
      .use(initReactI18next)
      // init i18next
      // for all options read: https://www.i18next.com/overview/configuration-options
      .init({
        lng: language,
        fallbackLng: 'en',
        debug: process.env.NODE_ENV !== 'production',

        resources: DEFAULT_STRINGS as Resource,

        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },

        parseMissingKeyHandler: key => {
          console.warn('missing i18next key:', key);

          return '';
        },
      })
  );
}
