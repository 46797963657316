import { call, all } from 'redux-saga/effects';
import debug from '../utils/debug';
import fetchConfig from './fetchConfig';
import fetchThemeOverrides from './fetchThemeOverrides';
import applyStringConfiguration from './applyStringConfiguration';
import updateMenu from './updateMenu';
import handleURLParams from './handleURLParams';

export default function* sync() {
  try {
    const [config, themeOverrides] = yield all([call(fetchConfig), call(fetchThemeOverrides)]);

    if (!config) {
      throw new Error('missing config');
    }

    debug('sync saga results', { config, themeOverrides });

    yield call(applyStringConfiguration, config.stringOverrides);

    const dontAttemptMenuUpdate = yield call(handleURLParams);

    if (!dontAttemptMenuUpdate) {
      yield call(updateMenu);
    }
  } catch (e) {
    debug(false, 'something went wrong during sync', { e });
  }
}
