import { put, select } from 'redux-saga/effects';

import { setCurrentModalId } from '../slices/currentModalId';
import { setReturnModalDetails } from '../slices/returnModalDetails';

export default function* setCurrentModal({ payload }) {
  if (payload == null) {
    const returnModalDetails = yield select(state => state.returnModalDetails);

    if (returnModalDetails) {
      yield put(setReturnModalDetails(null));

      yield put(
        setCurrentModalId({
          modalId: returnModalDetails.modalId,
          calloutOffsets: returnModalDetails.calloutOffsets || undefined,
        }),
      );
    } else {
      yield put(setCurrentModalId(null));
    }
  } else {
    if (payload?.preserveReturnModalDetails) {
      const currentModalId = yield select(state => state.currentModalId);
      const calloutOffsets = yield select(state => state.calloutOffsets);

      yield put(setReturnModalDetails({ modalId: currentModalId, calloutOffsets }));
    } else if (payload?.clearReturnModalDetails) {
      yield put(setReturnModalDetails(null));
    }

    yield put(setCurrentModalId(payload));
  }
}
