import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { SIGN_IN_MODAL_ID } from '../modals/SignInModal';
import Modal from '../components/Modal';
import StandardButton from '../components/StandardButton';
import { DESKTOP_MODAL_TYPES } from '../constants';
import { setCurrentModal } from '../thunks/setCurrentModal';
import determineCalloutOffsets from '../utils/determineCalloutOffsets';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import history from '../history';
import { PURCHASE_GIFT_CARD_ROUTE } from '../screens/PurchaseGiftCardScreen';
import { useLocation } from 'react-router-dom';
import { getLoyaltyDropdownMenu } from '../utils/getAvailableScreens';
import { orderNow } from '../utils/analytics';

export const NON_MEMBER_MODAL_ID = 'NON_MEMBER_MODAL_ID';

const NonMemberModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const selfRef = React.useRef<HTMLDivElement>(null);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const p = useAppSelector(getThemeLookup);
  const { pathname } = useLocation();
  const screens = getLoyaltyDropdownMenu();
  const isGiftcardPurchaseEnabled = useAppSelector(state => state.config.enableGiftcardPurchase);
  const isOnlyLoyaltyEnabled = useAppSelector(state => state.config.enableOnlyLoyalty);
  const onlyGiftcardPurchasesEnabled = useAppSelector(
    state => state.config.enableOnlyGiftcardPurchases,
  );
  const disableLoyalty = useAppSelector(state => state.config.disableLoyalty);

  const loyaltyScreenOn =
    screens.filter(screen => {
      if (screen.path === pathname) {
        return true;
      } else if (screen.subRoutes) {
        return screen.subRoutes.filter(route => route.path === pathname).length !== 0;
      }
    }).length !== 0;

  const availableRoutes = isGiftcardPurchaseEnabled
    ? screens.filter(screen => screen.path === PURCHASE_GIFT_CARD_ROUTE)
    : [];

  return (
    <Modal
      clickBackgroundToClose
      desktopModalType={DESKTOP_MODAL_TYPES.CALLOUT}
      desktopMaxWidth={200}
      desktopMinWidth={200}
      desktopContainerStyle={styles.modalDesktopContainer}
      mobileContainerStyle={styles.modalMobileContainer}
      hideFloatingHeader
    >
      {deviceTypeMobile && (
        <h1
          style={{
            paddingLeft: 20,
            cursor: 'pointer',
            color: p('closeIconColor', ['color']).color,
          }}
          onClick={() => dispatch(setCurrentModal(null))}
        >
          &times;
        </h1>
      )}

      {!onlyGiftcardPurchasesEnabled &&
        !isOnlyLoyaltyEnabled &&
        loyaltyScreenOn &&
        deviceTypeMobile && (
          <StandardButton //order online button
            containerStyle={combineStyles(styles.button, styles.buttonMobile)}
            themeKey="orderOnlineButton"
            label={t('button.orderOnline')}
            onClick={() => {
              orderNow();
              history.push('/');
              dispatch(setCurrentModal(null));
            }}
          />
        )}

      {!disableLoyalty && !onlyGiftcardPurchasesEnabled && (
        <StandardButton //login button
          ref={selfRef}
          invisibleContainer
          onClick={() => {
            dispatch(
              setCurrentModal({
                modalId: SIGN_IN_MODAL_ID,
                calloutOffsets: determineCalloutOffsets(
                  (
                    selfRef as React.MutableRefObject<HTMLDivElement>
                  ).current.getBoundingClientRect(),
                ),
              }),
            );
          }}
          label={t('button.headerMemberAbsent')}
          themeKey="defaultDropdownMenuButton"
          containerStyle={combineStyles(
            styles.button,
            deviceTypeMobile ? styles.buttonMobile : styles.buttonDesktop,
          )}
        />
      )}

      {availableRoutes?.map((route, index) => (
        <div key={index}>
          <StandardButton //giftcard button
            invisibleContainer
            themeKey={
              pathname === route.path ||
              (route.subRoutes &&
                route.subRoutes?.filter(route => route.path === pathname).length !== 0)
                ? 'activeMemberButton'
                : 'defaultDropdownMenuButton'
            }
            onClick={() => {
              history.push(route.path);
              dispatch(setCurrentModal(null));
            }}
            label={t(route.label)}
            containerStyle={combineStyles(
              styles.button,
              deviceTypeMobile ? styles.buttonMobile : styles.buttonDesktop,
              pathname === route.path && p('activeMemberButton', ['borderLeft']),
              route.subRoutes?.filter(subRoute => subRoute.path === pathname).length !== 0 &&
                route.subRoutes &&
                p('activeMemberButton', ['borderLeft']),
            )}
          />
          {deviceTypeMobile &&
            route.subRoutes?.filter(route => route.path === pathname).length !== 0 &&
            route.subRoutes?.map(route => (
              <div style={{ marginLeft: 10 }}>
                <StandardButton
                  key={index}
                  label={t(route.label)}
                  themeKey={pathname === route.path ? 'activeSubButton' : 'defaultSubButton'}
                  containerStyle={combineStyles(
                    styles.button,
                    deviceTypeMobile ? styles.buttonMobile : styles.buttonDesktop,
                  )}
                  onClick={() => {
                    history.push(route.path);
                    dispatch(setCurrentModal(null));
                  }}
                />
              </div>
            ))}
        </div>
      ))}
    </Modal>
  );
};

const styles: Styles = {
  modalDesktopContainer: {
    borderRadius: 2,
  },
  modalMobileContainer: {
    padding: 0,
    minHeight: '100vh',
    paddingBottom: 55,
  },
  buttonMobile: {
    paddingLeft: 40,
  },
  buttonDesktop: {
    paddingLeft: 20,
    borderTop: '1px solid lightgrey',
  },

  button: {
    justifyContent: 'left',
    margin: 0,
  },
};

export default NonMemberModal;
