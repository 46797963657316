import React from 'react';
import combineStyles from '../utils/combineStyles';
import getThemeLookup from '../selectors/getThemeLookup';
import ModalFloatingHeader from './ModalFloatingHeader';
import Text from './Text';
import { useAppSelector } from '../app/hooks';

interface IProps {
  title?: string;
  forceFloatingTitle: boolean;
  closeButtonLabel?: string;
  clickBackgroundToClose: boolean;
  previousModalId: string | null;
  setCurrentModal: (params: ModalParams) => void;
  locked?: boolean;
  hideFloatingHeader?: boolean;
  tallestPossible?: boolean;
  containerStyle?: React.CSSProperties;
  desktopContainerStyle?: React.CSSProperties;
  expandToMaxWidth?: boolean;
  desktopMaxWidth?: number;
  desktopMinWidth?: number;
  desktopMinHeight?: number;
}

const ModalDesktop: React.FC<IProps> = ({
  title,
  forceFloatingTitle,
  closeButtonLabel,
  clickBackgroundToClose,
  children,
  previousModalId,
  setCurrentModal,
  desktopMaxWidth,
  desktopMinWidth,
  desktopMinHeight,
  containerStyle,
  desktopContainerStyle,
  tallestPossible,
  expandToMaxWidth,
  locked,
  hideFloatingHeader,
}) => {
  const p = useAppSelector(getThemeLookup);
  const close = () => setCurrentModal(null);
  const goBack = () => previousModalId && setCurrentModal({ modalId: previousModalId });

  const showFloatingTitle = forceFloatingTitle || Boolean(previousModalId);

  return (
    <div
      style={combineStyles(
        styles.overlay,
        p('modalOverlay', ['backgroundColor']),
        p('modalOverlayDesktop', ['backgroundColor']),
      )}
      onClick={!locked && clickBackgroundToClose ? close : undefined}
    >
      <div
        style={combineStyles(
          styles.wrapper,
          tallestPossible && styles.tallestPossibleWrapper,
          expandToMaxWidth && styles.expandToMaxWidthWrapper,
          Boolean(desktopMaxWidth) && { maxWidth: desktopMaxWidth },
          Boolean(desktopMinWidth) && { minWidth: desktopMinWidth },
        )}
        onClick={event => event.stopPropagation()} // don't trigger the overlay's onClick event
      >
        {!hideFloatingHeader && (
          <ModalFloatingHeader
            goBack={goBack}
            close={close}
            closeButtonLabel={closeButtonLabel}
            showFloatingTitle={showFloatingTitle}
            previousModalId={previousModalId}
            title={title}
            locked={locked}
          />
        )}

        <div
          style={combineStyles(
            styles.modal,
            containerStyle,
            desktopContainerStyle,
            p('screen', ['backgroundColor']),
            p('modalDesktop', ['backgroundColor']),
            Boolean(desktopMinHeight) && { minHeight: desktopMinHeight },
            tallestPossible && styles.tallestPossibleModal,
          )}
        >
          {!showFloatingTitle && (
            <Text themeKey="modalTitle" style={styles.title}>
              {title}
            </Text>
          )}

          {children}
        </div>
      </div>
    </div>
  );
};

const styles: Styles = {
  overlay: {
    zIndex: 200,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    maxHeight: '100%',

    overflowY: 'auto',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backdropFilter: 'blur(3px)',
    WebkitBackdropFilter: 'blur(3px)',
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 900,
    maxHeight: '90%',
  },

  title: {
    textAlign: 'center',
  },

  modal: {
    borderRadius: 3,
    padding: '20px 0px',

    overflowY: 'auto',

    // maxHeight: 600,
  },

  tallestPossibleWrapper: {
    height: '100%',
    maxHeight: '100%',
    paddingBottom: 30,
    paddingTop: 20,
  },

  expandToMaxWidthWrapper: {
    width: '100%',
  },

  tallestPossibleModal: {
    flex: 1,
  },
};

export default ModalDesktop;
