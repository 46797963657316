import React from 'react';
import getSurcharges from '../selectors/getSurcharges';
import Text from './Text';
import { useAppSelector } from '../app/hooks';
import { OrderingHooks } from 'polygon-ordering';

const { useFormattedCurrency } = OrderingHooks;

const Surcharge = ({ surcharge }: { surcharge: Partial<Surcharge> }) => {
  const amount = useFormattedCurrency(Number(surcharge.amount));
  return (
    <div style={styles.surchargeContainer}>
      <Text themeKey="stagedPurchase" style={styles.description}>
        {surcharge.description}
      </Text>
      <Text themeKey="stagedPurchase">{amount}</Text>
    </div>
  );
};

const Surcharges = () => {
  const surcharges: Partial<Surcharge>[] = useAppSelector(getSurcharges);

  return (
    <div style={styles.mainContainer}>
      {surcharges.map(surcharge => (
        <Surcharge key={`${surcharge.type}${surcharge.description}`} surcharge={surcharge} />
      ))}
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: 9,
    paddingBottom: 0,
  },

  surchargeContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 5,
  },

  description: {
    marginRight: 13,
  },
};

export default Surcharges;
