import { ActionType, getType } from 'typesafe-actions';

import * as shadowOrder from '../../actionCreators/shadowOrder';

export default function (
  state: ShadowItem[] | null = null,
  action: ActionType<typeof shadowOrder>,
): ShadowItem[] | null {
  switch (action.type) {
    case getType(shadowOrder.setShadowCart): {
      const { value } = action.payload;
      return value || null;
    }
    case getType(shadowOrder.addToShadowCart): {
      const { value } = action.payload;
      return [...(state || []), ...value];
    }
    default:
      return state;
  }
}
