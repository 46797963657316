import lodash from 'lodash';

import generateSwapChoiceSetsForItem from './generateSwapChoiceSetsForItem';

export default function augmentItems(
  baseItems: _Items,
  baseChoiceSets: ChoiceSets,
  ingredientFamilies: BoostIngredientFamilies,
  sharedChoiceSetIds: string[],
): [_Items, ChoiceSets] {
  const augmentedItems: _Items = {};
  let allSwapChoiceSets: ChoiceSets = {};

  console.groupCollapsed('generateSwapChoiceSetsForItems');
  lodash.values(baseItems).forEach(baseItem => {
    const [
      itemSwapChoiceSets,
      nonSwapBaseNames,
    ] = generateSwapChoiceSetsForItem(
      baseItem,
      baseChoiceSets,
      ingredientFamilies,
    );

    allSwapChoiceSets = { ...allSwapChoiceSets, ...itemSwapChoiceSets };

    const description = nonSwapBaseNames.length
      ? `${nonSwapBaseNames.join(', ')}, `
      : '';

    augmentedItems[baseItem.id] = {
      ...baseItem,
      description,
      choiceSets: [...sharedChoiceSetIds, ...lodash.keys(itemSwapChoiceSets)],
    };
  });

  console.groupEnd();

  return [augmentedItems, allSwapChoiceSets];
}
