import { ActionType, getType } from 'typesafe-actions';

import * as currentOrder from '../../actionCreators/currentOrder';

const initialState = null;

export default function (
  state: string | null = null,
  action: ActionType<typeof currentOrder>,
): string | null {
  switch (action.type) {
    case getType(currentOrder.resetOrder): {
      return initialState;
    }
    case getType(currentOrder.setDeliveryTime): {
      const { value } = action.payload;
      return value || null;
    }
    case getType(currentOrder.applyBufferData): {
      const { deliveryTime } = action.payload.value;
      return deliveryTime === undefined ? state : deliveryTime;
    }
    default:
      return state;
  }
}
