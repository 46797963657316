import { createCustomAction } from 'typesafe-actions';

import {
  standardEmptyACC,
  standardFlowACC,
  getStandardFailedWithReasonACC,
} from '../../utils/sagas';

import * as fetchPOSPoints from './fetchPOSPoints';

import { FAILURE_REASON } from '../../constants';

const REQUESTED = '$APPLY_BARCODE/REQUESTED';
const BLOCKED = '$APPLY_BARCODE/BLOCKED';
const APPROVED = '$APPLY_BARCODE/APPROVED';
const SUCCEEDED = '$APPLY_BARCODE/SUCCEEDED';
const FAILED = '$APPLY_BARCODE/FAILED';

const request = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      barcode: string;
      locationId?: string;
    }>(type);
};

const succeed = <T>(type: T) => {
  // prettier-ignore
  return standardFlowACC<
    T,
    {
      member?: Member;
      offer?: Offer;
      giftCard?: _GiftCard;
      itemDetails?: ItemLookupResult;
      itemAdded?: boolean;
    }>(type);
};

export const events = {
  REQUESTED,
  BLOCKED,
  APPROVED,
  SUCCEEDED,
  FAILED,
};

export type FailureReason =
  | undefined
  | fetchPOSPoints.FailureReason
  | FAILURE_REASON.PLU_NOT_FOUND;

export const actions = {
  requested: createCustomAction(REQUESTED, request),
  approved: createCustomAction(APPROVED, request),
  blocked: createCustomAction(BLOCKED, standardEmptyACC),
  succeeded: createCustomAction(SUCCEEDED, succeed),
  failed: createCustomAction(
    FAILED,
    getStandardFailedWithReasonACC<FailureReason>(),
  ),
};

export default actions.requested;
