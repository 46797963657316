import { ActionType, getType } from 'typesafe-actions';
import { ORDERING_PROVIDER } from '../../constants/orderingProvider';

import * as config from '../../actionCreators/config';

export default function (
  state: ORDERING_PROVIDER | null = ORDERING_PROVIDER.REDCAT,
  action: ActionType<typeof config>,
): ORDERING_PROVIDER | null {
  switch (action.type) {
    case getType(config.setOrderingProvider): {
      const { value } = action.payload;
      return value || null;
    }
    default:
      return state;
  }
}
