import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { RiCloseCircleFill } from 'react-icons/ri';

import combineStyles from '../utils/combineStyles';

import getThemeLookup from '../selectors/getThemeLookup';

import { TEXT_PROPERTIES } from '../utils/theme';

import { OrderingSelectors } from 'polygon-ordering';

import Text from './Text';
import TouchableOpacity from './TouchableOpacity';
import StandardButton from './StandardButton';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import calculateStockBalanceData from '../utils/calculateStockBalanceData';
import { enqueueWarningSnackbar } from '../utils/snackbar';
import { OrderingHooks } from 'polygon-ordering';

const { useFormattedCurrency } = OrderingHooks;

const { getStagedPurchases, getStockBalances } = OrderingSelectors;

const ClearButton: React.FC<{ onClick: () => void; size?: number }> = ({ onClick, size = 20 }) => (
  <TouchableOpacity
    onClick={onClick}
    style={combineStyles(styles.clearButtonContainer, {
      top: -size / 2,
      right: -size / 2,
    })}
    isScrollChild
    ariaLabel="clear"
  >
    <RiCloseCircleFill
      style={{
        fontSize: size,
        color: 'black',
      }}
    />
  </TouchableOpacity>
);

interface ChoiceWithQuantity extends Choice {
  quantity?: number;
}

const Tag: React.FC<{
  choice: ChoiceWithQuantity;
  choiceSet: ValidatedChoiceSet;
  adjustChoice: (params: AdjustChoice) => void;
}> = ({ choice, adjustChoice, choiceSet }) => {
  const p = useAppSelector(getThemeLookup);
  const singular = choiceSet.max === 1;
  const { t } = useTranslation();
  const stagedPurchases = useAppSelector(getStagedPurchases);
  const stockBalances = useAppSelector(getStockBalances);

  const baseTagStyle = combineStyles(
    p('tagButton', TEXT_PROPERTIES),
    Boolean(choice.quantity) && p('selectedTagButton', TEXT_PROPERTIES),
  );

  const primaryTagStyle = combineStyles(
    baseTagStyle,
    Boolean(choice.quantity) && {
      textShadow: `0px 0px 1px ${baseTagStyle.color || 'black'}`,
    },
  );

  const emphasised = { fontWeight: '900' }; // maximum

  const hiddenTagStyle = combineStyles(baseTagStyle, emphasised, { color: 'transparent' });

  const labelBase = String(choice.name);

  const choicePrice = useFormattedCurrency(choice.baseMoneyPrice);

  const price = choice.baseMoneyPrice ? ` (${choicePrice})` : '';

  let stockBalanceData = calculateStockBalanceData(choice.id);
  useEffect(() => {
    stockBalanceData = calculateStockBalanceData(choice.id);
  }, [stockBalances, stagedPurchases]);

  // const revertSoldOutThresholdToLowInStock =
  //   stockBalanceData.stockBalanceThreshold === 'STOCK_BALANCE_THRESHOLD_0' &&
  //   stockBalanceData.itemBalance !== 0
  //     ? 'STOCK_BALANCE_THRESHOLD_1'
  //     : stockBalanceData.stockBalanceThreshold;

  const thresholdLabelColor = combineStyles(
    Boolean(stockBalanceData.stockBalanceThreshold) &&
      p(stockBalanceData.stockBalanceThreshold!, ['color']),
    Boolean(choice.quantity) && p('selectedTagButton', TEXT_PROPERTIES),
  );

  const checkIfDisabled = stockBalanceData.soldOut || stockBalanceData.soldOutByCartSelections;

  // NOTE: all this comlication is to ensure that the tag doesn't resize
  // as you select it/increase the quantity.

  // This is done by having hidden text that is approximately the same size,
  // which is removed when the quantity is > 0

  let label =
    choice.quantity && !singular ? (
      <Text
        style={combineStyles(
          primaryTagStyle,
          Boolean(checkIfDisabled) && styles.ChoiceSetTagOpacity,
        )}
      >
        {labelBase}
        <Text
          style={combineStyles(
            primaryTagStyle,
            emphasised,
            Boolean(checkIfDisabled) && styles.ChoiceSetTagOpacity,
          )}
        >
          {price}
        </Text>
        <Text
          style={combineStyles(
            primaryTagStyle,
            emphasised,
            Boolean(checkIfDisabled) && styles.ChoiceSetTagOpacity,
          )}
        >{` × ${choice.quantity}`}</Text>

        {stockBalanceData.stockBalanceThreshold && (
          <Text style={combineStyles(primaryTagStyle, emphasised, thresholdLabelColor)}>
            {' - ' + t('stockBalanceThreshold.' + stockBalanceData.stockBalanceThreshold)}
          </Text>
        )}
      </Text>
    ) : (
      <>
        <Text
          value="× "
          style={combineStyles(
            hiddenTagStyle,
            Boolean(checkIfDisabled) && styles.ChoiceSetTagOpacity,
          )}
          ariaHidden
        />
        <Text
          style={combineStyles(
            primaryTagStyle,
            Boolean(checkIfDisabled) && styles.ChoiceSetTagOpacity,
          )}
        >
          {labelBase}
        </Text>
        <Text
          style={combineStyles(
            primaryTagStyle,
            emphasised,
            Boolean(checkIfDisabled) && styles.ChoiceSetTagOpacity,
          )}
        >
          {price}
        </Text>
        {stockBalanceData.stockBalanceThreshold && (
          <Text style={combineStyles(primaryTagStyle, emphasised, thresholdLabelColor)}>
            {' - ' + t('stockBalanceThreshold.' + stockBalanceData.stockBalanceThreshold)}
          </Text>
        )}

        <Text
          value=" 1"
          style={combineStyles(
            hiddenTagStyle,
            Boolean(checkIfDisabled) && styles.ChoiceSetTagOpacity,
          )}
          ariaHidden
        />
      </>
    );

  const dispatch = useAppDispatch();
  return (
    <div style={styles.tag}>
      <StandardButton
        themeKey="tagButton"
        label={label}
        ariaLabel={labelBase}
        disabled={checkIfDisabled!}
        avertDefaultOpacity={{ opacity: 1 }}
        onClick={() => {
          let shouldClear;

          if (choiceSet.max && choice.quantity === choiceSet.max) {
            shouldClear = true;
          }
          if (choiceSet.individualMax && choice.quantity === choiceSet.individualMax) {
            shouldClear = true;
          }

          if (!shouldClear && choice.quantity! + 1 > stockBalanceData.cartAdjustedBalance!) {
            enqueueWarningSnackbar(t('stagedChoiceSetItemQuantityWarningMessage'));
          } else {
            dispatch(
              adjustChoice({
                targetChoiceSetId: choiceSet.key as string,
                targetChoiceId: choice.id,
                clear: shouldClear,
              }),
            );
          }
        }}
        containerStyle={
          choice.quantity ? p('selectedTagButton', ['backgroundColor', 'border']) : undefined
        }
        isScrollChild
      />

      {Boolean(choice.quantity) && choiceSet.max !== 1 && (
        <ClearButton
          onClick={() =>
            dispatch(
              adjustChoice({
                targetChoiceSetId: choiceSet.key as string,
                targetChoiceId: choice.id,
                clear: true,
              }),
            )
          }
        />
      )}
    </div>
  );
};

const ChoiceSetTags: React.FC<{
  choiceSet: ValidatedChoiceSet;
  adjustChoice: (params: AdjustChoice) => void;
}> = ({ choiceSet, adjustChoice }) => {
  return (
    <div style={styles.mainContainer}>
      {choiceSet.choices.map((choice: Choice) => (
        <Tag key={choice.id} choice={choice} choiceSet={choiceSet} adjustChoice={adjustChoice} />
      ))}
    </div>
  );
};

const styles: Styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '20px 20px 26px 20px',
  },
  tag: {
    marginRight: 5,
    marginBottom: 10,
    position: 'relative',
  },

  clearButtonContainer: {
    position: 'absolute',
    borderRadius: 9999,
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ChoiceSetTagOpacity: {
    // background: 'rgba(255, 255, 9, 0.3)',
    opacity: 0.5,
  },
};

export default ChoiceSetTags;
