import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RiCloseCircleFill } from 'react-icons/ri';
import reduxConnect from '../utils/reduxConnect';
import combineStyles from '../utils/combineStyles';
import { OrderingSelectors, OrderingHooks } from 'polygon-ordering';
import getThemeLookup from '../selectors/getThemeLookup';
import { TEXT_PROPERTIES } from '../utils/theme';
import Text from './Text';
import TouchableOpacity from './TouchableOpacity';
import StandardButton from './StandardButton';
import calculateStockBalanceData from '../utils/calculateStockBalanceData';
import { enqueueWarningSnackbar } from '../utils/snackbar';

const { useFormattedCurrency } = OrderingHooks;
const { getStagedPurchases, getStockBalances } = OrderingSelectors;

const ClearButton = ({ onClick, size = 20 }) => (
  <TouchableOpacity
    onClick={onClick}
    style={combineStyles(styles.clearButtonContainer, {
      top: -size / 2,
      right: -size / 2,
    })}
    isScrollChild
    ariaLabel="clear"
  >
    <RiCloseCircleFill
      style={{
        fontSize: size,
        color: 'black',
      }}
    />
  </TouchableOpacity>
);

const StagedTag = ({
  p,
  choice,
  adjustChoice,
  choiceSet,
  stagedQty,
  stagedPurchases,
  stockBalances,
}) => {
  const singular = choiceSet.max === 1;

  const { t } = useTranslation();

  const baseTagStyle = combineStyles(
    p('tagButton', TEXT_PROPERTIES),
    Boolean(choice.quantity) && p('selectedTagButton', TEXT_PROPERTIES),
  );

  const primaryTagStyle = combineStyles(
    baseTagStyle,
    Boolean(choice.quantity) && {
      textShadow: `0px 0px 1px ${baseTagStyle.color || 'black'}`,
    },
  );

  const emphasised = { fontWeight: '900' }; // maximum

  const hiddenTagStyle = combineStyles(baseTagStyle, emphasised, { color: 'transparent' });

  const labelBase = String(choice.name);
  const baseMoneyPrice = useFormattedCurrency(choice.baseMoneyPrice);

  const price = choice.baseMoneyPrice ? ` (${baseMoneyPrice})` : '';

  let stockBalanceData = calculateStockBalanceData(choice.id);
  useEffect(() => {
    stockBalanceData = calculateStockBalanceData(choice.id);
  }, [stockBalances, stagedPurchases]);

  let allowedQuantitySelection = stockBalanceData.itemBalance;
  // if say x number of item quanities are already in cart then we should subtract that out of item balance
  if (stagedQty[choice.id]) {
    allowedQuantitySelection = allowedQuantitySelection - stagedQty[choice.id];
  }

  const soldOutByPreviousCartSelection = allowedQuantitySelection === 0 ? true : false;

  const revertSoldOutThresholdToLowInStock =
    stockBalanceData.stockBalanceThreshold === 'STOCK_BALANCE_THRESHOLD_0' &&
    stockBalanceData.itemBalance !== 0
      ? 'STOCK_BALANCE_THRESHOLD_1'
      : stockBalanceData.stockBalanceThreshold;

  const checkIfDisabled = stockBalanceData.soldOut || soldOutByPreviousCartSelection;

  const thresholdLabelColor = combineStyles(
    Boolean(!checkIfDisabled) &&
      Boolean(stockBalanceData.stockBalanceThreshold) &&
      p(revertSoldOutThresholdToLowInStock, ['color']),
    Boolean(checkIfDisabled) &&
      Boolean(stockBalanceData.stockBalanceThreshold) &&
      p(stockBalanceData.stockBalanceThreshold, ['color']),
    Boolean(choice.quantity) && p('selectedTagButton', TEXT_PROPERTIES),
  );

  // NOTE: all this comlication is to ensure that the tag doesn't resize
  // as you select it/increase the quantity.

  // This is done by having hidden text that is approximately the same size,
  // which is removed when the quantity is > 0

  // when a choice tag is selected, it should not show Sold Out as the customer could
  // purchase the last item hence revertSoldOutThresholdToLowInStock
  let label =
    choice.quantity && !singular ? (
      <Text
        style={combineStyles(
          primaryTagStyle,
          Boolean(checkIfDisabled) && styles.ChoiceSetTagOpacity,
        )}
      >
        {labelBase}
        <Text
          style={combineStyles(
            primaryTagStyle,
            emphasised,
            Boolean(checkIfDisabled) && styles.ChoiceSetTagOpacity,
          )}
        >
          {price}
        </Text>
        <Text
          style={combineStyles(
            primaryTagStyle,
            emphasised,
            Boolean(checkIfDisabled) && styles.ChoiceSetTagOpacity,
          )}
        >{` × ${choice.quantity}`}</Text>

        {stockBalanceData.stockBalanceThreshold && (
          <Text style={combineStyles(primaryTagStyle, emphasised, thresholdLabelColor)}>
            {' - ' + t('stockBalanceThreshold.' + revertSoldOutThresholdToLowInStock)}
          </Text>
        )}
      </Text>
    ) : (
      <>
        <Text
          value="× "
          style={combineStyles(
            hiddenTagStyle,
            Boolean(checkIfDisabled) && styles.ChoiceSetTagOpacity,
          )}
          ariaHidden
        />
        <Text
          style={combineStyles(
            primaryTagStyle,
            Boolean(checkIfDisabled) && styles.ChoiceSetTagOpacity,
          )}
        >
          {labelBase}
        </Text>
        <Text
          style={combineStyles(
            primaryTagStyle,
            emphasised,
            Boolean(checkIfDisabled) && styles.ChoiceSetTagOpacity,
          )}
        >
          {price}
        </Text>

        {stockBalanceData.stockBalanceThreshold && !checkIfDisabled && (
          <Text style={combineStyles(primaryTagStyle, emphasised, thresholdLabelColor)}>
            {' - ' + t('stockBalanceThreshold.' + revertSoldOutThresholdToLowInStock)}
          </Text>
        )}

        {stockBalanceData.stockBalanceThreshold && checkIfDisabled && (
          <Text style={combineStyles(primaryTagStyle, emphasised, thresholdLabelColor)}>
            {' - ' + t('stockBalanceThreshold.' + stockBalanceData.stockBalanceThreshold)}
          </Text>
        )}

        <Text
          value=" 1"
          style={combineStyles(
            hiddenTagStyle,
            Boolean(checkIfDisabled) && styles.ChoiceSetTagOpacity,
          )}
          ariaHidden
        />
      </>
    );

  return (
    <div style={styles.tag}>
      <StandardButton
        themeKey="tagButton"
        label={label}
        ariaLabel={labelBase}
        disabled={checkIfDisabled}
        avertDefaultOpacity={{ opacity: 1 }}
        onClick={() => {
          let shouldClear;

          if (choiceSet.max && choice.quantity === choiceSet.max) {
            shouldClear = true;
          }
          if (choiceSet.individualMax && choice.quantity === choiceSet.individualMax) {
            shouldClear = true;
          }

          if (!shouldClear && choice.quantity + 1 > allowedQuantitySelection) {
            enqueueWarningSnackbar(t('stagedChoiceSetItemQuantityWarningMessage'));
          } else {
            adjustChoice({
              targetChoiceSetId: choiceSet.key,
              targetChoiceId: choice.id,
              clear: shouldClear,
            });
          }
        }}
        containerStyle={
          choice.quantity ? p('selectedTagButton', ['backgroundColor', 'border']) : undefined
        }
        isScrollChild
      />

      {Boolean(choice.quantity) && choiceSet.max !== 1 && (
        <ClearButton
          onClick={() => {
            adjustChoice({
              targetChoiceSetId: choiceSet.key,
              targetChoiceId: choice.id,
              clear: true,
            });
          }}
        />
      )}
    </div>
  );
};

const StagedChoiceSetTags = ({ p, choiceSet, adjustChoice, stagedQty }) => {
  return (
    <div style={styles.mainContainer}>
      {choiceSet.choices.map(choice => (
        <StagedTag
          key={choice.id}
          p={p}
          choice={choice}
          choiceSet={choiceSet}
          adjustChoice={adjustChoice}
          stagedQty={stagedQty}
        />
      ))}
    </div>
  );
};

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '20px 20px 26px 20px',
  },
  tag: {
    marginRight: 5,
    marginBottom: 10,
    position: 'relative',
  },

  clearButtonContainer: {
    position: 'absolute',
    borderRadius: 9999,
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ChoiceSetTagOpacity: {
    opacity: 0.5,
  },
};

export default reduxConnect({
  mapStateToProps: {
    p: getThemeLookup,
    stagedPurchases: getStagedPurchases,
    stockBalances: getStockBalances,
  },
  component: StagedChoiceSetTags,
});
