import { ActionType, getType } from 'typesafe-actions';

import { resetOrder } from '../../actionCreators/currentOrder';

import { actions as setGiftCard } from '../../actionCreators/flows/setGiftCard';
import { actions as fetchGiftCard } from '../../actionCreators/flows/fetchGiftCard';
import { actions as applyBarcode } from '../../actionCreators/flows/applyBarcode';

const initialState = {
  number: '',
  pin: '',
  moneyBalance: null,
  active: null,
  expiryDate: null,
};

export default function (
  state: _GiftCard = initialState,
  action: ActionType<
    | typeof setGiftCard
    | typeof fetchGiftCard
    | typeof applyBarcode
    | typeof resetOrder
  >,
): _GiftCard {
  switch (action.type) {
    case getType(resetOrder): {
      return initialState;
    }
    case getType(fetchGiftCard.succeeded):
    case getType(applyBarcode.succeeded): {
      const { giftCard } = action.payload;

      return { ...state, ...giftCard };
    }
    case getType(setGiftCard.succeeded): {
      const { giftCard } = action.payload;

      if (giftCard === null) {
        return initialState;
      }

      return {
        pin: state.pin,
        number: state.number,
        ...giftCard,
      };
    }
    default:
      return state;
  }
}
